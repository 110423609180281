import Vue from 'vue'

import initialState from './state'

export default {
  push(state, payload) {
    state[payload.state].push(payload.value)
  },
  setUnauthorizedlyAccessedRoute(state, payload) {
    state.unauthorizedlyAccessedRoute = payload
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
