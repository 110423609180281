import jwtDecode from 'jwt-decode'

import { requestLanguageStatics } from '@/i18n'
import router from '@/router'
import store from '@/store'

/* eslint-disable class-methods-use-this */
class TokenManager {
  constructor() {
    this.TTL = Number(process.env.VUE_APP_JWT_REFRESH_TTL)
  }

  getToken() {
    return this._store.state.auth.token
  }

  async handleExpiredToken() {
    // Sentry errors
    await this._store.dispatch('auth/logout', true)

    // When a token is expired and we have been logged out, we need to re-initialize
    // the language statics files, otherwise the labels would have missing translations
    // as they are cleared from the store when auth/logout event is dispatched.
    requestLanguageStatics()

    if (this._router.history.current.name !== 'AuthLogin') {
      // Avoided redundant navigation to current location => .catch(() => {})
      this._router.replace({ name: 'AuthLogin', params: { isExpiredSession: true } })
    }
  }

  isTokenAlive(token) {
    return new Promise((resolve, reject) => {
      const { exp } = jwtDecode(token)
      const currentTime = Date.now() / 1000

      if (exp > currentTime) {
        resolve(false) // isn't TTL
      } else if (exp + this.TTL > currentTime) {
        resolve(true) // is TTL
      } else {
        reject()
      }
    })
  }

  handleToken(shouldWaitForToken) {
    return new Promise((resolve, reject) => {
      const token = this.getToken()

      this.isTokenAlive(token)
        .then(isTTL => {
          if (!isTTL) {
            return resolve({
              token,
              shouldRequestBePaused: false,
            })
          }

          if (shouldWaitForToken) {
            return this.refreshToken(token)
                    .then(newToken => resolve(newToken))
                    .catch(err => reject(err))
          }

          this.refreshToken(token)

          resolve({ shouldRequestBePaused: true })
        })
        .catch(() => {
          this.handleExpiredToken()
          reject()
        })
    })
  }

  refreshToken(token) {
    this._store.commit('auth/setToken', null)

    return this._store.dispatch('auth/refreshToken', token)
  }

  get _router() {
    return router
  }

  get _store() {
    return store
  }
}

export default TokenManager
