class CoachNotInTeamError extends Error {
  constructor() {
    super(
      `Coach assigned to your account is
      no longer available in your program.
      Please contact us for assistance
      at <a href='mailto:customersupport@changinghealth.com'>
      customersupport@changinghealth.com</a>`,
    )

    this.title = 'Your coach isn\'t available'
  }
}

export default CoachNotInTeamError
