<template>
  <transition
    :name="'to-' + direction"
    @before-enter="beforeEnter"
    @enter="enter"
    @before-leave="beforeLeave"
    @leave="leave"
  >
    <div
      v-show="isVisible"
      class="stepper-custom-step"
      :class="{ 'stepper-custom-step--visible': isVisible }"
      data-testid="stepper-step"
      v-merge-attr:data-testid
      @click="onClick"
    >
      <div
        class="stepper-custom-step__header">
        <h2
          v-if="title"
          :id="`step_header_${uuid}`"
          class="stepper-custom-step__title"
          data-testid="stepper-step-heading"
          :class="{'nhsuk-u-margin-bottom-2': description}"
        >
          {{ fullTitle }}
        </h2>

        <span
          v-if="!hideStepCount"
          class="stepper-custom-step__progress-indicator"
          data-testid="stepper-step-progress"
        >
            {{ progressState }}
        </span>
      </div>
      <p
        v-if="description"
        class="nhsuk-u-margin-bottom-4">
        {{ description }}
      </p>

      <template
        v-if="appendValidator"
      >
        <validation-observer
          ref="observer"
          :tag="'div'"
        >
          <slot
            v-bind="{ uuid }"
          />
        </validation-observer>
      </template>

      <slot
        v-else
        v-bind="{ uuid }"
      />
    </div>
  </transition>
</template>

<script>
  import { ValidationObserver } from 'vee-validate'

  import mergeAttr from '@/directives/mergeAttr'

  import observerRegistration from '@/mixins/stepper-form-integration/observerRegistration'

  import eventBus from '@/tools/event-bus'

  const duration = 400

  export default {
    inheritAttrs: false,

    components: {
      ValidationObserver,
    },

    directives: {
      mergeAttr,
    },

    inject: {
      stepperData: {},
      stepperFormPresent: { default: false },
    },

    mixins: [
      observerRegistration,
    ],

    props: {
      alwaysShowProgress: {
        type: Boolean,
        default: false,
      },
      appendValidator: {
        type: Boolean,
        default() {
          return this.stepperFormPresent
        },
      },
      hideStepCount: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      useTitleNumeration: {
        type: Boolean,
        default: true,
      },
      validationEnabled: {
        type: Boolean,
        default: true,
      },
      disableTransitionEffect: {
        type: Boolean,
        default: false,
      },
      forceStepIndex: {
        type: Number,
        default: -1,
      },
      description: {
        type: String,
        default: '',
      },
      showTitleNumeration: {
        type: Boolean,
        default: true,
      },
    },

    mounted() {
      if (this.$parent.$vnode.tag.indexOf('stepper-custom') === -1) {
        throw new Error('StepperCustomStep component can only be used inside a StepperCustom')
      }

      this.$parent.registerChild(this)
    },

    beforeDestroy() {
      this.$parent.unregisterChild(this.thisStep)
      this.unregisterObserver()
    },

    watch: {
      currentStep(newStep, oldStep) {
        this.direction = newStep > oldStep ? 'left' : 'right'
      },
    },

    data() {
      return {
        direction: '',
        maxStep: -1,
        thisStep: -1,
        wrapperMinHeightPx: null,
      }
    },

    methods: {
      beforeEnter(el) {
        el.style.height = '0'
      },
      enter(el) {
        el.style.height = 'auto'
        this.$emit('step-transition', { height: el.scrollHeight, type: 'enterHeight' })
        setTimeout(() => {
          this.$emit('entered')
        }, duration)
      },
      beforeLeave(el) {
        el.style.height = 'auto'
      },
      leave(el) {
        this.$emit('step-transition', { height: el.scrollHeight, type: 'leaveHeight' })
        this.$emit('left')
      },
      onClick() {
        eventBus.$emit('stepper-click')
      },
    },

    computed: {
      currentStep() {
        return this.stepperData.currentStep
      },
      isVisible() {
        return this.thisStep === this.currentStep
      },
      stepPosition() {
        return { transform: `translateX(${this.currentStep * (-100)}%)` }
      },
      fullTitle() {
        return (this.showTitleNumeration ? this.titleNumeration : '') + this.$t(this.title)
      },
      titleNumeration() {
        return this.useTitleNumeration ? `${this.thisStep + 1}. ` : ''
      },
      progressState() {
        return (this.maxStep > 0 || this.alwaysShowProgress)
          ? this.$t(`${this.$options.slug}.progress`, {
            currentStep: this.currentStep + 1,
            allSteps: this.maxStep + 1,
          }) : ''
      },
    },

    slug: 'component.stepper-custom.stepper-custom-step',
  }
</script>

<style lang="scss">
  $stepper-animation-time: .4s;

  .stepper-custom-step {
    flex: 1 0 100%;
    align-self: flex-start;
    height: 0;
    padding: 0 10px;
    opacity: 0;
    transition: opacity $stepper-animation-time;
    &--visible {
      height: auto;
      opacity: 1;
    }
    &__header {
      display: grid;
      grid-template-columns: 1fr auto;
    }
    &__title {
      margin: 0 0 3rem;
      padding-right: 1rem;
      font-size: 1.8rem;
      @include min-lg {
        font-size: 2.2rem;
      }
    }
    &__progress-indicator {
      font-size: 1.2rem;
      color: color(dark-grey);
    }
  }

  .to-left-enter {
    transform: translateX(0);
  }
  .to-left-enter-to {
    transform: translateX(-100%);
  }

  .to-left-leave {
    transform: translateX(0);
  }
  .to-left-leave-to {
    transform: translateX(-100%);
  }

  .to-right-enter {
    transform: translateX(-100%);
  }
  .to-right-enter-to {
    transform: translateX(0);
  }

  .to-right-leave {
    transform: translateX(-100%);
    opacity: 1;
  }
  .to-right-leave-to {
    transform: translateX(0%);
    transition: transform $stepper-animation-time,
      opacity $stepper-animation-time;
  }

  .to-left-enter-active,
  .to-left-leave-active,
  .to-right-enter-active {
    transition: transform $stepper-animation-time,
      opacity $stepper-animation-time;
  }
</style>
