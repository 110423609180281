import {
  addManyNewLegalDocuments,
  addNewLegalDocument,
  removeLegalDocument,
} from '@/store/modules/legal-document/echoMethods'

const LISTEN_METHODS = {
  DocumentVersionCreated: addNewLegalDocument,
  DocumentsAssigned: addManyNewLegalDocuments,
  TcAccepted: removeLegalDocument,
}

const MUTATION_TYPES = [
  'auth/logout',
  'auth/resetState',
  'user/setUser',
  'user/decodeUser',
  '_package/setPackage',
]

const TYPES = {
  documents_assigned: 'DocumentsAssigned',
  document_version_created: 'DocumentVersionCreated',
  tcaccept: 'TcAccepted',
}

const CHANNEL_TYPES = {
  DocumentsAssigned: 'channel',
  DocumentVersionCreated: 'channel',
  TcAccepted: 'private',
}

const GETTERS = {
  documents_assigned: '_package/packageId',
  document_version_created: '_package/packageId',
  tcaccept: 'user/getCurrentUserId',
}

export default (ctx, store, echo) => {
  const uids = {
    documents_assigned: null,
    document_version_created: null,
    tcaccept: null,
  }

  const _getId = type => store.getters[GETTERS[type]]

  const _updateUid = (newUid, type) => {
    const oldUid = uids[type]

    if (oldUid != newUid) {
      if (oldUid) {
        echo.leave(`${type}.${oldUid}`)
      }

      if (newUid) {
        const event = TYPES[type]
        const channel = CHANNEL_TYPES[event]

        echo[channel](`${type}.${newUid}`)
          .listen(event, data => LISTEN_METHODS[event](data, ctx))
      }

      uids[type] = newUid
    }
  }

  const _updateUids = () => {
    Object.keys(TYPES).forEach(type => _updateUid(_getId(type), type))
  }

  store.subscribe(mutation => {
    const { type } = mutation

    if (MUTATION_TYPES.includes(type)) {
      _updateUids()
    }
  })

  _updateUids()
}
