import customEnGb from './custom/en_GB'

import messagesEnGb from './messages/en_GB'

export default {
  en_GB: {
    custom: customEnGb,
    messages: messagesEnGb,
  },
}
