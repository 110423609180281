<script>
  export default {
    functional: true,

    render(h, { children }) {
      return h(
        'transition', {
          props: {
            mode: 'out-in',
            name: 'scale',
          },
        }, children,
      )
    },
  }
</script>

<style lang="scss">
  .scale-enter-active {
    transition: all 0.1s ease-in-out;
  }
  .scale-enter {
    transform: scale(0.8);
    opacity: 0;
  }
</style>
