export default {
  browser: '',
  hasFixedLoadingIndicator: false,
  isCordova: window.cordova !== undefined,
  isFabOpen: false,
  isFooterVisible: false,
  isFloatingButtonOpen: false,
  isMobile: false,
  isNavigationDrawerOpen: false,
  isPWA: false,
  isTouch: Boolean('ontouchstart' in window || navigator.msMaxTouchPoints),
  isGlobalAriaHidden: false,
  menu: {
    isOpen: false,
    trigger: '',
  },
  navigationDrawer: {
    isOpen: false,
    trigger: '',
    isValid: false,
  },
  os: '',
  wW: window.innerWidth,
  isStepperContentVisible: false,
}
