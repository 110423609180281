<template>
  <base-main
    class="delete-account-view"
    data-testid="delete-account-view"
  >
    <div class="delete-account-view__wrapper">
      <stepper-custom-form
        ref="form"
        data-testid="delete-account-form"
        class="delete-account-form"
        :buttonLabels="{
          nextLabel: 'Next',
          previousLabel: 'Back',
          hasCancel: true,
          indexWithCancel: [6]
        }"
        :isNextDisabled="isDisabled"
        :displayLastButtons="false"
        @next="onNext"
        @previous="onPrevious"
        @cancel="goBack"
      >
        <template #default>
          <stepper-custom-step
            data-testid="delete-account-step-info"
            :title="$t(`${$options.slug}.title`)"
          >
            <p>
              We are sorry to see you go. Before you do, please provide some short feedback.
              Every bit of feedback received helps to improve the programme for future users.
            </p>
          </stepper-custom-step>
          <stepper-custom-step
            v-for="({ title, fields, ...action }, i) in stepperActions"
            :key="i"
            data-testid="delete-account-stepper-custom-step"
            :title="title"
          >
            <div
              aria-live="polite"
              class="delete-account__content"
              >
              <template v-if="currentStep === 2">
                <template v-for="(field, j) in fields">
                  <form-fields-generator
                    v-if="(j === 1 && form.question_1 === 4) || j !== 1"
                    :class="{deleteMarginTop: j === 2 }"
                    :key="j"
                    :dataModel="form"
                    :formFields="[field]"
                  />
                </template>
              </template>
              <form-fields-generator
                v-else
                :dataModel="form"
                :formFields="fields"
              />

              <form-fields-generator
                v-if="form.question_1 === 8 && i === 0"
                :dataModel="form"
                :formFields="fieldQuestion1Desc"
              />
            </div>
          </stepper-custom-step>
          <stepper-custom-step
            data-testid="delete-account-step-info"
            :title="'Are you sure you want to delete your account?'"
          >
            <p>
              Deleting your account will delete all of your personal and identifiable data, and you
              will not be able to access this programme any more.
            </p>
            <p class="nhsuk-u-margin-top-2">
              Anonymised usage and activity data may be retained for programme improvement
              purposes, even after your account is deleted.
            </p>
            <h3 class="nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-0">
              <b>Requesting removal of your anonymised data</b>
            </h3>
            <p class="nhsuk-u-margin-top-2">
              If you wish your anonymised data to be removed, send an email to our Support team at
              <a href="mailto:support@changinghealth.com">
                support@changinghealth.com
              </a>
            with the following heading:</p>
            <p class="nhsuk-u-margin-top-3 nhsuk-heading-m blue-text">
              <b>Request to remove anonymised data for user PPUID: {{ getCurrentUserId }}</b>
            </p>
            <p class="nhsuk-u-margin-top-3">
              You may wish to note these details down now, as you will not be able
              to return to this page if you continue with account deletion.</p>
          </stepper-custom-step>
          <stepper-custom-step
            data-testid="delete-account-step-info"
            class="delete-account-last-step"
            :title="'Are you sure you want to delete your account?'"
          >
            <p>
              If you proceed then this action cannot be undone.
            </p>
            <div class="stepper-buttons">
              <base-button
                ref="leftButton"
                light
                class="stepper-buttons__left"
                data-testid="stepper-buttons-left"
                @click="goBack"
              >
                {{ 'Cancel' }}
              </base-button>

              <base-button
                ref="rightButton"
                :key="'submit'"
                danger
                class="stepper-buttons__right"
                data-testid="stepper-buttons-right"
                :type="'submit'"
                @click="deleteMyAccount"
              >
                {{ 'Yes, delete my account' }}
              </base-button>
            </div>
          </stepper-custom-step>
        </template>
      </stepper-custom-form>
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import { BaseButton } from '@/components/base'
  import BaseMain from '@/components/base/BaseMain'
  import FormFieldsGenerator from '@/components/forms/form-fields-generator/FormFieldsGenerator'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'
  import goBack from '@/mixins/goBack'

  export default {

    components: {
      BaseMain,
      StepperCustomForm,
      StepperCustomStep,
      FormFieldsGenerator,
      BaseButton,
    },

    mixins: [
      goBack,
    ],

    data() {
      return {
        currentStep: 1,
        form: {
          reason: null,
          question_1: '',
          question_1_detail: '',
          question_1_desc: '',
          question_2: '',
          question_3: '',
          question_4: '',
        },
        optionsStep1_init: [
          { value: 1, label: 'I have achieved my goals' },
          { value: 2, label: 'I did not find the programme content helpful' },
          { value: 3, label: 'I have found a more appropriate programme' },
          { value: 4, label: 'I had technical difficulties using the programme' },
        ],
        optionsStep1_textarea: 'Please provide detail of the technical difficulties you encountered:',
        optionsStep1_last: [
          { value: 5, label: 'I was not clear how to use the programme' },
          { value: 6, label: 'It is missing content or features I need' },
          { value: 7, label: 'I was unable to get technical support when I needed it' },
          { value: 8, label: 'Other (please explain below)' },
        ],
        optionsStep2: [
          { value: 1, label: 'Strongly Agree' },
          { value: 2, label: 'Agree' },
          { value: 3, label: "I'm not sure" },
          { value: 4, label: 'Disagree' },
          { value: 5, label: 'Strongly Disagree' },
        ],
        optionsStep3: [
          { value: 1, label: 'Strongly Agree' },
          { value: 2, label: 'Agree' },
          { value: 3, label: "I'm not sure" },
          { value: 4, label: 'Disagree' },
          { value: 5, label: 'Strongly Disagree' },
        ],
      }
    },

    methods: {
      ...mapActions('user', [
        'deleteAccount',
      ]),
      ...mapActions('auth', [
        'logout',
      ]),
      deleteMyAccount() {
        const reasons = [
          this.form.question_1 === 8
            ? this.form.question_1_desc
            : [...this.optionsStep1_init, ...this.optionsStep1_last]
              .find(option => option.value === this.form.question_1)?.label || '',
          this.optionsStep2.find(option => option.value === this.form.question_2)?.label || '',
          this.optionsStep3.find(option => option.value === this.form.question_3)?.label || '',
          this.form.question_4 || '',
          this.form.question_1 === 4 ? this.form.question_1_detail : '',
        ]

        this.deleteAccount(reasons)
          .then(this.logout)
          .then(this.$nextTick)
          .then(() => this.$router.push({ name: 'AuthLogin' }))
      },
      onNext() {
        this.currentStep += 1
      },
      onPrevious() {
        this.currentStep -= 1
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStateForAction',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
      ]),
      isDisabled() {
        if (this.currentStep === 2) {
          // check form.question_1 & question_1_detail if form.question_1 === 4
          if (!this.form.question_1) {
            return true
          }
        }
        return false
      },
      fieldQuestion1Desc() {
        return [
          {
            type: 'TextareaGroup',
            name: 'question_1_desc',
            props: {
              name: 'question_1_desc',
              labelKey: 'label',
              valueKey: 'value',
              testid: 'textarea-question_1_desc',
              maxLength: 200,
              validation: { required: true },
            },
          },
        ]
      },
      stepperActions() {
        return [
          {
            title: 'Why have you chosen to delete your account?',
            fields: [
              {
                type: 'RadioMultiGroup',
                name: 'question_1',
                props: {
                  name: 'question_1',
                  labelKey: 'label',
                  valueKey: 'value',
                  legend: 'Select from the following options:',
                  options: this.optionsStep1_init,
                  testid: 'question_1',
                  validation: { required: true },
                },
              },
              {
                type: 'TextareaGroup',
                name: 'question_1_detail',
                props: {
                  name: 'question_1_detail',
                  labelKey: 'label',
                  valueKey: 'value',
                  label: this.optionsStep1_textarea,
                  testid: 'question_1_detail',
                  validation: { required: true },
                },
              },
              {
                type: 'RadioMultiGroup',
                name: 'question_1',
                props: {
                  name: 'question_1',
                  labelKey: 'label',
                  valueKey: 'value',
                  options: this.optionsStep1_last,
                  testid: 'question_1',
                  // validation: { required: true },
                },
              },
            ],
          },
          {
            title: 'How much do you agree with the following statement? "The programme gave me the information I needed."',
            fields: [
              {
                type: 'RadioMultiGroup',
                name: 'question_2',
                props: {
                  name: 'question_2',
                  labelKey: 'label',
                  valueKey: 'value',
                  legend: 'Select from the following options:',
                  options: this.optionsStep2,
                  testid: 'question_2',
                  validation: { required: true },
                },
              },
            ],
          },
          {
            title: 'How much do you agree with the following statement? "I will recommend this programme to other people."',
            fields: [
              {
                type: 'RadioMultiGroup',
                name: 'question_3',
                props: {
                  name: 'question_3',
                  labelKey: 'label',
                  valueKey: 'value',
                  legend: 'Select from the following options:',
                  options: this.optionsStep3,
                  testid: 'question_3',
                  validation: { required: true },
                },
              },
            ],
          },
          {
            title: 'How could we improve the programme?',
            fields: [
              {
                type: 'TextareaGroup',
                name: 'question_4',
                props: {
                  name: 'question_4',
                  labelKey: 'label',
                  label: 'How could we improve the programme?',
                  valueKey: 'value',
                  testid: 'question_4',
                  // validation: { required: true },
                },
              },
            ],
          },
        ]
      },
      isLoading() {
        return this.getLoadingStateForAction('user/deleteAccount')
      },
    },

    slug: 'component.dialog.delete-account-dialog',
  }
</script>

<style lang="scss">
  .blue-text {
    display: flex !important;
    justify-content: center;
    b {
      color: color(secondary-text);
    }
  }
  .delete-account-view {
    &__wrapper {
      @include view-wrapper(63rem);
    }
    &__description {
      font-size: $font-size-p-sm;
      @include min-lg {
        font-size: $font-size-p-lg;
      }
    }

    .stepper-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 2rem;
    }

    .deleteMarginTop {
      fieldset, .nhsuk-hint {
        margin: 0;
      }
    }
  }
</style>
