import Vue from 'vue'

import initialState from './state'

export default {
  setUser(state, user) {
    const { updated_at: updatedAt } = state.user || {}
    const { updated_at: payloadUpdatedAt } = user || {}

    if (
      updatedAt
      && payloadUpdatedAt
      && Date.parse(updatedAt) > Date.parse(payloadUpdatedAt)
    ) {
      Object.entries(user).forEach(([key, value]) => {
        if (state.user[key] === undefined) {
          Vue.set(state.user, key, value)
        }
      })

      return
    }

    state.user = user
  },
  setUserOffsetTimezone(state, offsetTimezone) {
    Vue.set(state.user, 'timezoneOffset', offsetTimezone)
  },
  decodeUser(state, user) {
    if (!state.user) state.user = {}

    Object.entries(user).forEach(([key, value]) => Vue.set(state.user, key, value))
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
