<template>
  <modular-tile
    class="widget-coaching"
    :class="{ 'widget-coaching--is-single': isSingle }"
    data-testid="widget-coaching"
  >
    <div class="widget-coaching__top">
      <img
        alt=""
        class="widget-coaching__top-img"
        :src="widget.img"
      >
      <p
        class="widget-coaching__top-text"
        v-html="'Looking forward to our call!'"
      />
    </div>
    <div class="widget-coaching__content">
      <h2 class="widget-coaching__header">
        {{ widget.title }}
      </h2>
      <p
        class="widget-coaching__text"
        v-html="widget.description"
      />
      <div class="widget-coaching__date-time">
        <p
          class="nhsuk-u-font-size-24"
          v-html="widget.date"
        />
        <p
          class="nhsuk-u-font-size-24"
          v-html="widget.time"
        />
      </div>
    </div>
    <template #action>
      <div
        @keydown.exact.tab="$emit('tabOut', $event)"
        @keydown.shift.tab="$emit('shiftTabOut', $event)"
      >
        <base-anchor
          data-testid="widget-coaching-cta"
          :to="link"
          @click="onCtaClick"
        >
          {{ renderText }}
        </base-anchor>
      </div>
    </template>
  </modular-tile>
</template>

<script>
  import { capitalize } from 'lodash'
  import { mapActions, mapGetters } from 'vuex'

  import { BaseAnchor } from '@/components/base'
  import ModularTile from '@/components/tiles/modular-tile'

  import { requireImage } from '@/helpers'

  import matchMedia from '@/mixins/matchMedia'

  const IMAGE_POSITIONS = {
    0: 'top',
    1: 'side',
  }

  export default {
    components: {
      BaseAnchor,
      ModularTile,
    },

    mixins: [
      matchMedia({
        media: 'min-lg',
      }),
      matchMedia({
        media: 'max-md',
      }),
      matchMedia({
        media: 'min-md',
      }),
    ],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    methods: {
      ...mapActions('triggers', [
        'saveTriggerAccess',
      ]),
      onCtaClick() {
        if (!this.isPlugin) return

        this.saveTriggerAccess(this.widget.plugin_id)
      },
    },

    computed: {
      ...mapGetters('triggers', [
        'getTriggerRouteByPluginId',
      ]),
      image() {
        const images = {
          top: this.widget.vertical_orientation_icon?.url,
          side: this.widget.horizontal_orientation_icon?.url,
        }

        return images[this.imagePosition] ?? this.defaultImage
      },
      defaultImage() {
        const requirePluginImage = name => (
          requireImage(`static-dashboard/${name}-${this.isImageSingle ? 'single' : 'double'}.jpg`)
        )

        return this.isPlugin
          ? requirePluginImage('plugin-widget-default')
          : requirePluginImage('help-plugin')
      },
      imagePosition() {
        return this.isMinMd ? IMAGE_POSITIONS[this.orientation] : 'top'
      },
      isSingle() {
        return this.orientation === 0 && this.isMinMd
      },
      isImageSingle() {
        return this.orientation === 0 || this.isMaxMd
      },
      link() {
        return { name: 'CoachingPluginEditAppointment', params: { id: this.widget.idAppointment } }
      },
      orientation() {
        return this.widget.display_orientation
      },
      renderText() {
        return capitalize(this.widget.cta_button_text)
      },
    },
  }
</script>

<style lang="scss">
  $block: 'widget-coaching';

  .#{$block} {
    &__top {
      display: flex;

      &-img {
        width: 7rem;
        height: 7rem;
        // border: 2px solid color(primary);
        border-radius: 50%;
        object-fit: cover;
        margin-right: 3rem;
      }

      &-text {
        background-color: color(dark-primary);
        color: white;
        border-radius: 4rem;
        padding: 1.4rem;
        &::before {
          content: '';
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-right: 25px solid color(dark-primary);
          margin-left: -35px;
          position: absolute;
          margin-top: 14px;
        }
      }
    }
    &__date-time {
      display: flex;
      flex-direction: column;
      background-color: color(light-grey);
      padding: 1rem;
      margin-top: 1rem;
      width: 100%;
      p {
        color: color(primary-text);

        &:first-child {
          font-weight: 600;
        }
      }
    }
    &--is-single {
      .#{$block}__header {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
    &__content {
      margin-top: 1rem;
      width: 100%;
    }
    &__header {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 600;
      @include min-md {
        font-size: 2.1rem;
        margin: 0 0 0.2rem;
        text-transform: initial;
      }
    }
    .modular-tile {
      &__main {
        padding: 2.2rem;
      }
      &__header-row {
        margin: 0;
      }
      &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
</style>
