import rootCommit from '@/store/helpers/rootCommit'
import { api } from '@/tools/api'

export default {
  fetchProgramByPackage({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get(`api/public/packages/${id}/programs`)
        .then(response => {
          const [program] = response
          rootCommit('colors/setLoginColors', program.colors)
          resolve(program)
        })
        .catch(() => {
          reject()
        })
    })
  },
}
