import { PAGE_TYPES } from '@/constants'

import routeParamToNumber from '@/router/helpers/routeParamToNumber'

export default {
  properties: {
    dir: 'plugins/reading-room/',
    sharedMeta: {
      fetchProgram: false,
      // header: 'Find Answers',
      trigger: 'reading-room',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'ReadingRoom',
    },
  },
  routes: [
    {
      path: '/reading-room',
      name: 'ReadingRoom',
      meta: {
        header: 'Find Answers',
        layout: 'home',
        parent: 'Tools',
        toParent: true,
        pluginHome: true,
        fetchProgram: false,
      },
    },

    {
      path: '/reading-room/topic/:topicId',
      name: 'ReadingRoomTopic',
      props: routeParamToNumber('topicId'),
      meta: {
        layout: 'home',
        parent: 'ReadingRoom',
        header: ' ',
        toParent: true,
        fetchProgram: false,
      },
    },

    {
      path: '/reading-room/topic/:topicId/category/:categoryId',
      name: 'ReadingRoomCategory',
      props: routeParamToNumber('categoryId'),
      meta: {
        layout: 'home',
        parent: 'ReadingRoomTopic',
        header: ' ',
        toParent: true,
        fetchProgram: false,
      },
    },
    {
      path: '/reading-room/topic/:topicId/category/:categoryId/article/:id',
      name: 'ReadingRoomArticle',
      props: routeParamToNumber('id'),
      meta: {
        layout: 'home',
        parent: 'ReadingRoomCategory',
        header: ' ',
        toParent: true,
        type: PAGE_TYPES.cms,
        fetchProgram: false,
      },
    },
    {
      path: '/reading-room/:categoryId',
      name: 'ReadingRoomCategory',
      props: routeParamToNumber('categoryId'),
      meta: {
        layout: 'home',
        parent: 'Home',
        header: ' ',
        toParent: true,
        fetchProgram: false,
      },
    },
    {
      path: '/reading-room/:categoryId/article/:id',
      name: 'ReadingRoomArticle',
      props: routeParamToNumber('id'),
      meta: {
        layout: 'home',
        parent: 'ReadingRoomCategory',
        header: ' ',
        toParent: true,
        fetchProgram: false,
      },
    },
  ],
}
