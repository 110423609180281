import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('blockedPlugin', [
      'completePlugin',
    ]),
    ...mapMutations('blockedPlugin', [
      'resetBlockedPluginReadyToComplete',
    ]),
    completeBlockedPlugin() {
      if (!this.shouldCompletePlugin) return

      this.resetBlockedPluginReadyToComplete()

      const sendData = {
        data: this.blockedPlugin,
        userId: this.blockedPlugin.user_id,
      }

      return this.completePlugin(sendData)
    },
    isPluginRoute(pluginId, route) {
      return route?.path?.includes(this.getTriggerSlugByPluginId(pluginId))
    },
  },

  computed: {
    ...mapGetters('triggers', [
      'getTriggerSlugByPluginId',
    ]),
    ...mapState('blockedPlugin', [
      'blockedPlugin',
      'blockedPluginReadyToComplete',
    ]),
    shouldCompletePlugin() {
      const { plugin_id: pluginId } = this.blockedPlugin || {}
      return !!this.blockedPlugin
        && this.blockedPluginReadyToComplete === this.blockedPlugin.id
        && !this.isPluginRoute(pluginId, this.$route)
    },
  },
}
