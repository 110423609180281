<template>
  <simple-tile
    class="home-notifications"
    data-testid="home-notifications"
    :direction="'column'"
  >
    <div class="home-notifications__header">
      <h2
        class="home-notifications__header-text"
        data-testid="home-notifications-heading"
      >
        {{ $t(`${$options.slug}.notifications`) }}
      </h2>

      <base-button
        v-if="hasHomeNotifications"
        no-bg
        class="home-notifications__clear"
        data-testid="home-notifications-clear-button"
        :isLoading="isSaving"
        @click="dismissAllNotifications"
        @keydown.shift.tab="$emit('shiftTabOut', $event)"
      >
        {{ $t(`${$options.slug}.clear`) }}
      </base-button>
    </div>

    <template v-if="hasHomeNotifications">
      <p
        aria-live="polite"
        class="home-notifications__amount"
      >
        {{ $t(`${$options.slug}.amount`, { amount: homeNotifications.length }) }}
      </p>
      <home-single-notification
        v-for="(notification, i) in notificationsToShow"
        :key="i"
        class="home-notifications__notification"
        :testid="'home-notifications'"
        v-bind="{ notification }"
        @tabOut="onTabOut($event, isLast(i))"
      />
      <button
        v-if="homeNotifications.length > 3"
        :dataTestid="'show-more'"
        class="show-more"
        @click="toggleShowAllNotifications()">
        Show {{ showAllNotifications ? 'less' : 'more' }} notifications
      </button>
    </template>

    <template v-else>
      <div class="home-notifications__empty">
        <h3 class="home-notifications__empty-title">
          {{ $t(`${$options.slug}.no-new`) }}
        </h3>
        <i18n
          :path="`${$options.slug}.will-appear`"
          tag="p"
          class="home-notifications__empty-link"
          @keydown.exact.tab="onTabOut($event, true)"
          @keydown.shift.tab="$emit('shiftTabOut', $event)"
        >
          <router-link :to="{ name: 'Learn' }">
            {{ $t('learning journey') }}.
          </router-link>
        </i18n>
      </div>
    </template>
  </simple-tile>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import { BaseButton } from '@/components/base'
  import HomeSingleNotification from '@/components/notification/home/HomeSingleNotification'
  import SimpleTile from '@/components/tiles/simple-tile'

  export default {
    inheritAttrs: false,

    components: {
      BaseButton,
      HomeSingleNotification,
      SimpleTile,
    },

    props: {
      widget: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        showAllNotifications: false,
      }
    },

    methods: {
      ...mapActions('notifications', [
        'markAsRead',
      ]),
      dismissAllNotifications() {
        this.homeNotifications.forEach(({ id }) => this.markAsRead(id))
      },
      onTabOut(e, isLast) {
        if (isLast) this.$emit('tabOut', e)
      },
      isLast(i) {
        return i === this.homeNotifications?.length - 1
      },
      toggleShowAllNotifications() {
        this.showAllNotifications = !this.showAllNotifications
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('notifications', [
        'homeNotifications',
      ]),
      hasHomeNotifications() {
        return this.homeNotifications.length > 0
      },
      isSaving() {
        const actions = this.homeNotifications.map(({ id }) => `notifications/markAsRead/${id}`)

        return this.getLoadingStatesForActions(actions)
      },
      notificationsToShow() {
        return this.showAllNotifications
          ? this.homeNotifications
          : this.homeNotifications.slice(0, 3)
      },
    },

    slug: 'component.notification.home.home-notifications',
  }
</script>

<style lang="scss">
  .home-notifications {
    margin-bottom: 0;
    &__clear.button {
      min-width: 0;
      height: auto;
      margin: 0;
      padding: 0;
      color: color(primary);
      &::after,
      &:active {
        color: color(primary);
      }
      .button-text {
        font-size: 1.4rem;
        font-weight: 400;
        text-transform: none;
      }
    }
    .show-more {
      text-decoration: underline;
      width: fit-content;
      margin-top: 2rem;
      color: color(primary);
      font-weight: 600;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
    }
    &__header-text {
      margin: 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
    &__notification {
      margin-bottom: 2rem;
      @include min-sm {
        margin-bottom: 2.5rem;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__empty {
      color: color(grey);
    }
    &__empty-title {
      font-size: 1.4rem;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }
    &__empty-info {
      margin-bottom: 1rem;
      padding: 0 2rem;
      text-align: center;
    }
    &__empty-link {
      font-weight: 400;
    }
    &__amount {
      position: absolute;
      pointer-events: none;
      opacity: 0;
    }
    & .simple-tile__content {
      margin-bottom: 0;
    }
    & .dropdown-menu {
      min-width: 24rem;
      @include max-sm {
        right: auto;
        left: 1rem;
      }
    }
  }
</style>
