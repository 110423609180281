<script>
  import Vue from 'vue'

  export default {
    functional: true,

    props: {
      html: {
        type: String,
        default: '',
      },
    },

    render(h, { props }) {
      const nodes = new Vue({
        beforeCreate() {
          this.$createElement = h
        },

        template: `<div>${props.html}</div>`,
      }).$mount()._vnode.children

      return nodes
    },
  }
</script>
