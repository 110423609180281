<template>
  <base-main
    class="auth"
    data-testid="auth-wrapper"
  >
    <div class="auth__content">

      <div class="auth__background">
        <img
          alt=""
          class="auth__background-image"
          :src="requireImage('login_bg_2.png')"
        >
      </div>

      <div class="auth__tile-wrapper">
        <simple-tile
          class="complete-profile-success-view__tile"
          direction="column"
          :data-testid="'complete-profile-success-tile'"
          :name="'complete-profile-success-tile'"
        >
          <div class="complete-profile-success-view__header">
            <img
              :src="requireImage('logo-ch.png')"
              class="auth__logo"
              alt=""
            >
          </div>
          <h1>Check your email inbox.</h1>
          <p class="complete-profile-success-view__paragraph">
            We have sent an email with the title 'Confirm your account' to the address you provided.
            Click the link in the email to confirm your email address and complete your
            registration.
          </p>
          <div class="complete-profile-success-view__buttons">
            <span class="nhsuk-u-margin-right-3">Need Help?</span>
            <base-button
              light
              data-testid="auth-complete-profile-contact-support"
              href="mailto:customersupport@changinghealth.com"
            >
              {{ $t('Contact Support') }}
            </base-button>
          </div>
        </simple-tile>
      </div>
    </div>
  </base-main>
</template>
<script>
  import { mapState } from 'vuex'

  import { BaseButton } from '@/components/base'

  import BaseMain from '@/components/base/BaseMain'
  import SimpleTile from '@/components/tiles/simple-tile'

  import requireImage from '@/mixins/requireImage'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.support.meta.title'),
      }
    },

    components: {
      BaseMain,
      SimpleTile,
      BaseButton,
    },

    mixins: [
      requireImage,
    ],

    computed: {
      ...mapState('auth', [
        'referralUser',
      ]),
      email() {
        return this.referralUser?.email
      },
    },
  }
</script>

<style lang="scss">
  .complete-profile-success-view {
    &__tile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;
      text-align: center;
      h1 {
        text-align: center;
        margin-bottom: 2rem;
      }
    }
    &__header {
      display: flex;
    }
    &__buttons {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &__paragraph {
      text-align: center;
    }
  }
</style>
