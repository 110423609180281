import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/face-age-test/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Face age test',
      layout: 'home',
      toParent: true,
      hasTabs: true,
      transition: true,
      trigger: 'face-age-test',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'FaceAgeTest',
    },
  },
  routes: [
    {
      path: '/face-age-test',
      name: 'FaceAgeTest',
      meta: {
        parent: 'Tools',
        pluginHome: true,
      },
    },
    {
      path: '/face-age-test/results',
      name: 'FaceAgeTestResults',
      meta: {
        parent: 'FaceAgeTest',
      },
    },
  ],
}
