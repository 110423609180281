import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/duk-risk-calculator/',
    sharedMeta: {
      fetchProgram: false,
      header: 'DUK Risk calculator',
      layout: 'home',
      toParent: true,
      trigger: 'duk-risk-calculator',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'DukRiskCalculatorResults',
    },
  },
  routes: [
    {
      path: '/duk-risk-calculator',
      name: 'DukRiskCalculator',
      meta: {
        parent: 'Tools',
        pluginHome: true,
      },
    },
    {
      path: '/duk-risk-calculator/form',
      name: 'DukRiskCalculatorForm',
      meta: {
        parent: 'DukRiskCalculator',
      },
    },
    {
      path: '/duk-risk-calculator/results',
      name: 'DukRiskCalculatorResults',
      props: true,
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
