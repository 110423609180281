export const descriptions = {
  goal: 'Update goals and action plans for your weight, food, activity and lifestyle change goals.',
  goals: 'Update goals and action plans for your weight, food, activity and lifestyle change goals.',
  'goal-plugin': 'Update goals and action plans for your weight, food, activity and lifestyle change goals.',
  coaching: 'Book and manage appointments, and view content selected for you by your coach.',
  'coaching-plugin': 'Book and manage appointments, and view content selected for you by your coach.',
  'step-tracking': 'Log your daily steps, view progress graphs, and set or adjust your step goals.',
  'weight-tracking': 'Log your weight, view progress graphs, and set or adjust your weight goals.',
  'food-tracking': 'Log and label the foods you eat, and set or adjust your food goals.',
  'carb-calculator': 'Interested in Low Carb eating? Find out how many carbs you eat on a typical day.',
  'shopping-list-switcher': 'Discover healthier alternatives to some of your favourite high-carb foods.',
  'activity-quiz': 'How much do you really know about physical activity? Take our quiz and find out!',
  'risk-calculator': 'Find out how diabetes risk level is calculated, and which risk factors you can manage.',
  'face-age-test': '',
  'reading-room': '',
  'getting-confident-quiz': '',
  'tricky-situations-quiz': '',
  'reflecting-quiz': '',
  'winning-against-temptations': '',
  'getting-support-quiz': '',
  'setting-the-strongest-plan': '',
  'maintenance-plan': '',
  'reasons-to-lose-weight': '',
  'capturing-bmi': '',
  'power-review': '',
  'eq-5d-health-questionnaire': '',
  'duk-risk-calculator': 'Find out how diabetes risk level is calculated, and which risk factors you can manage.',
  'initial-assessment': '',
}

export const quickActions = {
  goal: { label: 'View Goals', to: { name: 'GoalsView' } },
  goals: { label: 'View Goals', to: { name: 'GoalsView' } },
  'goal-plugin': { label: 'View Goals', to: { name: 'GoalsView' } },
  coaching: { label: 'New Appointment', to: { name: 'CoachingPluginNewAppointment' } },
  'coaching-plugin': { label: 'New Appointment', to: { name: 'CoachingPluginNewAppointment' } },
  'step-tracking': { label: 'Log Steps / Activity', to: { name: 'StepTrackingLog' } },
  'weight-tracking': { label: 'Log Weight', to: { name: 'WeightTracking', params: { logWeight: true } } },
  'food-tracking': { label: 'Log Food', to: { name: 'FoodTrackingNew' } },
  'carb-calculator': { label: 'Carb Calculator', to: { name: 'CarbCalculator' } },
  'shopping-list-switcher': { label: 'Shopping List Switcher', to: { name: 'ShoppingListSwitcher' } },
  'activity-quiz': { label: 'Activity Quiz', to: { name: 'ActivityQuiz' } },
  'risk-calculator': { label: 'Risk Calculator', to: { name: 'RiskCalculator' } },
  'face-age-test': { label: 'Face Age Test', to: { name: 'FaceAgeTest' } },
  'reading-room': { label: 'Reading Room', to: { name: 'ReadingRoom' } },
  'getting-confident-quiz': { label: 'Getting Confident Quiz', to: { name: 'GettingConfident' } },
  'tricky-situations-quiz': { label: 'Tricky Situations', to: { name: 'TrickySituations' } },
  'reflecting-quiz': { label: 'Reflecting on Walking Experiences', to: { name: 'ReflectingQuiz' } },
  'winning-against-temptations': { label: 'Winning Against Temptations', to: { name: 'TemptationsQuiz' } },
  'getting-support-quiz': { label: 'Getting Support Quiz', to: { name: 'GettingSupport' } },
  'setting-the-strongest-plan': { label: 'Setting The Strongest Plan', to: { name: 'SettingTheStrongestPlan' } },
  'maintenance-plan': { label: 'Maintenance Plan', to: { name: 'MaintenancePlan' } },
  'reasons-to-lose-weight': { label: 'Reasons To Lose Weight', to: { name: 'ReasonsToLoseWeight' } },
  'capturing-bmi': { label: 'Capturing BMI', to: { name: 'CapturingBMI' } },
  'my-messages': { label: 'My Messages', to: { name: 'MyMessagesIntro' } },
  'power-review': { label: 'Power Review', to: { name: 'WeeklyReviewIntro' } },
  'eq-5d-health-questionnaire': { label: 'EQ-5D Health Questionnaire', to: { name: 'Eq5d' } },
  'duk-risk-calculator': { label: 'DUK Risk Calculator', to: { name: 'DukRiskCalculator' } },
  'initial-assessment': { label: 'Initial Assessment', to: { name: 'InitialAssessment' } },
}

export const routes = {
  'activity-quiz': 'ActivityQuiz',
  'capturing-bmi': 'CapturingBMI',
  'carb-calculator': 'CarbCalculator',
  coaching: 'CoachingPlugin',
  'coaching-plugin': 'CoachingPlugin',
  'duk-risk-calculator': 'DukRiskCalculator',
  'eq-5d-health-questionnaire': 'Eq5d',
  'face-age-test': 'FaceAgeTest',
  'food-tracking': 'FoodTracking',
  'getting-confident-quiz': 'GettingConfident',
  'getting-support-quiz': 'GettingSupport',
  goal: 'GoalsView',
  'goal-plugin': 'GoalsView',
  goals: 'GoalsView',
  'initial-assessment': 'InitialAssessment',
  'maintenance-plan': 'MaintenancePlan',
  'my-messages': 'MyMessagesIntro',
  'power-review': 'WeeklyReviewIntro',
  'reading-room': 'ReadingRoom',
  'reflecting-quiz': 'Reflecting',
  'reasons-to-lose-weight': 'ReasonsToLoseWeight',
  'risk-calculator': 'RiskCalculator',
  'setting-the-strongest-plan': 'SettingTheStrongestPlan',
  'shopping-list-switcher': 'ShoppingListSwitcher',
  'step-tracking': 'StepTracking',
  'tricky-situations-quiz': 'TrickySituations',
  'weight-tracking': 'WeightTracking',
  'winning-against-temptations': 'Temptations',
}
