<template>
  <snackbar
    :isOpen.sync="isSnackbarOpen"
  >
    <template slot="content">
      {{ snackbar ? snackbar.message : null }}
    </template>
    <template slot="action">
      <base-button
        small
        data-testid="snackbar-close"
        :type="'submit'"
        @click="closeSnackbar"
      >
        {{ $t('Close') }}
      </base-button>
    </template>
  </snackbar>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import Snackbar from '@/components/snackbar/Snackbar'

  import forceAnnounce from '@/mixins/forceAnnounce'

  export default {
    components: {
      BaseButton,
      Snackbar,
    },

    mixins: [
      forceAnnounce,
    ],

    watch: {
      isSnackbarOpen(newValue) {
        if (newValue) return

        this.removeSnackbar()
      },
      async snackbar() {
        if (!this.snackbar) return

        this.isSnackbarOpen = false

        await this.$nextTick()

        this.isSnackbarOpen = true
        this.forceAnnounce(this.snackbar.message)
      },
    },

    data() {
      return {
        isSnackbarOpen: false,
      }
    },

    methods: {
      ...mapMutations('snackbars', [
        'removeSnackbar',
      ]),
      closeSnackbar() {
        this.isSnackbarOpen = false
        this.removeSnackbar()
      },
    },

    computed: {
      ...mapState('snackbars', [
        'snackbars',
      ]),
      snackbar() {
        if (!this.snackbars.length) return

        return this.snackbars[0]
      },
    },
  }
</script>
