var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.outsideDescription)?_c('div',[_c('p',{staticClass:"password-tips__title"},[_vm._v(" Your password should: ")]),_c('ul',[_c('li',{staticClass:"password-tips__option",class:{
          'password-tips__red-option': !_vm.passwordErrors.isAtLeastOneLetterValid && _vm.password,
          'password-tips__green-option': _vm.passwordErrors.isAtLeastOneLetterValid
        }},[_vm._v(" Contain at least one letter ")]),_c('li',{staticClass:"password-tips__option",class:{
          'password-tips__red-option':
            !_vm.passwordErrors.isAtLeastOneCapitalLetterValid && _vm.password,
          'password-tips__green-option': _vm.passwordErrors.isAtLeastOneCapitalLetterValid
        }},[_vm._v(" Contain at least one capital letter ")]),_c('li',{staticClass:"password-tips__option",class:{
          'password-tips__red-option':
            !_vm.passwordErrors.isAtLeastOneNumberValid && _vm.password,
          'password-tips__green-option': _vm.passwordErrors.isAtLeastOneNumberValid
        }},[_vm._v(" Contain at least one number ")]),_c('li',{staticClass:"password-tips__option",class:{
          'password-tips__red-option':
            !_vm.passwordErrors.isNotContainUsernameOrEmailValid && _vm.password,
          'password-tips__green-option': _vm.passwordErrors.isNotContainUsernameOrEmailValid
        }},[_vm._v(" Not contain username or email address ")]),_c('li',{staticClass:"password-tips__option",class:{
          'password-tips__red-option':
            !_vm.passwordErrors.isBeAtLeast9CharactersValid && _vm.password,
          'password-tips__green-option': _vm.passwordErrors.isBeAtLeast9CharactersValid
        }},[_vm._v(" Be at least 9 characters ")])])]):_vm._e(),_c('input-group',{staticClass:"validate-password-input",class:{ 'login__error-border': _vm.password && !_vm.validPassword() },attrs:{"has-hidden-asterisk":"","label":_vm.label,"name":'password',"type":'password',"validation":_vm.$options.validation.password,"autocomplete":'none',"hideToggleMask":false},scopedSlots:_vm._u([(_vm.insideDescription)?{key:"description",fn:function(){return [_c('p',{staticClass:"password-tips__title"},[_vm._v(" Your password should: ")]),_c('ul',{staticClass:"password-tips"},[_c('li',{staticClass:"password-tips__option",class:{
            'password-tips__red-option': !_vm.passwordErrors.isAtLeastOneLetterValid && _vm.password,
            'password-tips__green-option': _vm.passwordErrors.isAtLeastOneLetterValid
          },attrs:{"aria-label":_vm.ariaLabel(_vm.passwordErrors.isAtLeastOneLetterValid)}},[_vm._v(" Contain at least one letter ")]),_c('li',{staticClass:"password-tips__option",class:{
            'password-tips__red-option':
              !_vm.passwordErrors.isAtLeastOneCapitalLetterValid && _vm.password,
            'password-tips__green-option': _vm.passwordErrors.isAtLeastOneCapitalLetterValid
          },attrs:{"aria-label":_vm.ariaLabel(_vm.passwordErrors.isAtLeastOneCapitalLetterValid)}},[_vm._v(" Contain at least one capital letter ")]),_c('li',{staticClass:"password-tips__option",class:{
            'password-tips__red-option':
              !_vm.passwordErrors.isAtLeastOneNumberValid && _vm.password,
            'password-tips__green-option': _vm.passwordErrors.isAtLeastOneNumberValid
          },attrs:{"aria-label":_vm.ariaLabel(_vm.passwordErrors.isAtLeastOneNumberValid)}},[_vm._v(" Contain at least one number ")]),_c('li',{staticClass:"password-tips__option",class:{
            'password-tips__red-option':
              !_vm.passwordErrors.isNotContainUsernameOrEmailValid && _vm.password,
            'password-tips__green-option': _vm.passwordErrors.isNotContainUsernameOrEmailValid
          },attrs:{"aria-label":_vm.ariaLabel(_vm.passwordErrors.isNotContainUsernameOrEmailValid)}},[_vm._v(" Not contain username or email address ")]),_c('li',{staticClass:"password-tips__option",class:{
            'password-tips__red-option':
              !_vm.passwordErrors.isBeAtLeast9CharactersValid && _vm.password,
            'password-tips__green-option': _vm.passwordErrors.isBeAtLeast9CharactersValid
          },attrs:{"aria-label":_vm.ariaLabel(_vm.passwordErrors.isBeAtLeast9CharactersValid)}},[_vm._v(" Be at least 9 characters ")])])]},proxy:true}:null],null,true),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }