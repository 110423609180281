import rootCommit from '@/store/helpers/rootCommit'

import timezones from './timezones'

export default {
  getUtcByTimezone({ commit }, timezone) {
    return new Promise(resolve => {
      resolve(timezones[timezone])
    })
  },
  setUserOffsetTimezone({ dispatch }, timezone) {
    return new Promise((resolve, reject) => {
      if (!timezone) return
      dispatch('getUtcByTimezone', timezone).then(utcOffset => {
        rootCommit('user/setUserOffsetTimezone', utcOffset)
        resolve()
      })
      .catch(reject)
    })
  },
  getTimezones() {
    return new Promise(resolve => {
      resolve(Object.keys(timezones))
    })
  },
}
