<template>
  <validation-provider
    ref="provider"
    :aria-describedby="fieldsetDesc"
    :data-testid="`${dataTestid}-form-group`"
    :name="readableName"
    :rules="validation"
    :vid="name"
    v-bind="{ events, mode, name, tag }"
    v-slot="{ errors, validate }"
  >
    <prop-observer
      :value="errors"
      @change="onErrorsChange"
    />
    <slot
      v-bind="{ errors, validate, descriptionId }"
    />
  </validation-provider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate'

  import PropObserver from '@/components/helpers/PropObserver'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      ValidationProvider,
      PropObserver,
    },

    mixins: [
      testid(),
    ],

    props: {
      events: {
        type: Array,
        required: false,
        default: () => ['input'],
      },
      helper: {
        type: String,
        required: false,
        default: '',
      },
      hideErrorMessage: {
        type: Boolean,
        default: false,
      },
      mode: {
        type: [String, Function],
        required: false,
        default: 'aggressive',
      },
      name: {
        type: String,
        required: true,
      },
      tag: {
        type: String,
        required: false,
        default: 'fieldset',
      },
      validation: {
        type: [Object, String],
        required: true,
      },
      descriptionId: {
        type: [String, Boolean],
        default: false,
      },
      hasMultipleElements: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
    },

    methods: {
      onErrorsChange(newVal, oldVal) {
        this.$emit('errors', newVal, oldVal)
      },
    },

    computed: {
      fieldsetDesc() {
        return this.hasMultipleElements ? this.descriptionId : false
      },
      readableName() {
        return this.label || this.$t('This field')
      },
    },
  }
</script>
