export default {
  setMa(state, ma) {
    state.ma = ma
  },
  setMam(state, mam) {
    state.mam = mam
  },
  addToQueue(state, event) {
    state.toDispatch.push({ ...event })
  },
  setCanBeDispatched(state, value) {
    state.canBeDispatched = value
  },
  clearQueue(state) {
    state.toDispatch = []
  },
}
