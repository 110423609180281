import Vue from 'vue'

import initialState from './state'

export default {
  setToken(state, token) {
    state.token = token
  },
  setNext(state, payload) {
    state.next = payload
    if (!state.stepsArray.includes(payload)) {
      state.stepsArray.push(payload)
    }
  },
  setReviewMode(state, payload) {
    state.reviewMode = payload.review_mode
  },
  setWeight(state, payload) {
    state.loggedWeight = payload
  },
  setWeightImage(state, payload) {
    state.loggedWeightImage = payload
  },
  setMessages(state, payload) {
    payload.forEach(item => {
      Vue.set(state.messages, item.from, item)
    })
  },
  setFoodGoals(state, payload) {
    state.foodGoals = payload
  },
  setEatingPlan(state, payload) {
    state.eatingPlans = payload
  },
  setActivityGoals(state, payload) {
    state.activityGoals = payload
  },
  setWalkingGoal(state, payload) {
    state.walkingGoal = payload
  },
  setActivityPlan(state, payload) {
    state.activityPlans = payload
  },
  setSummary(state, payload) {
    state.summary = payload.goals
    state.hasSummary = true
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
