import underscorize from '@/helpers/underscorize'

import normalize from '@/store/helpers/normalize'

const normalizeOptionalData = categories => (
  categories.reduce((acc, curr) => {
    const { data, ...newCurr } = curr
    const { ids, items } = normalize(data, 'key')
    const key = underscorize(curr.category.slug)

    Object.assign(acc.optionalData, {
      [key]: {
        ...newCurr,
        items,
      },
    })
    Object.assign(acc.optionalDataKeys, { [key]: ids })

    const idsKeys = data
                    .reduce((accData, currData) => {
                      const { id, key: dataKey } = currData
                      return Object.assign(accData, { [id]: underscorize(dataKey) })
                    }, {})

    Object.assign(acc.optionalDataIdsKeys, idsKeys)

    return acc
  }, { optionalData: {}, optionalDataKeys: {}, optionalDataIdsKeys: {} })
)

export default normalizeOptionalData
