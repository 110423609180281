import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/weekly-review/',
  },
  routes: [
    {
      path: '/power-review',
      component: 'WeeklyReview',
      meta: {
        fetchProgram: false,
        header: 'Weekly review',
        layout: 'home',
        parent: 'Tools',
        toParent: true,
        weeklyReview: true,
        trigger: 'power-review',
        type: PAGE_TYPES.plugin,
        unblockPluginAt: 'WeeklyReview',
      },
      children: [
        {
          path: '',
          name: 'WeeklyReviewIntro',
        },
        {
          path: 'weight',
          name: 'WeeklyReviewWeight',
          component: 'weekly-review-weight/WeeklyReviewWeight',
        },
        {
          path: 'weight/feedback',
          name: 'WeeklyReviewWeightFeedback',
          component: 'WeeklyReviewFeedback',
          meta: {
            steps: 'weight',
          },
        },
        {
          path: 'bmi',
          name: 'WeeklyReviewBmi',
          component: 'WeeklyReviewFeedback',
          meta: {
            steps: 'bmi',
          },
        },
        {
          path: 'eating',
          name: 'WeeklyReviewEating',
          component: 'weekly-review-eating/WeeklyReviewEating',
        },
        {
          path: 'eating/feedback',
          name: 'WeeklyReviewEatingFeedback',
          component: 'WeeklyReviewFeedback',
          meta: {
            steps: 'eating',
          },
        },
        {
          path: 'eating/plan',
          name: 'WeeklyReviewEatingPlan',
          component: 'weekly-review-eating/WeeklyReviewEatingPlan',
        },
        {
          path: 'activity',
          name: 'WeeklyReviewActivity',
          component: 'weekly-review-activity/WeeklyReviewActivity',
        },
        {
          path: 'walking',
          name: 'WeeklyReviewWalking',
          component: 'weekly-review-walking/WeeklyReviewWalking',
        },
        {
          path: 'activity/feedback',
          name: 'WeeklyReviewActivityFeedback',
          component: 'WeeklyReviewFeedback',
          meta: {
            steps: ['activity-goals', 'walking'],
          },
        },
        {
          path: 'activity/plan',
          name: 'WeeklyReviewActivityPlan',
          component: 'weekly-review-activity/WeeklyReviewActivityPlan',
        },
        {
          path: 'completed',
          name: 'WeeklyReviewCompleted',
        },
      ],
    },
  ],
}
