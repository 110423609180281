<template>
  <base-dialog
    class="cancel-appointment"
    data-testid="dialog-coaching-appointment-cancellation"
  >
    <template #icon>
      <inline-svg src="icons/icon-exclamation.svg"/>
    </template>

    <div class="cancel-appointment__content">
      <h1
        class="cancel-appointment__heading"
        data-testid="dialog-coaching-appointment-cancellation-heading"
      >
        {{ $t('Cancel appointment') }}
      </h1>

      <p
        class="cancel-appointment__prompt"
        data-testid="dialog-coaching-appointment-cancellation-prompt"
      >
        {{ $t(`${$options.slug}.prompt`) }}
      </p>

      <div class="cancel-appointment__actions">
        <base-button
          no-margin
          class="cancel-appointment__confirm"
          data-testid="dialog-coaching-appointment-cancellation-confirm"
          @click="confirm"
        >
          {{ $t('Cancel appointment') }}
        </base-button>
        <base-button
          light
          no-margin
          class="cancel-appointment__back"
          data-testid="dialog-coaching-appointment-cancellation-back"
          @click="closeDialog"
        >
          {{ $t('Back') }}
        </base-button>
      </div>
    </div>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    props: {
      date: {
        type: String,
        required: true,
      },
      eventBus: {
        type: Object,
        required: true,
      },
      time: {
        type: String,
        required: true,
      },
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
      confirm() {
        this.eventBus.$emit('cancellationConfirmation')
        this.closeDialog()
      },
    },

    slug: 'component.dialog.appointment-cancellation-dialog',
  }
</script>

<style lang="scss">
  .cancel-appointment.dialog {
    max-width: 27rem;
  }
  .cancel-appointment {
    &__content {
      text-align: center;
    }
    &__heading {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    &__prompt {
      margin: 2rem 0 0;
      font-size: 1.2rem;
    }
    &__actions {
      display: flex;
      flex-direction: column;
      margin: 3rem 0 0;
      .button:not(:first-child) {
        margin: 1.5rem 0 0;
      }
    }
  }
</style>
