<template>
  <auth-wrapper
    has-language-select
    class="login"
    data-testid="auth-login"
    :program="program"
    @onSubmit="login"
  >
    <template #out-wrapper>
      <div
        v-if="isIos && !isPWA"
        class="govuk-notification-banner">
        <div class="govuk-notification-banner__header">
          <h2
            class="govuk-notification-banner__title"
            id="govuk-notification-banner-title">
            Bookmark the site
          </h2>
        </div>
        <div class="govuk-notification-banner__content">
          <p class="govuk-notification-banner__heading">
            To save a shortcut to this site on your iPhone home screen, select the
            <inline-svg
              class="nhsuk-icon nhsuk-icon__arrow-left base-pagination-button__arrow ios-share-icon"
              role="presentation"
              src="icons/ios-share.svg"
            />
            Share icon from the menu bar then select 'Add to Home Screen'.
          </p>
        </div>
      </div>
    </template>

    <template #error>
      <input-details
        v-if="customErrorMessage"
        class="login__custom-error"
        data-testid="auth-login-error"
        :inputError="customErrorMessage"
      />
    </template>
    <template #title>
      {{ titleProgram ? titleProgram : $t('Welcome to Changing Health') }}
    </template>
    <template #subtitle>
      {{ $t(`${$options.slug}.subtitle`) }}
    </template>

    <template v-slot="{touched}">
      <input-group
        has-hidden-asterisk
        :autocomplete="'email'"
        :class="{ 'login__error-border': showErrorBorder(touched) }"
        :label="$t('Email:')"
        :name="'email'"
        :screenReaderMessage="screenReaderErrorMessage"
        :type="'email'"
        :validation="$options.validation.email"
        v-model="form.login"
      />
      <input-group
        has-hidden-asterisk
        :autocomplete="'password'"
        :class="{ 'login__error-border': showErrorBorder(touched) }"
        :label="$t('Password:')"
        :name="'password'"
        :screenReaderMessage="screenReaderErrorMessage"
        :type="'password'"
        :validation="$options.validation.password"
        v-model="form.password"
      />
    </template>

    <template #action>
      <!-- <language-select
        class="login__select"
        data-testid="auth-login-language-select"
        :languages="allLanguages"
        :label="'Select your language'"
      /> -->
      <base-button
        class="login__login-button"
        data-testid="auth-login-submit"
        :customColor="program ? program.colors.button_background : null"
        :isLoading="isLoggingIn"
        :type="'submit'"
      >
        {{ $t('Login') }}
      </base-button>
    </template>
    <template #links>
      <router-link
        id="forgotten-password-link"
        data-testid="auth-login-forgot-details"
        :to="{ name: 'AuthResetPassword' }"
      >
        {{ $t('Reset your password') }}
      </router-link>
    </template>
  </auth-wrapper>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import { BaseButton } from '@/components/base'
  import InputDetails from '@/components/forms/input-details/InputDetails'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  // import { LanguageSelect } from '@/components/language'
  import AuthWrapper from '@/components/wrappers/AuthWrapper'

  import { stripHtml } from '@/helpers'

  import redirectToApplication from '@/mixins/redirectToApplication'
  import requireImage from '@/mixins/requireImage'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-login.meta.title'),
      }
    },

    components: {
      AuthWrapper,
      BaseButton,
      InputDetails,
      InputGroup,
      // LanguageSelect,
    },

    mixins: [
      redirectToApplication,
      requireImage,
    ],

    props: {
      isExpiredSession: {
        type: Boolean,
        required: false,
        default: false,
      },
      invalidTokenLink: {
        type: String,
        required: false,
        default: '',
      },
    },

    created() {
      if (this.isExpiredSession || this.$route.params.isExpiredSession) {
        this.openDialog({ component: 'AuthExpiredSession' })
      }
      const { invalidTokenLink } = this.$route.params
      if (invalidTokenLink) {
        this.customErrorMessage = invalidTokenLink
      }
      this.fetchLanguagesCoveredByTranslations()
      if (this.$route.query.package) {
        this.fetchProgramByPackage(this.$route.query.package)
          .then(program => {
            this.titleProgram = program.name
            this.program = program
          })
      }
    },

    mounted() {
      this.isMounted = true
      this.toggleIosClass('add')
    },

    beforeDestroy() {
      this.toggleIosClass('remove')
    },

    data() {
      return {
        customErrorMessage: null,
        form: {
          login: '',
          password: '',
        },
        isMounted: false,
        program: null,
        titleProgram: '',
      }
    },

    methods: {
      ...mapActions('auth', [
        'fetchCurrentUser',
        'isInvitationNewUser',
        'loginWithCredentials',
        'logout',
        'requestCode',
        'getConsent',
        'getConsentDocumentsByPackageId',
      ]),
      ...mapActions('dialog', [
        'openDialog',
      ]),
      ...mapActions('languages', [
        'fetchLanguagesCoveredByTranslations',
      ]),
      ...mapMutations('auth', [
        'setPoliciesToAccept',
      ]),
      ...mapActions('_package', [
        'fetchProgramByPackage',
      ]),
      toggleIosClass(action) {
        if (!this.isIos) return

        document.documentElement.classList[action]('ios--landing')
      },
      async login(isValid) {
        if (!isValid) return

        this.customErrorMessage = null

        try {
          if (this.token) {
            await this.logout()
          }

          await this.loginWithCredentials(this.form)

          await this.checkConsents()

          await this.performSecondStep()
        } catch (error) {
          this.logout()
          this.customErrorMessage = error?.message
          // eslint-disable-next-line no-unused-expressions
          this.$el.querySelector('input')?.focus()
        }
      },
      async checkConsents() {
        const activatedConsents = await this.getConsent()
        const { package_id: packageId, consent } = activatedConsents
        let isConsentActivated = true
        const newConsents = await this.getConsentDocumentsByPackageId(packageId)
        if ((consent === null || consent.length === 0) && newConsents.length > 0) {
          this.setPoliciesToAccept(
            { newConsentsNotAccepted: newConsents, newConsents, isAccepted: false },
          )
          return false
        }
        if (consent) {
          const { legal_notices: legalNotices } = consent
          const newConsentsNotAccepted = []
          newConsents.forEach(newConsent => {
            const { identifier: newIdentifier, version: newVersion } = newConsent
            const consentFinded = legalNotices
              .find(({ identifier, version }) => {
                const pVersion = version.split('.')[0]
                return identifier === newIdentifier && newVersion === pVersion
              })
            if (consentFinded === undefined) {
              newConsentsNotAccepted.push(newConsent)
            }

            isConsentActivated = isConsentActivated && consentFinded !== undefined
          })
          if (isConsentActivated) {
            this.setPoliciesToAccept({ newConsents, isAccepted: true })
            return true
          } else {
            this.setPoliciesToAccept({ newConsentsNotAccepted, newConsents, isAccepted: false })
            return false
          }
        }
        return false
      },
      performSecondStep() {
        if (this.is2fa && !this.isCurrentUserAuthorized) {
          this.redirectTo2FA()
        } else {
          this.finishLogin()
        }
      },
      finishLogin() {
        this.fetchCurrentUser()
          .then(this.redirectToApplication)
      },
      redirectTo2FA() {
        this.requestCode()
          .then(() => {
            this.$router.push({ name: 'Auth2FA', params: { email: this.form.login } })
          })
      },
      showErrorBorder(touched) {
        return this.customErrorMessage && !touched
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('auth', [
        'isCurrentUserAuthorized',
        'token',
        'is2fa',
      ]),
      ...mapState('languages', [
        'allLanguages',
      ]),
      ...mapState('layout', [
        'os',
        'isPWA',
      ]),
      isIos() {
        return this.os == 'ios'
      },
      isLoggingIn() {
        return this.getLoadingStatesForActions([
          'auth/loginWithCredentials',
          'auth/requestCode',
          '_package/fetchProgramByPackage',
        ])
      },
      screenReaderErrorMessage() {
        return stripHtml(this.customErrorMessage)
      },
    },

    slug: 'views.auth.auth-login',

    validation: {
      email: {
        required: true,
        email: true,
      },
      password: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .govuk-notification-banner {
    font-weight: 400;
    line-height: 1.25;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 5px solid #1d70b8;
    background-color: #1d70b8;
    position: relative;
    font-family: 'Averta';

    &__header {
      padding: 2px 15px 5px;
      border-bottom: 1px solid rgba(0,0,0,0);
    }

    &__title {
      // font-weight: bold;
      color: color(_white);
      margin-bottom: 0px;
    }

    &__content {
      color: color(_black);
      padding: 15px;
      background-color: color(_white);;
    }
  }
  .login {
    &__select {
      flex-grow: 1;
      margin: 0 2rem 0 0;
    }
    &__login-button.button {
      min-width: 10rem;
      margin: 1.8rem 0;
      font-size: 1.2rem;
      font-weight: 800;
      @include min-lg {
        min-width: 12rem;
        padding: 0.2rem 3.5rem;
        font-size: 1.4rem;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      @include min-sm {
        flex-direction: row;
        align-items: center;
      }
    }
    &__error-border {
      .input-group__input {
        border-color: color(error);
      }
    }
    .input-details__error {
      font-size: 1.2rem;
      @include min-lg {
        font-size: 1.4rem;
      }
    }
  }
  .ios-share-icon {
    svg {
      width: 15px;
    }
  }
</style>
