import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  sendMail({ commit }, form) {
    /* eslint-disable-next-line camelcase */
    const { reason, content, diagnostic_data = {} } = form

    return new Promise((resolve, reject) => {
      loading('support/sendMail', true)

      api.post('api/mail/support', { reason, content, diagnostic_data })
        .then(() => {
          loading('support/sendMail', false)

          resolve()
        })
        .catch(() => {
          loading('support/sendMail', false)

          reject()
        })
    })
  },
}
