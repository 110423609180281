<template>
  <div
    class="loading-indicator"
    :class="{
      'loading-indicator--inline': inline,
      'loading-indicator--relative': relative,
     }"
     :data-testid="dataTestid"
    >
      <spinner-dots/>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'

  import { SpinnerDots } from '@/components/spinner'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      SpinnerDots,
    },

    mixins: [
      testid('loading-indicator'),
    ],

    props: {
      inline: {
        type: Boolean,
        required: false,
        default: false,
      },
      relative: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    beforeMount() {
      this.setHasFixedLoadingIndicator(true)
    },

    beforeDestroy() {
      this.setHasFixedLoadingIndicator(false)
    },

    methods: {
      ...mapMutations('layout', [
        'set',
      ]),
      setHasFixedLoadingIndicator(value) {
        if (!this.isFixed) return

        this.set({ value, state: 'hasFixedLoadingIndicator' })
      },
    },

    computed: {
      isFixed() {
        return !this.inline && !this.relative
      },
    },
  }
</script>

<style lang="scss">
  .loading-indicator {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .2);
    z-index: z-index(over-all);
    &--inline {
      position: absolute;
      background-color: transparent;
    }
    &--relative {
      position: relative;
      background-color: transparent;
    }
  }
</style>
