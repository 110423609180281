<template>
  <header
    class="simple-header"
    :style="{borderColor: program.colors.primary}">
    <div class="simple-header__logo">
      <img
        alt=""
        class="logo"
        :src="program.logo.url"
      >
      <span
        class="nhsuk-organisation-name semibold"
        :style="{color: program.colors.primary}">{{ program.name }}
      </span>
      <span
        class="nhsuk-organisation-descriptor semibold"
        :style="{color: program.colors.primary}">{{ program.description }}
      </span>
    </div>
  </header>
</template>
<script>

  export default {
    props: {
      program: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .simple-header {
    width: 100%;
    z-index: z-index(header);
    border-bottom: solid 3px;
    background-color: color(_white);
    border-radius: 1rem 1rem 0 0;
    &__logo {
      padding: 1.2rem;
      @include min-lg {
        padding: 3rem 4rem;
      }
    }
    .logo {
      height: 40px !important;
      width: auto !important;
      margin-bottom: 1rem;
    }
  }
</style>
