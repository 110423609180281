export default {
  food_log_image: {
    required: 'Photo of food or drink is required',
  },
  food_log_description: {
    required: 'Name of food or drink is required',
  },
  maintenance_plans: {
    required: 'Please choose at least one option.',
  },
  accept: {
    checkbox_required: 'This is a required field',
  },
}
