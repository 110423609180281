import datasetPolyfill from 'element-dataset'
import Timeago from 'timeago.js'
import localeEn from 'timeago.js/locales/en'

datasetPolyfill()
Timeago.register('en', localeEn)

export default {
  bind(el) {
    const timeagoInstance = new Timeago()
    timeagoInstance.render(el)
  },
  componentUpdated(el, binding) {
    binding.def.unbind(el)

    binding.def.bind(el)
  },
  unbind(el) {
    Timeago.cancel(el)
  },
}
