/**
  * removes all dash occurrences in a string
  *
  * @param {String} string string to be unslugified
  * @return {String} string without dashes
  *
*/

const unslugify = string => string.replace(/-/g, ' ')

export default unslugify
