import Vue from 'vue'

import initialState from './state'

export default {
  setPackage(state, _package) {
    state.package = _package
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
