<template>
  <div class="form-fields">
    <template v-for="(field, i) in formFields">
      <form-fields-generator-field
        v-if="field.type"
        :key="i"
        v-bind="{ field, dataModel }"
      />

      <form-fields-generator
        v-else-if="isFieldsGroup(field)"
        :key="i"
        class="form-fields__group"
        :class="field.class"
        :formFields="field.formFields"
        v-bind="{ dataModel }"
      />
    </template>
  </div>
</template>

<script>
  import FormFieldsGeneratorField from './FormFieldsGeneratorField'
  import { isFieldsGroup } from './fieldsTypeValidation'

  /*
  {
    type: string,
    formFields?: [],
    name: string,
    class?: [] || '',
    props?: { ... },
    listeners?: { ... },
  }
  */

  const availableFormMethods = ['resetModel']

  export default {
    name: 'FormFieldsGenerator',

    inject: {
      $_veeObserver: {
        default: {},
      },
    },

    components: {
      FormFieldsGeneratorField,
    },

    props: {
      formFields: {
        type: Array,
        required: true,
      },
      dataModel: {
        type: Object,
        required: true,
      },
    },

    created() {
      const formMethods = {}

      availableFormMethods.forEach(methodName => {
        if (!formMethods[methodName]) {
          formMethods[methodName] = (...args) => this[methodName](...args)
        }
      })

      this.$emit('initialize', formMethods)
    },

    methods: {
      isFieldsGroup,
      resetModel(resetValidation = false) {
        if (this.$children) {
          this.$children.forEach(({ resetModel }) => resetModel())
        }
        if (resetValidation && this.$_veeObserver) {
          this.$_veeObserver.reset()
        }
      },
    },
  }
</script>
