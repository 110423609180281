<template>
  <base-main
    default
    class="feedback-view"
    data-testid="feedback"
    v-bind="{ loading }"
  >
    <stepper-custom-form
      ref="form"
      data-testid="feedback-form"
      class="feedback-form"
      :buttonLabels="{ finishLabel: i18n('send') }"
      :isLoading="isSaving"
      @cancel="goBack"
      @submit="onSubmit"
    >
      <template #default>
        <stepper-custom-step
          data-testid="feedback-stepper-custom-step"
          :title="i18n('send-us-feedback')"
        >
          <div
            aria-live="polite"
            class="feedback__content"
            >
            <form-fields-generator
              :dataModel="form"
              :formFields="formFields"
            />
          </div>
        </stepper-custom-step>
      </template>
    </stepper-custom-form>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import {
    BaseMain,
  } from '@/components/base'

  import FormFieldsGenerator from '@/components/forms/form-fields-generator/FormFieldsGenerator'

  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import goBack from '@/mixins/goBack'
  import i18n from '@/mixins/i18n'
  import pageName from '@/mixins/pageName'

  export default {
    components: {
      BaseMain,
      FormFieldsGenerator,
      StepperCustomForm,
      StepperCustomStep,
    },

    mixins: [
      i18n,
      pageName,
      goBack,
    ],

    data() {
      return {
        form: {
          comment: '',
          rating: null,
        },
      }
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
      ...mapActions('feedback', [
        'sendFeedback',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      onSubmit(isValid) {
        if (!isValid) return

        let { comment } = this.form
        if (this.form.comment === '') {
          comment = 'No comment'
        }

        const previousRoute = this.history[this.history.length - 2]
        let name = this.pageName
        let url = window.location.href
        if (previousRoute) {
          name = previousRoute.name
          url = previousRoute.url
        }

        const payload = {
          page_name: name,
          page_type: this.$route.meta.type,
          page_url: url,
          comment,
          rating: this.form.rating,
        }

        this.sendFeedback(payload)
          .then(() => {
            this.addSnackbar({ message: this.i18n('thank-you') })
            this.goBack()
          })
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('history', [
        'history',
      ]),
      loading() {
        const loadings = [
          'program/fetchProgram',
        ]

        const progressLoadings = [
          'articles/changeProgress',
          'program/fetchNextSection',
        ]

        return this.isSurveyWithTrackProgress
          ? loadings
          : [
            ...loadings,
            ...progressLoadings,
          ]
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'feedback/sendFeedback',
        ])
      },
      formFields() {
        return [
          {
            type: 'RadioMultiGroup',
            name: 'rating',
            class: 'feedback__radio',
            props: {
              name: 'rating',
              labelKey: 'label',
              valueKey: 'value',
              legend: this.i18n('question-1'),
              options: [
                { value: 1, label: this.i18n('option-1-a') },
                { value: 2, label: this.i18n('option-1-b') },
                { value: 3, label: this.i18n('option-1-c') },
                { value: 4, label: this.i18n('option-1-d') },
                { value: 5, label: this.i18n('option-1-e') },
              ],
              testid: 'radio-feedback',
              validation: { required: true },
            },
          },
          {
            type: 'TextareaGroup',
            name: 'comment',
            class: 'feedback__textarea',
            props: {
              hasHiddenAsterisk: true,
              label: this.i18n('question-2'),
              maxLength: 1000,
              name: 'comment',
              rows: 5,
              testid: 'radio-feedback',
              validation: {
                required: false,
                max: 1000,
              },
            },
          },
        ]
      },
    },

    slug: 'component.dialog.feedback-dialog',
  }
</script>

<style lang="scss">
  form.feedback-form {
    margin: auto;
  }
</style>
