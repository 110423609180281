<template>
  <base-main
    background
    class="goals"
    data-testid="goals-view"
    :loading="$options.loading"
  >
    <div class="goals__wrapper">
      <h1
        class="goals__heading"
        data-testid="goals-view-heading"
      >
        <b>{{ `${getCurrentUserFirstName}, ` }}</b>
        {{ headingText }}
      </h1>

      <base-button
        v-if="canAddGoal"
        class="goals__add-goal"
        data-testid="goals-view-add-goal"
        @click="clickAddLog()"
      >
        {{ $t('Add goal') }}
      </base-button>

      <template v-if="hasAnyGoals">
        <goals-view-row
          v-if="hasMainGoals"
          data-testid="goals-view-goals-main"
          :goals="mainGoals"
        >
          {{ $t('Main goal') }}
        </goals-view-row>

        <goals-view-row
          v-if="hasSecondaryGoals"
          data-testid="goals-view-goals-secondary"
          :goals="secondaryGoals"
        >
          {{ $t(`${$options.slug}.secondary-goals`) }}
        </goals-view-row>
      </template>

      <goals-view-row
        v-else
        goals-empty
        data-testid="goals-view-goals-empty"
        :goals="[]"
      />

      <div
        class="goals__single-tip"
        data-testid="goals-view-tip"
      >
        <modular-tile
          dark
          :headerText="$t(`${$options.slug}.know`)"
        >
          <p
            class="single-tip__tip-text"
            data-testid="goals-view-tip-text"
          >
            {{ $t(`${$options.slug}.did-you-know`) }}
          </p>
        </modular-tile>
      </div>
    </div>
  </base-main>
</template>

<script>
  import { isNil } from 'lodash'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import {
    BaseButton,
  } from '@/components/base'
  import BaseMain from '@/components/base/BaseMain'
  import GoalsViewRow from '@/components/goals/GoalsViewRow'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.goals.goals-view.meta.title'),
      }
    },

    components: {
      BaseMain,
      GoalsViewRow,
      ModularTile,
      BaseButton,
    },

    props: {
      welcomeText: {
        type: String,
        required: false,
        default: '',
      },
    },

    created() {
      this.fetchData()
        .then(this.showWelcomeText)
    },

    methods: {
      ...mapActions('goals', [
        'fetchGoals',
        'fetchPlugin',
      ]),
      ...mapActions('layout', {
        setLayout: 'set',
      }),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      fetchData() {
        const triggerDataId = this.getTriggerDataIdByPluginName('goal')
        const userId = this.getCurrentUserId
        const programId = this.currentProgramId
        const promises = []

        if (!isNil(triggerDataId)) {
          promises.push(this.fetchPlugin({ triggerDataId, userId }))
        }
        if (!isNil(programId)) {
          promises.push(this.fetchGoals({ programId, userId }))
        }
        if (!promises.length) return

        return Promise.all(promises)
      },
      showWelcomeText() {
        if (!this.welcomeText) return

        this.addSnackbar({ message: this.welcomeText })
      },
      goalsAvailableProfiles() {
        this.$router.push({ name: 'GoalsAvailableProfiles' })
      },
      clickAddLog() {
        this.$router.push({ name: 'GoalsAddAvailable' })
      },
    },

    computed: {
      ...mapGetters('goals', [
        'mainGoals',
        'secondaryGoals',
        'getUnlockedProfiles',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
        'getCurrentUserFirstName',
      ]),
      ...mapGetters('triggers', [
        'getTriggerDataIdByPluginName',
      ]),
      ...mapState('goals', [
        'goals',
      ]),
      headingText() {
        return this.hasAnyGoals
          ? this.$t(`${this.$options.slug}.goals-to-achive`)
          : this.$t(`${this.$options.slug}.no-goals`)
      },
      hasMainGoals() {
        return this.mainGoals.length > 0
      },
      hasSecondaryGoals() {
        return this.secondaryGoals.length > 0
      },
      hasAnyGoals() {
        return this.goals?.length > 0
      },
      canAddGoal() {
        return this.availableGoalsProfiles.length > 0
      },
      availableGoalsProfiles() {
        return this.getUnlockedProfiles
          .filter(profile => profile?.answerable)
          .map(profile => ({
            label: profile.title,
            link: { name: 'GoalsAddGoal', params: { profileId: profile.id } },
          }))
      },
    },

    slug: 'views.goals.goals-view',

    loading: [
      'goals/fetchGoals',
      'goals/fetchPlugin',
    ],
  }
</script>

<style lang="scss">
  $goals-tile-margin-sm: 1rem;
  $goals-tile-margin-lg: 1.5rem;

  .goals {
    &__wrapper {
      width: 100%;
      padding: 0 2rem;
      text-align: left;
      @include min-lg {
        padding: 0 6rem;
      }
    }
    &__heading {
      margin-bottom: 0;
      margin-top: 3rem;
      font-size: 2.2rem;
      font-weight: 400;
      @include min-lg {
        font-size: 3rem;
      }
    }
    &__add-goal {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
      margin-left: 0 !important;
      @include min-lg {
        margin-top: 2.2rem !important;
      }
    }
    &__single-tip {
      width: 100%;
      margin: 3rem 0;
      @include min-lg {
        width: 50%;
        padding-right: $goals-tile-margin-lg;
      }
      .modular-tile--dark .modular-tile__header {
        color: color(_white);
      }
      .single-tip__tip-text {
        font-size: 1.6rem;
        font-weight: 800;
        @include min-lg {
          font-size: 2rem;
        }
      }
    }
    .goals-view-row__empty-goal {
      margin: 3rem 0 0;
      @include min-sm {
        margin: 3rem (-$goals-tile-margin-sm) 0;
      }
    }
  }
</style>
