import Vue from 'vue'

import initialState from './state'

export default {
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
  setBlockedPlugin(state, payload) {
    state.blockedPlugin = payload
  },
  setBlockedPluginConditionally(state, payload) {
    if (!state.blockedPlugin) {
      const blocker = payload
        ? { ...payload, id: `${payload.plugin_id}-${(new Date()).getTime()}` }
        : null

      state.blockedPlugin = blocker
    }
  },
  setBeforeBlockedPluginRoute(state, payload) {
    state.beforeBlockedPluginRoute = payload
  },
  setBlockedPluginReadyToComplete(state, payload) {
    state.blockedPluginReadyToComplete = payload
  },
  resetBlockedPluginReadyToComplete(state) {
    state.blockedPluginReadyToComplete = null
  },
}
