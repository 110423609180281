import { formatDateFields } from '@/helpers'

import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

import { getPluginId } from './helpers'

export default {
  fetchAnswers({ commit, rootGetters }, pluginName) {
    return new Promise((resolve, reject) => {
      loading(`${pluginName}/fetchAnswers`, true)

      const programId = rootGetters['program/currentProgramId']
      const pluginId = getPluginId(pluginName, rootGetters)

      api.get(`api/programs/${programId}/plugins/${pluginId}/quiz-answers`)
        .then(response => {
          if (response.quiz_answer) {
            const { answers, id, updated_at: updatedAt } = response.quiz_answer

            commit('setAnswers', { answers, pluginName })
            commit('setAnswersId', { id, pluginName })
            commit('setAnswersUpdatedAt', { updatedAt, pluginName })
          }
          loading(`${pluginName}/fetchAnswers`, false)

          resolve()
        })
        .catch(() => {
          loading(`${pluginName}/fetchAnswers`, false)

          reject()
        })
    })
  },
  addAnswers({ commit, rootGetters }, { form, pluginName }) {
    return new Promise((resolve, reject) => {
      loading(`${pluginName}/addAnswers`, true)

      const _form = formatDateFields(form, {
        timezone: 0,
      })

      Object.assign(_form, {
        program_id: rootGetters['program/currentProgramId'],
        plugin_id: getPluginId(pluginName, rootGetters),
      })

      api.post('api/programs/quiz-answers', _form)
        .then(response => {
          const { answers, id, updated_at: updatedAt } = response.quiz_answer

          commit('setAnswers', { answers, pluginName })
          commit('setAnswersId', { id, pluginName })
          commit('setAnswersUpdatedAt', { updatedAt, pluginName })
          loading(`${pluginName}/addAnswers`, false)

          resolve()
        })
        .catch(() => {
          loading(`${pluginName}/addAnswers`, false)

          reject()
        })
    })
  },
  updateAnswers({ commit, state }, { form, pluginName }) {
    return new Promise((resolve, reject) => {
      loading(`${pluginName}/updateAnswers`, true)

      const _form = formatDateFields(form, {
        timezone: 0,
      })

      api.patch(`api/programs/quiz-answers/${state[pluginName].answersId}`, _form)
        .then(() => {
          commit('setAnswers', { answers: form.answers, pluginName })
          loading(`${pluginName}/updateAnswers`, false)

          resolve()
        })
        .catch(() => {
          loading(`${pluginName}/updateAnswers`, false)

          reject()
        })
    })
  },
}
