<template>
  <base-main
    class="auth"
    data-testid="auth-wrapper"
  >
    <div class="auth__content">

      <div class="auth__background">
        <img
          alt=""
          class="auth__background-image"
          :src="requireImage('login_bg_2.png')"
        >
      </div>

      <div class="auth__tile-wrapper">
        <simple-tile
          class="unsubscribe-success-view__tile"
          direction="column"
          :data-testid="'unsubscribe-success-view-tile'"
          :name="'unsubscribe-success-view-tile'"
        >
          <div class="unsubscribe-success-view-view__header">
            <img
              :src="requireImage('logo-ch.png')"
              class="auth__logo"
              alt=""
            >
          </div>
          <h1
            v-if="running">Unsubscribing ...</h1>

          <div
            v-if="success"
               id="completed">
            <h1>Unsubscribe success</h1>
            <p class="unsubscribe-success-view-view__paragraph">
              You have been unsubscribed from programme '{{ this.$route.query.type }}'
              notifications: reminders, nudges and updates about your programme.
            </p>
          </div>

          <div
            v-if="error"
            id="error">
            <h1>Unsubscribe failed</h1>
            <p class="unsubscribe-success-view-view__paragraph">
              An error occurred while unsubscribing
              <span v-if="errorMessage">, cause: {{ errorMessage }}</span>.
              Please contact us for assistance
              at <a href="mailto:customersupport@changinghealth.com">
              customersupport@changinghealth.com</a>.
            </p>
          </div>

        </simple-tile>
      </div>
    </div>
  </base-main>
</template>
<script>

  import BaseMain from '@/components/base/BaseMain'
  import SimpleTile from '@/components/tiles/simple-tile'

  import requireImage from '@/mixins/requireImage'

  import { api } from '@/tools/api'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.unsubscribe.meta.title'),
      }
    },

    components: {
      BaseMain,
      SimpleTile,
    },

    mixins: [
      requireImage,
    ],

    mounted() {
      const { email, expiry, type, hash } = this.$route.query
      return new Promise((resolve, reject) => {
        api.get(`api/user/unsubscribe?email=${encodeURIComponent(email)}&type=${type}&expiry=${expiry}&hash=${hash}`)
          .then(() => {
            this.running = false
            this.success = true
            resolve()
          })
          .catch(error => {
            this.running = false
            this.error = true
            if (error.response.data.cause) {
              this.errorMessage = error.response.data.cause
            }
            reject(error)
          })
      })
    },

    data() {
      return {
        running: true,
        success: false,
        error: false,
        errorMessage: null,
      }
    },

    computed: {
    },
  }
</script>

<style lang="scss">
  .unsubscribe-success-view {
    &__tile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;
      text-align: center;
      h1 {
        text-align: center;
        margin-bottom: 2rem;
      }
    }
    &__header {
      display: flex;
    }
    &__buttons {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &__paragraph {
      text-align: center;
    }
  }
</style>
