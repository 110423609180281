import Vue from 'vue'

import deleteProperty from '@/helpers/deleteProperty'

export default {
  addRequest(state, payload) {
    Vue.set(state.requests, [payload.id], payload)
    state.requestsOrder.push(payload.id)
  },
  removeRequest(state, id) {
    const i = state.requestsOrder.indexOf(id)

    if (i >= 0) {
      state.requestsOrder.splice(i, 1)
      state.requests = deleteProperty(id, state.requests)
    }
  },
  setRequestIsPaused(state, { id, value }) {
    Vue.set(state.requests[id], 'isPaused', value)
  },
  cancelAll(state) {
    Object.values(state.requests).forEach(request => request.source.cancel())
    state.requests = {}
    state.requestsOrder = []
  },
}
