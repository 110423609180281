import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/risk-calculator/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Risk calculator',
      layout: 'home',
      toParent: true,
      trigger: 'risk-calculator',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'RiskCalculatorResults',
    },
  },
  routes: [
    {
      path: '/risk-calculator',
      name: 'RiskCalculator',
      meta: {
        parent: 'Tools',
        pluginHome: true,
      },
    },
    {
      path: '/risk-calculator/form',
      name: 'RiskCalculatorForm',
      meta: {
        parent: 'RiskCalculator',
      },
    },
    {
      path: '/risk-calculator/results',
      name: 'RiskCalculatorResults',
      props: true,
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
