import { mapMutations, mapState } from 'vuex'

const { detect } = require('detect-browser')

const getOsClass = (os, isTouch) => {
  const osName = os.toLowerCase()
  const is = n => osName.indexOf(n) !== -1
  const isMacOs = is('mac')
  const isIpadOs = isMacOs && isTouch
  const isMac = isMacOs && !isTouch

  if (isMac) {
    return 'mac'
  } else if (is('windows')) {
    return 'windows'
  } else if (is('android')) {
    return 'android'
  } else if (is('ios') || isIpadOs) {
    return 'ios'
  } else if (is('linux')) {
    return 'linux'
  } else if (is('blackberry')) {
    return 'blackberry'
  } else if (is('windows mobile')) {
    return 'windows-mobile'
  } else if (is('amazon')) {
    return 'amazon'
  } else if (is('open bsd')) {
    return 'open-bsd'
  } else if (is('sun os')) {
    return 'sun-os'
  } else if (is('qnx')) {
    return 'qnx'
  } else if (is('beos')) {
    return 'beos'
  } else if (is('os/2')) {
    return 'os-2'
  } else if (is('search bot')) {
    return 'search-bot'
  } else {
    return ''
  }
}

export default {
  created() {
    const browser = detect()
    const osClass = getOsClass(browser.os, this.isTouch)
    const mobileOs = ['android', 'ios', 'blackberry', 'windows mobile']

    document.documentElement.classList.add(browser.name)
    document.documentElement.classList.add(osClass)

    this.set({ state: 'browser', value: browser.name })
    this.set({ state: 'os', value: osClass })
    this.set({ state: 'isMobile', value: mobileOs.includes(osClass) })
  },

  methods: {
    ...mapMutations('layout', [
      'set',
    ]),
  },

  computed: {
    ...mapState('layout', [
      'isTouch',
    ]),
  },
}
