import { isArray, mergeWith } from 'lodash'
import Vue from 'vue'

import initialState from './state'

export default {
  setProgram(state, program) {
    state.program = program
    state.isProgramFetched = true
    state.isJourneyUpToDate = program.journey !== undefined
  },
  setJourney(state, program) {
    if (state.program) {
      state.program = {
        ...state.program,
        ...program,
      }
      state.isJourneyUpToDate = true
    }
  },
  setInitialCurrentProgramId(state, id) {
    state.initialCurrentProgramId = id
  },
  setProgress(state, progress) {
    const { updated_at: stateProgressDate } = state.progress || {}
    const { updated_at: incomingProgressDate } = progress || {}

    let progressToSet

    if (!stateProgressDate) {
      progressToSet = progress
    } else {
      progressToSet = stateProgressDate < incomingProgressDate
        ? progress
        : state.progress
    }

    state.progress = {
      progress: parseInt(progressToSet.progress ?? 0, 10),
      updated_at: progressToSet.updated_at,
    }
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
  mergeProgram(state, program) {
    mergeWith(state.program, program, (objValue, srcValue) => {
      if (isArray(objValue)) {
        objValue.forEach((item, idx, arr) => {
          const duplicateItemIdx = srcValue.findIndex(srcItem => srcItem.key === item.key)

          if (duplicateItemIdx < 0) return

          arr[idx] = srcValue[duplicateItemIdx]
          srcValue.splice(duplicateItemIdx, 1)
        })

        return objValue.concat(srcValue)
      }
    })
  },
  flagSectionItemOpened(state, { sectionItem, flag }) {
    sectionItem.opened = flag
  },
  flagProgramUpToDate(state, flag) {
    state.isJourneyUpToDate = flag
  },
}
