<template>
  <base-dialog
    class="plugin-kiosk-dialog"
    data-testid="dialog-kiosk-mode-plugin"
    :headerText="$t(`${$options.slug}.title`)"
  >
    <template
      v-if="hasTriggers"
    >
      <h2
        class="plugin-kiosk-dialog-subheader"
        data-testid="dialog-kiosk-mode-plugin-subheader"
      >
        {{ $t(`${$options.slug}.subheader`) }}
      </h2>

      <base-form
        :hasSubmitButton="false"
        :testid="'dialog-kiosk-mode-plugin'"
        @submit="onSubmit"
      >
        <form-group
          data-testid="dialog-kiosk-mode-plugin-plugins"
          :name="'plugin'"
          :tag="'ul'"
          :validation="$options.validation.plugin"
        >
          <li
            v-for="(trigger, i) in getUnlockedTriggers"
            :key="i"
          >
            <base-radio
              :inputValue="getTriggerSlug(trigger.slug)"
              :label="trigger.plugin.system_name"
              :name="'plugin'"
              v-model="plugin"
            />
          </li>
        </form-group>
      </base-form>
    </template>

    <template
      v-else
    >
      {{ $t(`${$options.slug}.empty`) }}
    </template>

    <template #action>
      <base-button
        data-testid="plugin-kiosk-dialog-close"
        @click="closeDialog"
      >
        {{ $t('Close') }}
      </base-button>
      <base-button
        v-if="isLoading || hasTriggers"
        data-testid="plugin-kiosk-dialog-save"
        :disabled="!plugin"
        @click="onSubmit"
      >
        {{ $t('Save') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
    BaseForm,
    BaseRadio,
  } from '@/components/base'
  import FormGroup from '@/components/forms/form-group/FormGroup'

  import eventBus from '@/tools/event-bus'

  export default {
    components: {
      BaseButton,
      BaseDialog,
      BaseForm,
      BaseRadio,
      FormGroup,
    },

    data() {
      return {
        plugin: null,
      }
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
        'openDialog',
      ]),
      ...mapActions('kioskMode', [
        'enablePluginKioskMode',
      ]),
      async onSubmit() {
        const isValid = await this.$refs.form.$refs.observer.validate()

        if (!isValid) return

        this.openDialog({
          component: 'KioskModePin',
          props: { eventBus, selectedMode: 'Plugin', selectedPlugin: this.plugin },
        })
      },
    },

    computed: {
      ...mapGetters('triggers', [
        'getUnlockedTriggers',
      ]),
      ...mapState('kioskMode', [
        'plugins',
      ]),
      ...mapState('triggers', [
        'triggers',
      ]),
      hasTriggers() {
        return this.getUnlockedTriggers && this.getUnlockedTriggers.length
      },
    },

    slug: 'component.dialog.plugin-kiosk-mode-dialog',
  }
</script>

<style lang="scss">
  .dialog.plugin-kiosk-dialog {
    min-width: 30rem;
    max-width: 50rem;
  }
  .plugin-kiosk-dialog {
    &-subheader {
      margin: 0 0 1rem 0;
      font-size: 1.6rem;
      color: color(primary-text);
    }
    .radio-group {
      margin: 0.4rem 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
