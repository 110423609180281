<template>
  <base-main
    no-top
    class="main-complete-profile"
    data-testid="complete-profile"
    :loading="$options.loading"
  >
    <div
      class="complete-profile">
      <simple-tile
        class="complete-profile__wrapper"
        :direction="'column'"
      >
        <base-simple-header
          v-if="!!referral && program"
          :program="program" />

        <div class="complete-profile__container">
          <h1
            class="complete-profile__title"
            data-testid="auth-complete-profile-header"
          >
            {{ $t($options.statics.header) }}
          </h1>
          <p
            data-testid="auth-complete-profile-description"
          >
            {{ $t($options.statics.description) }}
          </p>

          <profile-form
            class="complete-profile__form"
            data-testid="auth-complete-profile-profile-form"
            :hasSubmitButton="false"
            :isProfile="false"
            :isCompleteProfile="true"
            :method="'PATCH'"
            :user="form"
            v-bind="{ isSaving }"
            @submit="onSubmit"
          >
            <input-details
              v-if="hasErrorOccured"
              class="complete-profile__custom-error"
              data-testid="auth-complete-profile-error"
              :inputError="errorMessage"
            />

            <p
              v-if="form.isAversaPackageId"
              class="privacy-policy-text nhsuk-u-margin-top-4 nhsuk-u-font-size-22">
              By filling this form you are consenting to share your personal
              information with Changing Health. Your information will be held
              confidentially and will not be shared with any third parties.
              For more information review our
              <a
                class="anchor"
                target="_blank"
                href="https://www.changinghealth.com/policies/privacy-policy/"
              >
                Privacy policy.
              </a>
            </p>
            <p
              v-if="form.isHealthyLivingPackageId"
              class="nhsuk-hint nhsuk-u-margin-top-4">
              * By filling this form you are consenting to share your personal
              information with NHS England. Your information will be held
              confidentially and will not be shared with any third parties.
              For more information review our
              <a
                class="anchor"
                target="_blank"
                href="https://www.changinghealth.com/policies/privacy-policy/"
              >
                Privacy policy.
              </a>
            </p>

            <base-button
              class="complete-profile__submit nhsuk-u-margin-top-4"
              data-testid="auth-complete-profile-submit"
              :isLoading="isSaving"
              :type="'submit'"
            >
              {{ $t('Continue') }}
            </base-button>

            <div class="container-need-help nhsuk-u-margin-top-3">
              <span>Need help completing this form?</span>
              <base-button
                light
                data-testid="auth-complete-profile-contact-support"
                href="mailto:customersupport@changinghealth.com"
                class="nhsuk-u-margin-top-0 nhsuk-u-margin-left-2"
              >
                {{ $t('Contact Support') }}
              </base-button>
            </div>
          </profile-form>
        </div>
      </simple-tile>
    </div>
  </base-main>
</template>

<script>

  import { trim } from 'lodash'
  import { mapActions, mapGetters, mapState } from 'vuex'

  import { BaseButton, BaseMain } from '@/components/base'
  import BaseSimpleHeader from '@/components/base/base-header/BaseSimpleHeader'
  import InputDetails from '@/components/forms/input-details/InputDetails'
  import ProfileForm from '@/components/forms/profile-form/ProfileForm'
  import SimpleTile from '@/components/tiles/simple-tile'
  import { i18n } from '@/i18n'

  export default {
    components: {
      BaseMain,
      InputDetails,
      ProfileForm,
      SimpleTile,
      BaseButton,
      BaseSimpleHeader,
    },

    mixins: [
      i18n,
    ],

    props: {
      referral: {
        type: String,
        required: true,
      },
    },

    created() {
      this.isV2 = this.$route.path.includes('v2')
      let get
      if (this.isV2) {
        get = this.getReferralV2(this.referral)
      } else {
        get = this.getReferral(this.referral)
      }

      get.then(() => {
          this.fetchProgramByPackage(this.userReferral.package_id)
            .then(program => this.program = program)
        })
        .then(() => {
          this.fillForm()
        })
        .catch(() => {
          this.$router.push({ name: 'AuthLogin', params: { invalidTokenLink: 'extensions.error.expired-invitation-token-error.message' } })
        })
    },

    data() {
      return {
        form: {
          distance_unit: '',
          first_name: '',
          last_name: '',
          birthdate: '',
          timezone: '',
          email: '',
          landline_phone: '',
          phone: '',
          agree: false,
          gp: '',
          package_id: '',
          isAversaPackageId: false,
          isHealthyLivingPackageId: false,
        },
        hasErrorOccured: false,
        errorMessage: '',
        program: null,
        isV2: false,
      }
    },

    methods: {
      ...mapActions('auth', [
        'completeProfileReferral',
        'completeProfileReferralV2',
        'getReferralV2',
        'getReferral',
        'loginWithCredentials',
      ]),
      ...mapActions('_package', [
        'fetchProgramByPackage',
      ]),
      onSubmit({ form: formData }) {
        const form = {}
        // eslint-disable-next-line no-unused-vars, no-restricted-syntax
        for (const [key, value] of formData.entries()) {
          form[key] = value
        }

        const {
          first_name, last_name, agree, gp, password, birthdate, distance_unit,
          email, landline_phone, phone, time_zone,
        } = form

        const emailTrimmed = trim(email)
        const phoneTrimmed = trim(phone)
        const landlinePhoneTrimmed = trim(landline_phone)
        const firstNameTrimmed = trim(first_name)
        const lastNameTrimmed = trim(last_name)

        const userData = {
          first_name: firstNameTrimmed,
          last_name: lastNameTrimmed,
          password,
          birthdate,
          distance_unit,
          email: emailTrimmed,
          landline_phone: landlinePhoneTrimmed,
          phone: phoneTrimmed,
          sys_first_name: firstNameTrimmed,
          sys_last_name: lastNameTrimmed,
          suid: this.referralUser.suid,
          unit_id: this.referralUser.unit_id,
          package_id: this.referralUser.package_id,
          locale_id: '1', // TODO
          pops: [],
          time_zone,
        }

        if (this.form.isHealthyLivingPackageId) {
          userData.pops = [
            { category: 'consent', key: 'i-agree', value: agree },
            { category: 'consent', key: 'consent-to-share-data-with-your-gp-practice', value: gp },
          ]
          if (this.referralUser.extra_data) {
            const { postcode, gender } = this.referralUser.extra_data
            userData.pops.push({ category: 'gender', key: 'gender-m-f-i-p', value: this.mapGender(gender) })
            userData.pops.push({ category: 'address', key: 'postcode', value: postcode })
          }
        }

        let completeProfile
        if (this.isV2) {
          completeProfile = this.completeProfileReferralV2(
            { tokenReferral: this.referral, userData },
          )
        } else {
          completeProfile = this.completeProfileReferral({ tokenReferral: this.referral, userData })
        }

        completeProfile.then(response => {
            if (emailTrimmed === this.referralUser.email) {
              this.loginSuccessReferral(response.user, userData)
            } else {
              this.$router.push({ name: 'CompleteProfileSuccess' })
            }
          })
          .catch(({ error: err }) => {
            const { error, errors, user } = err

            if (error && error === 'email already exists') {
              this.$router.push({ name: 'AccountAlreadyExistsView' })
              return
            }

            const {
              phone: errorPhone,
              unit_id: errorUnitId,
              package_id: errorPackageId,
              sys_first_name: nameError,
              sys_last_name: lastNameError,
            } = errors

            if (nameError || lastNameError) {
              this.hasErrorOccured = true
              this.errorMessage = 'First or last name has invalid format'
            } else if (errorUnitId || errorPackageId) {
              this.hasErrorOccured = true
              this.errorMessage = errorUnitId || errorPackageId
            } else if (errorPhone) {
              this.$router.push({ name: 'AccountAlreadyExistsView' })
            } else if (user && user.package_id.toString() !== this.referralUser.package_id) {
              this.$router.push({ name: 'SwitchProgramView', params: { isV2: this.isV2 } })
            }
          })
      },
      loginSuccessReferral(form, userData) {
        const authForm = {
          email: userData.email,
          password: userData.password,
          login: userData.email,
        }

        const loginIfCredentials = authForm
          ? this.loginWithCredentials(authForm)
          : Promise.resolve()

        loginIfCredentials
          .then(() => this.$router.push({ name: 'Home' }))
          .catch(this.handleError)
      },
      fillForm() {
        Object.keys(this.form).forEach(key => {
          if (key === 'first_name' || key === 'last_name') {
            this.form[key] = this.userReferral[key]
              ? this.userReferral[key]
              : this.userReferral[`sys_${key}`]
          } else if (this.userReferral[key]) {
            this.form[key] = this.userReferral[key]
          }
        })
        if (this.userReferral.extra_data) {
          this.form.birthdate = this.userReferral.extra_data.birthdate
        }
        this.form.isAversaPackageId = this.userReferral.package_id === '81'
          || this.userReferral.package_id === '98'
        this.form.isHealthyLivingPackageId = this.userReferral.package_id === '79'
          || this.userReferral.package_id === '75'
      },
      mapGender(value) {
        if (value?.toLowerCase() === 'male') return 0
        if (value?.toLowerCase() === 'female') return 1
        if (value?.toLowerCase() === 'intersex') return 2
        return 3
      },
    },

    computed: {
      ...mapState('auth', [
        'referralUser',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'auth/completeProfileReferralV2',
          'auth/completeProfileReferral',
          'auth/loginWithCredentials',
        ])
      },
      userReferral() {
        return this.referralUser
      },
    },

    loading: [
      'auth/getReferral',
      'auth/getConsentDocumentsByPackageId',
      '_package/fetchProgramByPackage',
    ],

    statics: {
      header: 'views.auth.auth-sign-up.header',
      description: 'views.auth.auth-sign-up.description',
      measurements: 'views.auth.auth-sign-up.measurements',
    },
  }
</script>

<style lang="scss">
  .main-complete-profile {
    height: auto
  }
  .complete-profile {
    @include view-wrapper(77.5rem);
    .simple-tile__main {
      padding: 0;
    }
    .simple-tile__content {
      margin-bottom: 0;
      padding: 0 !important;
    }
    &__container {
      padding: 1.2rem;
      @include min-lg {
        padding: 3rem 4rem;
      }
    }
    &__title {
      margin-top: 0;
      font-size: 2.1rem;
    }
    &__form {
      margin: 2rem 0 0;
    }
    &__measurements-label {
      margin: 4.5rem 0 1.5rem;
      font-weight: 600;
    }
    &__custom-error {
      margin: 3.5rem 0 0;
    }
    &__submit {
      align-self: flex-start;
      margin: 2rem 0 0;
      @include min-lg {
        margin: 3.5rem 0 0;
      }
    }
    .phone-input-group-label {
      max-width: 78.1vw;
      white-space: nowrap;
      @include min-sm {
        max-width: none;
        white-space: pre-wrap;
      }
      .complete-profile__label-attribution {
        margin-left: 0.4rem;
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
        @include min-sm {
          margin-left: 0;
        }
      }
    }
    .container-need-help {
        display: flex;
        align-items: center;
        button {
            margin: 0.6rem 0.8rem;
        }
    }
    .anchor {
        font-size: inherit;
    }
    .privacy-policy-text {
      color: color(primary-text);
    }
  }
</style>
