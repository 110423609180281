const slug = 'data.weekly-review.weekly-review-dialogs'

const links = {
  weight: {
    text: `${slug}.weight-text`,
    dialogId: 1,
  },
  eating: {
    plans: [
      {
        category: `${slug}.category-1`,
        children: [
          {
            text: `${slug}.plans-text-1`,
            dialogId: 2,
          },
          {
            text: `${slug}.plans-text-2`,
            dialogId: 3,
          },
          {
            text: `${slug}.plans-text-3`,
            dialogId: 4,
          },
        ],
      },
      {
        category: `${slug}.category-2`,
        children: [
          {
            text: `${slug}.plans-text-1`,
            dialogId: 5,
          },
          {
            text: `${slug}.plans-text-2`,
            dialogId: 6,
          },
          {
            text: `${slug}.plans-text-4`,
            dialogId: 7,
          },
        ],
      },
    ],
  },
  activity: {
    man: [
      {
        text: 'Walking plan',
        dialogId: 8,
      },
      {
        text: 'Mixed physical activity',
        dialogId: 9,
      },
    ],
    woman: [
      {
        text: 'Walking plan',
        dialogId: 10,
      },
      {
        text: 'Mixed physical activity',
        dialogId: 11,
      },
    ],
  },
}

const dialogs = {
  1: {
    title: `${slug}.accurate-weighing`,
    text: `${slug}.text-1`,
  },
  2: {
    title: `${slug}.low-calorie-eating-plan`,
    text: `${slug}.text-2`,
  },
  3: {
    title: `${slug}.low-calorie-eating-plan`,
    text: `${slug}.text-3`,
  },
  4: {
    title: `${slug}.low-calorie-eating-plan`,
    text: `${slug}.text-4`,
  },
  5: {
    title: `${slug}.low-carbohydrate-eating-plan`,
    text: `${slug}.text-5`,
  },
  6: {
    title: `${slug}.low-carbohydrate-eating-plan`,
    text: `${slug}.text-6`,
  },
  7: {
    title: `${slug}.low-carbohydrate-eating-plan`,
    text: `${slug}.text-7`,
  },
  8: {
    title: `${slug}.walking-plan`,
    text: `${slug}.text-8`,
  },
  9: {
    title: `${slug}.mixed-physical-activity-plan`,
    text: `${slug}.text-9`,
  },
  10: {
    title: `${slug}.walking-plan`,
    text: `${slug}.text-10`,
  },
  11: {
    title: 'Mixed physical activity plan',
    text: `${slug}.text-11`,
  },
}

export { dialogs, links }
