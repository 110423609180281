import pascalize from '@/helpers/pascalize'

/* eslint-disable-next-line import/prefer-default-export */
export const formFieldsDefinitions = {
  CalendarPickerGroup: { path: 'calendar-picker-group/CalendarPickerGroup' },
  CheckboxGroup: { path: 'checkbox-group/CheckboxGroup' },
  ChipCheckboxGroup: { path: 'chip-checkbox-group/ChipCheckboxGroup' },
  ChipRadioGroup: { path: 'chip-radio-group/ChipRadioGroup' },
  CustomLabel: { path: 'custom-label/CustomLabel' },
  DateSelectGroup: { path: 'date-select-group/DateSelectGroup' },
  FileInput: { path: 'file-input/FileInput' },
  HourPickerGroup: { path: 'hour-picker-group/HourPickerGroup' },
  ImageGroup: { path: 'image-group/ImageGroup' },
  InputGroup: { path: 'input-group/InputGroup' },
  PhoneInputGroup: { path: 'phone-input-group/PhoneInputGroup' },
  PhonesGroup: { path: 'phones-group/PhonesGroup' },
  RadioMultiGroup: { path: 'radio/RadioMultiGroup' },
  RangeGroup: { path: 'range-group/RangeGroup' },
  SelectGroup: { path: 'select-group/SelectGroup' },
  TextareaGroup: { path: 'textarea-group/TextareaGroup' },
}

export const getLazyComponents = () => (
  Object.entries(formFieldsDefinitions).reduce((acc, [key, { path }]) => {
    acc[pascalize(key)] = () => import(`@/components/forms/${path}`)

    return acc
  }, {})
)
