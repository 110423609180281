<template>
  <legend
    v-if="hasLegend"
    class="base-legend nhsuk-u-font-size-22"
    :class="{
      'base-legend--is-visually-hidden': isHidden,
      'base-legend--v-html': hasVHtml,
    }"
    :id="id || undefined"
  >
    <slot>
      <html-fragment :html="legend" />
    </slot>
  </legend>
</template>

<script>
  import HtmlFragment from '@/components/helpers/HtmlFragment'

  export default {
    components: {
      HtmlFragment,
    },

    props: {
      id: {
        type: String,
        default: '',
      },
      isHidden: {
        type: Boolean,
        default: false,
      },
      legend: {
        type: String,
        default: '',
      },
    },

    computed: {
      hasLegend() {
        return this.hasDefaultSlot || !!this.legend
      },
      hasDefaultSlot() {
        return 'default' in this.$scopedSlots
      },
      hasVHtml() {
        return !this.hasDefaultSlot && !!this.legend
      },
    },
  }
</script>

<style lang="scss">
  .base-legend {
    margin: 0 0 0.5rem;
    color: var(--color-dark-primary);
    &--v-html {
      font-weight: 600;
    }
    &--is-visually-hidden {
      @include visually-hidden;
    }
  }
</style>
