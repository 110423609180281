import Vue from 'vue'

import initialState from './state'

const components = {
  help_widget: 'Widget',
  notification_widget: 'HomeNotifications',
  plugin_widget: 'Widget',
  learn_widget: 'WidgetStart',
}

const widgetsMobileOrder = ['learn_widget', 'plugin_widget', 'notification_widget', 'help_widget']
const findAnswersPlugin = 11

export default {
  setWidgets(state, widgets) {
    widgets.columns.forEach(column => {
      column.items.forEach(widget => {
        widget.component = components[widget.type]
      })
    })
    state.widgets = widgets

    const mobileWidgets = { ...widgets }
    const allWidgets = []
    mobileWidgets.columns.forEach(c => {
      allWidgets.push(...c.items)
    })

    const pluginWidgetsItems = allWidgets
      .filter(({ type }) => type === widgetsMobileOrder[1]).sort(({ order }) => order)
    const answersPluginWidget = pluginWidgetsItems
      .filter(({ plugin_id: pluginId }) => pluginId === findAnswersPlugin)
    const otherPluginWidgets = pluginWidgetsItems
      .filter(({ plugin_id: pluginId }) => pluginId !== findAnswersPlugin)

    const items = [
      ...allWidgets.filter(({ type }) => type === widgetsMobileOrder[0]),
      ...[...answersPluginWidget, ...otherPluginWidgets],
      ...allWidgets.filter(({ type }) => type === widgetsMobileOrder[2]),
      ...allWidgets.filter(({ type }) => type === widgetsMobileOrder[3]),
    ]
    mobileWidgets.columns = [
      {
        items,
      },
    ]
    state.mobileWidgets = mobileWidgets
  },
  resetState(state) {
    const newState = initialState()

    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
