<template>
  <base-dialog
    class="auth-dialog"
    data-testid="dialog-user-2fa"
    :headerLevel="1"
    :headerText="'Confirm 2FA Change'"
  >
    <template
      v-if="errorMessage"
      #error
    >
      <error-box
        data-testid="dialog-user-2fa-error"
      >
        {{ errorMessage }}
      </error-box>
    </template>
    <base-form
      class="auth-dialog__form"
      :hasSubmitButton="false"
      :testid="'dialog-user-2fa'"
      @submit="onSubmit"
      v-bind="{ isSaving }"
    >
      <p
        class="auth-dialog__description"
        data-testid="dialog-user-2fa-description"
      >
        {{ $static.dialogDescription }}
      </p>

      <input-group
        class="auth-dialog__input"
        :label="$t('Password')"
        :name="'password'"
        :testid="'dialog-user-2fa'"
        :type="'password'"
        :validation="$options.validation.password"
        v-model="password"
      />

      <div class="auth-dialog__buttons">
        <base-button
          no-margin
          data-testid="dialog-user-2fa-submit"
          :isLoading="isSaving"
          :type="'submit'"
        >
          {{ $t('Confirm') }}
        </base-button>
        <base-button
          light
          no-margin
          data-testid="dialog-user-2fa-close"
          @click="closeDialog"
        >
          {{ $t('Close') }}
        </base-button>
      </div>
    </base-form>
  </base-dialog>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
    BaseForm,
  } from '@/components/base'
  import { ErrorBox } from '@/components/error-box'
  import InputGroup from '@/components/forms/input-group/InputGroup'

  import twoFactorStatics from '@/data/auth/2fa'

  const { dialogDescription } = twoFactorStatics

  export default {
    components: {
      BaseButton,
      BaseDialog,
      BaseForm,
      ErrorBox,
      InputGroup,
    },

    data() {
      return {
        password: null,
        errorMessage: null,
      }
    },

    methods: {
      ...mapActions('auth', [
        'change2fa',
      ]),
      ...mapActions('dialog', [
        'closeDialog',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      onSubmit(isValid) {
        this.errorMessage = null

        if (!isValid || this.isSaving) return

        this.change2fa({
          password: this.password,
          status: !this.is2fa,
        })
          .then(() => {
              this.addSnackbar({ message: this.$t('Saved') })
              this.closeDialog()
          })
          .catch(err => this.errorMessage = err)
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('auth', [
        'is2fa',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'auth/change2fa',
        ])
      },
    },

    static() {
      return {
        dialogDescription,
      }
    },

    validation: {
      password: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
.auth-dialog {
  max-width: 41rem;
  padding: 1rem;
  &__buttons {
    margin: 2rem 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & > .button {
      margin: 0.5rem;
    }
  }
}
</style>
