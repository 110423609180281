<template>
  <component
    :is="fieldComponent"
    :class="field.class"
    v-bind="{ name, ...field.props }"
    v-on="field.listeners || {}"
    v-model="dataModel[field.name]"
  />
</template>

<script>
  import pascalize from '@/helpers/pascalize'

  import { isFieldDefinition } from './fieldsTypeValidation'
  import { formFieldsDefinitions, getLazyComponents } from './formFieldsDefinitions'

  export default {
    components: {
      ...getLazyComponents(),
    },

    props: {
      field: {
        type: Object,
        required: true,
        validator: isFieldDefinition,
        default: () => ({}),
      },
      dataModel: {
        type: Object,
        required: true,
      },
    },

    created() {
      this.initializeModelKey()
    },

    methods: {
      initializeModelKey() {
        if (this.dataModel[this.name] === undefined) {
          this.resetModel()
        }
      },
      resetModel() {
        const val = 'default' in this.field ? this.field.default : null

        this.$set(this.dataModel, this.name, val)
      },
    },

    computed: {
      fieldComponent() {
        const fieldType = pascalize(this.field.type)
        const field = formFieldsDefinitions[fieldType]

        if (!field) {
          throw new Error(`Unregistered form component: ${fieldType}`)
        }

        if (fieldType === 'SelectGroup') {
          return 'RadioMultiGroup'
        }
        return fieldType
      },
      name() {
        return this.field.name
      },
    },
  }
</script>
