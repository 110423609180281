export default {
  getNotificationsByProgramId: (state, getters) => (programId, types) => {
    if (getters.hasCurrentUserUncompletedProfile) return []

    return state.notifications
      .filter(item => (
        !item.data.program_id || item.data.program_id == programId
      ))
      .filter(item => {
        if (!types) {
          return true
        } else if (Array.isArray(types)) {
          return types.find(type => item.data.type === type) !== undefined
        }
        return types === item.data.type
      })
  },
  hasCurrentUserUncompletedProfile: (state, getters, rootState, rootGetters) => (
    rootGetters['user/hasCurrentUserUncompletedProfile']
  ),
  homeNotifications: state => state.notifications.filter(({ data: { type, importance } }) => (
    type !== 'popup' && !(type === 'info' && importance === 'low')
  )) || [],
}
