// macOS voiceover sometimes won't announce a repeated message
// this mixin tricks voiceover by appending the &nbsp; char at the end
// of the announcement every second time its used

const nbsp = String.fromCharCode(160)
let willAppendChar = false

export default {
  methods: {
    forceAnnounce(announcement) {
      this.$announcer.set(`${announcement}${willAppendChar ? nbsp : ''}`)
      willAppendChar = !willAppendChar
    },
  },
}
