<script>
  import { mapGetters, mapState } from 'vuex'

  import { ContentNavigationButton, ContentNavigationEnd } from '@/components/content-navigation'
  import Spinner from '@/components/spinner/Spinner'

  import { bemConstructor } from '@/helpers'

  import testid from '@/mixins/testid/testid'

  import eventBus from '@/tools/event-bus'

  import TransitionFade from '@/transitions/transition-fade/TransitionFade'

  export default {
    inheritAttrs: false,

    mixins: [
      testid('journey-navigation'),
    ],

    props: {
      articleId: {
        type: [Number, String],
        required: true,
      },
      canProceed: {
        type: Boolean,
        required: false,
        default: true,
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
      beforeNavigation: {
        type: Function,
        default: () => Promise.resolve(),
      },
    },

    render() {
      const bem = bemConstructor('journey-navigation')
      const isNavigationEnd = this.next.data?.class === 'content-navigation-end'
      const isSectionChange = [this.previous, this.next].some(item => (
        !!item?.componentOptions?.propsData?.sectionTitle
      ))

      return (
        <div
          class={`${bem('', [
            this.previous && 'has-previous',
            isNavigationEnd && 'is-end',
            isSectionChange && 'is-section-change',
            this.isLoading && 'is-loading',
          ])}`}
          data-testid={this.dataTestid}
        >
          <TransitionFade>
            {
              this.isLoading
                ? <div
                    key="spinner"
                    class={bem('spinner')}
                  >
                    <Spinner/>
                  </div>
                : <nav
                    key="controls"
                    class={bem('controls')}
                    aria-label={this.$t('Articles')}
                  >
                    { this.previous }
                    { this.canProceed && this.next }
                  </nav>
              }
            </TransitionFade>
        </div>
      )
    },

    methods: {
      createNavigationItem(offset, showDetails = true) {
        /* eslint-disable camelcase */
        const isPrevious = offset < 0
        const { article, section, hasNextSection } = this.getArticleAndSection(offset)

        if (offset > 0 && !hasNextSection) {
          return (
            <ContentNavigationEnd
              testid={`${this.dataTestid}-finish`}
              title="Congratulations!"
              description="You completed your programme"
            />
          )
        } else if (offset < 0 && !article && !section) {
          return ''
        }

        return (
          <ContentNavigationButton
            is-previous={ isPrevious }
            sectionTitle={ showDetails
              ? section?.display_name ?? ''
              : ''
            }
            sectionDescription={ showDetails
              ? section?.description ?? ''
              : ''
            }
            articleTitle={ showDetails
              ? article?.title ?? article?.display_name
              : ''
            }
            testid={this.dataTestid}
            beforeNavigation={ this.beforeNavigation }
            onClick={() => this.triggerNavigation(offset, isPrevious)}
          />
        )
        /* eslint-enable camelcase */
      },
      async triggerNavigation(offset, isPrevious) {
        const beforeFn = isPrevious
          ? () => Promise.resolve()
          : this.beforeNavigation

        await beforeFn()
        await this.$nextTick()

        eventBus.$emit(`transition/article/${isPrevious ? 'previous' : 'next'}`, this.getRoute(offset))
      },
      getRoute(offset) {
        const { article } = this.getArticleAndSection(offset)

        return {
          name: 'ArticleView',
          params: { id: article.id },
        }
      },
    },

    computed: {
      ...mapGetters('program', [
        'getArticleOffset',
        'getSectionOfArticle',
        'getSectionOffset',
        'isTrailingOpd',
      ]),
      ...mapState('program', [
        'program',
      ]),
      previous() {
        return this.createNavigationItem(-1)
      },
      next() {
        return this.createNavigationItem(1, !this.isTrailingOpd(this.articleId))
      },
      getArticleAndSection() {
        return offset => {
          let article = this.getArticleOffset(this.articleId, offset)
          let section = null
          let hasNextSection = true

          if (!article) {
            const { id } = this.getSectionOfArticle(this.articleId)

            section = this.getSectionOffset(id, offset)

            if (!section && offset > 0) hasNextSection = this.program.has_next_section
            if (section) {
              article = section.items[offset > 0 ? 0 : section.items.length - 1]
            }
          }

          return {
            article,
            section,
            hasNextSection,
          }
        }
      },
    },
  }
</script>

<style lang="scss">
  $block: 'journey-navigation';

  .#{$block} {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    &__controls {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 4rem 0 0;
    }
    &__spinner {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      min-height: 9.3rem;
      width: 100%;
      svg {
        width: 3rem;
      }
      circle {
        stroke: color(light-grey);
      }
    }
    &--has-previous {
      .#{$block}__controls {
        justify-content: space-between;
      }
    }
    &--is-end {
      @include max-sm {
        flex-direction: column-reverse;
      }
    }
    &--is-section-change {
      .#{$block}__spinner {
        min-height: 11.5rem;
      }
    }
  }
</style>
