import PhoneNumber from 'awesome-phonenumber'
import { Validator } from 'vee-validate'

const phoneNumber = {
  getMessage: () => 'Invalid phone number',
  validate(value, [type]) {
    return new Promise(resolve => {
      // check for letters
      if (/[a-z]/i.test(value)) {
        return resolve({ valid: false })
      }
      // check number
      const phone = new PhoneNumber(value)

      if (type) {
        const isTypeMobile = type == 'mobile'

        return resolve({
          valid: phone.isValid() && phone[isTypeMobile ? 'isMobile' : 'isFixedLine'](),
        })
      }

      return resolve({ valid: phone.isValid() })
    })
  },
}

const phoneNumberError = {
  getMessage: () => 'Invalid phone number',
  validate(value, [valid]) {
    return new Promise(resolve => resolve({ valid }))
  },
}

Validator.extend('phone_number', phoneNumber)
Validator.extend('phone_number_error', phoneNumberError)
