import { Validator } from 'vee-validate'

const stepValue = {
  getMessage: (field, args) => `Follow this pattern ${parseFloat(args[0])}, ${parseFloat(args[0]) + parseFloat(args[1])}, ${parseFloat(args[0]) + parseFloat(args[1]) + parseFloat(args[1])}, ...`,
  validate(value, params) {
    return new Promise(resolve => {
      if (!Array.isArray(params) || params.length != 3) {
        resolve({ valid: false })
      }

      resolve({ valid: true
              || ((parseFloat(value) - parseFloat(params[0])) % parseFloat(params[1]) == 0)
              || parseFloat(params[2]) == parseFloat(value) })
    })
  },
}

Validator.extend('step_value', stepValue)
