import { PAGE_TYPES } from '@/constants'

import routeParamToNumber from '@/router/helpers/routeParamToNumber'

export default {
  properties: {
    dir: 'goals/',
    sharedMeta: {
      trigger: ['goal', 'goals', 'goal-plugin'],
      type: PAGE_TYPES.plugin,
    },
  },
  routes: [
    {
      path: '/goals/add-available',
      name: 'GoalsAddAvailable',
      meta: {
        fetchProgram: false,
        header: 'Set a Goal',
        layout: 'home',
        parent: 'GoalsView',
        toParent: true,
      },
    },
    {
      path: '/goals/add/:profileId',
      name: 'GoalsAddGoal',
      props: routeParamToNumber('profileId'),
      meta: {
        fetchProgram: false,
        header: 'Add Goal',
        layout: 'home',
        parent: 'GoalsView',
        toParent: true,
      },
    },
    {
      path: '/goals/:id',
      name: 'GoalsPreviewGoal',
      props: routeParamToNumber('id'),
      meta: {
        fetchProgram: false,
        header: ' ',
        layout: 'home',
        parent: 'GoalsView',
        toParent: true,
      },
    },
    {
      path: '/goals/:id/edit',
      name: 'GoalsEditGoal',
      props: routeParamToNumber('id'),
      meta: {
        fetchProgram: false,
        header: 'Edit your goal',
        layout: 'home',
        parent: 'GoalsPreviewGoal',
        toParent: true,
      },
    },
  ],
}
