function addUuid() {
  let uuid = 0

  return {
    beforeCreate() {
      this.uuid = uuid
      uuid += 1
    },
  }
}

export default {
  install(Vue) {
    Vue.mixin(addUuid())
  },
}
