import underscorize from '@/helpers/underscorize'

export default {
  getCategoryByKey: state => key => state.optionalData[key],
  getDataById: state => (category, subjectId) => {
    const key = state.optionalDataIdsKeys[subjectId]

    return state.optionalData[underscorize(category)].items[key]
  },
  getDataByKey: state => itemKey => {
    const category = Object.keys(state.optionalDataKeys)
                      .find(key => state.optionalDataKeys[key].includes(itemKey))

    return state.optionalData[category]?.items[itemKey]
  },
  gender: (state, getters) => {
    if (!state.optionalData || !state.optionalDataKeys) return

    const genderData = getters.getDataByKey('gender')

    return genderData?.scheme.meta[genderData.value]?.toLowerCase()
  },
}
