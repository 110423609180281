import router from '@/router'
import rootDispatch from '@/store/helpers/rootDispatch'

const logoutAndOpenDialog = (dialog, props) => {
  rootDispatch('dialog/openDialog', { component: dialog, props })
  rootDispatch('auth/logout')

  setTimeout(() => (
    router.replace({ name: 'AuthLogin' })
  ), 100)
}

export default logoutAndOpenDialog
