<script>
  export default {
    functional: true,

    render(h, { data, children }) {
      return h('div', {
        staticClass: ['blocked-app'].concat(data.staticClass).join(' ').trim(),
      }, children)
    },
  }
</script>

<style lang="scss">
  .blocked-app {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: color(off-white);
  }
</style>
