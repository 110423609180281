<template>
  <journey-section-item
    v-bind="{ isCompleted, isActive }"
  >
    <router-link
      tabindex="0"
      class="journey-article"
      :class="{
        'journey-article--disabled': isDisabled,
        'journey-article--is-active': isActive,
      }"
      data-testid="journey-article"
      :aria-disabled="isDisabled"
      :to="articleLink"
      @click.native="checkIfLaunchFullScreen(item.type)"
    >
      <journey-image
        data-testid="journey-article-image"
        v-bind="{
          hasImage,
          image,
          isCompleted,
        }"
      />
      <div class="journey-article__info">
        <journey-type
          data-testid="journey-article-type"
          :type="item.type"
        />
        <journey-status
          data-testid="journey-article-status"
          v-bind="{ isCompleted }"
        />
        <journey-title
          data-testid="journey-article-title"
          v-bind="{ title }"
        />
      </div>
    </router-link>
  </journey-section-item>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import launchIntoFullscreen from '@/mixins/fullscreen/launchIntoFullscreen'

  import {
    JourneyImage,
    JourneySectionItem,
    JourneyStatus,
    JourneyTitle,
    JourneyType,
  } from './journey-section-item'

  export default {
    components: {
      JourneyImage,
      JourneySectionItem,
      JourneyStatus,
      JourneyTitle,
      JourneyType,
    },

    mixins: [
      launchIntoFullscreen,
    ],

    props: {
      item: {
        type: Object,
        required: true,
      },
    },

    methods: {
      checkIfLaunchFullScreen(type) {
        if (type == 'video' && this.isMobile) {
          this.launchIntoFullscreen()
        }
      },
    },

    computed: {
      ...mapGetters('program', [
        'getCurrentArticleId',
        'isSectionItemCurrent',
      ]),
      ...mapState('layout', [
        'isMobile',
      ]),
      isDisabled() {
        return !this.isCompleted && !this.isActive
      },
      articleLink() {
        return this.isDisabled
          ? '#'
          : {
            name: 'ArticleView',
            params: {
              id: this.item.id,
            },
          }
      },
      articleIcon() {
        try {
          return this.item.icon
        } catch (e) {
          return null
        }
      },
      isActive() {
        return this.isSectionItemCurrent(this.item.id) && !this.isCompleted
      },
      isCompleted() {
        return this.item.opened
      },
      isProfileData() {
        return this.item.type === 'profile-data'
      },
      image() {
        const { articleIcon, isActive, isProfileData } = this

        return isProfileData
          ? `journey-profile-data${isActive ? '' : '-small'}.png`
          : (articleIcon && articleIcon.url ? articleIcon.url : 'card-media.png')
      },
      hasImage() {
        const { articleIcon, isProfileData } = this

        return isProfileData
          ? false
          : Boolean(articleIcon && articleIcon.url)
      },
      title() {
        const { item, isProfileData } = this

        return isProfileData
          ? item.display_name
          : item.title || item.name
      },
    },
  }
</script>

<style lang="scss">
  $articleBlock: 'journey-article';

  .#{$articleBlock} {
    display: inline-flex;
    width: 100%;
    height: 100%;
    text-decoration: none;
    border-radius: 1rem;
    &--disabled {
      cursor: auto;
    }
    &__info {
      display: grid;
      grid-template-areas: 'type status'
      'title title';
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1.6rem 1fr;
      width: 100%;
      padding: 1.6rem 1rem 1.6rem 2rem;
      background-color: color(incomplete-learn-card-background);
      border-radius: 0 1rem 1rem 0;
      @include min-lg {
        grid-template-rows: 2.6rem 1fr;
        padding: 2rem;
      }
    }
    &--is-active {
      .#{$articleBlock}__info {
        background-color: color(active-learn-card-background);
      }
    }
  }
</style>
