<script>
  export default {
    functional: true,

    props: {
      isOutIn: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    render(h, { children, props }) {
      return h(
        'transition', {
          props: {
            mode: props.isOutIn ? 'out-in' : null,
            name: 'fade',
          },
        }, children,
      )
    },
  }
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease-in-out;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
