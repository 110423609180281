<template>
  <div
    ref="item"
    class="search-results-item"
    :class="{
      'search-results-item--is-inactive': !isActive,
    }"
    data-testid="search-results-item"
  >
    <div
      class="search-results-item__container"
      role="row"
    >
      <img
        alt=""
        class="search-results-item__icon"
        :style="{
          '--icon-url': `url(${icon})`,
        }"
      >
      <div
        class="search-results-item__text"
        data-testid="search-results-item-text"
        role="cell"
      >
        <p
          class="search-results-item__truncated"
          data-testid="search-results-item-header"
          :id="`search-item-header-${uuid}`"
        >
          {{ prefix }}:
          <b
            data-testid="search-results-item-title"
            v-html="cleanTitle"
          />
        </p>

        <div
          class="search-results-item__mobile-category"
          data-testid="search-results-item-category"
        >
          {{ convertedSource }}
        </div>

        <p
          v-if="isActive"
          class="search-results-item__truncated"
          data-testid="search-results-item-content"
          v-html="descriptionPreview"
        />

        <router-link
          v-if="isActive && articleLink"
          tabindex="0"
          class="search-results-item__link"
          data-testid="search-results-item-link"
          :aria-describedby="`search-item-header-${uuid}`"
          :to="routerLinkTo"
        >
          {{ i18n('go-to-topic') }}
        </router-link>

        <div
          v-else
          data-testid="search-results-item-locked"
        >
          {{ i18n('unlock-later') }}
        </div>
      </div>

      <div
        class="search-results-item__category"
        data-testid="search-results-item-category"
        role="cell"
      >
        {{ convertedSource }}
      </div>
    </div>
  </div>
</template>

<script>
  import { capitalize } from 'lodash'
  import voca from 'voca'

  import i18n from '@/mixins/i18n'

  export default {
    mixins: [
      i18n,
    ],

    props: {
      articleLink: {
        type: Object,
        required: false,
        default: () => ({ name: 'Home' }),
      },
      content: {
        type: String,
        required: false,
        default: '',
      },
      icon: {
        type: String,
        required: false,
        default: '',
      },
      isActive: {
        type: Boolean,
        required: false,
        default: false,
      },
      query: {
        type: String,
        required: true,
      },
      source: {
        type: String,
        required: false,
        default: '',
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
      type: {
        type: String,
        required: false,
        default: '',
      },
    },

    data() {
      return {
        itemWidth: null,
      }
    },

    methods: {
      highlightQuery(text) {
        const getTag = word => `<span class="search-results-item__highlighted">${word}</span>`

        const translations = this.splittedQuery.reduce((acc, curr) => {
          const capitalized = capitalize(curr)

          acc[curr] = getTag(curr)
          acc[capitalized] = getTag(capitalized)

          return acc
        }, {})

        return voca.tr(text, translations)
      },
      removeHtmlTags(text) {
        const regex = /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});|<[^>]*>|\n/ig

        return text.replace(regex, ' ')
      },
      removeArticleLink(text) {
        const regex = /\[[/article-link].[^[]*\]/gm
        return text.replaceAll(regex, '')
      },
      removeVideoArticle(text) {
        const regex = /\[[/video\-article].[^[]*\]/gm
        return text.replaceAll(regex, '')
      },
    },

    computed: {
      convertedSource() {
        const source = this.source == 'reading_room' ? 'find_answers' : this.source
        const parts = source.split('_')
        const [firstWord, ...others] = parts

        return [capitalize(firstWord), ...others].join(' ')
      },
      prefix() {
        return this.source == 'reading_room'
          ? 'Topic'
          : capitalize(this.type)
      },
      descriptionPreview() {
        if (this.content) {
          const regex = /<p(.*?)>([\w|\d].*?)<\/p>/ig

          const x = this.content.match(regex)
          if (!x) {
            return ''
          }

          const [firstParagraph] = x

          let paragraph = this.removeArticleLink(firstParagraph)
          paragraph = this.removeVideoArticle(paragraph)

          const regexText = />([\w|\d].*?)(?=<\/)/ig
          const [text] = paragraph.match(regexText)
          return text.substring(1)
        }
        return ''
      },
      cleanTitle() {
        return this.highlightQuery(this.removeHtmlTags(this.title || ''))
      },
      splittedQuery() {
        return this.query.toLowerCase().split(' ')
      },
      routerLinkTo() {
        if (!this.articleLink || this.articleLink.name !== 'ReadingRoomArticle' || !this.articleLink.params) {
          return this.articleLink
        }

        const { categoryId, id } = this.articleLink.params

        if (!categoryId || !id) {
          return this.articleLink
        }

        return `/reading-room/${categoryId}/article/${id}`
      },
    },

    slug: 'component.search.search-results-item',
  }
</script>

<style lang="scss">
  .search-results-item {
    margin: 2rem 0 0;
    display: flex;
    justify-content: space-between;
    color: color(dark-primary);
    &--is-inactive {
      color: color(grey);
      img {
        opacity: 0.6;
      }
    }
    &__container {
      display: grid;
      width: 100%;
      @include min-md {
        grid-template-columns: 6.1rem 75% 1fr;
        grid-gap: 1.75rem;
      }
      @include max-md {
        grid-template-columns: 100%;
      }
    }
    &__icon {
      width: 6.1rem;
      height: 6.1rem;
      border-radius: 5rem;
      background-image: var(--icon-url);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @include max-md {
        display: none;
      }
    }
    &__link {
      font-weight: 400;
      text-decoration: underline;
    }
    &__text {
      overflow: visible;
    }
    &__truncated {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__highlighted {
      background-color: color(light-grey);
    }
    &__category {
      font-weight: 600;
      @include max-md {
        display: none;
      }
    }
    &__mobile-category {
      font-weight: 600;
      display: none;
      @include max-md {
        display: block;
      }
    }
  }
</style>
