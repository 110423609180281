import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  fetchResults({ commit }) {
    return new Promise((resolve, reject) => {
      loading('faceAgeTest/fetchResults', true)

      api.get('api/faceage')
        .then(response => {
          /* eslint-disable indent */
          const results = response.shots && response.shots.length
                            ? response.shots
                            : []
          /* eslint-enable */

          commit('setResults', results)
          loading('faceAgeTest/fetchResults', false)

          resolve()
        })
        .catch(() => {
          loading('faceAgeTest/fetchResults', false)

          reject()
        })
    })
  },
  addShot({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('faceAgeTest/addShot', true)

      api.post('api/faceage', form)
        .then(response => {
          commit('setTemporaryResult', response.shot)
          loading('faceAgeTest/addShot', false)

          resolve()
        })
        .catch(() => {
          loading('faceAgeTest/addShot', false)

          reject()
        })
    })
  },
  saveResult({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      loading('faceAgeTest/saveResult', true)

      api.post(`api/faceage/shot/${id}`)
        .then(() => {
          if (state.results) {
            commit('addResult', state.temporaryResult)
          }
          commit('setTemporaryResult', null)
          loading('faceAgeTest/saveResult', false)

          resolve()
        })
        .catch(() => {
          loading('faceAgeTest/saveResult', false)

          reject()
        })
    })
  },
  deleteResult({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading('faceAgeTest/deleteResult', true)

      api.delete(`api/faceage/shot/${id}`)
        .then(() => {
          commit('deleteResult', id)
          loading('faceAgeTest/deleteResult', false)

          resolve()
        })
        .catch(() => {
          loading('faceAgeTest/deleteResult', false)

          reject()
        })
    })
  },
}
