<script>
  export default {
    functional: true,

    props: {
      descriptionId: {
        type: [String, Boolean],
        default: false,
      },
    },
    render(h, { slots, props }) {
      return (
        <div
          aria-live="polite"
          class="error-box"
        >
          <inline-svg
            class="error-box__error-icon"
            role="presentation"
            src="icons/icon-exclamation.svg"
          />
          <div
            class="error-box__content"
            id={ props.descriptionId }
          >
            {slots().default || 'Something went wrong!'}
          </div>
        </div>
      )
    },
  }
</script>

<style lang="scss">

.error-box {
  position: relative;
  font-size: 1.2rem;
  &__content {
    padding: 1rem 1.4rem 1rem 4.8rem;
    color: color(error);
    font-size: 1.4rem;
    font-weight: 600;
    transition: padding 0.15s;
    border: 1px solid color(error);
    background-color: color(_white);
  }
  &__error-icon {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 50%;
    left: 1.4rem;
    transform: translateY(-50%);
    fill: color(error);
  }
}
</style>
