<template>
  <base-dialog
    data-testid="dialog-plugin-locked"
    :headerText="$t(title)"
  >
    <p
      data-testid="dialog-plugin-locked-message"
    >
      {{ $t(message) }}
    </p>

    <template #action>
      <base-button
        data-testid="dialog-plugin-locked-close"
        @click="closeDialog"
      >
        {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  const slug = 'component.dialog.plugin-locked-dialog'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    props: {
      message: {
        type: String,
        required: false,
        default: `${slug}.use-later`,
      },
      title: {
        type: String,
        rquired: false,
        default: `${slug}.plugin-locked`,
      },
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },

    slug,
  }
</script>
