export default () => ({
  activityChart: null,
  activityGoals: [],
  activityPlans: null,
  eatingPlans: null,
  foodGoals: [],
  loggedWeight: null,
  loggedWeightImage: null,
  reviewMode: null,
  messages: {},
  next: null,
  stepsArray: [],
  summary: [],
  token: null,
  hasSummary: false,
  walkingGoal: 0,
})
