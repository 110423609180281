import { formatDateFields } from '@/helpers'

import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

const convertResponse = ({ rootGetters, response }) => {
  const timezone = rootGetters['user/getTimezoneOffset']

  return formatDateFields(response, {
    timezone,
  })
}

export default {
  acceptLegalDocument({ commit }, id) {
    const actionName = 'legalDocument/acceptLegalDocument'

    loading(actionName, true)

    return new Promise((resolve, reject) => {
      api.post('api/document/accept', { id })
        .then(() => {
          commit('removeLegalDocument', id)

          resolve()
        })
        .catch(reject)
        .finally(() => loading(actionName, false))
    })
  },

  fetchAllLegalDocuments({ commit, rootGetters }) {
    const actionName = 'legalDocument/fetchAllLegalDocuments'
    loading(actionName, true)

    return new Promise((resolve, reject) => {
      api.get('api/document/me')
        .then(response => {
          const data = convertResponse({
            rootGetters,
            response,
          })
          commit('setAcceptedLegalDocuments', data)

          resolve()
        })
        .catch(reject)
        .finally(() => loading(actionName, false))
    })
  },

  fetchLegalDocument({ commit, rootGetters }, { id }) {
    const actionName = 'legalDocument/fetchLegalDocument'
    loading(actionName, true)

    return new Promise((resolve, reject) => {
      api.get(`api/document/${id}`)
        .then(response => {
          const legalDocument = convertResponse({
            rootGetters,
            response,
          })
          commit('addLegalDocument', legalDocument)

          resolve()
        })
        .catch(reject)
        .finally(() => loading(actionName, false))
    })
  },

  fetchLegalDocumentsByIds({ commit, rootGetters }, { document_ids }) {
    const actionName = 'legalDocument/fetchLegalDocumentsByIds'
    loading(actionName, true)

    return new Promise((resolve, reject) => {
      api.post('api/document/many-not-accepted', { document_ids })
        .then(response => {
          const data = convertResponse({
            rootGetters,
            response,
          })

          commit('addLegalDocuments', data)

          resolve()
        })
        .catch(reject)
        .finally(() => loading(actionName, false))
    })
  },
}
