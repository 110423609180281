import { isNil } from 'lodash'
import { Validator } from 'vee-validate'

const getRequireObject = valid => ({ valid, data: { required: true } })

const requiredObject = {
  getMessage: () => 'This is a required field',
  validate(value, { keys = [] }) {
    if (isNil(value)) return getRequireObject(false)

    const valid = keys.every(key => !isNil(value[key]))

    return getRequireObject(valid)
  },
}

const paramNames = ['keys']

Validator.extend('required_object', requiredObject, { computesRequired: true, paramNames })
