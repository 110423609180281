<template>
  <base-dialog
    class="package-not-enabled-dialog"
    data-testid="dialog-package-not-enabled"
    :headerText="$t('Your package is not enabled')"
  >
    {{ $t('Package assigned to your account isn\'t enabled.') }}

    <template #action>
      <base-button
        data-testid="dialog-package-not-enabled-close"
        @click="closeDialog"
      >
        {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },
  }
</script>

<style lang="scss">
  .tracking-goals-dialog.dialog {
    min-width: 90%;
    max-width: none;
    @media all and (min-width: map-get($breakpoints, sm)) {
      min-width: 50rem;
    }
  }
</style>
