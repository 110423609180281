const fieldDefinitionsRules = [
  cannotBeEmptyRule('name'),
  mustBeStringRule('name'),
  cannotBeEmptyRule('type'),
  mustBeStringRule('type'),
]

const fieldsGroupRules = [
  {
    isValid: ({ formFields }) => formFields && formFields instanceof Array,
    errorMsg: 'Group of fields should have fields and they should be an Array',
  },
  {
    isValid: ({ type }) => type === undefined || type === null,
    errorMsg: 'Group of fields cannot have type',
  },
]

function mustBeStringRule(key) {
  return {
    isValid: obj => typeof obj[key] === 'string' || obj[key] instanceof String,
    errorMsg: `"${key}" must be a string`,
  }
}

function cannotBeEmptyRule(key) {
  return {
    isValid: obj => !!obj[key],
    errorMsg: `"${key}" cannot be empty!`,
  }
}

function isFieldDefinition(prop) {
  return validateRules(fieldDefinitionsRules, prop)
}

function isFieldsGroup(prop) {
  return validateRules(fieldsGroupRules, prop)
}

function validateRules(rules, prop) {
  const errors = []
  let valid = true

  rules.forEach(({ isValid, errorMsg }) => {
    if (!isValid(prop)) {
      valid = false
      errors.push(errorMsg)
    }
  })

  displayErrors(errors)

  return valid
}

function displayErrors(errors) {
  if (errors.length <= 0) return

  const errorMsg = errors.reduce((acc, msg) => `${acc}\n${msg}`, '')

  throw new Error(errorMsg)
}

export { isFieldDefinition, isFieldsGroup }
