export default {
  triggerEvent({ commit, state }, { eventName, params }) {
    if (state.canBeDispatched && state.ma) {
      return state.ma.trackEvent({
        action: eventName,
        properties: {
          ...params,
        },
      })
    } else {
      return commit('addToQueue', {
        eventName,
        params,
      })
    }
  },
  setCanBeDispatched({ commit }, value) {
    commit('setCanBeDispatched', value)
  },
  dispatchAll({ commit, state, dispatch }) {
    if (state.canBeDispatched) {
      state.toDispatch.forEach(el => dispatch('triggerEvent', el))
      commit('clearQueue')
    }
  },
  setUserData({ state, rootState }, { program, user }) {
    const { unit_id: unitId } = user
    const unit = user.unit?.find(({ id }) => id === unitId)

    const sProps = {
      distinct_id: user.ppuid,
      $email: user.email,
      $first_name: user.first_name,
      participant_partner_UID: user.suid,
      participant_status: user.status,
      participant_status_date: user.updated_at,
      participant_partner: unit?.name,
      participant_partner_ID: unit?.id,
      participant_partner_unit: unit?.organization?.name,
      participant_partner_unit_ID: unit?.organization?.id,
      participant_program_SysName: program.system_name,
      participant_program_ID: program.id,
      participant_package_name: rootState._package.package.system_name,
      participant_package_ID: rootState._package.package?.id,
    }

    return Promise.all([
      state.ma.identify({ userId: user.ppuid }),
      state.ma.setUserProperties(sProps),
      state.ma.setSuperProperties(sProps),
    ])
  },
  reset({ state }) {
    state.ma.reset()
  },
  setMa({ commit }, ma) {
    commit('setMa', ma)
  },
  setMam({ commit }, mam) {
    commit('setMam', mam)
  },
}
