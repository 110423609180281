import { UNIT_CONVERTERS } from '@/constants'
import getCssColorVariable from '@/helpers/getCssColorVariable'

export default {
  getChartDataByTimePeriod: (state, getters, rootState, rootGetters) => period => {
    const colorDarkPrimary = getCssColorVariable('dark-primary')
    const colorPrimary = getCssColorVariable('primary')
    const initialBackgroundColors = state[period].value.map((val, i) => (
      i == state[period].value.length - 1 ? colorPrimary : colorDarkPrimary
    ))
    const isMetric = rootGetters['user/isMetric']
    const isPounds = rootGetters['user/isPounds']

    const format = value => {
      if (isMetric) {
        return { value }
      }
      const valueToPounds = Math.round(value * UNIT_CONVERTERS.kgInPounds)
      if (isPounds) {
        return { value: valueToPounds }
      }
      return {
        stones: parseInt(valueToPounds / UNIT_CONVERTERS.poundsInStones, 10),
        pounds: parseInt(valueToPounds % UNIT_CONVERTERS.poundsInStones, 10),
      }
    }

    return {
      data: {
        labels: state[period].label,
        datasets: [{
          pointBackgroundColor: initialBackgroundColors,
          pointHoverBackgroundColor: initialBackgroundColors,
          borderColor: colorPrimary,
          pointBorderColor: state[period].value.map(() => (
            colorDarkPrimary
          )),
          borderWidth: 4,
          pointRadius: 8,
          pointHitRadius: 8,
          pointBorderWidth: 4,
          pointHoverBorderWidth: 4,
          pointHoverRadius: 8,
          fill: false,
          lineTension: 0,
          spanGaps: true,
          data: state[period].value
            .map(format),
        }],
      },
    }
  },
}
