import Vue from 'vue'

import { simplifyTriggers } from './helpers'
import initialState from './state'

export default {
  setTriggers(state, payload) {
    const payloadTriggers = simplifyTriggers(payload)

    state.triggers = payloadTriggers.map(payloadTrigger => {
      const stateTrigger = state.triggers.find(({ id }) => id === payloadTrigger.id)

      if (!stateTrigger) return payloadTrigger

      const { unlocked_at: payloadUnlockedAt } = payloadTrigger || {}
      const { unlocked_at: stateUnlockedAt } = stateTrigger || {}

      return payloadUnlockedAt == null && stateUnlockedAt !== null ? stateTrigger : payloadTrigger
    })
  },
  setUpdatedTriggers(state, { changes, pluginId }) {
    const filtered = state.triggers
      .filter(trigger => trigger.plugin_id != pluginId)
    const updated = simplifyTriggers(changes)

    state.triggers = [...updated, ...filtered]
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
  addUpdating(state, id) {
    state.updating.push(id)
  },
  removeUpdating(state, id) {
    state.updating = state.updating.filter(v => v !== id)
  },
}
