import Vue from 'vue'

import store from '@/store'

import authChecker from './authChecker'
import awaitTicks from './awaitTicks/awaitTicks'
import inlineSvg from './inlineSvg'
import multiAnalytics from './multiAnalytics'
import uuid from './uuid'

Vue.use(authChecker, {
  store,
  storage: localStorage,
})
Vue.use(awaitTicks)
Vue.use(inlineSvg)
Vue.use(multiAnalytics)
Vue.use(uuid)
