<template>
  <div>
    <form-group
      v-bind="{
        descriptionId : 'country-autocomplete-container',
        name: 'country-autocomplete-container',
        testid,
        validation: {}
      }"
    >
      <div class="nhsuk-form-group input-group">
        <label
          class="input-group__label"
          for="country-autocomplete">
          {{ $t('Country') }}
        </label>
        <div
          id="country-autocomplete-container"/>
      </div>
    </form-group>
    <form-group
      :events="['change']"
      :mode="'lazy'"
      ref="mobilePhoneFormGroup"
      class="phone__form-group"
      v-bind="{
        descriptionId: 'mobil-number',
        name: 'mobil-number',
        testid,
        validation: validationMobile,
        tag,
      }"
      v-slot="{ errors }"
      >
      <div
        class="nhsuk-form-group input-group"
        :class="{ 'nhsuk-form-group--error': errors.length}">
        <label
          class="input-group__label"
          for="mobil-number">
          {{ $t('Mobile phone') }}
        </label>
        <div
          class="nhsuk-hint"
          data-testid="mobil-input-hint">
          {{ $t('For example, +447111111111') }}
        </div>
        <span
          v-if="errors.length > 0"
          class="nhsuk-error-message"
          id="mobile-number-error">
          <span class="nhsuk-u-visually-hidden">{{ i18n('(Error)') }}</span>
          {{ errors[0] }}
        </span>
        <input
          id="mobil-number"
          class="nhsuk-input input-group__input input-tel"
          data-testid="mobil-number"
          name="mobil-number"
          aria-describedby="mobile-number-error"
          v-model="modelMobile">
      </div>
    </form-group>
    <form-group
      :events="['change']"
      :mode="'lazy'"
      ref="landlinePhoneFormGroup"
      class="phone__form-group"
      v-bind="{
        descriptionId: 'landline-number',
        name: 'landline-number',
        testid,
        validation: validationLandline,
        tag,
      }"
      v-slot="{ errors }"
      >
      <div
        class="nhsuk-form-group input-group"
        :class="{ 'nhsuk-form-group--error': errors.length}">
        <label
          class="input-group__label"
          for="landline-number">
          {{ $t('Landline number') }}
        </label>
        <div
          class="nhsuk-hint"
          data-testid="landline-input-hint">
          {{ $t('For example, +442011111111 or +441302111111') }}
        </div>
        <span
          v-if="errors.length > 0"
          class="nhsuk-error-message"
          id="landline-number-error">
          <span class="nhsuk-u-visually-hidden">{{ i18n('(Error)') }}</span>
          {{ errors[0] }}
        </span>
        <input
          id="landline-number"
          class="nhsuk-input input-group__input input-tel"
          data-testid="landline-number"
          name="landline-number"
          aria-describedby="landline-number-error"
          v-model="modelLandline">
      </div>
    </form-group>
  </div>
</template>

<script>
  import accessibleAutocomplete from 'accessible-autocomplete'
  import { PhoneNumberUtil } from 'google-libphonenumber'

  import FormGroup from '@/components/forms/form-group/FormGroup'

  import i18n from '@/mixins/i18n'
  import testid from '@/mixins/testid/testid'

  import countryPhoneCode from './countryPhoneCode.json'

  const phoneUtil = PhoneNumberUtil.getInstance()

  const BASE_LOCALE = 'component.forms.phones-group'

  export default {
    components: {
      FormGroup,
    },

    mixins: [
      testid(),
      i18n,
    ],

    props: {
      isAnyRequired: {
        type: Boolean,
        required: true,
      },
      labelLandline: {
        type: String,
        default: `${BASE_LOCALE}.landline`,
      },
      labelMobile: {
        type: String,
        default: `${BASE_LOCALE}.mobile`,
      },
      landline: {
        type: String,
        required: false,
        default: '',
      },
      mobile: {
        type: String,
        required: false,
        default: '',
      },
      tooltipLandline: {
        type: String,
        required: false,
        default: '',
      },
      tooltipMobile: {
        type: String,
        required: false,
        default: '',
      },
      validationLandline: {
        type: Object,
        required: false,
        default: () => {},
      },
      validationMobile: {
        type: Object,
        required: false,
        default: () => {},
      },
      tag: {
        type: String,
        required: false,
        default: 'div',
      },
    },

    mounted() {
      const countriesName = countryPhoneCode.map(({ countryName }) => countryName)
      let defaultValue
      let item

      if (this.mobile) {
        const number = phoneUtil.parseAndKeepRawInput(
          this.mobile,
        )
        item = countryPhoneCode
          .find(({ phoneCode }) => parseInt(phoneCode, 10) === number.getCountryCode())
        defaultValue = item.countryName
      } else {
        defaultValue = 'United Kingdom'
        item = countryPhoneCode.find(({ countryName }) => countryName === defaultValue)
      }
      this.codePhone = item.phoneCode
      accessibleAutocomplete({
        element: document.querySelector('#country-autocomplete-container'),
        id: 'country-autocomplete',
        source: countriesName,
        defaultValue,
        onConfirm: confirmed => {
          const itemConfirmed = countryPhoneCode
            .find(({ countryName }) => countryName === confirmed)
          const oldCodePhone = this.codePhone
          this.codePhone = itemConfirmed.phoneCode
          if (oldCodePhone !== '') {
            const value = this.modelMobile.substr(3)
            this.setModelMobile(value)
          }
        },
        confirmOnBlur: false,
      })
    },

    watch: {
      isOpen() {
        this.$emit('update:isOpen', this.isOpen)
      },
    },

    data() {
      return {
        isOpen: false,
        codePhone: '',
      }
    },

    methods: {
      validateMobile(mobile) {
        if (mobile === '') {
          return true
        }
        const number = phoneUtil.parseAndKeepRawInput(
          mobile,
        )
        return phoneUtil.isValidNumber(number)
      },
      setModelMobile(value) {
        try {
          this.validateMobile(value)
          return value
        } catch (errNoCountryCode) {
          try {
            this.validateMobile(`+${this.codePhone}${value}`)
            return `+${this.codePhone}${value}`
          } catch (error) {
            return value
          }
        }
      },
    },

    computed: {
      modelMobile: {
        get() {
          return this.mobile
        },
        set(value) {
          this.$emit('update:mobile', this.setModelMobile(value))
        },
      },
      modelLandline: {
        get() {
          return this.landline
        },
        set(value) {
          this.$emit('update:landline', this.setModelMobile(value))
        },
      },
    },
  }
</script>

<style lang="scss">
    .nhsuk-form-group--error {
      padding-left: 16px !important;
        .input-tel {
            border-color: color(error);
        }
    }

    .input-tel.input-group__input {
      padding: 1rem !important;
      font-size: 1.4rem;
    }

    .autocomplete__input {
      border: 1px solid color(input-border) !important;
      caret-color: color(input-border);
      width: 100%;
      height: 4rem;
      padding: 1rem;
      color: var(--color-dark-grey);
      font-size: 1.4rem !important;
    }
</style>
