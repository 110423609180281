import { mapMutations, mapState } from 'vuex'

export default {
  methods: {
    ...mapMutations('history', [
      'setUnauthorizedlyAccessedRoute',
    ]),
    redirectToApplication() {
      const path = this.unauthorizedlyAccessedRoute
      const to = path
        ? { path }
        : { name: 'Home' }

      this.$router.push(to)
      this.setUnauthorizedlyAccessedRoute(null)
    },
  },

  computed: {
    ...mapState('history', [
      'unauthorizedlyAccessedRoute',
    ]),
  },
}
