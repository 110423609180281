<template>
  <div
    class="overlay"
    :class="{ 'overlay--is-visible': isVisible }"
    :data-testid="dataTestid"
  />
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    watch: {
      isVisible: {
        handler(value) {
          this.set({
            value,
            state: 'isGlobalAriaHidden',
          })
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions('layout', [
        'set',
      ]),
    },

    computed: {
      ...mapGetters('legalDocument', [
        'hasLegalDocumentToAccept',
      ]),
      ...mapState('layout', [
        'isFabOpen',
      ]),
      ...mapState('dialog', [
        'isDialogOpen',
      ]),
      isVisible() {
        return this.isFabOpen
          || (this.isDialogOpen && !this.hasLegalDocumentToAccept)
      },
      dataTestid() {
        return [
          'overlay',
          this.isVisible ? 'overlay-is-visible' : null,
        ].join(' ').trim()
      },
    },
  }
</script>

<style lang="scss">
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparentize(#000, 0.15);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: z-index(overlay);
    &--is-visible {
      opacity: 1;
      visibility: visible;
    }
  }
</style>
