import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/capturing-bmi/',
    sharedMeta: {
      type: PAGE_TYPES.plugin,
    },
  },
  routes: [
    {
      path: '/capturing-bmi',
      name: 'CapturingBMI',
      meta: {
        fetchProgram: false,
        header: 'Add profile data',
        layout: 'home',
        parent: 'Tools',
        toParent: true,
        trigger: 'capturing-bmi',
        unblockPluginAt: 'CapturingBMI',
      },
    },
  ],
}
