<template>
  <validation-observer
    class="form"
    ref="observer"
    :data-testid="dataTestid"
    :tag="'form'"
    v-merge-attr:data-testid
    v-slot="{ observerData, errors }"
    @submit.prevent="onSubmit"
  >
    <summary-errors
      v-if="showSummaryErrors && onSubmitClicked"
      :summaryErrors="errors" />

    <slot
      v-bind="observerData"
    />
    <div
      v-if="hasEndSection"
      class="form__end-row"
    >
      <base-button
        v-if="hasCancelButton"
        light
        align-start
        no-margin
        :data-testid="`${dataTestid}-cancel`"
        :disabled="isSaving"
        :isLoading="isSaving"
        :type="'button'"
        @click="onCancel"
      >
        <slot name="button">
          {{ $t(cancelText) }}
        </slot>
      </base-button>

      <base-button
        v-if="hasSubmitButton"
        no-margin
        :data-testid="`${dataTestid}-submit`"
        :disabled="isSaving"
        :isLoading="isSaving"
        :type="'submit'"
        @click="onClick"
      >
        <slot name="button">
          {{ $t(submitText) }}
        </slot>
      </base-button>
    </div>
  </validation-observer>
</template>

<script>
  import { ValidationObserver } from 'vee-validate'

  import { BaseButton } from '@/components/base'
  import SummaryErrors from '@/components/forms/summary-errors/SummaryErrors'

  import mergeAttr from '@/directives/mergeAttr'

  import forceAnnounce from '@/mixins/forceAnnounce'
  import scrollToTop from '@/mixins/scrollToTop'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      BaseButton,
      ValidationObserver,
      SummaryErrors,
    },

    directives: {
      mergeAttr,
    },

    mixins: [
      forceAnnounce,
      testid('base-form'),
      scrollToTop,
    ],

    props: {
      hasSubmitButton: {
        type: Boolean,
        required: false,
        default: true,
      },
      hasCancelButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      isSaving: {
        type: Boolean,
        required: false,
        default: false,
      },
      submitText: {
        type: String,
        required: false,
        default: 'Save changes',
      },
      cancelText: {
        type: String,
        required: false,
        default: 'Cancel',
      },
      linkTocancel: {
        type: String,
        required: false,
        default: 'Login',
      },
      showSummaryErrors: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        onSubmitClicked: false,
      }
    },

    methods: {
      async onSubmit() {
        const isValid = await this.$refs.observer.validate()
        this.onSubmitClicked = true

        if (!isValid) this.scrollToTop()

        this.$emit('submit', isValid)
      },
      onCancel() {
        this.$router.back()
      },
      onClick() {
        this.$emit('click')
      },
      reset() {
        this.$refs.observer.reset()
      },
      validate(args) {
        return this.$refs.observer.validate(args)
      },
      focusFirstInvalid() {
        const firstInvalid = this.$el?.querySelector('[aria-invalid=true]')

        if (!firstInvalid) return

        firstInvalid.focus()

        const error = firstInvalid
          .closest('fieldset')
          ?.querySelector('.input-details__error')
          ?.textContent

        if (!error) return

        this.forceAnnounce(error)
      },
    },

    computed: {
      hasEndSection() {
        return this.hasSubmitButton
      },
    },
  }
</script>

<style lang="scss">
  .form {
    display: flex;
    flex-direction: column;
    &__end-row {
      display: flex;
      flex-direction: column;
      @include min-lg {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    &__inverse-required-msg {
      margin: 1.2rem 0 0;
      @include min-lg {
        margin: 0;
      }
    }
  }
</style>
