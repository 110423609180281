<template>
  <details
    class="nhsuk-details nhsuk-expander"
    v-bind="{open: isExpanded}"
    @toggle="toggle"
    >
    <summary class="nhsuk-details__summary">
      <span class="nhsuk-details__summary-text nhsuk-u-font-size-24">
        {{ title }}
      </span>
    </summary>
    <div
      class="nhsuk-details__text"
      v-if="isExpanded">
      <slot/>
    </div>
  </details>
</template>

<script>

  export default {
    props: {
      isFullWidth: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      startExpanded: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        isExpanded: this.startExpanded,
      }
    },

    methods: {
      toggle({ target }) {
        this.isExpanded = target.open
      },
    },
  }
</script>

<style lang="scss">
  .nhsuk-details__summary-text {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .nhsuk-details__text, .nhsuk-details__summary {
    text-align: left;
  }
</style>
