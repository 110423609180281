<template>
  <base-dialog
    data-testid="dialog-kiosk-mode-enabled"
    :fullscreenOnClickOutside="!isPWA"
    :headerText="$t(`${$options.slug}.title`, { mode })"
  >
    {{ $t(`${$options.slug}.content`, { mode }) }}

    <template #action>
      <base-button
        data-testid="dialog-kiosk-mode-enabled-close"
        @click="close"
      >
        {{ $t(`${$options.slug}.button`) }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  import launchIntoFullscreen from '@/mixins/fullscreen/launchIntoFullscreen'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    mixins: [
      launchIntoFullscreen,
    ],

    props: {
      mode: {
        type: String,
        required: true,
        default: '',
      },
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
      close() {
        this.closeDialog()

        if (!this.isPWA) {
          this.launchIntoFullscreen()
        }
      },
    },

    computed: {
      ...mapState('layout', [
        'isPWA',
      ]),
    },

    slug: 'component.dialog.enabled-kiosk-mode-dialog',
  }
</script>
