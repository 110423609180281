import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/temptations-quiz/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Winning Against Temptations',
      layout: 'home',
      toParent: true,
      trigger: 'winning-against-temptations',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'TemptationsResults',
    },
  },
  routes: [
    {
      path: '/winning-against-temptations',
      name: 'Temptations',
      meta: {
        parent: 'Tools',
      },
    },
    {
      path: '/winning-against-temptations/quiz',
      name: 'TemptationsQuiz',
      props: true,
      meta: {
        parent: 'Temptations',
      },
    },
    {
      path: '/winning-against-temptations/results',
      name: 'TemptationsResults',
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
