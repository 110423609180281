import rootDispatch from '@/store/helpers/rootDispatch'

export default (ctx, store, BroadcastChannel) => {
  const channel = new BroadcastChannel('tc')
  ctx.commit('setBroadcastChannelLegalDocuments', channel)

  const removeLegalDocument = id => {
    if (ctx.state.legalDocuments[id]) {
      ctx.commit('removeLegalDocument', id)
      if (store.state.dialog.dialog.component == 'LegalDocument') {
        rootDispatch('dialog/closeDialog')
      }
    }
  }

  channel.addEventListener('message', removeLegalDocument)

  store.subscribe(mutation => {
    const { payload, type } = mutation

    if (type == 'auth/logout' || type == 'auth/resetState') {
      channel.removeEventListener('message', removeLegalDocument)
      channel.close()
    } else if (type == 'legalDocument/removeLegalDocument') {
      try {
        channel.postMessage(payload)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  })
}
