<template>
  <base-form
    ref="form"
    class="stepper-form"
    data-testid="stepper-form"
    :hasSubmitButton="false"
    @submit="onSubmit">
      <stepper-custom
        ref="stepper"
        is-form
        :testid="dataTestid"
        v-bind="{
          buttonLabels,
          eventCallbacks,
          hasCancel,
          hideStepCount,
          isLoading,
          isNextDisabled,
          validateStep,
          displayLastButtons,
        }"
        @cancel="reemitEvent('cancel', $event)"
        @next="reemitEvent('next', $event)"
        @previous="reemitEvent('previous', $event)"
        @step-set="reemitEvent('step-set', $event)"
        @step-changed="reemitEvent('step-changed', $event)"
        @last-step-accessed="reemitEvent('last-step-accessed', $event)"
        @focus-invalid="onFocusInvalid"
        v-slot="{ maxStep }"
      >
        <slot v-bind="{ maxStep }"/>
      </stepper-custom>
  </base-form>
</template>

<script>
  import BaseForm from '@/components/base/BaseForm'
  import StepperCustom from '@/components/stepper-custom/StepperCustom'

  import testid from '@/mixins/testid/testid'

  export default {
    inheritAttrs: false,

    components: {
      BaseForm,
      StepperCustom,
    },

    mixins: [
      testid('stepper-custom-form'),
    ],

    provide() {
      return {
        registerValidationObserver: observer => this.validationObservers.push(observer) - 1,
        unregisterValidationObserver: index => {
          if (index < 0) return

          this.validationObservers.splice(index, 1)
        },
        stepperFormPresent: true,
      }
    },

    props: {
      buttonLabels: {
        type: Object,
        default: null,
      },
      eventCallbacks: {
        type: Object,
        default: () => ({}),
      },
      hasCancel: {
        type: Boolean,
        default: true,
      },
      hideStepCount: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      isNextDisabled: {
        type: Boolean,
        default: false,
      },
      displayLastButtons: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        validationObservers: [],
      }
    },

    methods: {
      changeStep(step, event = 'step-set') {
        this.$refs.stepper.changeStep(step, event)
      },
      reemitEvent(name, data) {
        this.$emit(name, data)
      },
      onSubmit(isValid) {
        if (!isValid) {
          const invalidStep = this.getInvalidStep()

          if (invalidStep !== -1) this.$refs.stepper.currentStep = invalidStep
        } else {
          this.$emit('submit', isValid)
        }
      },
      getInvalidStep() {
        let invalidStep = -1
        this.validationObservers.find(({ isValid, getStepIndex }) => {
          if (!isValid) invalidStep = getStepIndex()

          return !isValid
        })

        return invalidStep
      },
      validateStep(step) {
        const { validate } = this.validationObservers
          .find(({ getStepIndex }) => getStepIndex() === step) || {}

        if (!validate) return Promise.resolve([])

        return validate()
      },
      onFocusInvalid() {
        this.$refs.form.focusFirstInvalid()
      },
    },

    computed: {
      stepperMaxStep() {
        return this.$refs?.stepper?.maxStep
      },
    },
  }
</script>
