export default class DeferredPromise {
  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this.resolve = value => {
        resolve(value)

        return this._promise
      }
      this.reject = reason => {
        reject(reason)

        return this._promise
      }
    })

    this.then = this._promise.then.bind(this._promise)
    this.catch = this._promise.catch.bind(this._promise)
    this[Symbol.toStringTag] = 'Promise'
  }
}
