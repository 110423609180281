import { sortBy } from 'lodash'

export default {
  visibleWidgets: ({ widgets, mobileWidgets }, getters, rootState, rootGetters) => {
    /* eslint-disable camelcase */
    const unlockedPluginIds = rootGetters['triggers/getUnlockedTriggers'].map(({ plugin_id }) => plugin_id)

    let columnsW = []
    if (widgets) {
      ({ columns: columnsW } = widgets)
    }
    const _widgets = {
      columns: columnsW.map(({ items }) => ({
        items: sortBy(items, [
          ({ display_orientation }) => -display_orientation,
          ({ order }) => order,
        ]).filter(({ type, plugin_id }) => (
          type !== 'plugin_widget' || unlockedPluginIds.includes(plugin_id)
        )),
      })),
    }

    let columnsMW = []
    if (mobileWidgets) {
      ({ columns: columnsMW } = mobileWidgets)
    }
    const _mobileWidgets = {
      columns: columnsMW.map(({ items }) => ({
        items: items.filter(({ type, plugin_id }) => (
          type !== 'plugin_widget' || unlockedPluginIds.includes(plugin_id)
        )),
      })),
    }
    /* eslint-enable camelcase */
    return { widgets: _widgets, mobileWidgets: _mobileWidgets }
  },
}
