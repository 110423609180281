const normalize = (response, property = 'id') => {
  const isId = property == 'id'
  const items = response
                  .reduce((acc, curr) => {
                    const key = isId
                                  ? curr[property]
                                  : curr[property].replace(/-/g, '_')
                    return Object.assign(acc, { [key]: curr })
                  }, {})

  const keys = Object.keys(items)

  const ids = isId
                ? keys.map(Number)
                : keys

  return { ids, items }
}

export default normalize
