import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/coaching-plugin/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Coaching',
      trigger: ['coaching-plugin', 'coaching'],
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'CoachingPlugin',
    },
  },
  routes: [
    {
      path: '/coaching',
      name: 'CoachingPlugin',
      meta: {
        layout: 'home',
        parent: 'Tools',
        toParent: true,
        hasFabFixed: false,
        hasTabs: true,
        transition: true,
        pluginHome: true,
      },
    },
    {
      path: '/coaching/start',
      name: 'CoachingPluginStart',
      props: true,
      meta: {
        layout: 'home',
        parent: 'Tools',
        toParent: true,
      },
    },
    {
      path: '/coaching/start/match',
      name: 'CoachingPluginStartMatch',
      props: true,
      meta: {
        header: 'Coaching availability',
        layout: 'home',
        parent: 'Tools',
        toParent: true,
      },
    },
    {
      path: '/coaching/start/no-match',
      name: 'CoachingPluginStartNoMatch',
      props: true,
      meta: {
        header: 'Coaching availability',
        layout: 'home',
        parent: 'Tools',
        toParent: true,
      },
    },
    {
      path: '/coaching/content',
      name: 'CoachingPluginContent',
      meta: {
        layout: 'home',
        parent: 'CoachingPlugin',
        toParent: true,
        hasFabFixed: false,
        hasTabs: true,
        transition: true,
      },
    },
    {
      path: '/coaching/appointments/new',
      name: 'CoachingPluginNewAppointment',
      component: 'CoachingPluginNewEditAppointment',
      meta: {
        header: 'New appointment',
        layout: 'home',
        parent: 'CoachingPlugin',
        toParent: true,
      },
    },
    {
      path: '/coaching/appointments/:id',
      name: 'CoachingPluginShowAppointment',
      props: true,
      meta: {
        header: 'Coaching appointment',
        layout: 'home',
        parent: 'CoachingPlugin',
        toParent: true,
      },
    },
    {
      path: '/coaching/appointments/:id/edit',
      name: 'CoachingPluginEditAppointment',
      component: 'CoachingPluginNewEditAppointment',
      props: true,
      meta: {
        header: 'Coaching appointment',
        layout: 'home',
        parent: 'CoachingPlugin',
      },
    },
    {
      path: '/coaching/content/:id',
      name: 'CoachingPluginShowContent',
      props: true,
      meta: {
        header: 'Coaching content',
        layout: 'home',
        parent: 'CoachingPluginContent',
        toParent: true,
      },
    },
    {
      path: '/coaching/content/articles/:id',
      name: 'CoachingPluginContentArticle',
      props: true,
      meta: {
        layout: 'home',
        parent: 'CoachingPluginContent',
      },
    },
    {
      path: '/coaching/coach',
      name: 'CoachingPluginCoach',
      meta: {
        header: 'Coach profile',
        layout: 'home',
        parent: 'CoachingPlugin',
        toParent: true,
      },
    },
  ],
}
