import getCssColorVariable from './getCssColorVariable'
import hexToRgb from './hexToRgb'

const cssColorVariableToRgba = (color, alpha = 1) => {
  const rgb = hexToRgb(getCssColorVariable(color)).toString()

  return `rgba(${rgb}, ${alpha})`
}

export default cssColorVariableToRgba
