import Vue from 'vue'

import initialState from './state'

export default {
  setFirstLoad(state, payload) {
    state.firstLoad = payload
  },
  setSearchResults(state, payload) {
    state.searchResults = payload
  },

  setSearchResultsPagination(state, payload) {
    state.searchResultsPagination = payload
  },

  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
