// The Vue build version to load with the `import` command
/* eslint-disable */
import 'element-closest-polyfill'
import 'element-scroll-polyfill'
import 'focus-within-polyfill'
import findIndex from 'array.prototype.findindex'
import fromEntries from 'object.fromentries'
import some from 'array.prototype.some'
findIndex.shim()
fromEntries.shim()
some.shim()

import './utils'

import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import PortalVue from 'portal-vue'
import Ripple from 'vue-ripple-directive'
import VueStatic from 'vue-static'
import VueMeta from 'vue-meta'

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import initOffline from './offline'

import './filters/globalFilters'
import './plugins'

import { i18n } from './i18n'
import validator from './validator'

import VueAnnouncer from '@vue-a11y/announcer'

validator.init(i18n)

/* Initialize offline stuff to run PWA */
initOffline()

Vue.use(VueMeta)

Ripple.color = 'rgba(255, 255, 255, 0.1)'
Vue.directive('ripple', Ripple)

Vue.use(PortalVue)
Vue.use(VueStatic, {
  namespaced: true,
})
Vue.use(VueAnnouncer, {
  complementRoute: i18n?.messages?.[process.env.VUE_APP_I18N_LOCALE]?.['has loaded'],
}, router)

if (process.env.VUE_APP_SENTRY_DSN) {
  try {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [new Integrations.Vue({Vue, attachProps: true})],
      environment: process.env.VUE_APP_LOGO,
    });
  } catch (error) {
    console.error('Sentry Init ERROR: ', {error});
  }
}

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  i18n,
  template: '<App/>'
})
