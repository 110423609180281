import { camelCase } from 'lodash'
import { mapState } from 'vuex'

import { breakpoints } from '@/assets/styles/utils/variables/breakpoints.json'

const isLessOrEqual = (value, breakpoint) => value <= breakpoint
const isGreater = (value, breakpoint) => value > breakpoint

const comparisonMethods = {
  min: isGreater,
  max: isLessOrEqual,
}

const getComputedName = media => camelCase(`is-${media}`)

const matchMedia = ({
  media,
}) => ({
  computed: {
    ...mapState('layout', [
      'wW',
    ]),
    [getComputedName(media)]() {
      const [type, breakpoint] = media.split('-') ?? []
      const breakpointValue = parseInt(breakpoints[breakpoint], 10)
      const compareMethod = comparisonMethods[type]

      return compareMethod(this.wW, breakpointValue)
    },
  },
})

export default matchMedia
