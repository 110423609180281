class PhoneTakenError extends Error {
  constructor() {
    super(
      'Something went wrong. Please contact Changing Health.',
    )

    this.name = 'PhoneTakenError'
  }
}

export default PhoneTakenError
