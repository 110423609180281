import CustomError from './CustomError'

class TwoFactorAuthError extends CustomError {
  constructor(error) {
    const errorMessage = Object.values(error?.response?.data?.errors || {}).join('')
      || 'Enabling / disabling two-factor authentication is temporarily impossible'

    super(errorMessage, '2FA code change error')
  }
}

export default TwoFactorAuthError
