import Vue from 'vue'

import deleteProperty from '@/helpers/deleteProperty'

import rootDispatch from '@/store/helpers/rootDispatch'

import initialState from './state'

export default {
  addLegalDocuments(state, payload) {
    const legalDocuments = payload || []

    legalDocuments.forEach(legalDocument => {
      const { version_id: id } = legalDocument

      if (state.legalDocuments[id]) return

      Vue.set(state.legalDocuments, [id], legalDocument)
      state.legalDocumentsOrder.push(id)
    })
  },
  addLegalDocument(state, payload) {
    const { version_id: id } = payload

    if (state.legalDocuments[id]) return

    Vue.set(state.legalDocuments, [id], payload)
    state.legalDocumentsOrder.push(id)
  },
  removeLegalDocument(state, id) {
    const i = state.legalDocumentsOrder.indexOf(id)
    if (i == -1) return

    const legalDocument = state.legalDocuments[id]

    state.legalDocumentsOrder.splice(i, 1)
    state.legalDocuments = deleteProperty(id, state.legalDocuments)

    rootDispatch('dialog/closeDialog', {
      component: 'legal-document',
      props: { legalDocument },
    })
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
  setAcceptedLegalDocuments(state, payload) {
    state.acceptedLegalDocuments = payload
  },
  setBroadcastChannelLegalDocuments(state, payload) {
    state.broadcastChannelLegalDocuments = payload
  },
}
