import { isNil } from 'lodash'

import articleStatus from '@/store/helpers/articleStatus'

export default {
  getTopics: state => state.topics || [],
  getTopicById: (state, getters) => id => getters.getTopics.find(topic => topic.id === id),
  getCategoryByItemId: ({ categories } = {}) => itemId => Object.values(categories || {})
    .find(({ items } = {}) => Object.values(items)
      .some(({ id }) => id.toString() === itemId.toString())),
  getCategoryIdByItemId: state => itemId => {
    const categoryIds = state.categoryItemsIds || state.categoriesItemsIds

    if (!categoryIds) return

    return Object.keys(categoryIds).find(key => categoryIds[key].includes(+itemId))
  },
  getItemById: (state, getters) => id => {
    const categoryId = getters.getCategoryIdByItemId(id)

    if (isNil(categoryId) || categoryId === -1) {
      return state.item?.[id] || null
    }

    return state.categories?.[categoryId]?.items?.[id]
      || state.category?.[categoryId]?.items?.[id]
      || null
  },
  getItemSourceById: state => id => {
    const categorySources = {
      categoriesItemsIds: 'categories',
      categoryItemsIds: 'category',
    }

    return Object.entries(categorySources)
            .reduce((acc, [key, value]) => {
              if (!state[key] || acc.categoryId) return acc

              const hasKey = Object.keys(state[key])
                              .find(k => state[key][k].includes(Number(id)))

              if (hasKey) Object.assign(acc, { name: value, categoryId: hasKey })
              return acc
            }, { name: 'item' })
  },
  getCategoryArticlesByStatus: state => (categoryId, filters) => {
    const category = state.category?.[categoryId]
      || state.categories?.[categoryId]

    const { extended_items: extendedItems } = category || {}

    if (!extendedItems) return []

    let articles = Object.entries(extendedItems).map(([, article]) => article)

    if (filters) {
      articles = articles.filter(article => filters.includes(articleStatus(article)))
    }

    return articles
  },
  getAllCategoryItems: ({ categories } = {}) => Object.values(categories || {})
    .reduce((acc, curr) => acc.concat(Object.values(curr.items)), []),
  getItemOffset: (state, { getCategoryByItemId }) => (itemId, offset) => {
    const items = Object.values(getCategoryByItemId(itemId)?.items || {}) || []
    const index = items.findIndex(({ id }) => String(id) === String(itemId))

    if (index === -1) return

    return items[index + offset]
  },
  getDetailsByCategoryId: state => categoryId => state.contentDetails?.[categoryId],
}
