<template>
  <div class="circular-chart">
    <div
      class="circular-chart__content"
    >
      <slot name="content" />
    </div>
    <svg
      class="circular-chart__ring"
      :aria-label="i18n('progress-chart')"
      viewBox="0 0 160 160"
    >
      <circle
        class="circular-chart__circle"
        :cx="'50%'"
        :cy="'50%'"
        :fill="'transparent'"
        :r="radius"
        :stroke-width="stroke"
      />
      <circle
        class="circular-chart__progress"
        :cx="'50%'"
        :cy="'50%'"
        :fill="'transparent'"
        :r="radius"
        :stroke-dasharray="dashArray"
        :stroke-dashoffset="dashOffset"
        :stroke-width="dashStroke"
        :style="circleVars"
      />
    </svg>
  </div>
</template>

<script>
  import i18n from '@/mixins/i18n'

  const SIZE = 160 // need to make it scalable to parent in future

  export default {
    mixins: [
      i18n,
    ],

    props: {
      value: {
        type: Number,
        required: true,
      },
    },

    computed: {
      dashArray() {
        return Math.PI * (this.radius * 2)
      },
      dashOffset() {
        return this.dashArray * (1 - this.value / 100)
      },
      radius() {
        return (SIZE / 2) - (this.stroke * 2)
      },
      stroke() {
        return SIZE / 12
      },
      dashStroke() {
        return this.stroke + 0.6
      },
      circleVars() {
        return {
          '--circular-chart-dasharray': this.dashArray,
          '--circular-chart-dashoffset': this.dashOffset,
        }
      },
    },

    slug: 'component.charts.circular-chart',
  }
</script>

<style lang="scss">
  .circular-chart {
    position: relative;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 60%;
      height: 60%;
      position: absolute;
      top: 50%;
      left: 50%;
      color: color(off-white);
      font-weight: 800;
      text-align: center;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: color(_black);
    }
    &__ring {
      transform: rotate(-90deg);
      position: relative;
      z-index: z-index(over-content);
    }
    &__circle {
      stroke: color(progress-base);
    }
    &__progress {
      animation: 1s animateProgress;
      stroke: color(progress-fill);
    }
    @keyframes animateProgress {
      from {
        stroke-dashoffset: var(--circular-chart-dasharray);
      }
      to {
        stroke-dashoffset: var(--circular-chart-dashoffset);
      }
    }
  }
</style>
