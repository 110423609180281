import Vue from 'vue'

import initialState from './state'

export default {
  setDay(state, day) {
    state.day = day
  },
  setWeek(state, week) {
    state.week = week
  },
  setMonth(state, month) {
    state.month = month
  },
  setYear(state, year) {
    state.year = year
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  setVisibleDateRange(state, visibleDateRange) {
    state.visibleDateRange = visibleDateRange
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
