import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/getting-confident-quiz/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Getting confident with physical activity',
      layout: 'home',
      toParent: true,
      trigger: 'getting-confident-quiz',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'GettingConfidentResults',
    },
  },
  routes: [
    {
      path: '/getting-confident-quiz',
      name: 'GettingConfident',
      meta: {
        parent: 'Tools',
      },
    },
    {
      path: '/getting-confident-quiz/quiz',
      name: 'GettingConfidentQuiz',
      props: true,
      meta: {
        parent: 'GettingConfident',
      },
    },
    {
      path: '/getting-confident-quiz/results',
      name: 'GettingConfidentResults',
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
