<template>
  <base-dialog
    class="package-not-enabled-dialog"
    data-testid="dialog-package-empty"
    :headerText="i18n('title')"
  >
    <i18n
      class="content"
      data-testid="dialog-package-empty-content"
      :path="i18n('content')"
      tag="div"
    >
      <template #email>
        <a
          data-testid="dialog-package-empty-email"
          href="mailto:customersupport@changinghealth.com"
        >
          customersupport@changinghealth.com
        </a>
      </template>
    </i18n>

    <template #action>
      <base-button
        data-testid="dialog-package-empty-close"
        @click="closeDialog">
          {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  import i18n from '@/mixins/i18n'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    mixins: [
      i18n,
    ],

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },

    slug: 'component.dialog.empty-package-dialog',
  }
</script>

<style lang="scss">
  .tracking-goals-dialog.dialog {
    min-width: 90%;
    max-width: none;
    @media all and (min-width: map-get($breakpoints, sm)) {
      min-width: 50rem;
    }
  }
</style>
