<template>
  <base-dialog
    data-testid="dialog-user-email-change"
    :headerText="$t(`${$options.slug}.title`)"
  >
    <p
      class="dialog-text"
      data-testid="dialog-user-email-change-content"
    >
      {{ $t(`${$options.slug}.content`) }}
      <br>
      {{ newEmail }}
    </p>

    <template #action>
      <base-button
        data-testid="dialog-user-email-change-close"
        @click="closeDialog"
      >
        {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    props: {
      newEmail: {
        type: String,
        required: true,
      },
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },

    slug: 'component.dialog.change-email-dialog',
  }
</script>

<style lang="scss">
  .dialog-text {
    word-break: break-all;
  }
</style>
