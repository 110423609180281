const MESSAGE = `Please try again to log in or contact us for assistance
                at <a href='mailto:customersupport@changinghealth.com'>
                customersupport@changinghealth.com</a>`

class BootstrapError extends Error {
  constructor(error) {
    super(error || MESSAGE)

    this.title = 'Error'
  }
}

export default BootstrapError
