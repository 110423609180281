import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'
import localStorage from '@/tools/local-storage'

export default {
  init({ commit }) {
    const localStorageState = localStorage.get('kioskMode', null)

    if (localStorageState) {
      commit('setState', localStorageState)
    }
  },
  disableAppKioskMode({ commit }, { form, programId }) {
    return new Promise((resolve, reject) => {
      loading('kioskMode/disableAppKioskMode', true)

      api.post(`api/programs/${programId}/pin`, form)
        .then(response => {
          if (response.result) {
            commit('setAppKioskMode', false)
          }
          loading('kioskMode/disableAppKioskMode', false)

          resolve(response.result)
        })
        .catch(() => {
          loading('kioskMode/disableAppKioskMode', false)

          reject()
        })
    })
  },
  enableAppKioskMode({ commit }, { form, programId }) {
    return new Promise((resolve, reject) => {
      loading('kioskMode/enableAppKioskMode', true)

      api.post(`api/programs/${programId}/pin`, form)
        .then(response => {
          if (response.result) {
            commit('setAppKioskMode', true)
          }
          loading('kioskMode/enableAppKioskMode', false)

          resolve(response.result)
        })
        .catch(() => {
          loading('kioskMode/enableAppKioskMode', false)

          reject()
        })
    })
  },
  disablePluginKioskMode({ commit }, { form, pluginName, programId }) {
    return new Promise((resolve, reject) => {
      loading('kioskMode/disablePluginKioskMode', true)

      api.post(`api/programs/${programId}/pin`, form)
        .then(response => {
          if (response.result) {
            commit('setPluginKioskMode', [pluginName, false])
          }
          loading('kioskMode/disablePluginKioskMode', false)

          resolve(response.result)
        })
        .catch(() => {
          loading('kioskMode/disablePluginKioskMode', false)

          reject()
        })
    })
  },
  enablePluginKioskMode({ commit }, { form, pluginName, programId }) {
    return new Promise((resolve, reject) => {
      loading('kioskMode/enablePluginKioskMode', true)

      api.post(`api/programs/${programId}/pin`, form)
        .then(response => {
          if (response.result) {
            commit('setPluginKioskMode', [pluginName, true])
          }
          loading('kioskMode/enablePluginKioskMode', false)

          resolve(response.result)
        })
        .catch(() => {
          loading('kioskMode/enablePluginKioskMode', false)

          reject()
        })
    })
  },
}
