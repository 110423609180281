import Vue from 'vue'

import localStorage from '@/tools/local-storage'

import initialState from './state'

export default {
  logout(state) {
    localStorage.set('Authorization', { session: state.session })
    localStorage.unset('TokenExpiredIn')
    localStorage.clear()
    state.token = null
    state.isCurrentUserAuthorized = false
  },
  set2faStatus(state, value) {
    state.is2fa = value
  },
  setAuthorization({ session, policiesToAccept }, token) {
    localStorage.set('Authorization', token ? { session, token, policiesToAccept } : { session })
  },
  setInvitationUser(state, user) {
    state.invitationUser = user
  },
  setInvitationUserEmail(state, email) {
    Vue.set(state.invitationUser, 'email', email)
  },
  setIsCurrentUserAuthorized(state, value) {
    state.isCurrentUserAuthorized = value
  },
  setIsRefreshingToken(state, value) {
    state.isRefreshingToken = value
  },
  setToken(state, token) {
    state.token = token ? token.replace('Bearer ', '') : token
  },
  setPoliciesToAccept(state, policiesToAccept) {
    state.policiesToAccept = policiesToAccept
    localStorage.set('Authorization', { session: state.session, token: state.token, policiesToAccept })
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
  setReferralUser(state, referralUser) {
    state.referralUser = referralUser
  },
  setReferralErrorUser(state, referralErrorUser) {
    state.referralErrorUser = referralErrorUser
  },
  setConsentDocuments(state, consentDocuments) {
    state.consentDocuments = consentDocuments
  },
}
