class PackageNotEnabledError extends Error {
  constructor() {
    super(
      `Package assigned to your account isn't enabled.
      Please contact us for assistance
      at <a href='mailto:customersupport@changinghealth.com'>
      customersupport@changinghealth.com</a>`,
    )

    this.name = 'PackageNotEnabledError'
    this.title = 'Your package is not enabled'
  }
}

export default PackageNotEnabledError
