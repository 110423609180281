import Vue from 'vue'

import initialState from './state'

const unshiftNotification = (state, notification) => {
  if (!notification.id) return

  if (state.notifications.find(({ id }) => id === notification.id) === undefined) {
    state.notifications.unshift(notification)
  }
}

const addNotification = (state, notification) => {
  if (!notification.id) return

  if (state.notifications.find(({ id }) => id === notification.id) === undefined) {
    state.notifications.push(notification)
  }
}

export default {
  setNotifications(state, payload) {
    Vue.set(state, 'notifications', payload)
  },
  notificationReceived(state, payload) {
    unshiftNotification(state, payload)
  },
  addNotifications(state, payload) {
    payload.forEach(item => addNotification(state, item))
  },
  markedNotificationAsRead(state, id) {
    const { notifications } = state
    const index = state.notifications.findIndex(item => item.id == id)

    if (index >= 0) {
      notifications.splice(index, 1)
      Vue.set(state, 'notifications', notifications)
    }
  },
  snoozedNotification(state, id) {
    const { notifications } = state
    const index = state.notifications.findIndex(item => item.id == id)

    if (index >= 0) {
      notifications.splice(index, 1)
      Vue.set(state, 'notifications', notifications)
    }
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
