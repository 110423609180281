import loading from '@/store/helpers/loading'
import rootDispatch from '@/store/helpers/rootDispatch'

import { api } from '@/tools/api'

export default {
  findArticlesByPhase({ commit }, { programId, query, page = 1 }) {
    loading('search/findArticlesByPhase', true)

    return new Promise((resolve, reject) => {
      api.post(`api/programs/${programId}/search-articles`, { query, page })
        .then(response => {
          const { items, pagination } = response || {}

          commit('setSearchResults', items)
          commit('setSearchResultsPagination', pagination)
          resolve()
        })
        .catch(reject)
        .finally(() => loading('search/findArticlesByPhase', false))
    })
  },

  async findArticlesByPhaseAndTotalItems({ commit },
    { programId, query, page = 1, perPage = 10 }) {
    loading('search/findArticlesFirstPhase', true)
    loading('search/findArticlesByPhaseAndTotalItems', true)

    const findArticlesByPage = perPg => new Promise((resolve, reject) => {
      api.post(`api/programs/${programId}/search-articles`, { query, page, per_page: perPg })
      .then(response => {
        resolve(response)
      })
      .catch(reject)
    })

    let response
    try {
      response = await findArticlesByPage(perPage)
    } catch (err) {
      return
    }

    if (!response) {
      return
    }

    const activeItemsFn = (accessed, source) => accessed === true || source === 'reading_room'

    const { items } = response || {}
    const activeItems = items
          .filter(({ accessed, source }) => activeItemsFn(accessed, source))
    commit('setFirstLoad', true)
    commit('setSearchResults', activeItems)
    loading('search/findArticlesFirstPhase', false)

    const { pagination } = response
    let secondResponse
    try {
      secondResponse = await findArticlesByPage(pagination.total)
    } catch (err) {
      return
    }

    if (!secondResponse) {
      return
    }

    const { items: itemsR } = secondResponse
    const activeItemsR = itemsR
          .filter(({ accessed, source }) => activeItemsFn(accessed, source))

    commit('setFirstLoad', false)
    commit('setSearchResults', activeItemsR)
    loading('search/findArticlesByPhaseAndTotalItems', false)
  },

  fetchContentArticlesSearch({ commit, state }, { items, firstItemPosition, lastPosition }) {
    if (!items) {
      return
    }
    const itemsIds = items.map(({ _source: source }) => source.article_id)
    if (!itemsIds) {
      return
    }

    const promises = itemsIds.filter(id => !!id).map(id => rootDispatch('article/fetchArticle', id))

    return new Promise((resolve, reject) => {
      Promise.all(promises).then(response => {
        const firsts = state.searchResults.slice(0, firstItemPosition)
        const itemsToChange = state.searchResults.slice(firstItemPosition, lastPosition)
        const lasts = state.searchResults.slice(lastPosition, state.searchResults.length)
        const responseArticles = itemsToChange.map((item, index) => ({
          ...item,
          content: response[index].content,
        }))

        const newItems = [
          ...firsts,
          ...responseArticles,
          ...lasts,
        ]

        commit('setSearchResults', newItems)
        resolve()
      })
      .catch(reject)
      .finally(() => loading('search/findArticlesByPhase', false))
    })
  },
}
