<template>
  <modular-tile
    class="progress-tile">
      <div class="progress-tile__content">
        <div class="progress-tile__text">
          <p class="progress-tile__title">
            {{ $t(`${$options.slug}.title`) }}:
            {{ progress }}% {{ $t('complete') }}
          </p>
          <p class="progress-tile__time">
            {{ $t(`${$options.slug}.started`) }}
            <span
              v-if="hasStartedHourAgo">
                {{ $t(`${$options.slug}.last-hour`) }}
            </span>
            <time
              v-else
              :datetime="startDate"
              v-time-ago/>
          </p>
        </div>
        <progress-indicator
          class="progress-tile__indicator"
          v-bind="{ progress }"/>
      </div>
  </modular-tile>
</template>

<script>
  import moment from 'moment'

  import ProgressIndicator from '@/components/progress-indicator/ProgressIndicator'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  import timeAgo from '@/directives/timeAgo'

  export default {
    components: {
      ProgressIndicator,
      ModularTile,
    },

    directives: {
      timeAgo,
    },

    props: {
      progress: {
        type: Number,
        required: true,
        default: 50,
      },
      startDate: {
        type: String,
        required: false,
        default: '',
      },
    },

    computed: {
      hasStartedHourAgo() {
        if (!this.startDate) return

        return moment
          .utc()
          .diff(moment.utc(this.startDate, 'YYYY-MM-DD HH:mm:ss'), 'hour') < 1
      },
    },

    slug: 'component.tiles.progress-tile',
  }
</script>

<style lang="scss">
  .progress-tile {
    flex-grow: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    z-index: z-index(default);
    @include min-lg {
      max-width: 77rem;
    }
    &__content {
      width: 100%;
    }
    &__text {
      display: flex;
      justify-content: space-between;
    }
    &__title {
      font-size: 1rem;
      font-weight: 600;
      font-size: 1.2rem;
    }
    &__indicator {
      margin-top: 1rem;
    }
    &__time {
      color: color(dark-grey);
      font-size: 1.1rem;
      font-weight: 600;
      @include min-lg {
        font-size: 1.5rem;
      }
    }
    .modular-tile__default {
      margin: 0;
    }
  }
</style>
