import { SPECIAL_WEIGHT_VALUE } from '@/constants'
import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  fetchSettings({ commit }) {
    return new Promise((resolve, reject) => {
      loading('weightTracking/fetchSettings', true)

      api.get('/api/weight-tracking/me/settings')
        .then(response => {
          if (response.weight_settings) {
            commit('setSettings', response.weight_settings[0])
          }
          loading('weightTracking/fetchSettings', false)

          resolve()
        })
        .catch(() => {
          loading('weightTracking/fetchSettings', false)

          reject()
        })
    })
  },
  saveSettings({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('weightTracking/saveSettings', true)

      api.post('/api/weight-tracking/me/settings', form)
        .then(() => {
          loading('weightTracking/saveSettings', false)

          resolve()
        })
        .catch(err => {
          loading('weightTracking/saveSettings', false)

          reject(err)
        })
    })
  },
  fetchTimePeriod({ commit }, type) {
    return new Promise((resolve, reject) => {
      loading('weightTracking/fetchTimePeriod', true)

      api.get(`/api/weight-tracking/me/weight/${type.toLowerCase()}`)
        .then(response => {
          const { settings, ...data } = response

          data.value = data.value.map(val => (val === SPECIAL_WEIGHT_VALUE ? 0 : val))

          commit('setSettings', settings[0])
          commit(`set${type}`, data)
          loading('weightTracking/fetchTimePeriod', false)

          resolve()
        })
        .catch(() => {
          loading('weightTracking/fetchTimePeriod', false)

          reject()
        })
    })
  },
  fetchByDay({ commit }, day) {
    return new Promise((resolve, reject) => {
      api.get(`/api/weight-tracking/me/weight/${day}`)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          reject()
        })
    })
  },
  logWeight({ dispatch }, { form, type }) {
    return new Promise((resolve, reject) => {
      loading('weightTracking/logWeight', true)

      api.post('/api/weight-tracking/me/weight', form)
        .then(() => {
          dispatch('fetchTimePeriod', type)
          loading('weightTracking/logWeight', false)

          resolve()
        })
        .catch(() => {
          loading('weightTracking/logWeight', false)

          reject()
        })
    })
  },
  deleteWeight({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading('weightTracking/deleteWeight', true)

      api.delete(`api/weight-tracking/me/weight/${id}`)
        .then(() => {
          loading('weightTracking/deleteWeight', false)

          resolve()
        })
        .catch(() => {
          loading('weightTracking/deleteWeight', false)

          reject()
        })
    })
  },
}
