<template>
  <div>
    <div v-if="outsideDescription">
      <p class="password-tips__title">
        Your password should:
      </p>
      <ul>
        <li
          class="password-tips__option"
          :class="{
            'password-tips__red-option': !passwordErrors.isAtLeastOneLetterValid && password,
            'password-tips__green-option': passwordErrors.isAtLeastOneLetterValid
          }"
        >
          Contain at least one letter
        </li>
        <li
          class="password-tips__option"
          :class="{
            'password-tips__red-option':
              !passwordErrors.isAtLeastOneCapitalLetterValid && password,
            'password-tips__green-option': passwordErrors.isAtLeastOneCapitalLetterValid
          }"
        >
          Contain at least one capital letter
        </li>
        <li
          class="password-tips__option"
          :class="{
            'password-tips__red-option':
              !passwordErrors.isAtLeastOneNumberValid && password,
            'password-tips__green-option': passwordErrors.isAtLeastOneNumberValid
          }"
        >
          Contain at least one number
        </li>
        <li
          class="password-tips__option"
          :class="{
            'password-tips__red-option':
              !passwordErrors.isNotContainUsernameOrEmailValid && password,
            'password-tips__green-option': passwordErrors.isNotContainUsernameOrEmailValid
          }"
        >
          Not contain username or email address
        </li>
        <li
          class="password-tips__option"
          :class="{
            'password-tips__red-option':
              !passwordErrors.isBeAtLeast9CharactersValid && password,
            'password-tips__green-option': passwordErrors.isBeAtLeast9CharactersValid
          }"
        >
          Be at least 9 characters
        </li>
      </ul>
    </div>

    <input-group
      has-hidden-asterisk
      :class="{ 'login__error-border': password && !validPassword() }"
      class="validate-password-input"
      :label="label"
      :name="'password'"
      :type="'password'"
      :validation="$options.validation.password"
      v-model="password"
      :autocomplete="'none'"
      :hideToggleMask="false"
      >
      <template
        v-if="insideDescription"
        #description>
        <p class="password-tips__title">
          Your password should:
        </p>
        <ul class="password-tips">
          <li
            class="password-tips__option"
            :class="{
              'password-tips__red-option': !passwordErrors.isAtLeastOneLetterValid && password,
              'password-tips__green-option': passwordErrors.isAtLeastOneLetterValid
            }"
            :aria-label="ariaLabel(passwordErrors.isAtLeastOneLetterValid)"
          >
            Contain at least one letter
          </li>
          <li
            class="password-tips__option"
            :class="{
              'password-tips__red-option':
                !passwordErrors.isAtLeastOneCapitalLetterValid && password,
              'password-tips__green-option': passwordErrors.isAtLeastOneCapitalLetterValid
            }"
            :aria-label="ariaLabel(passwordErrors.isAtLeastOneCapitalLetterValid)"
          >
            Contain at least one capital letter
          </li>
          <li
            class="password-tips__option"
            :class="{
              'password-tips__red-option':
                !passwordErrors.isAtLeastOneNumberValid && password,
              'password-tips__green-option': passwordErrors.isAtLeastOneNumberValid
            }"
            :aria-label="ariaLabel(passwordErrors.isAtLeastOneNumberValid)"
          >
            Contain at least one number
          </li>
          <li
            class="password-tips__option"
            :class="{
              'password-tips__red-option':
                !passwordErrors.isNotContainUsernameOrEmailValid && password,
              'password-tips__green-option': passwordErrors.isNotContainUsernameOrEmailValid
            }"
            :aria-label="ariaLabel(passwordErrors.isNotContainUsernameOrEmailValid)"
          >
            Not contain username or email address
          </li>
          <li
            class="password-tips__option"
            :class="{
              'password-tips__red-option':
                !passwordErrors.isBeAtLeast9CharactersValid && password,
              'password-tips__green-option': passwordErrors.isBeAtLeast9CharactersValid
            }"
            :aria-label="ariaLabel(passwordErrors.isBeAtLeast9CharactersValid)"
          >
            Be at least 9 characters
          </li>
        </ul>
      </template>
    </input-group>
  </div>
</template>

<script>
  import InputGroup from '@/components/forms/input-group/InputGroup'

  export default {
    components: {
      InputGroup,
    },
    props: {
      label: {
        type: String,
        default: 'Password:',
      },
      firstName: {
        type: String,
        required: false,
        default: '',
      },
      lastName: {
        type: String,
        required: false,
        default: '',
      },
      email: {
        type: String,
        required: false,
        default: '',
      },
      insideDescription: {
        type: Boolean,
        required: false,
        default: true,
      },
      outsideDescription: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    watch: {
      password() {
        this.validatePassword(this.password)
      },
    },

    data() {
      return {
        password: '',
        passwordErrors: {
          isAtLeastOneLetterValid: false,
          isAtLeastOneCapitalLetterValid: false,
          isAtLeastOneNumberValid: false,
          isBeAtLeast9CharactersValid: false,
          isNotContainUsernameOrEmailValid: false,
        },
        UNMET: 'unmet criteria',
        MET: 'met criteria',
      }
    },
    methods: {
      validateRegex(password, regex) {
        return regex.exec(password)
      },
      validPassword() {
        const {
          isAtLeastOneLetterValid,
          isAtLeastOneCapitalLetterValid,
          isAtLeastOneNumberValid,
          isBeAtLeast9CharactersValid,
          isNotContainUsernameOrEmailValid,
        } = this.passwordErrors
        return isAtLeastOneLetterValid && isAtLeastOneCapitalLetterValid && isAtLeastOneNumberValid
          && isBeAtLeast9CharactersValid && isNotContainUsernameOrEmailValid
      },
      containUsernameOrEmail(password) {
        // Does not contain username or email address
        let isPasswordValid = true
        const passwordToCompare = password.toLowerCase()
        const firstNameToCompare = this.firstName.toLowerCase()
        const lastNameToCompare = this.lastName.toLowerCase()
        isPasswordValid = this.firstName
          ? isPasswordValid && !passwordToCompare.includes(firstNameToCompare)
          : isPasswordValid && true
        isPasswordValid = this.lastName
          ? isPasswordValid && !passwordToCompare.includes(lastNameToCompare)
          : isPasswordValid && true

        const [firstPartEmail] = this.email.split('@')
        isPasswordValid = this.email
          ? isPasswordValid && !passwordToCompare.includes(firstPartEmail.toLowerCase())
          : isPasswordValid && true

        return isPasswordValid
      },
      validatePassword(password) {
        // Minimum 9 characters, at least one uppercase letter, one lowercase letter and one number
        // regex: /[A-Za-z\d@$!%*?&]{9,}/,
        this.passwordErrors.isAtLeastOneLetterValid = this.validateRegex(password, /[a-z]/)
        this.passwordErrors.isAtLeastOneCapitalLetterValid = this.validateRegex(password, /[A-Z]/)
        this.passwordErrors.isAtLeastOneNumberValid = this.validateRegex(password, /[\d]/)
        // this.passwordErrors.isAtLeastOneSpecialCharacterValid =
        //   this.validateRegex(password, /[@$!%*?&]/)
        this.passwordErrors.isNotContainUsernameOrEmailValid = password && this.containUsernameOrEmail(`${password}`)
        this.passwordErrors.isBeAtLeast9CharactersValid = password.length >= 9
        const isValid = this.validPassword()
        if (isValid) {
          this.$emit('validPassword', password)
        } else {
          this.$emit('validPassword', '')
        }
      },
      ariaLabel(isValid) {
        if (!this.password) {
          return ''
        }

        return isValid ? this.MET : this.UNMET
      },
    },

    slug: 'views.auth.auth-invitation',

    validation: {
      password: {
        required: true,
        // Minimum 9 characters, at least one uppercase letter, one lowercase letter and one number
        regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{9,}$/,
        min: 9,
      },
    },
  }
</script>

<style lang="scss">
.validate-password-input {
  input {
    border: 1px solid color(input-border);
  }
}
.password-tips {
  margin-bottom: 1rem;

  &__title {
    margin-bottom: 0.75rem;
  }

  &__option,
  &__green-option,
  &__red-option {
    padding-left: 2rem;
    display: flex;
    align-items: center;
  }

  &__option:not(.password-tips__green-option):not(.password-tips__red-option) {
    &::before {
      content: '';
      width: 6px;
      height: 6px;
      background-color: #2f2f2f;
      border-radius: 50%;
      margin-left: 3px;
    }
  }

  &__green-option,
  &__red-option {
    &::before {
      background-repeat: no-repeat;
      background-size: cover;
      width: 15px;
      height: 15px;
      // margin-top: 2px;
    }
  }

  &__green-option {
    &::before {
      content: '';
      background-image: url('~@/assets/img/icon-green-check.png');
    }
    color: green;
  }

  &__red-option {
    &::before {
      content: '';
      background-image: url('~@/assets/img/icon-red-cross.png');
    }
    color: red;
  }

  &.password-tips__show {
    display: block;
  }

  .password-tips__content {
    background: color(_white);
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    .password-tips__title {
      margin-bottom: 1rem;
    }
  }
}
</style>
