const getTrigger = (toTriggers, storeTriggers) => {
  const metaTriggers = Array.isArray(toTriggers) ? toTriggers : [toTriggers]
  /* eslint-disable indent */
  return storeTriggers
          .find(trigger => metaTriggers
            .map(metaTrigger => trigger.slug.includes(metaTrigger))
            .some(Boolean))
  /* eslint-enable */
}

export default getTrigger
