<script>
  export default {
    functional: true,

    render(h, { data, children }) {
      const { attrs, staticClass, ...newData } = data

      const classes = attrs
                        ? Object.keys(attrs).map(key => `container--${key}`)
                        : []

      classes.push(staticClass)

      const renderChildren = attrs && Object.keys(attrs).includes('flex-column')
                              ? h('div', { staticClass: 'container-flex' }, [children])
                              : children

      return h(
        'div', {
          staticClass: `container ${classes.join(' ').trim()}`,
          ...newData,
        }, [renderChildren],
      )
    },
  }
</script>

<style lang="scss">
  .container {
    flex: 1;
    width: 100%;
    padding: 0 $container_padding $container_padding $container_padding;
    background-color: color(off-white);
    @media all and (min-width: map-get($breakpoints, lg)) {
      width: $container_width--lg;
      max-width: 100%;
      padding: 0 $container_padding $footer-height--lg $container_padding;
    }
    @media all and (min-width: map-get($breakpoints, xl)) {
      width: $container_width--xl;
    }
    &--first {
      padding-top: $container_padding;
    }
    &--white {
      background-color: color(_white);
    }
    &--no-padding {
      padding: 0;
    }
    &--full {
      width: 100%;
      padding: 0;
    }
    &--narrow {
      max-width: (77.2rem + ($container_padding * 2));
      @include min-lg {
        width: (77.2rem + ($container_padding * 2));
      }
      &.container--no-padding {
        max-width: 77.2rem;
        @include min-lg {
          width: 77.2rem;
        }
      }
    }
    &--tile {
      flex: 0;
      padding: 0;
    }
    &--main-tile {
      @media all and (min-width: map-get($breakpoints, lg)) {
        padding: 0 $container_padding $container_padding $container_padding;
      }
    }
    &--stepper {
      padding: 3.4rem 0;
    }
    &--plugin-article {
      padding: 4rem 2.4rem $footer-height/2 2.4rem;
      @media all and (max-height: 500px) {
        padding: 2.4rem 2.4rem $footer-height--lg/2;
      }
      .container-flex {
        justify-content: space-between;
      }
    }
    &--plugin-items {
      padding: 2.4rem;
      @media all and (max-height: 650px) {
        padding: 1.4rem;
      }
    }
    &--plugin-content-item {
      padding: 2.7rem;
    }
    &--carb-results {
      padding: 2.5rem 2.2rem;
    }
    &--food-log {
      padding: 4rem 3rem;
    }
    &--tabs {
      position: relative;
      top: $tabs_height;
    }
    &--empty {
      overflow: hidden;
    }
    &--journey {
      margin-top: 6.9rem;
      @media all and (min-width: map-get($breakpoints, lg)) {
        margin-top: 7.9rem;
      }
    }
    &--has-visible-calendar {
      padding-bottom: 28rem;
    }
    &-title {
      padding-bottom: 1.1rem;
      font-size: 1.3rem;
    }
    &-flex {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
</style>
