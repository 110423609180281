<template>
  <base-main
    class="change-password-view"
    data-testid="change-password-view">
    <div class="change-password-view__wrapper">
      <stepper-custom-form
        ref="form"
        data-testid="change-password-form"
        class="change-password-form"
        :buttonLabels="{
          hasCancel: true,
        }"
        :displayLastButtons="false"
        @cancel="goBack"
      >
        <template #default>
          <stepper-custom-step
            data-testid="change-password-step-info"
            :showTitleNumeration="false"
            :title="$t(`Change password`)">
            <summary-errors
              v-if="summaryErrors"
              :summaryErrors="summaryErrors" />
            <validate-password
              label="Current password"
              :insideDescription="false"
              :firstName="form.first_name"
              :lastName="form.last_name"
              :email="form.email"
              @validPassword="password => (form.password = password)"
            />
            <validate-password
              label="New password"
              :firstName="form.first_name"
              :lastName="form.last_name"
              :email="form.email"
              @validPassword="password => (form.newPassword = password)"
            />
            <validate-password
              label="Confirm new password"
              :insideDescription="false"
              :firstName="form.first_name"
              :lastName="form.last_name"
              :email="form.email"
              @validPassword="password => (form.confirmPassword = password)"
            />
            <div class="stepper-buttons">
              <base-button
                ref="rightButton"
                :key="'cancelButton'"
                light
                class="stepper-buttons__right"
                data-testid="stepper-buttons-right"
                :type="'button'"
                @click="goBack"
              >
                {{ 'Cancel' }}
              </base-button>
              <base-button
                ref="rightButton"
                :key="'submit'"
                class="stepper-buttons__right"
                data-testid="stepper-buttons-right"
                :type="'submit'"
                :isLoading="isLoading"
                @click="onChangePassword"
              >
                {{ 'Change Password' }}
              </base-button>
            </div>
          </stepper-custom-step>
        </template>
      </stepper-custom-form>
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import { BaseButton } from '@/components/base'
  import BaseMain from '@/components/base/BaseMain'
  import SummaryErrors from '@/components/forms/summary-errors/SummaryErrors'
  import ValidatePassword from '@/components/forms/validate-password/ValidatePassword'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import goBack from '@/mixins/goBack'

  export default {
    components: {
      BaseMain,
      ValidatePassword,
      StepperCustomForm,
      StepperCustomStep,
      SummaryErrors,
      BaseButton,
    },

    mixins: [goBack],

    watch: {
      user: {
        handler() {
          if (!this.user) return

          this.form = {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            email: this.user.email,
          }
        },
        immediate: true,
      },
    },

    data() {
      return {
        form: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          newPassword: '',
          confirmPassword: '',
        },
        summaryErrors: { },
      }
    },

    methods: {
      ...mapActions('user', ['changePassword']),
      onChangePassword() {
        if (!this.form.newPassword || !this.form.password) {
          return
        }
        if (this.form.confirmPassword !== this.form.newPassword) {
          this.summaryErrors = { genericError: ['Confirm password and New password are not the same'] }
          return
        }

        this.changePassword({
          userId: this.user.id, newPassword: this.form.newPassword, password: this.form.password,
        })
        .then(response => {
          if (response && response.error) {
            this.summaryErrors = { genericError: ['Check if the current password is correct'] }
            return
          }
          this.$router.push({ name: 'Profile' })
        })
        .catch(err => {
          if (!err) return
          const { new_password: newPasswordErrors } = err
          if (!newPasswordErrors) return
          this.summaryErrors = { newPasswordError: newPasswordErrors }
        })
      },
    },

    computed: {
      ...mapGetters('loading', ['getLoadingStateForAction']),
      ...mapState('user', [
        'user',
      ]),
      isLoading() {
        return this.getLoadingStateForAction('user/changePassword')
      },
    },

    validation: {
      password: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
.change-password-view {
  &__wrapper {
    @include view-wrapper(63rem);
  }
  &__description {
    font-size: $font-size-p-sm;
    @include min-lg {
      font-size: $font-size-p-lg;
    }
  }

  .stepper-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  .input-border input {
    border: 1px solid color(input-border);
  }
}
</style>
