import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  fetchArticle({ commit, state }, id) {
    const articleExist = state.articles.find(article => article.id == id)
    if (articleExist) {
      commit('setArticle', articleExist)
      return articleExist
    }
    return new Promise((resolve, reject) => {
      loading('article/fetchArticle', true)
      api.get(`api/single-article-subject/${id}`)
        .then(response => {
          commit('setArticle', response)
          commit('saveArticle', response)

          resolve(response)
        })
        .catch(reject)
        .finally(() => {
          loading('article/fetchArticle', false)
        })
    })
  },

  fetchVideoArticle({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading('article/fetchVideoArticle', true)
      loading(`article/fetchVideoArticle/${id}`, true)

      api.get(`api/single-article-subject/${id}`)
        .then(response => {
          commit('setVideoArticle', response)

          resolve()
        })
        .catch(reject)
        .finally(() => {
          loading('article/fetchVideoArticle', false)
          loading(`article/fetchVideoArticle/${id}`, false)
        })
    })
  },

  saveArticleAccess({ commit, rootGetters }, articleId) {
    return new Promise((resolve, reject) => {
      loading('article/saveArticleAccess', true)

      const form = {
        article_id: articleId,
        program_id: rootGetters['program/currentProgramId'],
        user_id: rootGetters['user/getCurrentUserId'],
      }

      api.post('api/article/accessed', form)
        .then(resolve)
        .catch(reject)
        .finally(() => {
          loading('article/saveArticleAccess', false)
        })
    })
  },

  fetchVideoCaptions({ commit }, { id, language, isAlternateCaptions }) {
    const url = isAlternateCaptions ? 'alternate-captions' : 'captions'
    return new Promise((resolve, reject) => {
      loading('article/fetchVideoCaptions', true)
      loading(`article/fetchVideoCaptions/${id}`, true)

      api.get(`api/${url}/video/${id}/${language}`)
        .then(response => {
          if (!response.content) {
            resolve(null)
          }
          const blob = new Blob([response.content])

          resolve(window.URL.createObjectURL(blob))
        })
        .catch(err => {
          const isFileNotFoundException = err.response.status === 404

          if (!isFileNotFoundException) reject(err) // Captions are not required
        })
        .finally(() => {
          loading('article/fetchVideoCaptions', false)
          loading(`article/fetchVideoCaptions/${id}`, false)
        })
    })
  },

  checkTypeformAvailability({ commit }, { typeformId, systemSectionName }) {
    return new Promise((resolve, reject) => {
      loading('article/checkTypeformAvailability', true)

      api.post('api/typeform/can-render', {
        typeform_id: typeformId,
        system_section_name: systemSectionName,
      })
        .then(([response]) => resolve(!!response))
        .catch(reject)
        .finally(() => {
          loading('article/checkTypeformAvailability', false)
        })
    })
  },

  checkTypeformSavedAnswers({ commit }, typeformId) {
    return new Promise((resolve, reject) => {
      loading('article/checkTypeformSavedAnswers', true)

      api.post('api/typeform/check', { typeform_id: typeformId })
        .then(([response]) => resolve(!!response))
        .catch(reject)
        .finally(() => {
          loading('article/checkTypeformSavedAnswers', false)
        })
    })
  },
}
