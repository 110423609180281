<script>
  import { ContentNavigationButton } from '@/components/content-navigation'
  import Spinner from '@/components/spinner/Spinner'

  import { bemConstructor } from '@/helpers'

  import testid from '@/mixins/testid/testid'

  import eventBus from '@/tools/event-bus'

  import TransitionFade from '@/transitions/transition-fade/TransitionFade'

  export default {
    inheritAttrs: false,

    mixins: [
      testid(),
    ],

    props: {
      ariaLabel: {
        type: String,
        default: '',
      },
      component: {
        type: String,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
      next: {
        type: Object,
        required: false,
        default: null,
      },
      nextCategory: {
        type: Object,
        required: false,
        default: null,
      },
      previous: {
        type: Object,
        required: false,
        default: null,
      },
      previousCategory: {
        type: Object,
        required: false,
        default: null,
      },
    },

    render() {
      const bem = bemConstructor('content-navigation')

      /* eslint-disable operator-linebreak */
      return (
        <div
          class={bem('', this.previous ? 'has-previous' : '')}
          data-testid={this.dataTestid}
        >
          <TransitionFade>
            {this.isLoading
              ? <span
                key="spinner"
                class={bem('spinner')}
              >
                <Spinner/>
              </span>
              : <nav
                key="controls"
                aria-label={this.ariaLabel}
                class={bem('controls')}
              >
                {
                  !!this.previous &&
                    <ContentNavigationButton
                      ref="navigationButtonPrevious"
                      is-previous
                      articleTitle={this.previous.title}
                      testid={this.dataTestid}
                      onClick={() => this.triggerNavigation(
                        this.previous,
                        this.previousCategory,
                        true,
                      )}
                    />
                }
                {
                  !!this.next &&
                    <ContentNavigationButton
                      ref="navigationButtonNext"
                      articleTitle={this.next.title}
                      testid={this.dataTestid}
                      onClick={() => this.triggerNavigation(
                        this.next,
                        this.nextCategory,
                      )}
                    />
                }
              </nav>
            }
          </TransitionFade>
        </div>
      )
      /* eslint-enable operator-linebreak */
    },

    methods: {
      triggerNavigation(article, category, isPrevious = false) {
        eventBus.$emit(`transition/article/${isPrevious ? 'previous' : 'next'}`, this.getRoute(article, category))
      },
      getRoute({ id }, category) {
        return {
          name: this.component,
          params: {
            id,
            ...(category
              ? { categoryId: category.id }
              : null
            ),
          },
        }
      },
    },
  }
</script>

<style lang="scss">
  $block: 'content-navigation';

  .#{$block} {
    width: 100%;
    &__controls {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 4rem 0 0;
    }
    &__spinner {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      min-height: 9.3rem;
      svg {
        width: 3rem;
      }
      circle {
        stroke: color(light-grey);
      }
    }
    &--has-previous {
      .#{$block}__controls {
        justify-content: space-between;
      }
    }
  }
</style>
