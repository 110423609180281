<script>
  export default {
    functional: true,

    props: {
      image: {
        type: String,
        required: true,
      },
      imagePosition: {
        type: String,
        required: true,
      },
      testid: {
        type: String,
        default: 'modular-tile-image',
      },
    },

    render(h, { props: { image, imagePosition, testid } }) {
      const blockClass = 'modular-tile'

      return (
        <div
          data-testid={testid}
          class={`${blockClass}__${imagePosition}-image-row`}
          slot="feature">
            <img
              class={`${blockClass}__${imagePosition}-image`}
              alt=""
              src={image}/>
        </div>
      )
    },
  }
</script>

<style lang="scss">
  $block: modular-tile;
  $border-radius: 1rem;

  .#{$block} {
    &--has-side-image.#{$block} {
      flex-direction: row;
    }
    &__top-image-row {
      border-radius: $border-radius $border-radius 0 0;
      overflow: hidden;
    }
    &__side-image {
      width: auto;
      border-radius: $border-radius 0 0 $border-radius;
    }
  }
</style>
