import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  sendFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      loading('feedback/sendFeedback', true)

      api.post('api/user/feedback/', payload)
        .then(resolve)
        .catch(reject)
        .finally(() => {
          loading('feedback/sendFeedback', false)
        })
    })
  },
}
