import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  fetchTriggers({ commit, rootState }, programId) {
    return new Promise((resolve, reject) => {
      const id = programId || rootState.program.program.id
      loading('triggers/fetchTriggers', true)

      api.get(`api/programs/${id}/triggers`)
        .then(response => {
          commit('setTriggers', response)
          resolve()
        })
        .catch(reject)
        .finally(() => loading('triggers/fetchTriggers', false))
    })
  },
  saveTriggerAccess({ commit, rootGetters }, pluginId) {
    return new Promise((resolve, reject) => {
      loading('triggers/saveTriggerAccess', true)

      const form = {
        plugin_id: pluginId,
        program_id: rootGetters['program/currentProgramId'],
        user_id: rootGetters['user/getCurrentUserId'],
      }

      api.post('api/programs/plugin/accessed', form)
        .then(resolve)
        .catch(reject)
        .finally(() => loading('triggers/saveTriggerAccess', false))
    })
  },
  fetchTriggersData({ commit, rootState }, { pluginId }) {
    return new Promise((resolve, reject) => {
      loading('triggers/fetchTriggersData', true)
      const { id } = rootState.program.program || {}

      commit('addUpdating', id)

      api.get(`api/programs/${id}/plugin/${pluginId}/fresh`)
        .then(changes => {
          commit('setUpdatedTriggers', { changes, pluginId })
        })
        .catch(reject)
        .finally(() => {
          loading('triggers/fetchTriggersData', false)
          commit('removeUpdating', id)
        })
    })
  },
}
