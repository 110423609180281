export default (ctx, store, echo) => {
  let uid

  const _updateUid = newUid => {
    if (uid != newUid) {
      if (uid) {
        echo.leave(`trigger-updated${uid}`)
      }

      if (newUid) {
        echo.private(`trigger-updated.${newUid}`)
          .listen('TriggerUpdated', ({ plugin_id: pluginId }) => {
            store.dispatch('triggers/fetchTriggersData', { pluginId })
          })
      }

      uid = newUid
    }
  }

  store.subscribe(mutation => {
    const { type } = mutation
    if (type == 'auth/logout'
      || type == 'auth/resetState'
      || type == 'user/decodeUser'
      || type == 'user/setUser') {
      _updateUid(store.getters['user/getCurrentUserId'])
    }
  })

  _updateUid(store.getters['user/getCurrentUserId'])
}
