<template>
  <base-dialog
    data-testid="dialog-auth-expired-session"
    :headerText="$t(`${$options.slug}.title`)"
  >
    <p
      data-testid="dialog-auth-expired-session-content"
    >
      {{ $t(`${$options.slug}.content`) }}
    </p>

    <template #action>
      <base-button
        data-testid="dialog-auth-expired-session-close"
        @click="closeDialog"
      >
        {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },

    slug: 'component.dialog.expired-session-dialog',
  }
</script>
