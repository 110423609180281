export default function awaitTicks(Vue) {
  async function _awaitTicks(amount = 1) {
    for (let curr = 0; curr < amount; curr += 1) {
      // eslint-disable-next-line no-await-in-loop
      await Vue.nextTick()
    }
  }

  Vue.prototype.$awaitTicks = _awaitTicks
}
