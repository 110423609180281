<script>
  export default {
    functional: true,

    render(h, { slots, data }) {
      return h('div', {
        ...data,
        staticClass: `info-label ${data.staticClass}`,
      }, [
        slots().default,
      ])
    },
  }
</script>

<style lang="scss">
  .info-label {
    padding: 0.6rem 2.4rem;
    color: color(_white);
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 2rem;
    background-color: color(primary);
    box-shadow: box-shadow(button);
    @include min-lg {
      font-size: 1.6rem;
    }
  }
</style>
