<script>
  export default {
    props: {
      value: {
        required: true,
        validator: () => true,
      },
      immediate: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      value(newVal, oldVal) {
        this.$emit('change', newVal, oldVal)
      },
    },

    render() {
      return this.$scopedSlots.default?.({})
    },
  }
</script>
