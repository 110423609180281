<template>
  <component
    :is="component"
    class="optional-profile-data-journey"
    data-testid="optional-profile-data-journey"
    v-merge-attr:data-testid
  >
    <div class="journey-profile-data-dialog__content">
      <h1
        class="journey-profile-data-dialog__heading nhsuk-u-margin-top-4"
        data-testid="optional-profile-data-journey-heading"
      >
        {{ title }}
      </h1>
      <base-form
        ref="form"
        :hasSubmitButton="hasSubmitButton"
        :submitText="'Save'"
        :testid="'optional-profile-data-journey'"
        v-bind="{ isSaving }"
      >
        <optional-profile-data-field
          is-journey
          :item="data"
          v-model="field"
        />
      </base-form>
    </div>

    <slot v-bind="{ onSubmit }" />
  </component>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import {
    BaseDialog,
    BaseForm,
  } from '@/components/base'
  import ModularTile from '@/components/tiles/modular-tile'

  import mergeAttr from '@/directives/mergeAttr'

  import OptionalProfileDataField from './OptionalProfileDataField'

  export default {
    components: {
      BaseDialog,
      BaseForm,
      ModularTile,
      OptionalProfileDataField,
    },

    directives: {
      mergeAttr,
    },

    props: {
      data: {
        type: Object,
        required: true,
      },
      isDialog: {
        type: Boolean,
        default: false,
      },
      isSaving: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        required: true,
      },
      hasSubmitButton: {
        type: Boolean,
        default: true,
      },
    },

    watch: {
      'item.id': {
        handler: 'setField',
        immediate: true,
      },
    },

    data() {
      return {
        field: this.data.value,
      }
    },

    methods: {
      ...mapActions('articles', [
        'changeProgress',
      ]),
      ...mapActions('dialog', [
        'closeDialog',
      ]),
      ...mapActions('optionalProfileData', [
        'updateOptionalData',
      ]),
      ...mapActions('program', [
        'fetchNextSection',
      ]),
      async onSubmit() {
        const isValid = await this.$refs.form.$refs.observer.validate()

        if (!isValid) {
          // eslint-disable-next-line no-unused-expressions
          this.$el.querySelector('[aria-invalid=true]')?.focus()
          return Promise.reject()
        }
        const { category, key } = this.data

        if (this.data.scheme.type == 'checkbox' && !this.field) {
          this.field = false
        }

        return this.updateOptionalData([{
          category,
          key,
          value: this.field,
        }])
          .then(() => {
            if (this.isCompleted) return Promise.resolve()

            return this.changeProgress({
              done: 1,
              program_id: this.currentProgramId,
              subject_id: this.data.id,
              type: this.item.type,
            })
          })
          .then(() => {
            if (this.isDialog) {
              this.closeDialog()
            }

            if (!this.isTrailingOpd(this.item.id)
              && (!this.isAtSectionEnd || this.isCompleted)) return

            return this.fetchNextSection({
              programId: this.currentProgramId,
              sectionKey: this.getCurrentSection.key,
            })
          })
      },
      setField() {
        this.field = this.data.value
      },
    },

    computed: {
      ...mapGetters('program', [
        'currentProgramId',
        'getCurrentSection',
        'isAtSectionEnd',
        'isTrailingOpd',
      ]),
      component() {
        return this.isDialog ? 'base-dialog' : 'modular-tile'
      },
      isCompleted() {
        return this.item?.opened
      },
      title() {
        return this.item.display_name
      },
    },
  }
</script>

<style lang="scss">
  .optional-profile-data-journey {
    .base-select {
      &__label {
        font-weight: 400;
      }
    }
  }
  .journey-profile-data-dialog__heading {
    font-weight: normal;
  }
</style>
