<template>
  <container
    empty
    full>
      <div class="empty-view">
        <div class="empty-view-container">
          <div class="empty-view-content">
            <slot/>
          </div>
        </div>
        <inline-svg
          class="empty-view-clouds"
          role="presentation"
          src="clouds.svg"/>
      </div>
  </container>
</template>

<script>
  import Container from '@/components/container/Container'

  export default {
    components: {
      Container,
    },
  }
</script>

<style lang="scss">
  .empty-view {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    color: color(primary);
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    @media (min-width: 340px) {
      font-size: 1.6rem;
    }
    @media (min-width: map-get($breakpoints, md)) {
      font-size: 1.7rem;
    }
    &-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: -2.5%;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 2rem;
      z-index: z-index(base);
      @media (min-width: 340px) {
        top: -10%;
      }
    }
    &-content {
      max-width: 60rem;
      p {
        margin: 0.75rem 0;
      }
    }
    &-clouds {
      position: absolute;
      bottom: -1.5rem;
      width: 100rem;
      @media (min-width: 340px) {
        bottom: 0;
      }
      @media (min-width: map-get($breakpoints, md)) {
        position: relative;
        left: 0;
        width: 100%;
        transform: none;
      }
      @media (min-width: map-get($breakpoints, lg)) {
        width: 80%;
        margin: 0 auto;
      }
      @media (min-width: map-get($breakpoints, xl)) {
        width: 70%;
      }
    }
  }
</style>
