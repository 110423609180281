<template>
  <base-dialog
    class="dialog--legal-document legal-document"
    data-testid="dialog-legal-document"
    :closeOnClickOutside="!hasToBeAccepted"
    :headerLevel="1"
    :headerText="legalDocument.display_title"
  >
    <div
      class="legal-document__text"
      data-testid="dialog-legal-document-text"
      tabindex="0"
    >
      <p
        class="legal-document__version"
        data-testid="dialog-legal-document-version"
      >
        {{ i18n('version', legalDocument) }}
      </p>
      <p
        class="legal-document__updated"
        data-testid="dialog-legal-document-updated"
        :aria-label="legalDocumentDate"
      >
        {{ i18n('updated') }}
        {{ legalDocument.created_at | formatDate(DATE_FORMATS.dateTimeShort) }}
      </p>
      <p
        class="legal-document__description"
        data-testid="dialog-legal-document-description"
      >
        {{ legalDocument.display_description }}
      </p>
      <div
        ref="content"
        class="legal-document__content"
        data-testid="dialog-legal-document-content"
        v-html="legalDocument.content"
      />
    </div>
    <base-form
      v-if="hasToBeAccepted"
      ref="form"
      :hasSubmitButton="false"
      :testid="'dialog-legal-document'"
      v-bind="{ isSaving }"
      @submit="onSubmit"
    >
      <checkbox-group
        id="legal-document-accept"
        :label="i18n('accept-label', { title: legalDocument.display_title })"
        :name="'accept'"
        :testid="'dialog-legal-document'"
        :validation="$options.validation.accept"
        v-model="form.accept"
      />
    </base-form>
    <p
      v-if="hasToBeAccepted"
      class="legal-document__access"
      data-testid="dialog-legal-document-access"
    >
      {{ i18n('access-text', { packageName }) }}
    </p>
    <template slot="action">
      <div class="legal-document__actions">
        <div
          class="legal-document__buttons"
          v-if="hasToBeAccepted"
        >
          <base-button
            class="legal-document__button legal-document__accept"
            data-testid="dialog-legal-document-submit"
            :isDisabled="isSaving"
            :isLoading="isSaving"
            @click="onSubmit"
          >
            {{ $t('Accept') }}
          </base-button>
          <base-button
            light
            class="legal-document__button legal-document__decline"
            data-testid="dialog-legal-document-decline"
            :isDisabled="isLoggingOut"
            :isLoading="isLoggingOut"
            @click="onCancel"
          >
            {{ $t('Decline') }}
          </base-button>
        </div>
        <base-button
          v-else
          class="legal-document__close"
          data-testid="dialog-legal-document-close"
          @click="closeDialog"
        >
          {{ $t('Close') }}
        </base-button>
      </div>
    </template>
  </base-dialog>
</template>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
    BaseForm,
  } from '@/components/base'
  import CheckboxGroup from '@/components/forms/checkbox-group/CheckboxGroup'

  import { DATE_FORMATS } from '@/constants'

  import findKeyboardFocusableElements from '@/helpers/findKeyboardFocusableElements'

  import i18n from '@/mixins/i18n'

  export default {
    metaInfo() {
      const { display_title: title } = this.legalDocument || {}

      return {
        title,
      }
    },

    components: {
      BaseButton,
      BaseDialog,
      BaseForm,
      CheckboxGroup,
    },

    mixins: [
      i18n,
    ],

    props: {
      legalDocument: {
        type: Object,
        required: true,
      },
    },

    watch: {
      /* eslint-disable-next-line object-shorthand */
      'legalDocument.id'() {
        /* eslint-disable-next-line no-unused-expressions */
        findKeyboardFocusableElements(this.$refs.form?.$el)[0]?.focus()
      },
    },

    data() {
      return {
        DATE_FORMATS,
        form: {
          accept: false,
        },
      }
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
      ...mapActions('legalDocument', [
        'acceptLegalDocument',
      ]),
      ...mapActions('auth', [
        'logout',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      onCancel() {
        this.logout()
          .then(this.$nextTick)
          .finally(() => {
            this.closeDialog()
            this.$router.push({ name: 'AuthLoggedOut' })
          })
      },
      async onSubmit() {
        const isValid = await this.formObserver().validate()

        if (!isValid) return this.$el.querySelector('[aria-invalid=true]').focus()

        this.acceptLegalDocument(this.legalDocument.version_id)
          .then(this.resetForm)
          .catch(() => {
            this.addSnackbar({ message: 'Something went wrong, please try again.' })
          })
      },
      resetForm() {
        this.form.accept = false

        return this.formObserver()?.reset()
      },
      formObserver() {
        return this?.$refs.form?.$refs.observer
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('legalDocument', [
        'hasLegalDocumentToAccept',
      ]),
      ...mapGetters('_package', [
        'packageName',
      ]),
      hasToBeAccepted() {
        return !this.legalDocument.accepted_at
      },
      isLoggingOut() {
        return this.getLoadingStatesForActions([
          'auth/logout',
        ])
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'legalDocument/acceptLegalDocument',
        ])
      },
      legalDocumentDate() {
        return `${this.$t(this.updatedSlug)} ${this.$options.filters.formatDate(this.legalDocument.created_at, DATE_FORMATS.dateTimeShort)}`
      },
      updatedSlug() {
        return this.i18n('updated')
      },
    },

    slug: 'component.dialog.legal-document-dialog',

    validation: {
      accept: {
        checkbox_required: true,
      },
    },
  }
</script>
<style lang="scss">
  .dialog {
    &.dialog--legal-document {
      width: 90%;
      min-width: 30rem;
      max-width: 92rem;
      max-height: none;
      overflow: visible;
      @include min-lg {
        width: 80%;
      }
    }
  }
  .legal-document {
    scroll-behavior: smooth;
    &__version {
      font-size: 1.8rem;
      font-weight: 600;
    }
    &__updated {
      color: color(dark-grey);
    }
    &__description {
      margin-top: 0.8rem;
      font-size: 1.4rem;
      @include min-lg {
        font-size: 1.6rem;
      }
    }
    &__text {
      max-height: 35vh;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      @media (min-height: 65rem) {
        max-height: 40vh;
      }
      @media (min-height: 71rem) {
        max-height: 45vh;
      }
      @media (min-height: 100rem), (min-width: map-get($breakpoints, lg)) {
        max-height: 55vh;
      }
    }
    &__content {
      margin: 2.5rem 0 1.2rem;
      padding: 1.5rem;
      word-break: break-word;
      border: 1px solid color(primary);
      background-color: color(off-white);
      @include min-lg {
        padding: 2.5rem;
      }
    }
    &__access {
      margin: 2rem 0 0;
    }
    &__buttons {
      @include max-lg {
        width: 16rem;
      }
    }
    &__actions {
      margin: 3rem 0 0;
    }
    &__button {
      margin: 0 0.5rem 1rem;
      @include min-lg {
        margin: 0 0.8rem 0 0;
      }
    }
    .modular-tile {
      &__main {
        padding: 4rem;
        @include min-lg {
          padding: 4rem;
        }
      }
      &__header {
        font-size: 2.1rem;
        font-weight: bold;
        text-transform: none;
      }
      &__content {
        flex-direction: column;
      }
    }
    .input-details__error {
      background: none;
    }
    ul {
      padding-left: 3rem;
      list-style-type: initial;
    }
    ol {
      li {
        color: color(primary-text);
      }
    }
  }
</style>
