import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  completePlugin({ commit }, { userId, data }) {
    return new Promise((resolve, reject) => {
      loading('blockedPlugin/completePlugin', true)
      commit('setBlockedPlugin', null)

      api.post(`api/user/${userId}/complete-plugin`, data)
        .then(response => {
          loading('blockedPlugin/completePlugin', false)
          /* eslint-disable-next-line camelcase */
          commit('setBlockedPlugin', response?.data?.blocked_plugin)

          resolve()
        })
        .catch(() => {
          loading('blockedPlugin/completePlugin', false)

          reject()
        })
    })
  },
}
