import { mapMutations, mapState } from 'vuex'

export default {
  methods: {
    ...mapMutations('blockedPlugin', [
      'setBlockedPluginReadyToComplete',
    ]),
    unblockPlugin() {
      if (!this.blockedPlugin) return

      this.setBlockedPluginReadyToComplete(this.blockedPlugin.id)
    },
  },

  computed: {
    ...mapState('blockedPlugin', [
      'blockedPlugin',
    ]),
  },
}
