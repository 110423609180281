/* eslint-disable */
import assign from 'object-assign'

export default {
  install(Vue) {
    const needToFix = document.querySelector('head base')

    if (needToFix) {
      const fixProps = ['fill']

      function fixSvgPath(el) {
        const baseUrl = location.href.split('#')[0]
        fixProps.forEach(prop => {
          [].forEach.call(el.querySelectorAll(`[${prop}^="url("]`), item => {
            if (item.getAttribute(prop).indexOf('#') >= 0) {
              item.setAttribute(
                prop,
                `url(${baseUrl}#${item.getAttribute(prop).split('#')[1].replace(')', '')})`,
              )
            }
          })
        })
      }

      Vue.directive('fix-svg-path', {
        bind(el) {
          fixSvgPath(el)
        },
        componentUpdated(el) {
          fixSvgPath(el)
        },
      })
    }

    Vue.component('inline-svg', {
      name: 'InlineSvg',
      functional: true,
      props: ['src'],
      render(h, { data, props }) {
        const { 'aria-label': ariaLabel, ...attrs } = data?.attrs || {}

        if (needToFix) {
          data = assign({
            directives: [
              {
                name: 'fix-svg-path',
              },
            ],
          }, data)
        }

        const svg = require(`!!svg-inline-loader?removeTags=true&removeSVGTagAttrs=false&removingTags=title,desc!../assets/svg/${props.src}`)

        if (typeof svg === 'string') {
          const innerHTML = ariaLabel
            ? svg.replace(/<svg/g,`<svg aria-label="${ariaLabel}" `)
            : svg

          return h('span', assign({ domProps: { innerHTML } }, { ...data, attrs }))
        }

        if (ariaLabel) {
          svg.setAttribute('aria-label', ariaLabel)
        }

        return h('span', { ...data, attrs }, svg)
      },
    })
  },
}
