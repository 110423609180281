import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/tricky-situations/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Quiz - Tricky Situations',
      layout: 'home',
      toParent: true,
      trigger: 'tricky-situations',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'TrickySituationsResults',
    },
  },
  routes: [
    {
      path: '/tricky-situations-quiz',
      name: 'TrickySituations',
      meta: {
        parent: 'Tools',
      },
    },
    {
      path: '/tricky-situations-quiz/quiz',
      name: 'TrickySituationsQuiz',
      props: true,
      meta: {
        parent: 'TrickySituations',
      },
    },
    {
      path: '/tricky-situations-quiz/results',
      name: 'TrickySituationsResults',
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
