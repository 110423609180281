<template>
  <form-group
    class="select-group"
    v-bind="{
      descriptionId,
      hideErrorMessage,
      label,
      name,
      testid,
      validation,
    }"
    v-slot="{ errors }"
  >
    <legend
      v-if="legend"
      class="select-group__legend"
      :class="{
        'select-group__legend--is-visually-hidden': isLegendHidden,
      }"
      v-html="legend"
    />

    <base-select
      :label="labelText"
      :showError="!!errors.length || showError"
      v-bind="{
        ...$attrs,
        capitalize,
        config,
        descriptionId,
        disabled,
        isLabelHidden,
        isRequired,
        name,
        options,
        placeholder,
        showLabel,
        testid,
      }"
      v-model="model"
      @change="onChange"
    >
      <template
        #singleLabel="option"
      >
        <slot
          name="singleLabel"
          v-bind="option"
        />
      </template>
      <template
        #option="option"
      >
        <slot
          name="option"
          v-bind="option"
        />
      </template>
    </base-select>
  </form-group>
</template>

<script>
  import { BaseSelect } from '@/components/base'
  import FormGroup from '@/components/forms/form-group/FormGroup'

  import addVModel from '@/mixins/addVModel'
  import labelText from '@/mixins/labelText'
  import testid from '@/mixins/testid/testid'

  export default {
    inheritAttrs: false,

    components: {
      BaseSelect,
      FormGroup,
    },

    mixins: [
      addVModel('model', [Number, String, Object], 'undefined'),
      labelText,
      testid(),
    ],

    props: {
      capitalize: {
        type: Boolean,
        default: false,
      },
      config: {
        type: Object,
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      hideErrorMessage: {
        type: Boolean,
        default: false,
      },
      isLabelHidden: {
        type: Boolean,
        default: false,
      },
      isLegendHidden: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
      legend: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      placeholder: {
        type: String,
        default: '',
      },
      showError: {
        type: Boolean,
        default: false,
      },
      showLabel: {
        type: Boolean,
        required: false,
        default: true,
      },
      validation: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {
      onChange(val) {
        this.$emit('change', val)
      },
    },

    computed: {
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
    },
  }
</script>

<style lang="scss">
  .select-group {
    &__legend {
      margin: 0 0 1rem;
      color: var(--color-dark-primary);
      font-weight: 600;
      &--is-visually-hidden {
        @include visually-hidden;
      }
    }
  }
</style>
