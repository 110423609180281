import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

const module = 'initialAssessment'

export default {
  saveAnswers({ commit }, form) {
    return new Promise((resolve, reject) => {
      const action = `${module}/saveAnswers`
      loading(action, true)

      api.post('api/questionnaire/dpp-ia', form)
        .then(response => resolve(response))
        .catch(err => reject(err))
        .finally(() => loading(action, false))
    })
  },
}
