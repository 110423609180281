import capitalize from '@/filters/capitalize'

import {
  logoutAndOpenDialog,
  unslugify,
} from '@/helpers'

import loading from '@/store/helpers/loading'

import getTrigger from './getTrigger'

const DIALOG_MESSAGES = {
  later: name => `You'll be able to use ${name} later in your Learn journey.`,
  unavailable: name => `${name} is not available in your current program.`,
}
const PLUGIN_NAMES = ['coaching', 'goal']

const handleTrigger = (from, metaTrigger, next, store) => {
  const hasProgramId = store.getters['program/currentProgramId']
  const action = `router/pluginsGuard${hasProgramId ? '' : 'WithBootstrap'}`
  const bootstrap = () => (hasProgramId
                            ? Promise.resolve()
                            : store.dispatch('bootstrap/bootstrap'))
  loading(action, true)

  bootstrap()
    .then(() => {
      const trigger = getTrigger(metaTrigger, store.state.triggers.triggers)
      const { plugin_id: blockerPluginId } = store.state.blockedPlugin?.blockedPlugin || {}
      loading(action, false)

      if (trigger && (trigger.unlocked || trigger.plugin_id == blockerPluginId)) {
        next()
      } else {
        const pluginName = PLUGIN_NAMES
                            .find(name => metaTrigger.includes(name))
                          || unslugify(metaTrigger)
        const message = DIALOG_MESSAGES[trigger ? 'later' : 'unavailable'](capitalize(pluginName))

        if (!from.name) {
          next({ name: 'Tools', replace: true })
        }

        setTimeout(() => {
          store.dispatch('dialog/openDialog', {
            component: 'PluginLocked',
            props: { message },
          })
        }, 0)
      }
    })
    .catch(err => logoutAndOpenDialog('Error', { err }))
}

export default handleTrigger
