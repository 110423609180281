import Vue from 'vue'

import initialState from './state'

export default {
  setArticle(state, article) {
    Vue.set(state, 'article', article)
  },
  setVideoArticle(state, article) {
    Vue.set(state.videoArticles, article.id, article)
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
  saveArticle(state, article) {
    state.articles.push(article)
  },
}
