import capitalize from '@/filters/capitalize'

import loading from '@/store/helpers/loading'
import rootCommit from '@/store/helpers/rootCommit'

import { api } from '@/tools/api'

export default {
  addImage({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('weeklyReview/addImage', true)

      api.post('api/review/file/icon', form)
        .then(response => {
          commit('setWeightImage', response.data.file)
          loading('weeklyReview/addImage', false)

          resolve(response.data.file.id)
        })
        .catch(err => {
          loading('weeklyReview/addImage', false)

          reject(err.response.data.errors.file[0])
        })
    })
  },
  fetchInitWeeklyReviewData({ commit }) {
    return new Promise((resolve, reject) => {
      loading('weeklyReview/fetchInitWeeklyReviewData', true)

      api.get('/api/review/begin')
        .then(response => {
          commit('resetState')
          commit('setToken', response.token)
          commit('setNext', response.next)
          loading('weeklyReview/fetchInitWeeklyReviewData', false)

          resolve()
        })
        .catch(() => {
          loading('weeklyReview/fetchInitWeeklyReviewData', false)

          reject()
        })
    })
  },
  fetchWeeklyReviewData({ commit, state }) {
    return new Promise((resolve, reject) => {
      loading('weeklyReview/fetchWeeklyReviewData', true)

      api.get(`/api/review/${state.token}`)
        .then(response => {
          if (response.next) {
            commit('setNext', response.next)
          }

          switch (state.next) {
            case 'weight':
              commit('setReviewMode', response)
              commit('setWeight', response)
              commit('setWeightImage', response)
              break
            case 'eating':
              commit('setFoodGoals', response.goals)
              break
            case 'eating-plan':
              commit('setEatingPlan', response)
              break
            case 'activity-goals':
              commit('setActivityGoals', response.goals)
              break
            case 'walking':
              rootCommit('stepTracking/setWeek', response.chart)
              commit('setWalkingGoal', response.goal)
              break
            case 'activity-plan':
              commit('setActivityPlan', response)
              break
            // no default
          }

          loading('weeklyReview/fetchWeeklyReviewData', false)

          resolve()
        })
        .catch(() => {
          loading('weeklyReview/fetchWeeklyReviewData', false)

          reject()
        })
    })
  },
  saveWeeklyReviewData({ commit, state }, { form }) {
    return new Promise((resolve, reject) => {
      loading('weeklyReview/saveWeeklyReviewData', true)

      api.post(`/api/review/${state.token}`, form)
        .then(response => {
          const properties = ['messages', 'summary', 'next']

          properties.forEach(property => {
            if (response[property]) {
              commit(`set${capitalize(property)}`, response[property])
            }
          })

          loading('weeklyReview/saveWeeklyReviewData', false)

          resolve()
        })
        .catch(() => {
          loading('weeklyReview/saveWeeklyReviewData', false)

          reject()
        })
    })
  },
}
