const routeParamToNumber = paramName => route => {
  let param = route.params[paramName]
  if (param === 'default') {
    param = -1
  }

  return {
    ...route.params,
    [paramName]: +param,
  }
}

export default routeParamToNumber
