let observerIndex = -1

export default {
  inject: {
    registerValidationObserver: {
      default: null,
    },
    unregisterValidationObserver: {
      default: null,
    },
  },

  mounted() {
    if (!this.registerValidationObserver || !this.appendValidator) return
    const that = this

    observerIndex = this.registerValidationObserver({
      get isValid() {
        return that.$refs.observer?.ctx.valid
      },
      validate: () => (
        this.validationEnabled
          ? this.$refs.observer?.validate()
          : Promise.resolve(true)
      ),
      getStepIndex: this.getStepIndex,
    })
  },

  methods: {
    getStepIndex() {
      return this.thisStep
    },
    unregisterObserver() {
      if (!this.unregisterValidationObserver || !this.appendValidator) return

      this.unregisterValidationObserver(observerIndex)
    },
  },
}
