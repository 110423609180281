import { logoutAndOpenDialog } from '@/helpers'

export default store => {
  store.subscribe(mutation => {
    const { type } = mutation
    const isBootstrapping = store.getters['loading/getLoadingStateForAction']('bootstrap/preBootstrap')
      || store.getters['loading/getLoadingStateForAction']('bootstrap/bootstrap')

    if (type == 'auth/setIsCurrentUserAuthorized'
      && store.state.auth.isCurrentUserAuthorized
      && !store.state.bootstrap.hasBootstrapped
      && !isBootstrapping
    ) {
      store.dispatch('bootstrap/preBootstrap')
        .catch(err => logoutAndOpenDialog('Error', { err }))
    }
  })
}
