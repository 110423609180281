import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  fetchSettings({ commit }) {
    return new Promise((resolve, reject) => {
      loading('stepTracking/fetchSettings', true)

      api.get('/api/fit-tracking/me/settings')
        .then(response => {
          commit('setSettings', response[0])
          loading('stepTracking/fetchSettings', false)

          resolve(response[0])
        })
        .catch(() => {
          loading('stepTracking/fetchSettings', false)

          reject()
        })
    })
  },
  saveSettings({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('stepTracking/saveSettings', true)

      api.post('api/fit-tracking/me/settings', form)
        .then(() => {
          loading('stepTracking/saveSettings', false)

          resolve()
        })
        .catch(() => {
          loading('stepTracking/saveSettings', false)

          reject()
        })
    })
  },
  fetchTimePeriod({ commit }, type) {
    return new Promise((resolve, reject) => {
      loading('stepTracking/fetchTimePeriod', true)

      api.get(`api/fit-tracking/me/fit/${type.toLowerCase()}`)
        .then(response => {
          const { settings, ...newResponse } = response

          commit('setSettings', settings[0])
          commit(`set${type}`, newResponse)

          loading('stepTracking/fetchTimePeriod', false)

          resolve()
        })
        .catch(() => {
          loading('stepTracking/fetchTimePeriod', false)

          reject()
        })
    })
  },
  fetchDayStep({ commit }, day) {
    return new Promise((resolve, reject) => {
      api.get(`api/fit-tracking/me/fit/${day}`)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          reject()
        })
    })
  },
  logSteps({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('stepTracking/logSteps', true)

      api.post('api/fit-tracking/me/fit', form)
        .then(() => {
          loading('stepTracking/logSteps', false)

          resolve()
        })
        .catch(() => {
          loading('stepTracking/logSteps', false)

          reject()
        })
    })
  },
  deleteSteps({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading('stepTracking/deleteSteps', true)

      api.delete(`api/fit-tracking/me/fit/${id}`)
        .then(() => {
          loading('stepTracking/deleteSteps', false)

          resolve()
        })
        .catch(() => {
          loading('stepTracking/deleteSteps', false)

          reject()
        })
    })
  },
}
