import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'

import initialState from './state'

export default {
  setProgramColors(state, colors) {
    if (!colors) return

    const variables = Object.entries(colors).reduce((acc, [key, value]) => (
      Object.assign(acc, { [`color-${key.replace(/_/g, '-')}`]: value })
    ), {})
    state.programColors = variables

    cssVars({
      rootElement: document.body,
      variables,
    })
  },
  setLoginColors(state, colors) {
    if (!colors) return

    const variables = Object.entries(colors).reduce((acc, [key, value]) => (
      Object.assign(acc, { [`color-${key.replace(/_/g, '-')}`]: value })
    ), {})
    state.loginColors = variables
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
    document.documentElement.removeAttribute('style')
  },
}
