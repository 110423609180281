import { Validator } from 'vee-validate'
import XRegExp from 'xregexp'

import { NAME_REGEX } from '@/constants'

const nameLastName = {
  getMessage: () => 'The value does not appear to be valid',
  validate(value) {
    return new Promise(resolve => {
      const pattern = new XRegExp(NAME_REGEX)
      resolve({ valid: pattern.test(value) })
    })
  },
}

Validator.extend('name_lastname', nameLastName)
