export default {
  methods: {
    scrollToTop(hasContainer = true) {
      const selector = this.isIos && hasContainer ? '.container' : '.main'
      const element = document.querySelector(selector)

      element.scrollTo(0, 0)
    },
  },
}
