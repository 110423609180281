<template>
  <span class="journey-status">
    {{ $t(statusText) }}
  </span>
</template>

<script>
  export default {
    props: {
      isCompleted: {
        type: Boolean,
        required: true,
      },
    },

    computed: {
      statusText() {
        return this.isCompleted
          ? `${this.$options.slug}.completed`
          : `${this.$options.slug}.incompleted`
      },
    },

    slug: 'component.journey.journey-section-item.journey-status',
  }
</script>

<style lang="scss">
  .journey-status {
    grid-area: status;
    font-size: 1rem;
    font-weight: 400;
    text-align: right;
    @include min-lg {
      font-size: 1.2rem;
    }
  }
  .journey-section-item {
    .journey-status {
      color: color(incomplete-learn-card-status);
    }
    &--is-completed {
      .journey-status {
        color: color(complete-learn-card-status);
      }
    }
    &--is-active {
      .journey-status {
        color: color(active-learn-card-status);
      }
    }
  }
</style>
