function unqKey(key) {
  return `ca_${process.env.NODE_ENV}_${key}`
}

export default function storageFactory(storage) {
  return {
    get(key, defaultValue) {
      const val = storage.getItem(unqKey(key))

      if (val && val !== undefined) {
        return JSON.parse(val).value
      }

      return defaultValue
    },
    set(key, value) {
      storage.setItem(unqKey(key), JSON.stringify({ value }))

      return this
    },
    unset(key) {
      storage.removeItem(unqKey(key))
    },
    generateStorageKey(key) {
      return unqKey(key)
    },
    parseValue(val) {
      return val ? JSON.parse(val).value : val
    },
    clear() {
      storage.clear()
    },
  }
}
