import { v4 as uuidv4 } from 'uuid'

export default () => ({
  invitationUser: null,
  is2fa: false,
  isCurrentUserAuthorized: false,
  isRefreshingToken: false,
  session: uuidv4(),
  token: null,
  policiesToAccept: null,
  referralUser: null,
  referralErrorUser: null,
  consentDocuments: null,
})
