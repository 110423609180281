<template>
  <svg
    ref="spinner"
    class="spinner-dots"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 300 300"
    style="enable-background:new 0 0 300 300;"
    xml:space="preserve"
  >
    <!-- eslint-disable vue/max-attributes-per-line -->
    <circle class="spinner-dots__dot" cx="150" cy="71.5" r="21.5"/>
    <circle class="spinner-dots__dot" cx="218.33" cy="110.91" r="21.5"/>
    <circle class="spinner-dots__dot" cx="218.09" cy="189.5" r="21.5"/>
    <circle class="spinner-dots__dot" cx="150.5" cy="228.5" r="21.5"/>
    <circle class="spinner-dots__dot" cx="82.17" cy="189.09" r="21.5"/>
    <circle class="spinner-dots__dot" cx="82.41" cy="110.5" r="21.5"/>
    <!-- eslint-enable -->
  </svg>
</template>

<script>
  import {
    gsap,
  } from 'gsap'

  const COLORS = [
    '#bbbcbd',
    '#dddede',
    '#303233',
    '#54585a',
    '#767a7b',
    '#989b9c',
  ]

  const COLORS_AMOUNT = COLORS.length

  export default {
    mounted() {
      this.setDots()
      this.changeColors()

      this.interval = setInterval(this.changeColors, 150)
    },

    beforeDestroy() {
      clearTimeout(this.interval)
    },

    data() {
      return {
        index: 0,
      }
    },

    methods: {
      changeColors() {
        this.dots.forEach((dot, i) => {
          const index = this.wrapIndex(i + this.index)

          gsap.set(dot, { fill: COLORS[index] })
        })

        this.updateIndex()
      },
      updateIndex() {
        this.index = this.wrapIndex(this.index + 1)
      },
      setDots() {
        this.dots = [...this.$refs.spinner.querySelectorAll('.spinner-dots__dot')].reverse()
      },
      wrapIndex(i) {
        return i >= COLORS_AMOUNT ? i - COLORS_AMOUNT : i
      },
    },
  }
</script>

<style lang="scss">
  .spinner-dots {
    width: 7rem;
    height: 7rem;
  }
</style>
