import activityQuiz from './activity-quiz'
import analytics from './analytics'
import article from './article'
import articles from './articles'
import auth from './auth'
import blockedPlugin from './blocked-plugin'
import bootstrap from './bootstrap'
import coaching from './coaching'
import colors from './colors'
import dashboard from './dashboard'
import dialog from './dialog'
import eq5d from './eq-5d'
import faceAgeTest from './face-age-test'
import feedback from './feedback'
import foodTracking from './food-tracking'
import glucoseTracking from './glucose-tracking'
import goals from './goals'
import history from './history'
import initialAssessment from './initial-assessment'
import kioskMode from './kiosk-mode'
import languages from './languages'
import layout from './layout'
import legalDocument from './legal-document'
import loading from './loading'
import multiAnalytics from './multianalytics'
import notifications from './notifications'
import optionalProfileData from './optional-profile-data'
import _package from './package' // package is a reserved word
import personalDataStore from './personal-data-store'
import program from './program'
import progress from './progress'
import quickQuestions from './quick-questions'
import quizzesAnswers from './quizzes-answers'
import readingRoom from './reading-room'
import requests from './requests'
import route from './route'
import search from './search'
import snackbars from './snackbars'
import stepTracking from './step-tracking'
import support from './support'
import timezone from './timezone'
import trickySituations from './tricky-situations'
import triggers from './triggers'
import unsubscribe from './unsubscribe'
import user from './user'
import weeklyReview from './weekly-review'
import weightTracking from './weight-tracking'

export default {
  _package,
  activityQuiz,
  article,
  articles,
  auth,
  blockedPlugin,
  bootstrap,
  coaching,
  colors,
  dashboard,
  dialog,
  eq5d,
  faceAgeTest,
  feedback,
  foodTracking,
  glucoseTracking,
  goals,
  history,
  initialAssessment,
  kioskMode,
  languages,
  layout,
  legalDocument,
  loading,
  multiAnalytics,
  notifications,
  optionalProfileData,
  personalDataStore,
  program,
  progress,
  quizzesAnswers,
  readingRoom,
  requests,
  search,
  snackbars,
  stepTracking,
  support,
  trickySituations,
  triggers,
  unsubscribe,
  user,
  weeklyReview,
  weightTracking,
  route,
  timezone,
  analytics,
  quickQuestions,
}
