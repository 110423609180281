import axios from 'axios'
import jwtDecode from 'jwt-decode'

import {
  NO_AUTH_ENDPOINTS,
  OPTIONAL_TOKEN_ENDPOINTS,
  VERBS,
} from '@/constants'

import { UnauthorizedError } from '@/extensions/error'
import DeferredPromise from '@/extensions/promise/deferredPromise'

import { formatDateFields } from '@/helpers'
import isResponseStatus from '@/helpers/isResponseStatus'
import normalizeUrl from '@/helpers/normalizeUrl'

import store from '@/store'
import getUserLang from '@/store/helpers/getUserLang'

import localStorage from '@/tools/local-storage'

import { tokenManager } from './index'

const TOTAL_NETWORK_RETRIES = 3
const TIME_BETWEEN_RETRIES = 2000 // ms
/* eslint-disable class-methods-use-this */
class Api {
  constructor() {
    this.retries = 0
    this._api = this._createApi()

    this._createVerbsMethods()
    this._setupRequestInterceptors()
    this._setupResponseInterceptors()
  }

  _createApi() {
    return axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      timeout: process.env.VUE_APP_TIMEOUT,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept-Language': 'pl',
        Accept: 'application/json, multipart/form-data',
        'Cache-Control': 'no-store',
        'X-Custom-Lang': getUserLang(),
        Origin: window.location.origin,
      },
    })
  }

  _createVerbsMethods() {
    Object.entries(VERBS).forEach(([key, verbs]) => {
      verbs.forEach(verb => {
        this[verb] = async function createVerbMethod(url, data, config) {
          const args = key == 'payload' ? [data, config] : [config]
          const deferred = new DeferredPromise()
          const isPaused = await this._shouldRequestBePaused(url)
          const source = axios.CancelToken.source()
          const id = `${new Date().getTime()}${Math.floor(Math.random() * 9000000000)}`
          const promise = this._createVerbPromise(args, id, url, verb)

          this._store.commit('requests/addRequest', { deferred, id, isPaused, promise, source, url })

          if (isPaused) {
            this._store.commit('bootstrap/setHasInitialized', true)
          }

          return isPaused ? deferred : promise()
        }
      })
    })
  }

  _createVerbPromise(args, id, url, verb) {
    return () => (
      new Promise((resolve, reject) => {
        this._api[verb](normalizeUrl(url), ...args)
          .then(response => {
            this._store.commit('requests/removeRequest', id)
            // shitty but works, to be fixed once we have a legit preloader
            // rejects when it was too late to cancel a request on logout
            if (!this._isUrlNoAuthEndpoint(url)
              && !this.hasToken
              && !this._isTokenOptional(url)
            ) {
              return reject()
            }
            resolve(response.data)
          })
          .catch(err => {
            if (!axios.isCancel(err)) {
              this._store.commit('requests/removeRequest', id)
            }

            reject(err)
          })
      })
    )
  }

  _setupRequestInterceptors() {
    this._api.interceptors.request
      .use(config => {
        const token = tokenManager.getToken()
        const { url } = config || {}

        if (this._shouldAppendToken(url, token)) {
          config.headers.Authorization = `Bearer ${token}`
        }

        return config
      })
  }

  _setLangHeader(lang) {
    localStorage.set('X-Custom-Lang', lang)
    Object.assign(this._api.defaults, { headers: { 'X-Custom-Lang': lang } })
  }

  _setupResponseInterceptors(decode = jwtDecode) {
    this._api.interceptors.response
      .use(response => {
        this.retries = 0
        const { isCurrentUserAuthorized, token } = this._store.state.auth

        if (token && !isCurrentUserAuthorized) {
          const decoded = decode(token)
          this._store.commit('auth/setIsCurrentUserAuthorized', !decoded.user._2fa || decoded.verified)
        }
        if (this._store.state.user?.user?.timezone) {
          response.data = formatDateFields(response.data)
        }

        return response
      }, err => {
        // retry Network Error for TOTAL_NETWORK_RETRIES times after 2000ms
        if (err.code === 'ERR_NETWORK') {
          if (this.retries >= TOTAL_NETWORK_RETRIES) {
            this.retries = 0
          } else {
            const { config } = err
            const delayRetryRequest = new Promise(resolve => {
              setTimeout(() => {
                this.retries += 1
                resolve()
              }, TIME_BETWEEN_RETRIES)
            })
            return delayRetryRequest.then(() => this._api.request(config))
          }
        }

        if (!isResponseStatus(401, err)
            || (err && this._isUrlNoAuthEndpoint(err.config.url))
           || (err && this._isTokenOptionalAndMissing(err.config.url))) {
          return Promise.reject(err)
        }

        return tokenManager.handleToken(true)
                .then(token => {
                  const request = err.config
                  request.headers.Authorization = `Bearer ${token}`

                  return axios.request(request)
                })
                .then(response => Promise.resolve(response))
                .catch(() => Promise.reject(new UnauthorizedError()))
      })
  }

  _shouldRequestBePaused(url) {
    if (this._isUrlNoAuthEndpoint(url) || this._isTokenOptionalAndMissing(url)) {
      return false
    } else if (this._store.state.auth.isRefreshingToken) {
      return true
    }

    return tokenManager.handleToken(false)
            .then(({ shouldRequestBePaused }) => shouldRequestBePaused)
            .catch(() => true)
  }

  _isUrlNoAuthEndpoint(url) {
    return NO_AUTH_ENDPOINTS.some(endpoint => url.includes(endpoint))
  }

  _isTokenOptional(url) {
    return OPTIONAL_TOKEN_ENDPOINTS.some(endpoint => url.includes(endpoint))
  }

  _isTokenOptionalAndMissing(url) {
    return this._isTokenOptional(url) && !this.hasToken
  }

  _shouldAppendToken(url, token) {
    return token && (!this._isUrlNoAuthEndpoint(url) || this._isTokenOptional(url))
  }

  get _store() {
    return store
  }

  get hasToken() {
    return store.getters['auth/hasToken']
  }
}

export default Api
