<template>
  <span class="journey-type">
    {{ displayText }}
  </span>
</template>

<script>
  import { capitalize } from 'lodash'

  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
    },
    computed: {
      displayText() {
        return capitalize(this.$t(this.$static.journeyTypes[this.type]))
      },
    },
    static() {
      return {
        journeyTypes: {
          'profile-data': 'Profile Data',
          article: 'Article',
          survey: 'Quiz',
          video: 'Video',
        },
      }
    },
  }
</script>

<style lang="scss">
  .journey-type {
    grid-area: type;
    font-size: 0.9rem;
    font-weight: 600;
    @include min-lg {
      font-size: 1.2rem;
    }
  }
  .journey-section-item {
    .journey-type {
      color: color(incomplete-learn-card-type);
    }
    &--is-completed {
      .journey-type {
        color: color(complete-learn-card-type);
      }
    }
    &--is-active {
      .journey-type {
        color: color(active-learn-card-type);
      }
    }
  }
</style>
