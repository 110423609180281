export default () => ({
  gettingConfidentQuiz: {
    answers: null,
    answersId: null,
    answersUpdatedAt: null,
  },
  gettingSupportQuiz: {
    answers: null,
    answersId: null,
    answersUpdatedAt: null,
  },
  maintenancePlan: {
    answers: null,
    answersId: null,
    answersUpdatedAt: null,
  },
  reasonsToLoseWeight: {
    answers: null,
    answersId: null,
    answersUpdatedAt: null,
  },
  reflectingQuiz: {
    answers: null,
    answersId: null,
    answersUpdatedAt: null,
  },
  settingTheStrongestPlan: {
    answers: null,
    answersId: null,
    answersUpdatedAt: null,
  },
  winningAgainstTemptations: {
    answers: null,
    answersId: null,
    answersUpdatedAt: null,
  },
  trickySituations: {
    answers: null,
    answersId: null,
    answersUpdatedAt: null,
  },
  habitsQuiz: {
    answers: null,
    answersId: null,
    answersUpdatedAt: null,
  },
})
