import { Validator } from 'vee-validate'

import { postcodes } from '@/regex'

const postcodeUK = {
  getMessage: () => 'Invalid postcode.',
  validate(value) {
    return new Promise(resolve => resolve({ valid: postcodes.UK.test(value) }))
  },
}

Validator.extend('postcode_UK', postcodeUK)
