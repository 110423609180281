<template>
  <component
    class="screen-reader-only"
    :is="component"
  >
    <slot />
  </component>
</template>

<script>
  export default {
    props: {
      component: {
        type: String,
        default: 'span',
      },
    },
  }
</script>

<style lang="scss">
  .screen-reader-only {
    @include visually-hidden;
  }
</style>
