import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  fetchFoods({ commit }, date) {
    return new Promise((resolve, reject) => {
      loading('foodTracking/fetchFoods', true)

      api.get(`api/food-tracking/me/food/${date}`)
        .then(response => {
          commit('setFoods', response)
          loading('foodTracking/fetchFoods', false)

          resolve()
        })
        .catch(() => {
          loading('foodTracking/fetchFoods', false)

          reject()
        })
    })
  },
  fetchFoodsByDateTime({ commit }, date) {
    return new Promise((resolve, reject) => {
      loading('foodTracking/fetchFoods', true)

      api.get(`api/food-tracking/me/food/show-by-date/${date}`)
        .then(response => {
          loading('foodTracking/fetchFoods', false)

          resolve(response)
        })
        .catch(() => {
          loading('foodTracking/fetchFoods', false)

          reject()
        })
    })
  },
  fetchFood({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading('foodTracking/fetchFood', true)

      api.get(`api/food-tracking/me/food/show/${id}`)
        .then(response => {
          commit('setFood', response)
          loading('foodTracking/fetchFood', false)

          resolve()
        })
        .catch(() => {
          loading('foodTracking/fetchFood', false)

          reject()
        })
    })
  },
  addFood({ commit }, { form }) {
    return new Promise((resolve, reject) => {
      loading('foodTracking/addFood', true)

      api.post('api/food-tracking/me/food/day', form)
        .then(() => {
          loading('foodTracking/addFood', false)

          resolve()
        })
        .catch(() => {
          loading('foodTracking/addFood', false)

          reject()
        })
    })
  },
  deleteFood({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading('foodTracking/deleteFood', true)

      api.delete(`api/food-tracking/me/food/delete/${id}`)
        .then(() => {
          loading('foodTracking/deleteFood', false)

          resolve()
        })
        .catch(() => {
          loading('foodTracking/deleteFood', false)

          reject()
        })
    })
  },
  addImage({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('foodTracking/addImage', true)

      api.post('api/food-tracking/file/icon', form)
        .then(response => {
          loading('foodTracking/addImage', false)

          resolve(response.data.file.id)
        })
        .catch(err => {
          loading('foodTracking/addImage', false)

          reject(err.response.data.errors.file[0])
        })
    })
  },
  editFood({ commit }, { form, id }) {
    return new Promise((resolve, reject) => {
      loading('foodTracking/editFood', true)

      api.post(`api/food-tracking/me/food/update/${id}`, form)
        .then(() => {
          loading('foodTracking/editFood', false)
          resolve()
        })
        .catch(() => {
          loading('foodTracking/editFood', false)

          reject()
        })
    })
  },
}
