import loading from '@/store/helpers/loading'
import rootCommit from '@/store/helpers/rootCommit'

import { api } from '@/tools/api'

export default {
  changeProgress({ commit, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      loading('articles/changeProgress', true)
      api.post('api/progress', data)
        .then(() => {
          rootCommit('program/flagSectionItemOpened', {
            sectionItem: rootGetters['program/getCurrentSectionItem'],
            flag: true,
          })
          return resolve()
        })
        .catch(reject)
        .finally(() => loading('articles/changeProgress', false))
    })
  },
}
