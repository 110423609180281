<template>
  <optional-profile-data-journey
    is-dialog
    class="journey-profile-data-dialog"
    data-testid="dialog-journey-profile-data"
    v-bind="{ data, isSaving, item }"
  />
</template>

<script>
  import { mapGetters } from 'vuex'

  import { OptionalProfileDataJourney } from '@/components/optional-profile-data'

  export default {
    components: {
      OptionalProfileDataJourney,
    },

    props: {
      data: {
        type: Object,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        field: this.data.value,
      }
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'articles/changeProgress',
          'optionalProfileData/updateOptionalData',
        ])
      },
    },
  }
</script>

<style lang="scss">
  .journey-profile-data-dialog {
    @include max-lg {
      max-height: 100%;
      overflow: visible;
    }
    &__heading {
      font-size: 2.2rem;
      font-weight: 800;
    }
    &__information {
      margin: 0 0 2rem;
      color: color(primary);
      font-size: 1.8rem;
      font-weight: 600;
    }
    .modular-tile {
      max-width: 32rem;
      @include min-lg {
        max-width: 63rem;
      }
      &__main {
        padding: 0 3rem 3rem;
      }
    }
    .form__end-row {
      margin: 3rem 0 2rem;
    }
  }
</style>
