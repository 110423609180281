import loading from '@/store/helpers/loading'
import normalize from '@/store/helpers/normalize'

import { api } from '@/tools/api'

import normalizeCategories from './helpers/normalizeCategories'

export default {
  fetchTopics({ commit }, { triggerDataId }) {
    loading('readingRoom/fetchTopics', true)

    return new Promise((resolve, reject) => {
      api.get(`api/programs/optional-content/${triggerDataId}/topics`)
        .then(response => {
          commit('setTopics', response)

          resolve()
        })
        .catch(reject)
        .finally(() => loading('readingRoom/fetchTopics', false))
    })
  },
  fetchCategories({ commit }, { triggerDataId, offset = 0, topicId }) {
    loading('readingRoom/fetchCategories', true)

    return new Promise((resolve, reject) => {
      api.post(`api/programs/optional-content/list/${triggerDataId}/4`, { offset, topicId })
        .then(response => {
          if (!response || response.length === 0) {
            commit('setCategories', [])
            commit('setCategoriesItemsIds', [])
            commit('setCategoriesItemsOrder', [])

            resolve()
          }
          const content = response.optional_contents
          const {
            categories,
            categoriesItemsIds,
            categoriesItemsOrder,
          } = normalizeCategories(content)

          commit('setCategories', categories)
          commit('setCategoriesItemsIds', categoriesItemsIds)
          commit('setCategoriesItemsOrder', categoriesItemsOrder)

          resolve()
        })
        .catch(reject)
        .finally(() => loading('readingRoom/fetchCategories', false))
    })
  },
  fetchCategory({ commit }, id) {
    loading('readingRoom/fetchCategory', true)

    return new Promise((resolve, reject) => {
      api.get(`api/programs/optional-content/category/${id}`)
        .then(response => {
          const content = [response.optional_content]
          const {
            categories,
            categoriesItemsIds,
            categoriesItemsOrder,
          } = normalizeCategories(content)

          commit('setCategory', categories)
          commit('setCategoryItemsIds', categoriesItemsIds)
          commit('setCategoryItemsOrder', categoriesItemsOrder)

          resolve()
        })
        .catch(reject)
        .finally(() => loading('readingRoom/fetchCategory', false))
    })
  },
  filterCategory({ commit }, { id, type }) {
    loading('readingRoom/filterCategory', true)

    return new Promise((resolve, reject) => {
      api.get(`api/programs/optional-content/filter/${id}/${type}`)
        .then(response => resolve(response.optional_content_items || []))
        .catch(reject)
        .finally(() => loading('readingRoom/filterCategory', false))
    })
  },
  fetchItem({ commit }, id) {
    loading('readingRoom/fetchItem', true)

    return new Promise((resolve, reject) => {
      api.get(`api/programs/optional-content/show/${id}`)
        .then(response => {
          const { items: item } = normalize([response.optional_content_item])

          commit('setItem', item)
          resolve(item.value)
        })
        .catch(reject)
        .finally(() => loading('readingRoom/fetchItem', false))
    })
  },
  setFavorite({ commit, getters }, { favorite, id }) {
    loading('readingRoom/setFavorite', true)

    return new Promise((resolve, reject) => {
      const action = favorite ? 'favorite' : 'unfavorite'

      api.put(`api/programs/optional-content/${action}/${id}`)
        .then(response => {
          const source = getters.getItemSourceById(id)

          commit('setContentUser', { id, content_user: response.optional_content_user, source })
          resolve()
        })
        .catch(reject)
        .finally(() => loading('readingRoom/setFavorite', false))
    })
  },
  markItemAsRead({ commit, getters }, id) {
    loading('readingRoom/markItemAsRead', true)

    return new Promise((resolve, reject) => {
      api.put(`api/programs/optional-content/read/${id}`)
        .then(response => {
          const source = getters.getItemSourceById(id)

          commit('setContentUser', { id, content_user: response.optional_content_user, source })
          resolve()
        })
        .catch(reject)
        .finally(() => loading('readingRoom/markItemAsRead', false))
    })
  },
  fetchOptionalContentDetails({ commit }, id) {
    loading('readingRoom/fetchOptionalContentDetails', true)

    return new Promise((resolve, reject) => {
      api.get(`api/programs/optional-content/${id}/details`)
        .then(response => {
          commit('setContentDetails', { id, contentDetails: response })
          resolve()
        })
        .catch(reject)
        .finally(() => loading('readingRoom/fetchOptionalContentDetails', false))
    })
  },
}
