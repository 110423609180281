import { mapGetters } from 'vuex'

import { UNIT_CONVERTERS, WEIGHT_UNITS } from '@/constants'

import { kilogramsToPounds, poundsToKilograms } from '@/helpers/convertWeightUnits'

const applyPrecision = (value, precision = -1) => (
  precision === -1 ? value : (+value).toFixed(precision)
)

export default {
  methods: {
    convertToKgsIfNeeded(weightValue) {
      if (this.isMetric) return weightValue

      if (this.isPounds) {
        return this.getConvertedWeigth(weightValue, WEIGHT_UNITS.kg, 2)
      }

      // isStonesAndPounds
      // convert stones to pounds
      const pounds = weightValue.stones * UNIT_CONVERTERS.poundsInStones + weightValue.pounds
      // convert pounds to kgs
      return this.getConvertedWeigth(pounds, WEIGHT_UNITS.kg, 2)
    },
    convertToStonesOrPoundsIfNeeded(weightValue) {
      if (weightValue === 0) {
        return this.isStonesAndPounds ? { stones: 0, pounds: 0 } : { value: 0 }
      }
      if (this.isMetric) return { value: weightValue }

      if (this.isPounds) {
        return this.getConvertedWeigth(weightValue, WEIGHT_UNITS.lbs, 0)
      }

      // isStonesAndPounds
      return this.getConvertedWeigth(weightValue, WEIGHT_UNITS['st-lbs'])
    },
    getConvertedWeigth(weight, targetUnit, precision = null) {
      switch (targetUnit) {
        case WEIGHT_UNITS.kg:
          return {
            value: poundsToKilograms(weight),
          }
        case WEIGHT_UNITS.lbs:
          return {
            value: +applyPrecision(kilogramsToPounds(weight), precision),
          }
        case WEIGHT_UNITS['st-lbs']: {
          const pounds = +applyPrecision(kilogramsToPounds(weight), precision)
          return {
            stones: parseInt(pounds / UNIT_CONVERTERS.poundsInStones, 10),
            pounds: parseInt(pounds % UNIT_CONVERTERS.poundsInStones, 10),
          }
        }
        default:
          return NaN
      }
    },
  },

  computed: {
    ...mapGetters('user', [
      'isMetric',
      'isPounds',
      'isStonesAndPounds',
    ]),
  },
}
