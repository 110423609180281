import { kebabCase } from 'lodash'

export default function testid(name) {
  return {
    props: {
      testid: {
        type: String,
        default: '',
      },
    },

    computed: {
      dataTestid() {
        const _name = name || this.name

        return this.testid
                ? kebabCase(`${this.testid}${_name ? `-${_name}` : ''}`)
                : kebabCase(`${this.$route.name}-${_name}}`)
      },
    },
  }
}
