import { PAGE_TYPES } from '@/constants'

export default {
  routes: [
    {
      path: '/offline',
      name: 'Offline',
      meta: {
        authorization: false,
        layout: 'plain',
        type: PAGE_TYPES.other,
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: {
        appKioskMode: false,
        fetchProgram: false,
        header: 'My profile',
        layout: 'home',
        parent: 'Home',
        type: PAGE_TYPES.other,
      },
    },
    {
      path: '/support',
      name: 'Support',
      meta: {
        fetchProgram: false,
        layout: 'home',
        parent: 'Home',
        header: 'Support',
        type: PAGE_TYPES.tab,
      },
    },
    {
      path: '/articles/:id',
      name: 'ArticleView',
      props: true,
      meta: {
        fetchProgram: false,
        header: 'Welcome to Changing Health',
        layout: 'home',
        parent: 'Learn',
        toParent: true,
        type: PAGE_TYPES.cms,
      },
    },
  ],
}
