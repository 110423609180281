export default (ctx, store, echo) => {
  let uid = null

  const _getUserId = () => store.getters['user/getCurrentUserId']
  const _getProgramId = () => store.getters['program/getCurrentProgramIdForBlockerOrProgress']

  const _updateUid = () => {
    const programId = _getProgramId()
    const userId = _getUserId()
    const newUid = `${programId}.${userId}`

    if (uid != newUid) {
      if (uid) {
        echo.leave(`blocker.${uid}`)
      }

      if (!userId || !programId) return

      if (newUid) {
        echo.private(`blocker.${newUid}`)
          .listen('PluginBlocked', data => {
            ctx.commit('setBlockedPluginConditionally', data)
          })
      }

      uid = newUid
    }
  }

  store.subscribe(mutation => {
    const { type } = mutation

    if (type == 'auth/logout'
      || type == 'auth/resetState'
      || type == 'user/setUser'
      || type == 'user/decodeUser'
      || type == 'package/setPackage'
      || type == 'program/setProgram') {
      _updateUid()
    }
  })

  _updateUid()
}
