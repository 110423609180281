export default {
  methods: {
    addCustomValidatorError(key, message) {
      const field = this.$validator.fields.find({ name: key, scope: this.$options.scope })

      if (!field) return

      this.$validator.errors.add({
        id: field.id,
        field: key,
        msg: message,
        scope: this.$options.scope,
      })

      field.setFlags({
        invalid: true,
        valid: false,
        validated: true,
      })
    },
  },
}
