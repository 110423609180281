<template>
  <base-dialog
    class="forgotten-sent-dialog"
    data-testid="dialog-auth-forgotten-password-sent"
    :headerLevel="1"
    :headerText="$t(`${$options.slug}.title`)"
  >
    <p
      data-testid="dialog-auth-forgotten-password-sent-content-1"
    >
      {{ $t(`${$options.slug}.content-1`) }}
    </p>

    <p
      data-testid="dialog-auth-forgotten-password-sent-content-2"
    >
      {{ $t(`${$options.slug}.content-2`) }}
    </p>

    <p
      data-testid="dialog-auth-forgotten-password-sent-content-3"
    >
      {{ $t(`${$options.slug}.content-3`) }}
      <a href="mailto:hello@changinghealth.com">
        hello@changinghealth.com
      </a>
    </p>

    <template #action>
      <base-button
        class="forgotten-sent-dialog__close"
        data-testid="dialog-auth-forgotten-password-sent-close"
        @click="closeDialog"
      >
          {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },

    slug: 'component.dialog.auth.forgotten-password-sent-dialog',
  }
</script>

<style lang="scss">
  .dialog.forgotten-sent-dialog {
    width: 90%;
    max-width: none;
    @include min-sm {
      max-width: 40rem;
    }
  }
  .forgotten-sent-dialog {
    a {
      color: color(dark-primary);
      text-decoration: underline;
    }
    &__close {
      margin: 0 2.4rem 1.4rem;
    }
  }
</style>
