class PhoneTakenError extends Error {
  constructor() {
    super(
      `Phone or/and landline phone you used are already taken.
      Please use another one.`,
    )

    this.name = 'PhoneTakenError'
  }
}

export default PhoneTakenError
