<template>
  <div
    class="goals-view-row"
    data-testid="goals-view-row"
  >
    <div
      v-if="goalsEmpty"
      class="goals-view-row__single-tile goals-view-row__empty-goal"
      data-testid="goals-view-row-empty"
    >
      <modular-tile
        is-empty
        :headerText="$t(`${$options.slug}.no-goal`)"
      >
        <i18n
          data-testid="goals-view-row-empty-text"
          :path="`${$options.slug}.empty-text`"
          tag="p"
        >
          <router-link :to="{ name: 'Learn' }">
            {{ $t('learning journey') }}
          </router-link>
        </i18n>
      </modular-tile>
    </div>

    <template
      v-else
    >
      <h2
        data-testid="goals-view-row-heading"
      >
        <slot />
      </h2>
      <div
        class="goals-view-row__goals"
        data-testid="goals-view-row-goals"
      >
        <div
          v-for="(goal, i) in goals"
          :key="i"
          class="goals-view-row__single-tile"
          data-testid="goals-view-row-goal"
        >
          <goals-tile
            v-bind="{ goal }"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import GoalsTile from '@/components/goals/GoalsTile'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  export default {
    components: {
      GoalsTile,
      ModularTile,
    },

    props: {
      goals: {
        type: Array,
        required: true,
      },
      goalsEmpty: {
        type: Boolean,
        default: false,
      },
    },

    slug: 'component.goals.goals-view-row',
  }
</script>

<style lang="scss">
  $goals-tile-margin-sm: 1rem;
  $goals-tile-margin-lg: 1.5rem;

  .goals-view-row {
    &__goals {
      display: flex;
      flex-wrap: wrap;
      @include min-sm {
        margin: 0 (-$goals-tile-margin-sm);
      }
    }
    &__single-tile {
      flex: 1 0 100%;
      padding: 0 0 $goals-tile-margin-sm;
      @include min-sm {
        display: flex;
        flex-basis: 34%;
        max-width: 50%;
        padding: 0 $goals-tile-margin-sm $goals-tile-margin-sm;
      }
      @include min-md {
        flex-basis: 26%;
        max-width: 33.33333%;
      }
      @include min-lg {
        flex-basis: 21%;
        max-width: 25%;
      }
      @include min-xl {
        flex-basis: 17%;
        max-width: 20%;
      }
    }
    &__empty-goal {
      flex: 1;
      .modular-tile {
        margin-bottom: 0;
        text-align: center;
      }
      .modular-tile__header {
        font-weight: 800;
        text-transform: none;
      }
    }
  }
</style>
