import { mapState } from 'vuex'

export default {
  methods: {
    goBack() {
      this.$router.push(this.previousRoute)
    },
  },

  computed: {
    ...mapState('history', [
      'history',
    ]),
    previousRoute() {
      return this.$route.meta.toParent
              ? { name: this.$route.meta.parent }
              : this.history?.[this.history.length - 2]?.name
                ? this.history[this.history.length - 2]
                : { name: this.$route.meta.parent }
    },
  },
}
