import md5 from '@/helpers/md5'

import {
  getCookie,
  removeCookie,
  setCookie,
} from './cookie'

const getDecodedTokensFromCookies = () => {
  const tokens = getCookie('vdevice')

  return JSON.parse(tokens?.includes('{') ? tokens : '{}')
}

export const forgetAllDevices = () => removeCookie('vdevice')

export const getUserDeviceToken = ({ email }) => {
  const decodedTokens = getDecodedTokensFromCookies()
  const hashedEmail = md5(email)

  return decodedTokens[hashedEmail]
}

export const rememberUserDevice = ({ email, saveDeviceToken }) => {
  const decodedTokens = getDecodedTokensFromCookies()
  const expires = new Date()
  expires.setTime(expires.getTime() + 1000 * 3600 * 24 * 31 * 3) // +3 months

  const hashedEmail = md5(email)
  const tokensToSave = { ...decodedTokens, [hashedEmail]: saveDeviceToken }
  const parsedTokensToSave = JSON.stringify(tokensToSave)

  setCookie('vdevice', parsedTokensToSave, expires.toGMTString())
}
