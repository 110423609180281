import { BootstrapError } from '@/extensions/error'

import { formatDateFields } from '@/helpers'

import { setI18nStatics } from '@/i18n'
import loading from '@/store/helpers/loading'
import rootCommit from '@/store/helpers/rootCommit'
import rootDispatch from '@/store/helpers/rootDispatch'

import { api } from '@/tools/api'

export default {
  preBootstrap({ dispatch }) {
    return new Promise((resolve, reject) => {
      const action = 'bootstrap/preBootstrap'

      loading(action, true)

      api.get('/api/pre-bootstrap')
        .then(({
          program_id: programId,
          user_id: userId,
          language,
        }) => {
          rootCommit('program/setProgram', { id: programId })
          rootCommit('user/setUser', { id: userId })
          rootCommit('languages/setCurrentUserLanguage', { language, priority: 2 })
        })
        .then(() => dispatch('bootstrap'))
        .catch(reject)
        .finally(() => loading(action, false))
    })
  },
  init({ commit }) {
    return new Promise(() => {
      const action = 'bootstrap/init'

      loading(action, true)

      api.get('/api/init')
        .then(response => {
          const { lang, translates } = response?.statics || {}

          setI18nStatics(lang, translates)
          commit('setHasInitialized', true)
        })
        .catch(() => {
          setI18nStatics({}, {})
          commit('setHasInitialized', true)
        })
        .finally(() => loading(action, false))
    })
  },
  bootstrap({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      const action = 'bootstrap/bootstrap'

      loading(action, true)

      api.get('/api/v2/bootstrap')
        .then(response => {
          const {
            active_program: activeProgram,
            language,
            legal_documents: legalDocuments,
            blocked_plugin: blockedPlugin,
            ..._package
          } = response.user_package

          const { colors, progress, ...program } = activeProgram
          const {
            personal_data_store,
            enabled_2fa: is2fa,
            ...user
          } = response.user

          const dateFormatConfig = {
            timezone: response.user.time_zone,
          }
          const packageTimezoned = formatDateFields(_package, dateFormatConfig)
          const personalDataStoreTimezoned = formatDateFields(personal_data_store, dateFormatConfig)
          const programTimezoned = formatDateFields(program, dateFormatConfig)
          const legalDocumentsTimezoned = formatDateFields(legalDocuments, dateFormatConfig)
          const triggersTimezoned = formatDateFields(response.triggers, dateFormatConfig)
          const userTimezoned = formatDateFields(user, dateFormatConfig)

          rootCommit('auth/set2faStatus', is2fa)
          rootCommit('languages/setCurrentUserLanguage', { language, priority: 2 })
          rootCommit('_package/setPackage', packageTimezoned)
          rootCommit('blockedPlugin/setBlockedPluginConditionally', blockedPlugin)
          rootCommit('colors/setProgramColors', colors)
          rootCommit('dashboard/setWidgets', response.widgets)
          rootCommit('notifications/addNotifications', response.unread.notifications)
          rootCommit('optionalProfileData/setOptionalData', response['optional-data'])
          rootCommit('personalDataStore/setStore', personalDataStoreTimezoned)
          rootCommit('program/setProgram', programTimezoned)
          rootCommit('program/setProgress', progress)
          rootCommit('legalDocument/addLegalDocuments', legalDocumentsTimezoned)
          rootCommit('triggers/setTriggers', triggersTimezoned)
          rootCommit('user/setUser', userTimezoned)
          commit('setHasBootstrapped', true)

          rootDispatch('timezone/setUserOffsetTimezone', response.user.time_zone)
          resolve()
        })
        .catch(({ response } = {}) => reject(new BootstrapError(response?.data?.error)))
        .finally(() => {
          loading(action, false)
          dispatch('journey')
        })
    })
  },
  journey() {
    return new Promise((resolve, reject) => {
      const action = 'bootstrap/journey'

      loading(action, true)

      api.get('/api/v4/journey')
        .then(response => {
          const { active_program: activeProgram } = response

          rootCommit('program/setJourney', activeProgram)

          resolve()
        })
        .catch(({ response } = {}) => reject(new BootstrapError(response?.data?.error)))
        .finally(() => loading(action, false))
    })
  },
}
