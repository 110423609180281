<template>
  <div
    class="journey-section"
  >
    <div
      class="journey-section__items">
      <journey-article
        v-for="(item, i) in section.items"
        :key="i"
        v-bind="{ item }"
      />
    </div>
  </div>
</template>

<script>
  import JourneyArticle from './JourneyArticle'

  export default {
    components: {
      JourneyArticle,
    },

    props: {
      section: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .journey-section {
    position: relative;
    &__items {
      padding: 2rem 0;
      position: relative;
      &::after {
        @include min-lg {
          content: '';
          display: block;
          clear: both;
        }
      }
    }
  }
</style>
