<template>
  <div
    class="stepper-custom"
    :class="{ 'stepper-custom--hide-step-count': hideStepCount }"
    data-testid="stepper"
    v-merge-attr:data-testid
  >
    <div
      :class="{ 'stepper-custom__content--visible': isStepperContentVisible }"
      class="stepper-custom__content"
      data-testid="stepper-content"
    >
      <div
        class="stepper-custom__steps"
        data-testid="stepper-steps"
        :style="{ height: wrapperHeight }"
      >
        <slot v-bind="{ maxStep }"/>
      </div>

      <stepper-custom-buttons
        v-if="displayLastButtons || maxStep !== currentStep"
        ref="buttons"
        v-bind="{
          ...buttonLabels,
          currentStep,
          hasCancel,
          isForm,
          isLoading,
          isNextDisabled,
          maxStep,
        }"
        @cancel="onCancel"
        @finish="onFinish"
        @next="onNext"
        @previous="onPrevious"
        @focus-lost="onFocusLost"
      />
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  import { mapState } from 'vuex'

  import StepperCustomButtons from '@/components/stepper-custom/StepperCustomButtons'

  import mergeAttr from '@/directives/mergeAttr'

  import { getAllFocusables } from '@/helpers'

  import testid from '@/mixins/testid/testid'
  import unblockPlugin from '@/mixins/unblockPlugin'

  import eventBus from '@/tools/event-bus'

  const DURATION = 450
  const stepperData = Vue.observable({ currentStep: 0 })

  export default {
    components: {
      StepperCustomButtons,
    },

    directives: {
      mergeAttr,
    },

    provide() {
      return {
        stepperData,
      }
    },

    mixins: [
      testid(),
      unblockPlugin,
    ],

    props: {
      buttonLabels: {
        type: Object,
        default: () => ({}),
      },
      eventCallbacks: {
        type: Object,
        default: () => ({}),
      },
      isForm: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      isNextDisabled: {
        type: Boolean,
        default: false,
      },
      hasCancel: {
        type: Boolean,
        default: true,
      },
      hideStepCount: {
        type: Boolean,
        default: false,
      },
      validateStep: {
        type: Function,
        default: null,
      },
      displayLastButtons: {
        type: Boolean,
        default: true,
      },
    },

    beforeDestroy() {
      this.allSteps.forEach(step => {
        step.$off('step-transition', this.setHeight)
      })
      clearTimeout(this.timeoutId)
    },

    watch: {
      currentStep: {
        handler() {
          stepperData.currentStep = this.currentStep
        },
        immediate: true,
      },
    },

    data() {
      return {
        allSteps: [],
        currentStep: 0,
        testShow: true,
        timeoutId: null,
        wrapperHeight: 'auto',
        animationData: { enterHeight: -1, leaveHeight: -1 },
        toFocus: null,
      }
    },

    methods: {
      registerChild(child) {
        const updateIndexes = this.addChildToSteps(child)

        this.updateChildrenMaxStep()
        if (updateIndexes) this.updateChildrenThisStep()

        child.$on('step-transition', this.setHeight)
      },
      unregisterChild(childStep) {
        const index = this.allSteps.findIndex(step => step.thisStep === childStep)

        this.allSteps[index].$off('step-transition', this.setHeight)
        this.allSteps.splice(index, 1)
        this.updateChildrenMaxStep()
        if (index !== this.allStepsAmount) this.updateChildrenThisStep()
      },
      addChildToSteps(child) {
        const forceIndex = child.forceStepIndex !== -1
        const retVal = forceIndex
          ? this.addChildToForcedPosition(child)
          : this.addChildToFreePosition(child)

        child.thisStep = retVal.index

        return retVal.updateIndexes
      },
      addChildToForcedPosition(child) {
        const childIndex = child.forceStepIndex
        let updateIndexes = false

        if (this.allSteps[childIndex] === undefined) {
          this.allSteps[childIndex] = child
        } else {
          this.allSteps.splice(childIndex, 0, child)
          updateIndexes = true
        }

        return { index: childIndex, updateIndexes }
      },
      addChildToFreePosition(child) {
        const emptyIndex = this.allSteps.findIndex(val => val === undefined)
        let index = -1

        if (emptyIndex === -1) {
          index = this.allSteps.push(child) - 1
        } else {
          this.allSteps[emptyIndex] = child
          index = emptyIndex
        }

        return { index, updateIndexes: false }
      },
      setHeight({ height, type }) {
        this.animationData[type] = height
        const { enterHeight, leaveHeight } = this.animationData

        if (enterHeight !== -1 && leaveHeight !== -1) {
          this.wrapperHeight = `${leaveHeight}px`
          requestAnimationFrame(() => {
            this.wrapperHeight = `${enterHeight}px`

            this.animationData.enterHeight = -1
            this.animationData.leaveHeight = -1

            clearTimeout(this.timeoutId)
            this.timeoutId = setTimeout(this.onAnimationFinish, DURATION)
          })
        }
      },
      updateChildrenMaxStep() {
        this.allSteps.forEach(child => child.maxStep = this.maxStep)
      },
      updateChildrenThisStep() {
        this.allSteps.forEach((child, index) => child.thisStep = index)
      },

      onAnimationFinish() {
        this.wrapperHeight = 'auto'
        eventBus.$emit('stepper-animation-finished')

        // eslint-disable-next-line no-unused-expressions
        this.toFocus?.focus()
      },

      onPrevious() {
        if (this.currentStep === 0) return

        this.useEventCallback('onPrevious', () => {
          this.changeStep(this.currentStep - 1, 'previous')
        })
      },
      async onNext() {
        if (this.currentStep === this.maxStep || this.isNextDisabled) return

        if (this.isForm && this.validateStep) {
          const validation = await this.validateStep(this.currentStep)

          if (!validation) {
            return this.$emit('focus-invalid')
          }
        }
        this.useEventCallback('onNext', () => {
          this.changeStep(this.currentStep + 1, 'next')
        })
      },
      changeStep(step, event = 'step-set') {
        if (step >= 0 && step <= this.maxStep) {
          this.currentStep = step
          this.$emit(event, this.currentStep)
          this.$emit('step-changed', this.currentStep)
          this.announceStepChange()
          /* eslint-disable-next-line prefer-destructuring */
          this.toFocus = getAllFocusables(this.allSteps[this.currentStep].$el)[0]

          if (this.currentStep == this.maxStep) {
            this.$emit('last-step-accessed')
          }
        } else {
          throw new Error(`
            Step doesn't exist!\n
            Tried to change to step: ${step} from ${this.currentStep}
          `)
        }
      },
      onCancel() {
        this.useEventCallback('onCancel', () => this.$emit('cancel'))
      },
      async onFinish() {
        if (this.isForm && this.validateStep) {
          const validation = await this.validateStep(this.currentStep)

          if (!validation) {
            return this.$emit('focus-invalid')
          } else {
            this.useEventCallback('onFinish', () => this.$emit('finish'))
          }
        } else {
          this.useEventCallback('onFinish', () => this.$emit('finish'))
        }
      },
      onFocusLost() {
        const focusable = getAllFocusables(this.allSteps[this.currentStep].$el)

        this.toFocus = focusable[0] || this.$refs.buttons?.$refs.leftButton
      },
      useEventCallback(event, cb) {
        this.eventCallbacks[event]
          ? this.eventCallbacks[event](cb, this.currentStep, this.maxStep)
          : cb()
      },
      announceStepChange() {
        const text = this.$t('component.stepper-custom.stepper-custom-step.progress', {
          currentStep: this.currentStep + 1,
          allSteps: this.allStepsAmount,
        })

        this.$announcer.set(`${this.$t('Changed step.')} ${text}`)
      },
    },

    computed: {
      ...mapState('layout', [
        'isStepperContentVisible',
      ]),
      allStepsAmount() {
        return this.allSteps.length
      },
      maxStep() {
        return this.allStepsAmount - 1
      },
    },
  }
</script>

<style lang="scss">
  $padding-wrapper-sm: 1.4rem;
  $padding-wrapper-lg: 3.5rem;
  $border-radius: 1rem;

  .stepper-custom {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    max-height: fit-content;
    padding: $padding-wrapper-sm;
    position: relative;
    font-size: 1.4rem;
    text-decoration: none;
    border-radius: $border-radius;
    background-color: color(_white);
    box-shadow: box-shadow(default);
    @include min-lg {
      padding: $padding-wrapper-lg;
    }
    &--hide-step-count {
      .stepper-step__progress-indicator {
        display: none;
      }
    }
    &__content {
      width: 100%;
      margin: 0 -0.6rem;
      padding: 0 0.6rem;
      // z-index: z-index(over-base);
      z-index: z-index(over-base) + 1;
      overflow: hidden;
      &--visible {
        overflow: visible;
      }
    }
    &__steps {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 0 -1rem;
      transition: height 0.4s;
    }
  }
</style>
