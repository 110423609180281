import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/shopping-list-switcher/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Shopping List Switcher',
      layout: 'home',
      toParent: true,
      trigger: 'shopping-list-switcher',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'ShoppingListSwitcher',
    },
  },
  routes: [
    {
      path: '/shopping-list-switcher',
      name: 'ShoppingListSwitcher',
      meta: {
        parent: 'Tools',
        pluginHome: true,
      },
    },
    {
      path: '/shopping-list-switcher/options',
      name: 'ShoppingListSwitcherItems',
      meta: {
        parent: 'ShoppingListSwitcher',
      },
    },
  ],
}
