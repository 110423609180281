<template>
  <popper
    ref="popper"
    class="popper-container"
    :options="$static.popperOptions"
    :transition="'fade'"
    :trigger="'click'"
  >
    <span class="popper">
      <span
        :id="contentId"
        class="popper__content"
      >
        {{ $t(message) }}
      </span>
    </span>

    <slot/>

    <button
      slot="reference"
      class="tooltip-toggle"
      aria-label="Show tooltip"
      type="button"
      :aria-describedby="contentId"
      :data-testid="`${dataTestid}-tooltip-toggle`"
      @keydown.esc="close"
    >
      <inline-svg
        src="icons/icon-information.svg"
        class="tooltip-toggle-icon"
      />
    </button>
  </popper>
</template>

<script>
  import Popper from 'vue-popperjs'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      Popper,
    },

    mixins: [
      testid(),
    ],

    props: {
      message: {
        type: String,
        default: '',
      },
    },

    methods: {
      close() {
        this.$refs.popper.doClose()
      },
    },

    computed: {
      contentId() {
        return `tooltip-${this.uuid}`
      },
    },

    static() {
      return {
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: true,
            },
            flip: {
              enabled: true,
            },
            hide: {
              enabled: true,
            },
            inner: {
              enabled: false,
            },
            offset: {
              offset: '10px',
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
              padding: 40,
            },
            placement: 'bottom',
            positionFixed: true,
          },
        },
      }
    },
  }
</script>

<style lang="scss">
  @import '~vue-popperjs/dist/vue-popper';

  $popperBlock: popper;
  $transitionTime: .2s;

  .tooltip-toggle {
    display: inline-block;
    padding: .6rem;
    cursor: pointer;
    &:active {
      svg {
        fill: color(dark-primary);
      }
    }
    svg {
      width: 2.4rem;
      fill: color(grey);
      vertical-align: top;
    }
  }
  .#{$popperBlock} {
    display: block;
    width: 28rem;
    max-width: calc(100vw - 4rem);
    background: none;
    border: none;
    box-shadow: none;
    word-break: break-word;
    &[x-out-of-boundaries] {
      .#{$popperBlock}__content {
        transform: scale(0);
      }
      .#{$popperBlock}__arrow {
        opacity: 0;
      }
    }
    &[x-placement^="bottom"] {
      .#{$popperBlock}__content {
        transform-origin: top;
      }
      .#{$popperBlock}__arrow {
        top: -0.8rem;
        border-width: 1.2rem;
        border-top-width: 0;
        border-bottom-color: color(_white);
      }
    }
    &[x-placement^="top"] {
      .#{$popperBlock}__content {
        transform-origin: bottom;
      }
      .#{$popperBlock}__arrow {
        bottom: -0.8rem;
        border-width: 1.2rem;
        border-bottom-width: 0;
        border-top-color: color(_white);
      }
    }
  }
  .#{$popperBlock}__content {
    display: block;
    padding: 1.8rem;
    transform: scale(1);
    transition: transform $transitionTime;
    background: color(_white);
    font-size: 1.4rem;
    border-radius: 1rem;
    color: color(primary-text);
    box-shadow: box-shadow(default);
    line-height: 1.8rem;
  }
  .#{$popperBlock}-container {
    position: relative;
    z-index: z-index(over-content);
    & > span {
      transition: opacity $transitionTime;
      opacity: 1;
      will-change: opacity;
      &.fade-enter-active, &.fade-leave-active {
        transition: transform $transitionTime;
      }
      &.fade-enter, &.fade-leave-to {
        opacity: 0;
        .popper__content {
          transform: scale(0);
        }
      }
    }
  }
</style>
