const clearTags = selector => {
  [...selector].forEach(item => {
    if (item.tagName === 'STYLE' || item.tagName === 'SCRIPT') {
      item.removeAttribute('type')
      item.removeAttribute('charset')
    }
  })
}

window.addEventListener('load', () => {
  clearTags(document.body.children)
})

new MutationObserver(() => {
  clearTags(document.head.children)
}).observe(
  document.head,
  { subtree: true, characterData: true, childList: true },
)
