<template>
  <form-group
    :validation="validationDate"
    :hideErrorMessage="true"
    v-bind="{
      descriptionId,
      mode,
      name,
      testid,
      validationDate,
      tag,
    }"
    v-slot="{ errors }"
  >
    <div
      class="nhsuk-form-group nhsuk-u-margin-bottom-0"
      :class="{ 'nhsuk-form-group--error nhsuk-u-margin-bottom-2': !!errors.length }">
      <fieldset
        class="nhsuk-fieldset"
        aria-describedby="date-input-fieldset">
        <legend
        class="input-group__label">
          {{ i18n(label) }} {{ required ? i18n('(required)') : '' }}
        </legend>
        <div
          class="nhsuk-hint"
          data-testid="date-input-hint">
          {{ i18n('For example, 15 3 1984') }}
        </div>

        <span
            class="nhsuk-error-message"
            id="date-error"
            v-if="!!errors.length">
            <span class="nhsuk-u-visually-hidden">{{ i18n('(Error)') }}</span>
            {{ i18n(messageError()) }}
        </span>
        <div
          class="nhsuk-date-input"
          :class="{'input-group--has-error': errors.length}"
          data-testid="date-input">
          <div class="nhsuk-date-input__item">
            <div class="nhsuk-form-group">
              <label
                class="nhsuk-label nhsuk-date-input__label"
                for="date-input-day">
                    {{ i18n('Day') }}
              </label>
              <input
                id="date-input-day"
                class="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 input-group__input"
                data-testid="date-input-day"
                name="date-input-day"
                type="text"
                pattern="[0-9]*"
                maxlength="2"
                aria-describedby="date-error"
                :value="getModelValue('day')"
                @blur="dateChanged($event, 'day')"
                @keydown="keydownNumber($event)">
            </div>
          </div>
          <div class="nhsuk-date-input__item">
            <div class="nhsuk-form-group">
              <label
                class="nhsuk-label nhsuk-date-input__label"
                for="date-input-month">
                {{ i18n('Month') }}
              </label>
              <input
                id="date-input-month"
                class="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 input-group__input"
                data-testid="date-input-month"
                name="date-input-month"
                type="text"
                pattern="[0-9]*"
                maxlength="2"
                :value="getModelValue('month')"
                @blur="dateChanged($event, 'month')"
                @keydown="keydownNumber($event)">
            </div>
          </div>
          <div class="nhsuk-date-input__item">
            <div class="nhsuk-form-group">
              <label
                class="nhsuk-label nhsuk-date-input__label"
                for="date-input-year">
                {{ i18n('Year') }}
              </label>
              <input
                id="date-input-year"
                class="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4 input-group__input"
                data-testid="date-input-year"
                name="date-input-year"
                type="text"
                pattern="[0-9]*"
                minlength="4"
                maxlength="4"
                :value="getModelValue('year')"
                @blur="dateChanged($event, 'year')"
                @keydown="keydownNumber($event)">
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    </form-group>
</template>

<script>
  import moment from 'moment'

  import FormGroup from '@/components/forms/form-group/FormGroup'

  import i18n from '@/mixins/i18n'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      FormGroup,
    },

    mixins: [
      i18n,
      testid(),
    ],

    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: [String, Object],
        default: null,
      },
      format: {
        type: String,
        default: null,
      },
      mode: {
        type: [String, Function],
        default: 'aggressive',
      },
      name: {
        type: String,
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      validation: {
        type: Object,
        default: () => ({}),
      },
      tag: {
        type: String,
        required: false,
        default: 'div',
      },
    },

    mounted() {
      if (Object.keys(this.value).length) {
        const momentDate = moment(this.value)
        this.valueDate = {
          day: momentDate.date(),
          month: momentDate.month() + 1,
          year: momentDate.year(),
        }
        this.emitDate()
      }

      if (this.value && this.valueDate.day && this.valueDate.month && this.valueDate.year) {
        this.validationDate = {}
      } else {
        this.validationDate = this.validation
      }
    },

    data() {
      return {
        valueDate: { day: '', month: '', year: '' },
        validationDate: {},
      }
    },

    methods: {
      getModelValue(key) {
        return this.valueDate?.[key]
      },
      dateChanged(event, key) {
        this.valueDate[key] = parseInt(event.target.value, 10) || ''
        this.emitDate()
      },
      keydownNumber(evt) {
        const ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
          evt.stopPropagation()
          evt.preventDefault()
          return false
        }
        return true
      },
      emitDate() {
        const momentModel = moment()
            .date(this.valueDate.day).month(this.valueDate.month - 1).year(this.valueDate.year)
            .format(this.format)
        if (this.valueDate.day && this.valueDate.month && this.valueDate.year) {
          this.validationDate = {}
        } else {
          this.validationDate = this.validation
        }
        this.$emit('input', momentModel)
      },
      messageError() {
        if (!this.valueDate.day && !this.valueDate.month && !this.valueDate.year) {
          return 'This is a required field'
        }
        let message = ''
        if (!this.valueDate.day || this.valueDate.day > 31) {
          message += 'Day must be less than equal to 31'
        }
        if (!this.valueDate.month || this.valueDate.month > 12) {
          message += message === '' ? '' : ', '
          message += 'Month must be less than equal to 12'
        }
        if (!this.valueDate.year || this.valueDate.year < 1900) {
          message += message === '' ? '' : ', '
          message += 'Year must be greater than equal to 1900'
        }
        return message
      },
    },

    computed: {
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
    },
  }

</script>

<style lang="scss">
    .input-group--has-error {
        &.input-group__input {
            border-color: color(error);
        }
    }

    .input-group__input {
        border: 1px solid color(input-border);
        caret-color: color(input-border);
        font-size: 1.4rem;
    }
</style>
