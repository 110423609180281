import getters from './getters'
import mutations from './mutations'
import state from './state'

export default {
  getters,
  mutations,
  state,
  namespaced: true,
}
