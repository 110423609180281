<template>
  <router-link
    :to="link"
    class="next-article-tile"
    @keydown.shift.tab="$emit('shiftTab', $event)"
  >
   <modular-tile
      class="next-article-tile__card"
      :headerText="title"
      :image="imageUrl"
      :imagePosition="'side'"
    />
  </router-link>
</template>

<script>
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  export default {
    components: {
      ModularTile,
    },

    props: {
      imageUrl: {
        type: String,
        required: false,
        default: '',
      },
      link: {
        type: [Object, String],
        required: false,
        default: '',
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
    },
  }
</script>

<style lang="scss">
  .next-article-tile {
    display: block;
    border-radius: 1rem;
    &__card {
      margin: 1.4rem 0;
      .modular-tile {
        &__main {
          display: flex;
          justify-content: center;
        }
        &__header {
          font-size: 1.4rem;
          text-transform: none;
        }
        &__side-image-row {
          @include min-md {
            max-width: 12rem;
            max-height: 12rem;
          }
        }
        &__side-image {
          max-width: 10rem;
          overflow: hidden;
          height: 10rem;
          object-fit: cover;
          position: center;
          @include min-md {
            max-width: 12rem;
            height: 12rem;
          }
        }
      }
    }
    & .modular-tile {
      &__main {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
      &__header {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
      }
    }
  }
</style>
