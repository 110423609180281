import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

const module = 'optionalProfileData'

export default {
  fetchOptionalData({ commit }) {
    return new Promise((resolve, reject) => {
      const action = `${module}/optionalProfileData`
      loading(action, true)

      api.get('api/user/optional-data/all')
        .then(response => {
          commit('setOptionalData', response)
          resolve()
        })
        .catch(reject)
        .finally(() => loading(action, false))
    })
  },
  updateOptionalData({ commit }, form) {
    return new Promise((resolve, reject) => {
      const action = `${module}/updateOptionalData`
      loading(action, true)

      api.patch('api/user/optional-data', form)
        .then(response => {
          commit('updateOptionalData', response)
          resolve()
        })
        .catch(reject)
        .finally(() => loading(action, false))
    })
  },
}
