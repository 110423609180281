export default {
  items: [
    {
      id: 1,
      image: 'activity/tennis2.png',
      question: 'store.modules.activity-quiz.question-1',
      answers: [
        { correct: true, name: 'store.modules.activity-quiz.minutes', val: 150 },
        { correct: false, name: 'store.modules.activity-quiz.minutes', val: 75 },
        { correct: false, name: 'store.modules.activity-quiz.minutes', val: 170 },
      ],
      explanation: 'store.modules.activity-quiz.explanation-1',
    },
    {
      id: 2,
      image: 'activity/tennis.png',
      question: 'store.modules.activity-quiz.question-2',
      answers: [
        { correct: false, name: 'store.modules.activity-quiz.minutes', val: 30 },
        { correct: false, name: 'store.modules.activity-quiz.minutes', val: 50 },
        { correct: true, name: 'store.modules.activity-quiz.minutes', val: 75 },
      ],
      explanation: 'store.modules.activity-quiz.explanation-2',
    },
    {
      id: 3,
      image: 'activity/tennis1.png',
      question: 'store.modules.activity-quiz.question-3',
      answers: [
        { correct: false, name: '40-50%', val: 50 },
        { correct: true, name: '50-70%', val: 70 },
        { correct: false, name: '80-90%', val: 90 },
      ],
      explanation: 'store.modules.activity-quiz.explanation-3',
    },
    {
      id: 4,
      image: 'activity/tennis1.png',
      question: 'store.modules.activity-quiz.question-4',
      answers: [
        { correct: true, name: '70-85%', val: 85 },
        { correct: false, name: '60-80%', val: 80 },
        { correct: false, name: '95-100%', val: 100 },
      ],
      explanation: 'store.modules.activity-quiz.explanation-4',
    },
    {
      id: 5,
      image: 'activity/tennis2.png',
      question: 'store.modules.activity-quiz.question-5',
      answers: [
        { correct: false, name: 'store.modules.activity-quiz.hours', val: 1.5 },
        { correct: false, name: 'store.modules.activity-quiz.hours', val: 3 },
        { correct: true, name: 'store.modules.activity-quiz.hours', val: 5 },
      ],
      explanation: 'store.modules.activity-quiz.explanation-5',
    },
    {
      id: 6,
      image: 'activity/tennis3.png',
      question: 'store.modules.activity-quiz.question-6',
      answers: [
        { correct: false, name: 'store.modules.activity-quiz.q6-answer-1', val: 'Yes' },
        { correct: false, name: 'store.modules.activity-quiz.q6-answer-2', val: 'No' },
        { correct: true, name: 'store.modules.activity-quiz.q6-answer-3', val: 'Unsure' },
      ],
      explanation: 'store.modules.activity-quiz.explanation-6',
    },
    {
      id: 7,
      image: 'activity/weight.png',
      question: 'store.modules.activity-quiz.question-7',
      answers: [
        { correct: true, name: '1-3kg', val: '1-3kg' },
        { correct: false, name: '5kg', val: '5kg' },
        { correct: false, name: '1.5kg', val: '1.5kg' },
      ],
      explanation: 'store.modules.activity-quiz.explanation-7',
    },
    {
      id: 8,
      image: 'activity/weight.png',
      question: 'store.modules.activity-quiz.question-8',
      answers: [
        { correct: false, name: 'store.modules.activity-quiz.q8-answer-1', val: 1 },
        { correct: false, name: 'store.modules.activity-quiz.q8-answer-2', val: 2 },
        { correct: true, name: 'store.modules.activity-quiz.q8-answer-3', val: 3 },
      ],
      explanation: 'store.modules.activity-quiz.explanation-8',
    },
    {
      id: 9,
      image: 'activity/weight1.png',
      question: 'store.modules.activity-quiz.question-9',
      answers: [
        { correct: true, name: 'store.modules.activity-quiz.q9-answer-1', val: 'True' },
        { correct: false, name: 'store.modules.activity-quiz.q9-answer-2', val: 'False' },
        { correct: false, name: 'store.modules.activity-quiz.q9-answer-3', val: 'Unsure' },
      ],
      explanation: 'store.modules.activity-quiz.explanation-9',
    },
    {
      id: 10,
      image: 'activity/tennis3.png',
      question: 'store.modules.activity-quiz.question-10',
      answers: [
        { correct: false, name: 'store.modules.activity-quiz.q10-answer-1', val: 1 },
        { correct: false, name: 'store.modules.activity-quiz.q10-answer-2', val: 2 },
        { correct: true, name: 'store.modules.activity-quiz.q10-answer-3', val: 3 },
      ],
      explanation: 'store.modules.activity-quiz.explanation-10',
    },
  ],
}
