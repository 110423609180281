import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/glucose-tracking/',
    sharedMeta: {
      fetchProgram: false,
      layout: 'home',
      toParent: true,
      trigger: 'blood-glucose-tracking',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'GlucoseTracking',
    },
  },
  routes: [
    {
      path: '/blood-glucose-tracking',
      name: 'GlucoseTracking',
      meta: {
        parent: 'Tools',
        header: 'Blood glucose tracker',
        pluginHome: true,
      },
    },
    {
      path: '/blood-glucose-tracking/log',
      name: 'GlucoseTrackingLog',
      props: true,
      meta: {
        parent: 'GlucoseTracking',
        pluginHome: true,
        header: 'Blood glucose tracker Log',
      },
    },
    {
      path: '/blood-glucose-tracking/settings',
      name: 'GlucoseTrackingSettings',
      meta: {
        parent: 'GlucoseTracking',
        header: 'Blood glucose tracker Settings',
      },
    },
    {
      path: '/blood-glucose-tracking/delete/:id?',
      name: 'GlucoseTrackingDelete',
      props: true,
      meta: {
        parent: 'GlucoseTracking',
        header: 'Blood Glucose tracking Delete',
      },
    },
  ],
}
