import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/getting-support-quiz/',
    sharedMeta: {
      fetchProgram: false,
      header: 'The family support quiz',
      layout: 'home',
      toParent: true,
      trigger: 'getting-support-quiz',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'GettingSupportResults',
    },
  },
  routes: [
    {
      path: '/getting-support-quiz',
      name: 'GettingSupport',
      meta: {
        parent: 'Tools',
        toParent: true,
        trigger: 'getting-support-quiz',
      },
    },
    {
      path: '/getting-support-quiz/quiz',
      name: 'GettingSupportQuiz',
      props: true,
      meta: {
        parent: 'GettingSupport',
      },
    },
    {
      path: '/getting-support-quiz/results',
      name: 'GettingSupportResults',
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
