<template>
  <component
    :is="containerComponent"
    :class="{
      [`${block}`]: true,
      [`${block}--is-open`]: isDialogOpen,
    }"
  >
    <transition-scale>
      <focus-lock
        v-if="isDialogOpen"
        :key="isDialogOpen"
        :class="`${block}__focus-lock`"
      >
        <component
          :is="`Dialog${dialog.component}`"
          v-bind="dialog.props"
        />
      </focus-lock>
    </transition-scale>
  </component>
</template>

<script>
  import FocusLock from 'vue-focus-lock'
  import { mapState } from 'vuex'

  import TransitionScale from '@/transitions/transition-scale/TransitionScale'

  const importDialog = file => () => import(`@/components/dialogs/${file}`)
  const dialogsPaths = require.context('@/components/dialogs/', true, /\.vue$/).keys()
  const dialogs = dialogsPaths.reduce((acc, curr) => {
    const path = curr.replace('./', '')
    const name = path.match('Dialog[^>]+.vue')[0].replace('.vue', '')

    acc[name] = importDialog(path)

    return acc
  }, {})

  export default {
    components: {
      ...dialogs,
      FocusLock,
      TransitionScale,
    },

    data() {
      return {
        block: 'dialogs',
      }
    },

    computed: {
      ...mapState('dialog', [
        'dialog',
        'isDialogOpen',
      ]),
      containerComponent() {
        return !(document.body.querySelector(`main:not(.${this.block})`)) && this.isDialogOpen
          ? 'main'
          : 'div'
      },
    },
  }
</script>

<style lang="scss">
  .dialogs {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: z-index(dialog);
    visibility: hidden;
    opacity: 0;
    &--is-open {
      visibility: visible;
      opacity: 1;
    }
    &__focus-lock {
      width: 100%;
      height: 100%;
      & > div[data-lock] {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }
    }
    .modular-tile {
      margin: 2.2rem 0;
      &.modular-tile--icon {
        margin-top: 4.4rem;
        @include min-sm {
          margin-top: 5.8rem;
        }
      }
    }
  }
</style>
