export default {
  methods: {
    guard(route) {
      const pluginDir = 'plugins/'
      const { meta: { trigger, unblockPluginAt, dir }, name } = route

      if (!dir || !dir.includes(pluginDir)) return

      if (unblockPluginAt === undefined) {
        this.throwErrorAbout(`'unblockPluginAt' property at ${trigger} route meta`)
      } else if (unblockPluginAt === name && !this.isMixinImplemented(unblockPluginAt)) {
        this.throwErrorAbout(`'unblockPlugin' mixin at ${trigger}`)
      }
    },
    isMixinImplemented(component) {
      const pluginRoute = this.$router.history.current.matched.find(el => el.name == component)

      return pluginRoute.components.default?.mixins
              .reduce((acc, { methods }) => [Object.keys(methods), ...acc], [])
              .flat()
              .includes('unblockPlugin')
    },
    throwErrorAbout(about) {
      throw new Error(`You have to implement ${about}. It's related with blocker feature.`)
    },
  },
}
