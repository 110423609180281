import Vue from 'vue'
import Router from 'vue-router'

import { arrayize } from '@/helpers'

import store from '@/store'

import { handleTrigger } from './helpers'
import routes from './routes'
import scrollBehavior from './scrollBehavior'

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router)
}

const router = new Router({
  routes,
  scrollBehavior,
  mode: 'history',
})

router.beforeEach(({ meta, name, path }, from, next) => {
  const is = routeName => name == routeName

  const { getters, state, commit } = store
  const { isCurrentUserAuthorized, token, policiesToAccept, is2fa } = state.auth
  const { isAppKioskMode } = state.kioskMode

  const getTriggerRouteBySlug = getters['triggers/getTriggerRouteBySlug']
  const hasToCompleteProfile = getters['user/hasCurrentUserUncompletedProfile']
  const isAuth2fa = is('Auth2FA')
  const isLogin = is('AuthLogin')
  const isSignUp = is('AuthSignUp')
  const isAuthUpdatePolicies = is('AuthUpdatePolicies')
  const isPluginKioskMode = getters['kioskMode/isPluginKioskMode']
  const isUnavailableInAppKioskMode = meta.appKioskMode === false && isAppKioskMode
  const replace = true
  const requiresAuthorization = meta.authorization !== false
  const letAuthorizedUsersIn = meta.letAuthorizedUsersIn === true
  const shouldPreventUserFromAccessingUnauthorizedRoute = !requiresAuthorization
    && isCurrentUserAuthorized
    && !hasToCompleteProfile
    && !letAuthorizedUsersIn
    && policiesToAccept?.isAccepted

  if (hasToCompleteProfile && !isSignUp) {
    next({ name: 'AuthSignUp', params: { isUserFetched: true } })
  } else if (isAuth2fa && is2fa) {
    next()
  } else if ((isLogin && isCurrentUserAuthorized)
    || isUnavailableInAppKioskMode
    || shouldPreventUserFromAccessingUnauthorizedRoute) {
    const unauthorizedPath = store?.state?.history?.unauthorizedlyAccessedRoute
    const redirectTo = unauthorizedPath
        ? { path: unauthorizedPath }
        : { name: 'Home' }

    next({ ...redirectTo, replace: true })
    // Sentry errors
    // next()
  } else if (requiresAuthorization && !isCurrentUserAuthorized && !token) {
    commit('history/setUnauthorizedlyAccessedRoute', path)
    next({ name: 'AuthLogin', replace })
  } else if (isPluginKioskMode) {
    const metaTriggers = arrayize(meta.trigger)
    const currentPlugin = getters['kioskMode/getCurrentPluginName']

    metaTriggers.includes(currentPlugin)
      ? next()
      : next({ name: getTriggerRouteBySlug(currentPlugin), replace })
  } else if (meta.trigger && name !== from.name) {
    handleTrigger(from, meta.trigger, next, store)
  } else if (
    !hasToCompleteProfile
    && policiesToAccept && !policiesToAccept.isAccepted
    && !isAuthUpdatePolicies) {
    next({ name: 'AuthUpdatePolicies' })
  } else {
    next()
  }
})

export default router
