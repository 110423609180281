<script>
  export default {
    functional: true,

    props: {
      tag: {
        type: String,
        required: true,
      },
    },

    render(h, { props, data, children }) {
      // fix for using the component in another functional component and passing props
      // for instance: when tag prop is 'router-link', tag prop is passed to data and
      // becomes router-link's prop too
      const { props: dataProps, ...newData } = data

      return h(props.tag, newData, children)
    },
  }
</script>
