<template functional>
  <div
    class="journey-section-item"
    :class="[
      { 'journey-section-item--is-active': props.isActive },
      { 'journey-section-item--is-completed': props.isCompleted },
    ]"
    v-on="listeners"
  >
    <div class="journey-section-item__content">
      <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isCompleted: {
        type: Boolean,
        required: false,
        default: false,
      },
      isActive: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  }
</script>

<style lang="scss">
  $sectionItemBlock: 'journey-section-item';

  .#{$sectionItemBlock} {
    display: block;
    padding-top: 8.6rem;
    position: relative;
    @include min-lg {
      float: left;
      width: 50%;
      padding-top: 11.5rem;
    }
    &:not(:last-of-type) {
      margin-bottom: 2.4rem;
      @include min-lg {
        margin-bottom: 2.5rem;
      }
    }
    &:nth-of-type(even) {
      @include min-lg {
        float: right;
        &::after {
          right: auto;
          left: -0.8rem;
        }
        .#{$sectionItemBlock}__content {
          left: auto;
        }
      }
    }
    &__content {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 1rem;
      box-shadow: box-shadow(default);
      @include min-lg {
        width: 95%;
      }
    }
    &--is-completed {
      .#{$sectionItemBlock}__content,
      .journey-article__info {
        background-color: color(complete-learn-card-background);
      }
    }
    &--is-active {
      .#{$sectionItemBlock}__content,
      .journey-article__info {
        background-color: color(active-learn-card-background);
      }
    }
  }
</style>
