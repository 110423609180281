import VueMultianalytics from 'vue-multianalytics'

import multiAnalyticsMixin from '@/mixins/multiAnalytics'

// eslint-disable-next-line
const mixpanelConfig = {
  token: process.env.VUE_APP_MIXPANEL_TOKEN,
  debug: true,
  config: {
    api_host: process.env.VUE_APP_MIXPANEL_ENDPOINT,
  },
}

export default {
  install(Vue) {
    Vue.use(VueMultianalytics, {
      modules: {
        // mixpanel: mixpanelConfig,
      },
    }, multiAnalyticsMixin)
  },
}
