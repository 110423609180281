import { PAGE_TYPES } from '@/constants'

import AccountAlreadyExistsView from '@/views/AccountAlreadyExistsView'
import ArticleView from '@/views/ArticleView'
import ChangePasswordView from '@/views/ChangePasswordView'
import CompleteProfile from '@/views/CompleteProfile'
import CompleteProfileSuccess from '@/views/CompleteProfileSuccess'
import DeleteAccountView from '@/views/DeleteAccountView'
import Home from '@/views/Home'
import Learn from '@/views/Learn'
import SearchResults from '@/views/SearchResults'
import SwitchProgramView from '@/views/SwitchProgramView'
import Tools from '@/views/Tools'
import Unsubscribe from '@/views/Unsubscribe'
import AuthLogin from '@/views/auth/AuthLogin'
import FeedbackAuthView from '@/views/feedback/FeedbackAuthView'
import FeedbackView from '@/views/feedback/FeedbackView'
import Goals from '@/views/goals/GoalsView'

export default {
  properties: {
    isLazy: false,
  },
  routes: [
    {
      path: '/auth',
      name: 'AuthLogin',
      component: AuthLogin,
      meta: {
        authorization: false,
        layout: 'plain',
        type: PAGE_TYPES.auth,
      },
    },
    {
      path: '/auth-feedback',
      name: 'FeedbackAuthView',
      props: true,
      component: FeedbackAuthView,
      meta: {
        fetchProgram: false,
        authorization: false,
        header: 'Feedback',
        type: PAGE_TYPES.auth,
      },
    },
    {
      path: '/v2/referral/:referral',
      name: 'CompleteProfile',
      component: CompleteProfile,
      props: true,
      meta: {
        fetchProgram: false,
        authorization: false,
        header: 'Complete Profile',
        type: PAGE_TYPES.auth,
      },
    },
    {
      path: '/referral/:referral',
      name: 'CompleteProfile',
      component: CompleteProfile,
      props: true,
      meta: {
        fetchProgram: false,
        authorization: false,
        header: 'Complete Profile',
        type: PAGE_TYPES.auth,
      },
    },
    {
      path: '/switch-program',
      name: 'SwitchProgramView',
      component: SwitchProgramView,
      props: true,
      meta: {
        fetchProgram: false,
        authorization: false,
        header: 'Switch Program',
        type: PAGE_TYPES.auth,
      },
    },
    {
      path: '/account-already-exists',
      name: 'AccountAlreadyExistsView',
      component: AccountAlreadyExistsView,
      meta: {
        fetchProgram: false,
        authorization: false,
        header: 'Account Already Exists',
        type: PAGE_TYPES.auth,
      },
    },
    {
      path: '/referral-success',
      name: 'CompleteProfileSuccess',
      component: CompleteProfileSuccess,
      meta: {
        fetchProgram: false,
        authorization: false,
        header: 'Complete Profile Success',
        type: PAGE_TYPES.auth,
      },
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        fetchProgram: false,
        hasHeaderArrow: false,
        header: 'Dashboard',
        layout: 'home',
        type: PAGE_TYPES.tab,
      },
    },
    // send Support message to Auth page isn't possible
    // {
    //   path: '/auth-support',
    //   name: 'SupportAuth',
    //   component: SupportAuth,
    //   meta: {
    //     fetchProgram: false,
    //     authorization: false,
    //     header: 'Support',
    //     parent: 'Login',
    //     type: PAGE_TYPES.auth,
    //   },
    // },
    {
      path: '/learn',
      name: 'Learn',
      component: Learn,
      meta: {
        fetchProgram: true,
        hasHeaderArrow: false,
        layout: 'home',
        type: PAGE_TYPES.tab,
      },
    },
    {
      path: '/tools',
      name: 'Tools',
      component: Tools,
      meta: {
        fetchProgram: true,
        hasHeaderArrow: false,
        layout: 'home',
        type: PAGE_TYPES.tab,
      },
    },
    {
      path: '/goals',
      name: 'GoalsView',
      component: Goals,
      props: true,
      meta: {
        trigger: 'goal',
        fetchProgram: true,
        hasHeaderArrow: false,
        header: 'Your goals',
        layout: 'home',
        parent: 'Tools',
        type: PAGE_TYPES.tab,
      },
    },
    {
      path: '/search/:query/page/:page',
      props: true,
      name: 'SearchResults',
      component: SearchResults,
      meta: {
        fetchProgram: true,
        hasHeaderArrow: true,
        header: 'Search',
        layout: 'home',
        type: PAGE_TYPES.other,
      },
    },
    {
      path: '/search-articles/:id',
      name: 'SearchArticleView',
      props: true,
      component: ArticleView,
      meta: {
        fetchProgram: false,
        header: 'Welcome to Changing Health',
        layout: 'home',
      },
    },
    {
      path: '/feedback',
      name: 'FeedbackView',
      props: true,
      component: FeedbackView,
      meta: {
        fetchProgram: false,
        header: 'Feedback',
        layout: 'home',
        type: PAGE_TYPES.tab,
      },
    },
    {
      path: '/unsubscribe',
      name: 'Unsubscribe',
      component: Unsubscribe,
      meta: {
        fetchProgram: false,
        authorization: false,
        header: 'Unsubscribe',
        type: PAGE_TYPES.auth,
      },
    },
    {
      path: '/delete-account',
      name: 'DeleteAccountView',
      component: DeleteAccountView,
      meta: {
        fetchProgram: false,
        header: 'Delete Account',
        layout: 'home',
        type: PAGE_TYPES.tab,
      },
    },
    {
      path: '/change-password',
      name: 'ChangePasswordView',
      component: ChangePasswordView,
      meta: {
        fetchProgram: false,
        header: 'Change Password',
        layout: 'home',
        type: PAGE_TYPES.tab,
      },
    },
  ],
}
