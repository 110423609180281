export default {
  currentProgramId: state => state?.program?.id,
  currentProgramIdForBlocker: state => state.program?.id || state.initialCurrentProgramId,
  /* eslint-disable-next-line camelcase */
  currentProgramSecondaryLogo: state => state.program?.secondary_logo?.url,
  getArticle: (state, getters) => id => getters
    .getArticles?.find(item => item.id == id) || {},
  getArticleOffset: (state, getters) => (articleId, offset = 0) => {
    const section = getters.getSectionOfArticle(articleId)
    const articleSectionIndex = getters.getArticleIndexInSection(articleId)

    return section.items[articleSectionIndex + offset]
  },
  getArticleIndexInSection: (state, getters) => articleId => getters
    .getSectionOfArticle(articleId)
    .items.map(item => String(item.id))
    .indexOf(String(articleId)),
  getCurrentArticleId: (state, getters) => {
    const item = getters.getCurrentSectionItem
    const types = ['article', 'video', 'survey']

    return item && types.includes(item.type)
      ? item.id
      : null
  },
  getCurrentProgramIdForBlockerOrProgress: state => state.program?.id
  || state.initialCurrentProgramId,
  getCurrentProgramLogo: state => state.program?.logo?.url,
  getCurrentProgramName: state => state.program?.name,
  /* eslint-disable-next-line camelcase */
  getNavExtraItems: state => state.program?.extra_nav_items || [],
  /* eslint-disable-next-line camelcase */
  getCurrentProgramOptionalData: state => state.program?.participant_profile_data,
  getCurrentSection: state => state.program?.journey?.sections
    ?.find(el => !el.completed)
  || state.program?.journey?.sections?.slice(-1)[0],
  getCurrentSectionItem: (state, getters) => {
    const { items } = getters.getCurrentSection || {}
    const firstUnopenedItem = items?.find(item => !item.opened)

    return firstUnopenedItem || items?.[items?.length - 1]
  },
  getCurrentSectionItemId: (state, getters) => getters.getCurrentSectionItem.id,
  getArticles: state => state.program?.journey?.sections
    ?.reduce((acc, curr) => acc.concat(curr.items), []),
  getSectionNameOfArticle: (state, getters) => articleId => getters
    /* eslint-disable-next-line camelcase */
    .getSectionOfArticle(articleId)?.display_name,
  getSectionOfArticle: state => articleId => state.program?.journey?.sections?.find(section => (
    section.items?.find(item => item.id == articleId)
  )),
  getSectionOffset: state => (sectionId, offset) => {
    const { sections } = state.program?.journey

    return sections?.[sections.map(section => section.id).indexOf(sectionId) + offset]
  },
  /* eslint-disable-next-line camelcase */
  hasKioskMode: state => !!state.program?.has_kiosk_mode,
  isArticleCompleted: (state, getters) => id => getters.getArticle(id).opened,
  isAtSectionEnd: (state, getters) => {
    const items = getters.getCurrentSection?.items

    return items?.[items.length - 1].id === getters.getCurrentSectionItemId
  },
  isArticleCurrent: (state, getters) => id => getters.getCurrentArticleId == id,
  isJourneyUpToDate: state => state.isJourneyUpToDate,
  isSectionItemCurrent: (state, getters) => id => getters.getCurrentSectionItemId == id,
  isFirstArticleInSection: (state, getters) => articleId => getters
    .getArticleIndexInSection(articleId) === 0,
  isLastArticleInSection: (state, getters) => articleId => getters
    .getArticleIndexInSection(articleId)
      === getters.getSectionOfArticle(articleId).items.length - 1,
  isOpd: (state, getters) => articleId => getters.getArticle(articleId).type === 'profile-data',
  isTrailingOpd: (state, getters) => articleId => getters
    .isLastArticleInSection(articleId) && getters.isOpd(articleId),
  isProgramFetched: state => state.isProgramFetched,
}
