import actions from './actions'

const state = ({
  hasAnalyticsInitialized: false,
})

const mutations = ({
  setHasInitialized(s, value) {
    s.hasAnalyticsInitialized = value
  },
})

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
