<template>
  <base-dialog
    class="reset-password-success-dialog"
    data-testid="dialog-auth-reset-password-success"
    :headerLevel="1"
    :headerText="$t(`${$options.slug}.title`)"
  >
    <p
      data-testid="dialog-auth-reset-password-success-content"
    >
      {{ $t(`${$options.slug}.content`) }}
    </p>

    <template #action>
      <base-button
        class="reset-password-success-dialog__close"
        data-testid="dialog-auth-reset-password-success-close"
        @click="closeDialog"
      >
        {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },

    slug: 'component.dialog.auth.reset-password-success-dialog',
  }
</script>

<style lang="scss">
  .dialog.reset-password-success-dialog {
    width: 90%;
    max-width: none;
    @include min-sm {
      max-width: 40rem;
    }
  }
  .reset-password-success-dialog {
    &__close {
      margin: 0 2.4rem 1.4rem;
    }
  }
</style>
