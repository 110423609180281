<template>
  <base-dialog
    class="coach-dialog"
    data-testid="dialog-coaching-coach"
    :headerText="'Meet your coach'"
  >
    <div>
      <speech-bubble
        data-testid="dialog-coaching-coach-speech-bubble"
        :imageLink="coach.icon ? coach.icon.url : null"
      >
        Hello {{ getCurrentUserFirstName }}!<br>
        You can book your first appointment with me
        <router-link
          data-testid="dialog-coaching-coach-new-appointment"
          :to="{ name: 'CoachingPluginNewAppointment' }"
          @click.native="closeDialog"
        >
          here
        </router-link>
      </speech-bubble>
      <p
        data-testid="dialog-coaching-coach-name"
      >
        <b>Your Lifestyle Coach is {{ coach.name }}.</b>
      </p>
      <p
        data-testid="dialog-coaching-coach-description"
        v-html="coach.description"
      />
    </div>

    <template #action>
      <base-button
        data-testid="dialog-coaching-coach-close"
        @click="closeDialog"
      >
        Close
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'
  import SpeechBubble from '@/components/speech-bubble/SpeechBubble'

  export default {
    components: {
      BaseButton,
      BaseDialog,
      SpeechBubble,
    },

    props: {
      coach: {
        type: Object,
        required: true,
      },
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },

    computed: {
      ...mapGetters('user', [
        'getCurrentUserFirstName',
      ]),
    },
  }
</script>

<style lang="scss">
  .coach-dialog.dialog {
    max-width: 40rem;
  }
  .coach-dialog {
    a {
      color: color(dark-primary);
      text-decoration: underline;
    }
  }
</style>
