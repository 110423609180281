export default {
  getLoadingStateForAction: state => action => !!state.loadings[action],
  getLoadingStatesForActions: state => actions => (
    !!actions
      .map(action => !!state.loadings[action])
      .filter(item => item)
      .length
  ),
  isPackageOrProgramLoading: (state, getters) => (
    getters.getLoadingStatesForActions([
      'program/fetchProgram',
    ])
  ),
  isPackageOrProgramOrOptionalDataLoading: (state, getters) => (
    getters.getLoadingStatesForActions([
      'optionalProfileData/fetchOptionalData',
      'program/fetchProgram',
    ])
  ),
  isPackageOrProgramOrUserLoading: (state, getters) => (
    getters.getLoadingStatesForActions([
      'auth/fetchCurrentUser',
      'program/fetchProgram',
    ])
  ),
  isPackageOrPreferencesOrProgramOrUserLoading: (state, getters) => (
    getters.getLoadingStatesForActions([
      'auth/fetchCurrentUser',
      'coaching/fetchPreferences',
      'program/fetchProgram',
    ])
  ),
}
