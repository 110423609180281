<template>
  <component
    :is="componentProps.is"
    class="optional-profile-data-field"
    data-testid="optional-profile-data-field"
    :legend="description"
    :name="item.key"
    :required="isRequired"
    :validation="validation"
    v-bind="{ ...componentProps, label }"
    v-model="model"
    v-merge-attr:data-testid
  />
</template>

<script>
  import CheckboxGroup from '@/components/forms/checkbox-group/CheckboxGroup'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import RadioMultiGroup from '@/components/forms/radio/RadioMultiGroup'
  import SelectGroup from '@/components/forms/select-group/SelectGroup'

  import mergeAttr from '@/directives/mergeAttr'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      /* eslint-disable vue/no-unused-components */
      CheckboxGroup,
      InputGroup,
      SelectGroup,
      RadioMultiGroup,
      /* eslint-enable */
    },

    directives: {
      mergeAttr,
    },

    mixins: [
      testid(),
    ],

    props: {
      hasLabel: {
        type: Boolean,
        required: false,
        default: true,
      },
      hasLegend: {
        type: Boolean,
        default: false,
      },
      isJourney: {
        type: Boolean,
        default: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: true,
      },
      item: {
        type: Object,
        required: true,
      },
      value: {
        type: [String, Boolean, Number, Array, Object],
        required: false,
        default: '',
      },
    },

    methods: {
      getStep(number) {
        return number.step ? parseFloat(number.step, 10) : 0.1
      },
    },

    computed: {
      componentProps() {
        const { meta, type } = this.item.scheme

        const types = {
          checkbox: () => ({
            is: 'checkbox-group',
          }),
          select: m => ({
            is: 'radio-multi-group',
            options: Object.entries(m)
                      // eslint-disable-next-line no-unused-vars
                      .map(([value, label]) => label),
            optionsObjects: Object.entries(m).map(([value, label]) => ({ value, label })),
            itemChecked:
              Object.entries(m).map(([value, label]) => ({ value, label }))
              .find(item => item.value == this.value)?.label,
            hasHiddenAsterisk: true,
          }),
          text: () => ({
            is: 'input-group',
            type: 'text',
            hasHiddenAsterisk: true,
          }),
          number: m => ({
            is: 'input-group',
            type: 'number',
            min: parseFloat(m.min, 10),
            max: parseFloat(m.max, 10),
            step: this.getStep(m),
            hasHiddenAsterisk: true,
          }),
        }

        return types[type](meta)
      },
      description() {
        return this.hasLegend ? this.item.scheme.display_description : ''
      },
      model: {
        get() {
          if (this.isCheckbox) {
            return this.value || false
          } else if (this.isSelect) {
            return this.componentProps.itemChecked
          }

          return this.value
        },
        set(setValue) {
          const newValue = this.isSelect
            ? this.componentProps.optionsObjects.find(item => item.label == setValue).value
            : setValue

          this.$emit('input', newValue)
        },
      },
      validation() {
        switch (this.item.scheme.type) {
          case 'checkbox':
            return {
              required: false,
            }
          case 'number':
            return {
              required: this.isRequired,
              min_value: this.item.scheme.meta.min,
              max_value: this.item.scheme.meta.max,
            }
          case 'text':
            return {
              required: this.isRequired,
              min: this.item.scheme.meta.minlength,
              max: this.item.scheme.meta.maxlength,
            }
          default:
            return {
              required: this.isRequired && !this.isCheckbox,
            }
        }
      },
      label() {
        return this.hasLabel || this.isCheckbox
                ? this.item.scheme[this.isJourney ? 'display_description' : 'display_name']
                : ''
      },
      isCheckbox() {
        return this.item.scheme.type === 'checkbox'
      },
      isSelect() {
        return this.item.scheme.type === 'select'
      },
    },
  }
</script>

<style lang="scss">
  .optional-profile-data-field {
    .select-group {
      &__legend {
        font-weight: 400;
        color: inherit;
      }
    }
  }
</style>
