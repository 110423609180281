import activityQuiz from './activity-quiz'
import auth from './auth'
import base from './base'
import capturingBmi from './capturing-bmi'
import carbCalculator from './carb-calculator'
import coaching from './coaching'
import dukRiskCalculator from './duk-risk-calculator'
import eq5d from './eq-5d'
import faceAgeTest from './face-age-test'
import foodTracking from './food-tracking'
import gettingConfidentQuiz from './getting-confident-quiz'
import gettingSupportQuiz from './getting-support-quiz'
import glucoseTracking from './glucose-tracking'
import goals from './goals'
import habitsQuiz from './habits-quiz'
import initialAssessment from './initial-assessment'
import maintenancePlan from './maintenance-plan'
import other from './other'
import readingRoom from './reading-room'
import reasonsToLoseWeight from './reasons-to-lose-weight'
import reflectingQuiz from './reflecting-quiz'
import riskCalculator from './risk-calculator'
import settingTheStrongestPlan from './setting-the-strongest-plan'
import shoppingListSwitcher from './shopping-list-switcher'
import stepTracking from './step-tracking'
import trickySituations from './tricky-situations'
import weeklyReview from './weekly-review'
import weightTracking from './weight-tracking'
import winningAgainstTemptations from './winning-against-temptations'

export default [
  activityQuiz,
  auth,
  base,
  capturingBmi,
  carbCalculator,
  coaching,
  eq5d,
  dukRiskCalculator,
  faceAgeTest,
  foodTracking,
  gettingConfidentQuiz,
  gettingSupportQuiz,
  glucoseTracking,
  goals,
  habitsQuiz,
  initialAssessment,
  maintenancePlan,
  other,
  readingRoom,
  reasonsToLoseWeight,
  reflectingQuiz,
  riskCalculator,
  settingTheStrongestPlan,
  shoppingListSwitcher,
  stepTracking,
  trickySituations,
  weeklyReview,
  weightTracking,
  winningAgainstTemptations,
]
