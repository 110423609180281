import { Validator } from 'vee-validate'

const requiredArray = {
  getMessage: () => 'This is a required field',
  validate(value) {
    return new Promise(resolve => {
      resolve({ valid: Boolean(value.length) })
    })
  },
}

Validator.extend('required_array', requiredArray)
