 <template>
  <main
    id="main"
    :class="partitionedAttrs.classes"
    v-bind="partitionedAttrs.newAttrs"
  >

    <loading-indicator
      v-if="isFirstLoading || isReloading"
      inline
    />

    <slot
      v-else
    />
  </main>
</template>

<script>
  import { mapGetters } from 'vuex'

  import LoadingIndicator from '@/components/loading-indicator'

  import { BASE_MAIN_MODIFIERS } from '@/constants'

  import { partitionAttrs } from '@/helpers'

  export default {
    inheritAttrs: false,

    components: {
      LoadingIndicator,
    },

    props: {
      loading: {
        type: Array,
        required: false,
        default: () => [],
      },
      reloading: {
        type: Array,
        required: false,
        default: () => [],
      },
    },

    watch: {
      isLoading: {
        handler(newValue, oldValue) {
          if (newValue === false && oldValue === true) {
            this.hasLoadingFinished = true
          } else if (oldValue === undefined) {
            this.hasLoadingFinished = false
          }
        },
        immediate: true,
      },
    },

    data() {
      return {
        hasLoadingFinished: true,
      }
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isFirstLoading() {
        return this.isLoading && !this.hasLoadingFinished
      },
      isLoading() {
        return this.getLoadingStatesForActions([
          ...this.$options.defaultLoadings,
          ...this.loading,
        ])
      },
      isReloading() {
        return this.getLoadingStatesForActions([
          ...this.$options.defaultLoadings,
          ...this.reloading,
        ])
      },
      partitionedAttrs() {
        return partitionAttrs({
          attrs: this.$attrs,
          baseClass: 'main',
          modifiers: BASE_MAIN_MODIFIERS,
        })
      },
    },

    defaultLoadings: [
      'blockedPlugin/completePlugin',
      'languages/fetchLanguagesCoveredByTranslations',
      'program/fetchProgram',
      'router/pluginsGuard',
      'triggers/fetchTriggers',
    ],
  }
</script>

<style lang="scss">
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 2rem ($footer_height + 2rem) 2rem;
    background-color: color(off-white);
    -webkit-overflow-scrolling: touch;
    @include min-lg {
      position: absolute;
      top: $header_height--lg;
      right: 0;
      bottom: 0;
      left: 0;
      height: auto;
      padding-bottom: $footer_height--lg + 2rem;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .ios-webview & {
      @supports (padding-bottom: constant(safe-area-inset-bottom)) { /* iOS 11.0 */
        padding-bottom: constant(safe-area-inset-bottom);
      }
      @supports (padding-bottom: env(safe-area-inset-bottom)) { /* iOS 11.2 */
        padding-bottom: env(safe-area-inset-bottom);
      }
    }
    &--no-top {
      top: 0;
    }
    &--white {
      background-color: color(_white);
    }
    &--background {
      background-color: color(off-white);
    }
    &--appbar {
      top: $header_height;
      @include min-lg {
        top: $header_height--lg;
      }
    }
    &--flexible-space {
      padding-top: 40vh;
      background-color: transparent;
    }
    &--is-open-fab {
      overflow-y: hidden;
    }
  }
  .firefox {
    .main {
      @include min-lg {
        padding-bottom: 0;
      }
      & > * {
        @include min-lg {
          padding-bottom: $footer_height--lg + 3rem;
        }
      }
    }
  }
</style>
