var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({staticClass:"select-group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.legend)?_c('legend',{staticClass:"select-group__legend",class:{
      'select-group__legend--is-visually-hidden': _vm.isLegendHidden,
    },domProps:{"innerHTML":_vm._s(_vm.legend)}}):_vm._e(),_c('base-select',_vm._b({attrs:{"label":_vm.labelText,"showError":!!errors.length || _vm.showError},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"singleLabel",fn:function(option){return [_vm._t("singleLabel",null,null,option)]}},{key:"option",fn:function(option){return [_vm._t("option",null,null,option)]}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'base-select',Object.assign({}, _vm.$attrs,
      {capitalize: _vm.capitalize,
      config: _vm.config,
      descriptionId: _vm.descriptionId,
      disabled: _vm.disabled,
      isLabelHidden: _vm.isLabelHidden,
      isRequired: _vm.isRequired,
      name: _vm.name,
      options: _vm.options,
      placeholder: _vm.placeholder,
      showLabel: _vm.showLabel,
      testid: _vm.testid}),false))]}}])},'form-group',{
    descriptionId: _vm.descriptionId,
    hideErrorMessage: _vm.hideErrorMessage,
    label: _vm.label,
    name: _vm.name,
    testid: _vm.testid,
    validation: _vm.validation,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }