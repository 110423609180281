import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'auth/',
    sharedMeta: {
      authorization: false,
      layout: 'plain',
      type: PAGE_TYPES.auth,
    },
  },
  routes: [
    {
      path: '/auth/two-factor',
      name: 'Auth2FA',
      component: 'Auth2FA',
      props: true,
    },
    {
      path: '/auth/confirm/:token/:emailHash',
      name: 'AuthConfirm',
      props: true,
    },
    {
      path: '/auth/:invitationToken',
      name: 'AuthInvitation',
      props: true,
    },
    {
      path: '/sign-up',
      name: 'AuthSignUp',
      props: true,
      meta: {
        authorization: false,
      },
    },
    {
      path: '/update-policies',
      name: 'AuthUpdatePolicies',
      props: false,
      meta: {
        authorization: false,
      },
    },
    {
      path: '/reset-password',
      name: 'AuthResetPassword',
      component: 'AuthResetPassword',
      props: true,
    },
    {
      path: '/reset-password/:token',
      name: 'AuthResetPasswordConfirm',
      component: 'AuthResetPasswordConfirm',
      props: true,
    },
    {
      path: '/logged-out',
      name: 'AuthLoggedOut',
      props: false,
    },
    {
      path: '/change-email/:token',
      name: 'AuthChangeEmail',
      props: true,
      meta: {
        letAuthorizedUsersIn: true,
      },
    },
  ],
}
