import Vue from 'vue'

import initialState from './state'

export default {
  setAppointments(state, appointments) {
    state.appointments = appointments
  },
  setAppointment(state, appointment) {
    state.appointment = appointment
  },
  setCoach(state, coach) {
    state.coach = coach
  },
  setContent(state, content) {
    state.content = content
  },
  setContentItem(state, contentItem) {
    state.contentItem = contentItem
  },
  setPreferences(state, preferences) {
    state.preferences = preferences
  },
  setStats(state, stats) {
    state.stats = stats
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
