export default {
  getGoalById: state => id => (
    state.goals.find(goal => goal.id == id)
  ),
  getGoalByProfileId: state => id => {
    if (!state.goals) return null

    return state.goals.find(goal => (
      goal.profile_id == id && !goal.completed_at
    ))
  },
  getGoalsByType: state => {
    if (!state.goals) return

    const main = state.goals.filter(goal => Boolean(goal.main))
    const shortUncompleted = state.goals.filter(goal => !goal.main && !goal.completed_at)
    const shortCompleted = state.goals.filter(goal => !goal.main && goal.completed_at)
    return { main, shortUncompleted, shortCompleted }
  },
  getProfileByGoalTypeId: state => id => (
    state.plugin.profiles.find(profile => profile.type == id)
  ),
  getProfilesByGoalTypeId: state => id => (
    state.plugin
      ? Object.values(state.plugin.profiles).filter(profile => profile.type == id)
      : []
  ),
  getProfileById: state => id => state.plugin?.profiles[id],
  getUnlockedProfiles: state => {
    if (!state.plugin) return []

    return state.plugin.unlocked_profiles
            .map(id => state.plugin.profiles[id])
  },
  hasGoals: state => {
    if (!state.goals) return false

    return Boolean(state.goals.length)
  },
  mainGoals: state => state?.goals?.filter(goal => goal.main) || [],
  secondaryGoals: state => state?.goals?.filter(goal => !goal.main) || [],
}
