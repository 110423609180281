// creates an Array in form [from, to) => right excluding
const rangeArray = (from, to, rightExcluding = true, step = 1) => {
  const retArr = []
  const nextStep = from < to
    ? i => i + step
    : i => i - step
  let continueCondition

  if (from < to) {
    continueCondition = rightExcluding
      ? i => i < to
      : i => i <= to
  } else {
    continueCondition = rightExcluding
      ? i => i > to
      : i => i >= to
  }

  for (let i = from; continueCondition(i); i = nextStep(i)) {
    retArr.push(i)
  }

  return retArr
}

export default rangeArray
