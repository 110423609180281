import { traverseModules } from './helpers'
import initializeEcho from '../../tools/echo'

export default store => {
  let echo = null

  const init = () => {
    echo = initializeEcho({
      /* eslint-disable-next-line no-undef */
      authEndpoint: `${process.env.VUE_APP_BASE_URL}/api/pusher/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${store.state.auth.token}`,
        },
      },
    })

    traverseModules(
      store._modules,
      store,
      'echo',
      echo,
    )
  }

  const setAuthorizationHeader = () => {
    if (!echo) return

    echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${store.state.auth.token}`
  }

  store.subscribe(mutation => {
    const { type } = mutation

    if (type == 'auth/logout' || type == 'auth/resetState') {
      setAuthorizationHeader()
    } else if (type == 'auth/setAuthorization' || type == 'auth/setToken') {
      if (!store.state.auth.token) return

      echo
        ? setAuthorizationHeader()
        : init()
    }
  })
}
