import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/reasons-to-lose-weight/',
    sharedMeta: {
      type: PAGE_TYPES.plugin,
    },
  },
  routes: [
    {
      path: '/reasons-to-lose-weight',
      name: 'ReasonsToLoseWeight',
      meta: {
        fetchProgram: false,
        header: 'Reasons to lose weight',
        layout: 'home',
        parent: 'Tools',
        toParent: true,
        trigger: 'reasons-to-lose-weight',
        unblockPluginAt: 'ReasonsToLoseWeight',
      },
    },
  ],
}
