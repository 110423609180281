class UserDeletedError extends Error {
  constructor() {
    super(
      `Your account was deleted.
      Please contact us for assistance
      at <a href='mailto:customersupport@changinghealth.com'>
      customersupport@changinghealth.com</a>`,
    )

    this.name = 'UserDeletedError'
    this.title = 'AccountDeleted'
  }
}

export default UserDeletedError
