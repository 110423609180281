<template>
  <base-main
    class="auth"
    data-testid="auth-wrapper"
  >
    <div class="auth__content">

      <div class="auth__background">
        <img
          alt=""
          class="auth__background-image"
          :src="requireImage('login_bg_2.png')"
        >
      </div>

      <div class="auth__tile-wrapper">
        <simple-tile
          class="account-already-exists-view__tile"
          direction="column"
          :data-testid="'account-already-exists-tile'"
          :name="'account-already-exists-tile'"
        >
          <div class="account-already-exists-view__header">
            <img
              :src="requireImage('logo-ch.png')"
              class="auth__logo"
              alt=""
            >
          </div>
          <p
            v-if="referralErrorUser"
            class="account-already-exists-view__paragraph">
            {{ errorEmail && `An Account with email ${ email } already exists.` }}
            {{ errorPhone && `An Account with phone ${ phone } already exists.` }}
            Follow the link below to login with your existing username and password,
            or to reset your password if you don't remember it.
          </p>
          <div class="account-already-exists-view__buttons">
            <base-button
              class="account-already-exists-view__submit"
              :data-testid="'account-already-exists-view-button-submit'"
              :type="'submit'"
              :name="'account-already-exists-view__submit'"
              :link="{ name: 'AuthLogin' }"
            >
              {{ $t('Login') }}
            </base-button>
          </div>
        </simple-tile>
      </div>
    </div>
  </base-main>
</template>
<script>
  import { mapState } from 'vuex'

  import { BaseButton } from '@/components/base'

  import BaseMain from '@/components/base/BaseMain'
  import SimpleTile from '@/components/tiles/simple-tile'

  import requireImage from '@/mixins/requireImage'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.support.meta.title'),
      }
    },

    components: {
      BaseMain,
      SimpleTile,
      BaseButton,
    },

    mixins: [
      requireImage,
    ],

    computed: {
      ...mapState('auth', [
        'referralUser',
        'referralErrorUser',
      ]),
      errorEmail() {
        return this.referralErrorUser?.errorEmail
      },
      errorPhone() {
        return this.referralErrorUser?.errorPhone
      },
      email() {
        return this.referralErrorUser?.userData?.email
      },
      phone() {
        return this.referralErrorUser?.userData?.phone
      },
    },
  }
</script>

<style lang="scss">
  .account-already-exists-view__tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    .account-already-exists-view__header {
      display: flex;
    }
    .account-already-exists-view__buttons {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
</style>
