// this method works the same as `this.$t`, except it will prepend the parameter with the lang slug
// you can also pass an array of strings to get translations of all provided fields
// include a `slug` propoerty in your component with a path the desired lang field
//
// eg.
// ```
// export default {
//   data() { ... },
//   slug: 'component.base.base-article',
// }
// ```
//
// if the component has no `slug`, `this.i18n` will default to the same behaviour as `this.$t`

export default {
  methods: {
    i18n(slug, bindings = {}) {
      if (!this.$t) return

      const t = s => this.$t(`${this.$options.slug ? `${this.$options.slug}.` : ''}${s}`, bindings)

      return Array.isArray(slug) ? slug.map(t) : t(slug)
    },
  },
}
