import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/food-tracking/',
    sharedMeta: {
      layout: 'home',
      trigger: 'food-tracking',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'FoodTracking',
    },
  },
  routes: [
    {
      path: '/food-tracking',
      name: 'FoodTracking',
      meta: {
        fetchProgram: true,
        parent: 'Tools',
        toParent: true,
        pluginHome: true,
        header: 'Food Tracking',
      },
    },
    {
      path: '/food-tracking/log/:id?',
      name: 'FoodTrackingLog',
      props: true,
      meta: {
        parent: 'FoodTracking',
        header: 'Food tracking Log',
      },
    },
    {
      path: '/food-tracking/delete/:id?',
      name: 'FoodTrackingDelete',
      props: true,
      meta: {
        parent: 'FoodTracking',
        header: 'Food tracking Delete',
      },
    },
  ],
}
