export default {
  computed: {
    isRequired() {
      /* eslint-disable camelcase */
      return this.required
        || (this.validation?.required)
        || (this.validation?.required_radio)
        || false
      /* eslint-enable camelcase */
    },
  },
}
