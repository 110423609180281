import moment from 'moment'

import { CoachNotInTeamError } from '@/extensions/error'

import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

const module = 'coaching'
const format = 'YYYY-MM-DD HH:mm:ss'
const times = ['pre', 'call', 'post']
const convertAppointmentToUserTimezone = (appointment, timezone) => (times.forEach(time => {
  appointment.timedata[time].time = moment.utc(appointment.timedata[time].time, format)
    .utcOffset(timezone)
    .format(format)
}))

const convertAppointmentDateToUTC = (form, timezone) => {
  form.since = moment.utc(form.since, format)
    .utcOffset(timezone, true)
    .utc()
    .format(format)

  form.to = moment.utc(form.to, format)
    .utcOffset(timezone, true)
    .utc()
    .format(format)
}

export default {
  fetchAppointments({ commit, rootGetters }, { coachId, userId, silence }) {
    return new Promise((resolve, reject) => {
      if (!silence) {
        loading('coaching/fetchAppointments', true)
      }

      api.get(`api/coaching/appointments/coach/${coachId}/participant/${userId}`)
        .then(response => {
          const timezone = rootGetters['user/getTimezoneOffset']

          response.items.forEach(appointment => (
            convertAppointmentToUserTimezone(appointment, timezone)))

          commit('setAppointments', response.items)
          commit('setStats', response.stats)
          loading('coaching/fetchAppointments', false)

          resolve()
        })
        .catch(() => {
          loading('coaching/fetchAppointments', false)

          reject()
        })
    })
  },
  fetchAppointment({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      loading('coaching/fetchAppointment', true)

      api.get(`api/coaching/appointments/${id}`)
        .then(response => {
          if (response.appointment.cancelled) {
            reject()
          }

          const { user, ...appointment } = response.appointment
          const timezone = rootGetters['user/getTimezoneOffset']

          convertAppointmentToUserTimezone(appointment, timezone)

          commit('setAppointment', appointment)
          loading('coaching/fetchAppointment', false)

          resolve()
        })
        .catch(() => {
          loading('coaching/fetchAppointment', false)

          reject()
        })
    })
  },
  createAppointment({ commit, rootGetters }, { form }) {
    return new Promise((resolve, reject) => {
      loading('coaching/createAppointment', true)

      const timezone = rootGetters['user/getTimezoneOffset']

      convertAppointmentDateToUTC(form, timezone)

      api.post('api/coaching/appointments', form)
        .then(response => {
          const { appointment } = response

          convertAppointmentToUserTimezone(appointment, timezone)

          commit('setAppointment', appointment)
          loading('coaching/createAppointment', false)

          resolve(appointment.id)
        })
        .catch(err => {
          /* eslint-disable-next-line camelcase */
          const error = err.response.data?.errors?.program_id?.[0]
          loading('coaching/createAppointment', false)

          reject(error)
        })
    })
  },
  editAppointment({ commit, rootGetters }, { form, id }) {
    return new Promise((resolve, reject) => {
      loading('coaching/editAppointment', true)

      const timezone = rootGetters['user/getTimezoneOffset']

      convertAppointmentDateToUTC(form, timezone)

      api.patch(`api/coaching/appointments/${id}`, form)
        .then(response => {
          const { appointment } = response

          convertAppointmentToUserTimezone(appointment, timezone)

          commit('setAppointment', appointment)
          loading('coaching/editAppointment', false)

          resolve(appointment.id)
        })
        .catch(() => {
          loading('coaching/editAppointment', false)

          reject()
        })
    })
  },
  cancelAppointment({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading('coaching/cancelAppointment', true)

      api.put(`api/coaching/appointments/${id}/cancel`)
        .then(response => {
          commit('setAppointment', response.appointment)
          loading('coaching/cancelAppointment', false)

          resolve()
        })
        .catch(() => {
          loading('coaching/cancelAppointment', false)

          reject()
        })
    })
  },
  fetchCoach({ commit }, { programId, userId, silence }) {
    return new Promise((resolve, reject) => {
      if (!silence) {
        loading('coaching/fetchCoach', true)
      }

      api.get(`api/coaching/coaches/user/${userId}/program/${programId}?entity=1`)
        .then(response => {
          if (response.coach) {
            const properties = ['description', 'icon', 'id', 'name', 'title', 'user']
            const coach = properties
                          .reduce((acc, curr) => (
                            { ...acc, [curr]: response.coach[curr] }
                          ), {})

            commit('setCoach', coach)

            resolve()
          } else {
            reject()
          }

          loading('coaching/fetchCoach', false)
        })
        .catch(() => {
          loading('coaching/fetchCoach', false)

          reject()
        })
    })
  },
  fetchCoachAvailability({ commit }, { form }) {
    return new Promise((resolve, reject) => {
      loading('coaching/fetchCoachAvailability', true)

      api.post('api/coaching/query-availability', form)
        .then(response => {
          loading('coaching/fetchCoachAvailability', false)

          resolve(response.data)
        })
        .catch(() => {
          loading('coaching/fetchCoachAvailability', false)

          reject(new CoachNotInTeamError())
        })
    })
  },
  fetchCoachAvailabilityForAppointment({ commit }, { form, appointmentId }) {
    return new Promise((resolve, reject) => {
      loading('coaching/fetchCoachAvailabilityForAppointment', true)

      api.post(`api/coaching/query-availability/for-appointment/${appointmentId}`, form)
        .then(response => resolve(response.data))
        .catch(() => reject(new CoachNotInTeamError()))
        .finally(() => loading('coaching/fetchCoachAvailabilityForAppointment', false))
    })
  },
  fetchCoachAvailableDays({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('coaching/fetchCoachAvailableDays', true)

      api.post('api/coaching/calendar-preflight', form)
        .then(response => {
          loading('coaching/fetchCoachAvailableDays', false)

          resolve(response.data)
        })
        .catch(() => {
          loading('coaching/fetchCoachAvailableDays', false)

          reject(new CoachNotInTeamError())
        })
    })
  },
  fetchContent({ commit }, { userId, programId }) {
    return new Promise((resolve, reject) => {
      loading('coaching/fetchContent', true)

      api.get(`api/coaching/items/participant/${userId}/${programId}/assigned-items`)
        .then(response => {
          commit('setContent', response.user_items)
          loading('coaching/fetchContent', false)

          resolve()
        })
        .catch(() => {
          loading('coaching/fetchContent', false)

          reject()
        })
    })
  },
  fetchContentItem({ commit }, { itemId, userId, programId }) {
    return new Promise((resolve, reject) => {
      loading('coaching/fetchContentItem', true)

      api.get(`api/coaching/items/participant/${userId}/${programId}/assigned-items/${itemId}`)
        .then(response => {
          commit('setContentItem', response.user_item)
          loading('coaching/fetchContentItem', false)

          resolve()
        })
        .catch(() => {
          loading('coaching/fetchContentItem', false)

          reject()
        })
    })
  },
  fetchPreferences({ commit }, id) {
    return new Promise((resolve, reject) => {
      const action = `${module}/fetchPreferences`
      loading(action, true)

      api.get(`api/coaching/preferences/users/${id}`)
        .then(({ preference }) => {
          if (!Object.prototype.hasOwnProperty.call(preference, 'id')) {
            return resolve({ shouldRedirectToStart: true })
          }

          commit('setPreferences', preference)

          resolve()
        })
        .catch(reject)
        .finally(() => loading(action, false))
    })
  },
  createPreferences({ commit, dispatch }, { form, id }) {
    return new Promise((resolve, reject) => {
      loading('coaching/createPreferences', true)

      api.post(`api/coaching/preferences/users/${id}`, form)
        .then(response => {
          dispatch('multiAnalytics/triggerEvent', {
            eventName: 'Coach booking - time slot selected',
          }, { root: true })

          commit('setCoach', response.coach)
          loading('coaching/createPreferences', false)

          resolve(response.coach)
        })
        .catch(() => {
          loading('coaching/createPreferences', false)

          reject()
        })
    })
  },
  contactSupport({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      loading('coaching/contactSupport', true)

      const form = {
        program_id: rootGetters['program/currentProgramId'],
        user_id: rootGetters['user/getCurrentUserId'],
      }

      api.post('api/coaching/contact-support', form)
        .then(() => {
          loading('coaching/contactSupport', false)

          resolve()
        })
        .catch(() => {
          loading('coaching/contactSupport', false)

          reject()
        })
    })
  },
}
