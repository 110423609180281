<template>
  <base-main
    background
    class="home"
    data-testid="home"
    :loading="$options.loading"
  >
    <dashboard/>
  </base-main>
</template>

<script>
  import BaseMain from '@/components/base/BaseMain'
  import Dashboard from '@/components/dashboard'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.home.meta.title'),
      }
    },

    components: {
      BaseMain,
      Dashboard,
    },

    loading: [
      'notifications/fetchNotifications',
      'notifications/markAsRead',
    ],
  }
</script>
