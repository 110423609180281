<template>
  <base-main
    class="auth"
    data-testid="auth-wrapper"
    v-bind="{ loading }"
  >
    <div class="auth__content">

      <div class="auth__background">
        <img
          alt=""
          class="auth__background-image"
          :src="requireImage('login_bg_2.png')"
        >
      </div>

      <div class="auth__tile-wrapper">
        <modular-tile>
          <div class="auth__container">
            <div
              class="auth__error"
              data-testid="auth-wrapper-error"
            >
              <slot name="error" />
            </div>

            <img
              :src="requireImage('logo-ch.png')"
              class="auth__logo"
              alt=""
            >

            <h1
              class="auth__title"
              data-testid="auth-wrapper-header"
            >
              {{ i18n('send-us-feedback') }}
            </h1>
            <div>
              <div
                aria-live="polite"
                class="feedback-auth__content"
              >
                <div
                  v-if="feedbackSent"
                  class="feedback-auth__content-thank-you">
                  <p
                    class="feedback-auth__thank-you"
                    data-testid="feedback-thank-you"
                  >
                    {{ i18n('thank-you') }}
                  </p>
                  <base-button
                    data-testid="feedback-auth__thank-you-btn"
                    :link="{ name: 'Login'}"
                    class="btn-login">
                    {{ $t('Back to login') }}
                  </base-button>
                </div>

                <base-form
                  v-else
                  ref="form"
                  class="feedback-auth-form"
                  :submitText="i18n('send')"
                  :testid="'feedback'"
                  :hasCancelButton="true"
                  :showSummaryErrors="true"
                  :cancelText="'Back to Login'"
                  :linkToCancel="{ name: 'Login'}"
                  v-bind="{ isSaving }"
                  @submit="onSubmit"
                >
                  <form-fields-generator
                    :dataModel="form"
                    :formFields="formFields"
                  />
                </base-form>
              </div>
            </div>
          </div>
        </modular-tile>
      </div>
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import {
    BaseButton,
    BaseForm,
    BaseMain,
  } from '@/components/base'
  import FormFieldsGenerator from '@/components/forms/form-fields-generator/FormFieldsGenerator'
  import ModularTile from '@/components/tiles/modular-tile'

  import i18n from '@/mixins/i18n'
  import pageName from '@/mixins/pageName'
  import requireImage from '@/mixins/requireImage'

  export default {
    components: {
      FormFieldsGenerator,
      BaseMain,
      ModularTile,
      BaseForm,
      BaseButton,
    },

    mixins: [
      i18n,
      pageName,
      requireImage,
    ],

    data() {
      return {
        form: {
          comment: '',
          rating: null,
        },
        feedbackSent: false,
      }
    },

    methods: {
      ...mapActions('feedback', [
        'sendFeedback',
      ]),
      onSubmit(isValid) {
        if (!isValid) return

        let { comment } = this.form
        if (this.form.comment === '') {
          comment = 'No comment'
        }

        const previousRoute = this.history[this.history.length - 2]
        let name = this.pageName
        let url = window.location.href
        if (previousRoute) {
          name = previousRoute.name
          url = previousRoute.url
        }

        const payload = {
          page_name: name,
          page_type: this.$route.meta.type,
          page_url: url,
          comment,
          rating: this.form.rating,
        }

        this.sendFeedback(payload)
          .then(() => {
            this.feedbackSent = true
          })
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('history', [
        'history',
      ]),
      loading() {
        const loadings = [
          'program/fetchProgram',
        ]

        const progressLoadings = [
          'articles/changeProgress',
          'program/fetchNextSection',
        ]

        return this.isSurveyWithTrackProgress
          ? loadings
          : [
            ...loadings,
            ...progressLoadings,
          ]
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'feedback/sendFeedback',
        ])
      },
      formFields() {
        return [
          {
            type: 'RadioMultiGroup',
            name: 'rating',
            class: 'feedback__radio',
            props: {
              name: 'rating',
              labelKey: 'label',
              valueKey: 'value',
              legend: this.i18n('question-1'),
              options: [
                { value: 1, label: this.i18n('option-1-a') },
                { value: 2, label: this.i18n('option-1-b') },
                { value: 3, label: this.i18n('option-1-c') },
                { value: 4, label: this.i18n('option-1-d') },
                { value: 5, label: this.i18n('option-1-e') },
              ],
              testid: 'radio-feedback',
              validation: { required: true },
            },
          },
          {
            type: 'TextareaGroup',
            name: 'comment',
            class: 'feedback__textarea',
            props: {
              hasHiddenAsterisk: true,
              label: this.i18n('question-2'),
              maxLength: 1000,
              name: 'comment',
              rows: 5,
              testid: 'radio-feedback',
              validation: {
                required: false,
                max: 1000,
              },
            },
          },
        ]
      },
    },

    slug: 'component.dialog.feedback-dialog',
  }
</script>

<style lang="scss">
  form.feedback-auth-form button, .btn-login {
    margin: 1rem auto;
  }
  .feedback-auth__content-thank-you {
    margin: auto;
    width: fit-content;
    p {
      margin: 2rem 0;
    }
  }
</style>
