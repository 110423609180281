<template>
  <form-group
    ref="group"
    v-bind="{
      descriptionId,
      helper,
      label,
      name,
      tag,
      testid,
      validation,
    }"
    v-slot="{ errors }"
  >
    <div
      class="input-autocomplete-group"
      :class="{
        'input-autocomplete-group--has-after-text': afterText,
        'input-autocomplete-group--has-error': errors.length,
        'nhsuk-form-group--error': errors.length,
        'input-autocomplete-group--has-icon': icon,
        'input-autocomplete-group--has-toggle-mask': showToggleMask,
        'input-autocomplete-group--has-tooltip': hasTooltip,
        'input-autocomplete-group--is-disabled': disabled,
        'input-autocomplete-group--is-focused': isFocused,
        'input-autocomplete-group--is-required': isRequired && !hasHiddenAsterisk
      }"
      :data-testid="`${dataTestid} input-autocomplete-group`"
      v-merge-attr:data-testid
    >
      <label
        v-if="showLabel && label"
        :for="id"
        class="input-autocomplete-group__label"
        :class="{ 'input-autocomplete-group__label--is-active': isFocused || value }"
        data-testid="input-autocomplete-group-label"
      >
        {{ labelText }}
        <screen-readers-only
          v-if="screenReaderMessage"
        >
          {{ screenReaderMessage }}
        </screen-readers-only>
      </label>

      <slot name="description"/>

      <div
        class="textarea-group__errors"
        v-for="(error, i) in errors"
        :key="i"
        :id="descriptionId">
        <span class="nhsuk-error-message">
          <span class="nhsuk-u-visually-hidden">{{ i18n('(Error)') }}</span>
          {{ error }}
        </span>
      </div>

      <div class="input-autocomplete-group__container">
        <div
          :class="{'autocomplete-container__error': errors.length}"
          id="autocomplete-container"/>

        <input
          ref="input"
          class="input-autocomplete-group__input nhsuk-input autocomplete__custom-input"
          data-testid="input-autocomplete-group-input"
          :aria-describedby="descriptionId || describedBy"
          :aria-invalid="!!errors.length"
          :aria-label="customAriaLabel"
          :aria-required="validation.required"
          :id="id"
          :placeholder="placeholderProp"
          :readonly="readonlyProp"
          :type="inputType"
          v-bind="{
            autocomplete,
            disabled,
            name,
            ...additional,
            ...extraProps,
            ...ariaLabelProp,
          }"
          v-model="model"
          @blur="toggle('isFocused')"
          @click="onClick"
          @focus="onFocus"
          @keydown="onKeydown"
          >

        <inline-svg
          v-if="icon"
          class="input-autocomplete-group__icon"
          data-testid="input-autocomplete-group-icon"
          :src="`${icon}.svg`"
          @click="onClick"
        />
        <span
          v-if="afterText"
          class="input-autocomplete-group__after-text"
        >
          {{ afterText }}
        </span>
        <button-masked
          v-if="showToggleMask"
          class="input-autocomplete-group__button-masked"
          data-testid="input-autocomplete-group-toggle-masked"
          v-bind="{ isMasked }"
          @click="toggle('isMasked')"
        />
        <tooltip
          v-if="hasTooltip"
          :message="tooltip"
        />
      </div>
    </div>
  </form-group>
</template>

<script>
  import accessibleAutocomplete from 'accessible-autocomplete'

  import ButtonMasked from '@/components/buttons/button-masked/ButtonMasked'
  import FormGroup from '@/components/forms/form-group/FormGroup'
  import ScreenReadersOnly from '@/components/screen-readers-only/ScreenReadersOnly'
  import Tooltip from '@/components/tooltip/Tooltip'

  import mergeAttr from '@/directives/mergeAttr'

  import i18n from '@/mixins/i18n'
  import labelText from '@/mixins/labelText'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      ButtonMasked,
      FormGroup,
      ScreenReadersOnly,
      Tooltip,
    },

    directives: {
      mergeAttr,
    },

    mixins: [
      labelText,
      testid(),
      i18n,
    ],

    props: {
      additional: {
        type: Object,
        default: () => ({}),
      },
      afterText: {
        type: String,
        required: false,
        default: '',
      },
      autocomplete: {
        type: String,
        default: null,
      },
      ariaLabel: {
        type: String,
        default: '',
      },
      describedBy: {
        type: [String, Boolean],
        default: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasHiddenAsterisk: {
        type: Boolean,
        default: false,
      },
      helper: {
        type: String,
        required: false,
        default: '',
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      hideToggleMask: {
        type: Boolean,
        required: false,
        default: true,
      },
      icon: {
        type: String,
        required: false,
        default: '',
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      max: {
        type: [Number, Object],
        required: false,
        default: null,
      },
      min: {
        type: [Number, Object],
        required: false,
        default: null,
      },
      name: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      placeholder: {
        type: [String],
        required: false,
        default: '',
      },
      readonly: {
        type: Boolean,
        required: false,
        default: false,
      },
      required: {
        type: Boolean,
        required: false,
        default: true,
      },
      screenReaderMessage: {
        type: String,
        default: '',
      },
      step: {
        type: Number,
        required: false,
        default: 1,
      },
      stopPropagation: {
        type: Boolean,
        required: false,
        default: false,
      },
      tag: {
        type: String,
        required: false,
        default: 'div',
      },
      tooltip: {
        type: String,
        required: false,
        default: '',
      },
      type: {
        type: String,
        required: false,
        default: 'text',
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      value: {
        type: [Number, String],
        required: false,
        default: '',
      },
      options: {
        type: Array,
        required: false,
        default: () => [],
      },
      defaultValue: {
        type: String,
        required: false,
        default: '',
      },
    },

    mounted() {
      this.init()
    },

    watch: {
      options() {
        this.init()
      },
    },

    data() {
      return {
        isFocused: false,
        isMasked: true,
      }
    },

    methods: {
      onFocus() {
        this.$emit('focus')
        this.toggle('isFocused')
      },
      onClick(e) {
        this.$emit('click', e)
      },
      onKeydown(e) {
        if (this.type == 'number'
          && e.key?.length <= 1
          && !(/[\d.,]/.test(e.key))) {
            e.preventDefault()
        }

        if (this.stopPropagation) e.stopPropagation()

        this.$emit('keydown', e)
      },
      toggle(property) {
        this[property] = !this[property]
      },
      async validate(value) {
        const { valid } = await this.$refs.group.$refs.provider.validate(value)

        return valid
      },
      init() {
        if (!this.options.length) return

        const valueExist = this.options.find(option => option === this.model)
        if (!valueExist) {
          this.model = this.defaultValue
        }
        accessibleAutocomplete({
          element: document.querySelector('#autocomplete-container'),
          id: this.id,
          source: this.options,
          defaultValue: valueExist ? this.model : this.defaultValue,
          onConfirm: confirmed => {
            const { value } = document.querySelector(`input#${this.id}`)
            const newValue = confirmed || ''
            if (newValue || (!newValue && !value)) {
              this.model = newValue
              this.validate(newValue)
            }
          },
        })
      },
    },

    computed: {
      hasTooltip() {
        return this.tooltip?.length
      },
      inputType() {
        if (this.isPassword && !this.isMasked) {
          return 'text'
        }

        return this.type
      },
      isPassword() {
        return this.type == 'password'
      },
      model: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      showLabel() {
        return (this.label || this.isRequired) && !this.hideLabel
      },
      showToggleMask() {
        return this.isPassword && !this.hideToggleMask
      },
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
      customAriaLabel() {
        return this.ariaLabel
          || this.labelText
          || this.placeholder
          || 'Text Input'
      },
      readonlyProp() {
        const allowedTypes = [
          'date',
          'datetime-local',
          'email',
          'month',
          'number',
          'password',
          'search',
          'tel',
          'text',
          'time',
          'url',
          'week',
        ]

        const {
          readonly,
        } = this

        return allowedTypes.includes(this.type) && readonly
      },
      extraProps() {
        const allowedTypes = [
          'date',
          'datetime-local',
          'number',
          'range',
          'month',
          'time',
          'week',
        ]

        const {
          min,
          max,
          step,
        } = this

        return allowedTypes.includes(this.type)
          ? {
            min,
            max,
            step,
          }
          : {}
      },
      placeholderProp() {
        const allowedTypes = [
          'email',
          'number',
          'password',
          'search',
          'tel',
          'text',
          'url',
        ]

        return allowedTypes.includes(this.type)
          ? this.placeholder
          : undefined
      },
      ariaLabelProp() {
        return this.showLabel
          ? {}
          : { 'aria-label': this.customAriaLabel }
      },
    },
  }
</script>

<style lang="scss">
  $block: input-autocomplete-group;

  .#{$block} {
    width: 100%;
    margin: 1.8rem 0 0 0;
    position: relative;
    &--has-icon {
      .#{$block}__container {
        display: flex;
        align-items: center;
      }
      .#{$block}__input {
        padding-left: 3.6rem;
      }
    }
    &--has-tooltip {
      .#{$block}__container {
        display: flex;
      }
    }
    &--is-disabled {
      .#{$block}__input {
        background-color: color(_transparent);
      }
    }
    &--has-error {
      .#{$block}__input {
        border-color: color(error);
      }
    }
    &--has-toggle-mask {
      .#{$block}__container {
        display: flex;
      }
      & ~ .input-details {
        margin-right: 2.8rem;
      }
    }
    &--has-after-text {
      .#{$block}__container {
        display: grid;
        grid-column-gap: 1rem;
        grid-template-columns: 1fr auto;
      }
    }
    &__container {
      display: flex;
      position: relative;
      font-size: 1.4rem;
    }
    &__label {
      display: inline-block;
      padding: 0 0 0.8rem 0;
      color: color(dark-primary);
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1;
      cursor: pointer;
    }
    &__input {
      width: 100%;
      height: 4rem;
      padding: 1rem;
      position: relative;
      color: color(dark-grey);
      font-size: 1.4rem !important;
      border: 1px solid color(input-border);
      border-radius: 0;
      background-color: color(_white);
      appearance: none;
      caret-color: color(input-border);
      &::placeholder {
        color: color(input-placeholder);
        opacity: 1;
      }
      &[type=number] {
        -moz-appearance: textfield;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.6rem;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
      svg {
        width: 1.8rem;
        fill: color(primary);
      }
    }
    &__button-masked {
      padding: 0 0.7rem;
    }
    &__after-text {
      display: flex;
      align-self: center;
    }
  }
  .ios {
    .#{$block}__input {
      font-size: 1.6rem !important;
      transform: translate3d(0,0,0);
    }
  }
  #autocomplete-container {
    width: 100%;
    .autocomplete__input {
      border: 1px solid color(input-border) !important;
      caret-color: color(input-border);
      font-size: 1.4rem;
      width: 100%;
      height: 4rem;
      padding: 1rem;
      color: var(--color-dark-grey);
    }
    &.autocomplete-container__error {
      .autocomplete__input {
        border-color: color(error) !important;
        caret-color: color(error);
      }
    }
  }
  .autocomplete__custom-input {
    display: none;
  }
</style>
