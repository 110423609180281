<template>
  <div
    class="speech-bubble"
    :data-testid="`speech-bubble ${dataTestid}`"
    v-merge-attr:data-testid
  >
    <div class="speech-bubble-image speech-bubble-image--desktop">
      <img
        alt=""
        class="speech-bubble-img"
        :src="imageLink ? imageLink : require(`@/assets/img/${image}`)"
      >
    </div>
    <div class="speech-bubble-bubble">
      <div class="speech-bubble-image speech-bubble-image--mobile">
        <img
          alt=""
          class="speech-bubble-img speech-bubble-img--mobile"
          :src="imageLink ? imageLink : require(`@/assets/img/${image}`)"
        >
      </div>
      <p
        ref="text"
        class="speech-bubble-text"
        :data-testid="`speech-bubble-text ${dataTestid}-text`"
      >
        <span
          v-if="text"
          v-html="text"
        />
        <slot/>
      </p>
    </div>
  </div>
</template>

<script>
  import mergeAttr from '@/directives/mergeAttr'

  import testid from '@/mixins/testid/testid'

  export default {
    directives: {
      mergeAttr,
    },

    mixins: [
      testid('speech-bubble'),
    ],

    props: {
      image: {
        type: String,
        required: false,
        default: 'card-media.png',
      },
      imageLink: {
        type: String,
        required: false,
        default: '',
      },
      text: {
        type: String,
        required: false,
        default: '',
      },
    },

    mounted() {
      this.$refs.text.innerHTML = this.$refs.text.innerHTML.trim()
    },
  }
</script>

<style lang="scss">
  .speech-bubble {
    display: flex;
    margin: 1.2rem 0;
    &-image {
      position: relative;
      &--desktop {
        @include max-sm {
          display: none;
        }
      }
      &--mobile {
        margin: 0 0 2rem;
        @include min-sm {
          display: none;
        }
      }
    }
    &-img {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      object-fit: cover;
      @media all and (min-width: 350px) {
        width: 8rem;
        height: 8rem;
      }
    }
    &-bubble {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      min-height: 6rem;
      margin: 0;
      padding: 1rem 2rem;
      position: relative;
      word-break: break-word;
      border-radius: 3rem;
      background-color: color(dark-primary);
      box-shadow: box-shadow(default);
      @include min-sm {
        min-height: 8.4rem;
        margin: 0 0 0 2.4rem;
        padding: 1rem 4.2rem;
        border-radius: 4.2rem;
      }
      @media all and (min-width: 350px) {
        min-height: 8rem;
      }
      &::before {
        position: absolute;
        top: 3rem;
        left: -3rem;
        box-sizing: content-box;
        transform: translateY(-50%);
        border-width: 0.6rem 1.6rem;
        border-style: solid;
        border-color: transparent;
        border-right-color: color(dark-primary);
        @include min-sm {
          content: '';
          top: 4.2rem;
        }
        @media all and (min-width: 350px) {
          top: 4rem;
        }
      }
      p {
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    &-text {
      color: color(_white);
      font-weight: 600;
      a {
        display: inline-block;
        color: color(_white);
        font-weight: 600;
        text-decoration: underline;
      }
    }
    &-tail {
      position: absolute;
      top: 4.3rem;
      right: 0;
      transform: translateX(100%);
      svg {
        width: 2.9rem;
        height: 2.4rem;
        fill: color(_white);
      }
    }
  }
</style>
