const requireImage = (file, showError = true) => {
  try {
    /* eslint-disable-next-line global-require */
    return require(`@/assets/img/${file}`)
  } catch (e) {
    if (showError) {
      /* eslint-disable-next-line no-console */
      console.error(`could not load an image: '${file}'`)
    }

    return null
  }
}

export default requireImage
