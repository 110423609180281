<template>
  <form-group
    class="checkbox-group"
    data-testid="checkbox-group"
    :tag="'div'"
    v-bind="{
      descriptionId,
      name,
      testid,
      validation,
    }"
    v-slot="{ errors }"
  >
    <base-checkbox
      ref="checkbox"
      :hasErrors="hasErrors(errors)"
      :class="{ 'checkbox-group__error': hasErrors(errors) }"
      v-bind="{
        descriptionId,
        inputValue,
        isBoolean,
        label,
        name,
        tooltip,
        validation,
      }"
      v-model="model"
    />
  </form-group>
</template>

<script>
  import { BaseCheckbox } from '@/components/base'
  import FormGroup from '@/components/forms/form-group/FormGroup'

  import addVModel from '@/mixins/addVModel'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      BaseCheckbox,
      FormGroup,
    },

    mixins: [
      addVModel('model', [Boolean, Number, String]),
      testid(),
    ],

    props: {
      inputValue: {
        type: [Boolean, Number, String],
        required: false,
        default: false,
      },
      isBoolean: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: true,
      },
      tooltip: {
        type: String,
        required: false,
        default: '',
      },
      validation: {
        type: [Object, String],
        required: false,
        default: () => ({}),
      },
    },

    methods: {
      hasErrors(errors) {
        return !!errors.length
      },
    },

    computed: {
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
    },
  }
</script>

<style lang="scss">
  .checkbox-group {
    &__error {
      .base-checkbox {
        &__check {
          border: 1px solid color(error);
        }
        &__container {
          color: color(error);
        }
      }
    }
    .input-details {
      &__error {
        padding-left: 0;
        border: none;
      }
      &__error-icon {
        display: none;
      }
    }
  }
</style>
