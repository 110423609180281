var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({staticClass:"checkbox-group",attrs:{"data-testid":"checkbox-group","tag":'div'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-checkbox',_vm._b({ref:"checkbox",class:{ 'checkbox-group__error': _vm.hasErrors(errors) },attrs:{"hasErrors":_vm.hasErrors(errors)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'base-checkbox',{
      descriptionId: _vm.descriptionId,
      inputValue: _vm.inputValue,
      isBoolean: _vm.isBoolean,
      label: _vm.label,
      name: _vm.name,
      tooltip: _vm.tooltip,
      validation: _vm.validation,
    },false))]}}])},'form-group',{
    descriptionId: _vm.descriptionId,
    name: _vm.name,
    testid: _vm.testid,
    validation: _vm.validation,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }