var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({staticClass:"radio-multi-group",attrs:{"data-testid":_vm.dataTestid,"label":_vm.labelText},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:[
      {'nhsuk-form-group--error': errors.length}
    ]},[_c('base-legend',{attrs:{"data-testid":"checkbox-radio-group-legend","aria-hidden":_vm.isLegendHidden,"isHidden":_vm.isLegendHidden,"legend":_vm.legend || _vm.labelText}},[_vm._t("legend")],2),_c('div',{staticClass:"nhsuk-hint",attrs:{"data-testid":"date-input-hint"}},[_vm._v(" "+_vm._s(_vm.description)+" ")]),(errors[0])?_c('span',{staticClass:"nhsuk-error-message",attrs:{"id":_vm.ariaDescribedby}},[_c('span',{staticClass:"nhsuk-u-visually-hidden"},[_vm._v(_vm._s(_vm.i18n('(Error)')))]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),_c('div',{staticClass:"radio-multi-group__wrapper"},_vm._l((_vm.options),function(option,i){return _c('base-radio',_vm._b({key:i,attrs:{"is-multi":"","aria-invalid":!!errors.length,"inputValue":_vm.extractValue(option, _vm.valueKey),"isFirst":i === 0,"isRequired":_vm.validation.required,"label":_vm.extractValue(option, _vm.labelKey),"description":option.description || '',"aria-describedby":_vm.ariaDescribedby},scopedSlots:_vm._u([{key:"option",fn:function(){return [_vm._t("option",null,null,{ option: option })]},proxy:true},{key:"default",fn:function(){return [_vm._t("default",null,null,{ option: option })]},proxy:true}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'base-radio',{
          describedBy: _vm.describedBy,
          isAnySelected: _vm.isAnySelected,
          name: _vm.name,
        },false))}),1)],1)]}}],null,true)},'form-group',{
    hideErrorMessage: _vm.hideErrorMessage,
    name: _vm.name,
    tag: _vm.tag,
    testid: _vm.testid,
    validation: _vm.validation,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }