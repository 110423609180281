/**
  * capitalizes first word or all words passed
  *
  * @param {String} words string to be capitalized
  * @param {Boolean} [isGlobal=false] global flag for regexp
  * @return {String} string with capitalized word(s)
  *
*/

const capitalize = (words, isGlobal = false) => {
  const pattern = new RegExp(/(^|\s)\S/, isGlobal ? 'g' : '')

  return words.replace(pattern, e => e.toUpperCase())
}

export default capitalize
