import isRequired from './isRequired'

export default {
  mixins: [
    isRequired,
  ],

  computed: {
    labelText() {
      return `${this.$t(this.label || this.legend)}${this.isRequired ? ` (${this.$t('required')})` : ''}`
    },
  },
}
