import jwtDecode from 'jwt-decode'

import {
  CustomError,
  EmptyPackageError,
  ExpiredInvitationTokenError,
  ExpiredTokenTTLError,
  PackageNotEnabledError,
  TwoFactorAuthError,
} from '@/extensions/error'

import { formatDateFields } from '@/helpers'
import isServerError from '@/helpers/isServerError'
import logoutAndOpenDialog from '@/helpers/logoutAndOpenDialog'

import loading from '@/store/helpers/loading'
import rootCommit from '@/store/helpers/rootCommit'
import rootDispatch from '@/store/helpers/rootDispatch'
import modules from '@/store/modules'

import { api, tokenManager } from '@/tools/api'
import {
  forgetAllDevices,
  getUserDeviceToken,
  rememberUserDevice,
} from '@/tools/deviceManager'
import localStorage from '@/tools/local-storage'

export default {
  init({ commit }) {
    const { token, policiesToAccept } = localStorage.get('Authorization', {})

    if (token) {
      commit('setToken', token)
      commit('setPoliciesToAccept', policiesToAccept)

      // dunno why loading and rootCommit don't work here
      commit('loading/changeLoadingState', ['auth/authGuard', true], { root: true })
    }
  },
  loginWithCredentials({ commit, dispatch }, form) {
    return new Promise((resolve, reject) => {
      loading('auth/loginWithCredentials', true)
      const sendForm = {
        ...form,
        save_device_token: getUserDeviceToken({
          email: form.login,
        }),
      }

      api.post('api/auth/login', sendForm)
        .then(response => {
          const { access_token: accessToken, _2fa: is2fa } = response || {}

          dispatch('decodeJWT', { accessToken })
          commit('set2faStatus', is2fa)

          dispatch('multiAnalytics/triggerEvent', {
            eventName: 'Sign in',
          }, { root: true })

          loading('auth/loginWithCredentials', false)

          resolve()
        })
        .catch(err => {
          loading('auth/loginWithCredentials', false)

          isServerError(err)
            ? reject(err)
            : reject(new CustomError(err.response.data.message, err.response.data.error))
        })
    })
  },
  fetchCurrentUser({ commit }) {
    return new Promise((resolve, reject) => {
      loading('auth/fetchCurrentUser', true)
      rootCommit('user/setUser', null)

      api.get('api/auth/me')
        .then(response => {
          if (!response.user.package_enabled) {
            logoutAndOpenDialog('PackageEmpty')
            loading('auth/fetchCurrentUser', false)

            reject(new EmptyPackageError())
            return
          }

          const {
            current_program_id,
            personal_data_store,
            unit,
            locale: { locale: language },
            enabled_2fa,
            ...newUser
          } = formatDateFields(response.user, {
            timezone: response.user.timezone,
          })

          commit('set2faStatus', enabled_2fa)
          rootCommit('program/setInitialCurrentProgramId', current_program_id)
          rootCommit('languages/setCurrentUserLanguage', { language, priority: 1 })
          rootCommit('user/setUser', newUser)
          rootCommit('personalDataStore/setStore', personal_data_store)
          loading('auth/fetchCurrentUser', false)

          resolve()
        })
        .catch(() => {
          loading('auth/fetchCurrentUser', false)

          reject()
        })
    })
  },
  requestCode({ commit }, data) {
    loading('auth/requestCode', true)

    return new Promise((resolve, reject) => {
      api.post('api/auth/one-time-code/request', data)
        .then(resolve)
        .catch(reject)
        .finally(() => loading('auth/requestCode', false))
    })
  },
  checkCode({ commit, dispatch }, { code, remember, email }) {
    loading('auth/checkCode', true)

    return new Promise((resolve, reject) => {
      api.post('api/auth/one-time-code/verify', { code, remember })
        .then(response => {
          const {
            access_token: accessToken,
            save_device_token: saveDeviceToken,
          } = response?.data || {}

          if (saveDeviceToken) {
            rememberUserDevice({
              email,
              saveDeviceToken,
            })
          }

          dispatch('decodeJWT', { accessToken })
          loading('auth/checkCode', false)

          resolve()
        })
        .catch(err => {
          loading('auth/checkCode', false)
          reject(err)
        })
    })
  },
  decodeJWT({ commit, dispatch }, { accessToken, omitLocalStorage = false }) {
    if (!accessToken) return

    const decoded = jwtDecode(accessToken)
    const { personal_data_store, ...user } = decoded.user

    commit('setToken', accessToken)
    rootCommit('user/decodeUser', user)
    rootCommit('personalDataStore/setStore', personal_data_store)

    if (!omitLocalStorage) {
      const isAuthorized = !decoded.user._2fa || decoded.verified

      dispatch('setJWTInStorage', { accessToken, isAuthorized })
    }
  },
  setJWTInStorage({ commit }, { accessToken, isAuthorized }) {
    commit('setAuthorization', accessToken)
    commit('setIsCurrentUserAuthorized', isAuthorized)
  },
  logout({ commit, dispatch, state }, isTokenExpired = false) {
    return new Promise(resolve => {
      dispatch('multiAnalytics/triggerEvent', {
        eventName: 'Sing out',
        params: {},
      }, { root: true })
        .then(() => dispatch('multiAnalytics/reset', {}, { root: true }))

      rootCommit('requests/cancelAll')

      const handleToken = () => (isTokenExpired
                                  ? Promise.resolve()
                                  : api.post('api/auth/logout', { token: state.token }))

      handleToken()
        .finally(() => {
            Object.entries(modules).forEach(([key, _module]) => {
              if (!_module.mutations || !_module.mutations.resetState) return

              rootCommit(`${key}/resetState`)
            })

            commit('logout')
            resolve()
        })
    })
  },
  isInvitationNewUser({ commit }, token) {
    return new Promise((resolve, reject) => {
      loading('auth/isInvitationNewUser', true)

      api.get(`api/invitation/${token}/info`)
        .then(response => {
          if (!response.invitation) {
            loading('auth/isInvitationNewUser', false)

            return reject(new ExpiredInvitationTokenError())
          }

          commit('setInvitationUser', response.invitation?.user)
          rootCommit('languages/setPackageLanguagesFromIntro', response.package_intro)

          loading('auth/isInvitationNewUser', false)

          resolve({
            isNewUser: Boolean(response.is_invitation_new_user),
            isPublicLink: Boolean(response.is_public),
            _package: response.package_intro,
          })
        })
        .catch(err => {
          /* eslint-disable-next-line camelcase */
          if (err?.response?.data?.errors?.package_id) {
            loading('auth/isInvitationNewUser', false)

            return reject(new PackageNotEnabledError())
          }

          loading('auth/isInvitationNewUser', false)

          reject(err)
        })
    })
  },
  confirmInvitation({ commit }, { token, credentials }) {
    return new Promise((resolve, reject) => {
      loading('auth/confirmInvitation', true)

      api.post(`api/invitation/${token}/connect`, credentials)
        .then(() => {
          loading('auth/confirmInvitation', false)

          resolve()
        })
        .catch(err => {
          loading('auth/confirmInvitation', false)

          reject(new Error(err?.response?.data?.errors?.password[0]))
        })
    })
  },
  putEmail({ commit }, { token, email }) {
    return new Promise((resolve, reject) => {
      loading('auth/putEmail', true)

      api.put(`api/invitation/${token}/email`, { email })
        .then(data => {
          loading('auth/putEmail', false)
          commit('setInvitationUserEmail', data?.email)

          resolve()
        })
        .catch(err => {
          loading('auth/putEmail', false)

          reject(err)
        })
    })
  },
  refreshToken({ commit, state }, token) {
    return new Promise((resolve, reject) => {
      if (state.isRefreshingToken) {
        return resolve()
      }

      loading('auth/refreshToken', true)
      commit('setIsRefreshingToken', true)

      api.post('api/auth/refresh', { token })
        .then(({ data }) => {
          const newToken = data.access_token

          commit('setToken', newToken)
          commit('setAuthorization', newToken)
          commit('setIsRefreshingToken', false)
          commit('set2faStatus', data._2fa)
          rootDispatch('requests/invokePausedRequests')

          loading('auth/refreshToken', false)

          resolve(newToken)
        })
        .catch(() => {
          loading('auth/refreshToken', false)

          tokenManager.handleExpiredToken()
          reject(new ExpiredTokenTTLError())
        })
    })
  },
  change2fa({ commit }, data) {
    loading('auth/change2fa', true)

    return new Promise((resolve, reject) => {
      api.post('api/profile/2fa', data)
        .then(() => {
          commit('set2faStatus', data?.status)

          if (!data?.status) {
            forgetAllDevices()
          }

          resolve()
        })
        .catch(err => reject(new TwoFactorAuthError(err)))
        .finally(() => loading('auth/change2fa', false))
    })
  },
  completeProfileReferral({ commit, dispatch }, { tokenReferral, userData }) {
    loading('auth/completeProfileReferral', true)

    return new Promise((resolve, reject) => {
      api.post(`api/public/referral/${tokenReferral}`, userData)
        .then(response => {
          dispatch('createConsent', response.user.user_id)
          resolve(response)
        })
        .catch(err => {
          const data = err?.response?.data
          const { error, user, errors } = data.error
          commit('setReferralErrorUser', {
            userData,
            user,
            errorPhone: errors?.phone && errors?.phone[0],
            errorEmail: (error && error === 'email already exists'),
          })
          reject(data)
        })
        .finally(() => loading('auth/completeProfileReferral', false))
    })
  },
  completeProfileReferralV2({ commit, dispatch }, { tokenReferral, userData }) {
    loading('auth/completeProfileReferral', true)

    return new Promise((resolve, reject) => {
      api.post(`api/public/v2/referral/${tokenReferral}`, userData)
        .then(response => {
          dispatch('createConsent', response.user.user_id)
          resolve(response)
        })
        .catch(err => {
          const data = err?.response?.data
          const { error, user, errors } = data.error
          commit('setReferralErrorUser', {
            userData,
            user,
            errorPhone: errors?.phone && errors?.phone[0],
            errorEmail: (error && error === 'email already exists'),
          })
          reject(data)
        })
        .finally(() => loading('auth/completeProfileReferral', false))
    })
  },
  getReferral({ commit, dispatch }, tokenReferral) {
    return new Promise((resolve, reject) => {
      loading('auth/getReferral', true)
      api.get(`api/public/referral/${tokenReferral}`)
        .then(response => {
          const { referral_user: referralUser } = response

          commit('setReferralUser', referralUser)
          dispatch('getConsentDocumentsByPackageId', referralUser.package_id)

          resolve(referralUser)
        })
        .catch(err => reject(err))
        .finally(() => loading('auth/getReferral', false))
    })
  },
  getReferralV2({ commit, dispatch }, tokenReferral) {
    return new Promise((resolve, reject) => {
      loading('auth/getReferral', true)
      api.get(`api/public/v2/referral/${tokenReferral}`)
        .then(response => {
          const { referral_user: referralUser } = response

          commit('setReferralUser', referralUser)
          dispatch('getConsentDocumentsByPackageId', referralUser.package_id)

          resolve(referralUser)
        })
        .catch(err => reject(err))
        .finally(() => loading('auth/getReferral', false))
    })
  },
  getConsentDocumentsByPackageId({ commit }, packageId) {
    return new Promise((resolve, reject) => {
      loading('auth/getConsentDocumentsByPackageId', true)
      api.get(`api/public/legal-documents/legal-notices?package_id=${packageId}`)
        .then(response => {
          const legalNotices = []
          response.forEach(legalN => {
            const itemIndex = legalNotices
              .findIndex(({ identifier }) => identifier === legalN.identifier)
            const item = legalNotices[itemIndex]
            if (item) {
              if (parseInt(item.version, 10) < parseInt(legalN.version, 10)) {
                legalNotices[itemIndex] = legalN
              }
            } else {
              // "en" -> language
              legalNotices.push({
                ...legalN.content.en,
                package_id: packageId,
                identifier: legalN.identifier,
                version: legalN.version,
              })
            }
          })
          const legalNoticesFiltered = legalNotices.filter(({ title, url }) => title && url && title !== '_')
          commit('setConsentDocuments', legalNoticesFiltered)
          resolve(legalNoticesFiltered)
        })
        .catch(err => reject(err))
        .finally(() => loading('auth/getConsentDocumentsByPackageId', false))
    })
  },
  createConsent({ state, dispatch }, userId) {
    return dispatch('acceptConsents', { userId, newConsents: state.consentDocuments })
  },
  updateConsentPolicies({ state, dispatch }, userId) {
    return dispatch('acceptConsents', { userId, newConsents: state.policiesToAccept.newConsents })
  },
  acceptConsents({ commit }, { userId, newConsents }) {
    const [first] = newConsents
    const packageId = first.package_id
    const legalNotices = []
    newConsents.forEach(doc => {
      const { identifier, version } = doc
      legalNotices.push({ identifier, version })
    })
    return new Promise((resolve, reject) => {
      api.post('api/legal-documents/consent/accept', {
        package_id: packageId,
        verified: true,
        legal_notices: legalNotices,
        user_id: userId,
      })
        .then(resolve)
        .catch(reject)
    })
  },
  getConsent() {
    return new Promise((resolve, reject) => {
      api.get('api/legal-documents/consent/myLastConsent')
        .then(resolve)
        .catch(reject)
    })
  },
  switchProgramReferral({ commit }, { tokenReferral, userData }) {
    loading('auth/switchProgramReferral', true)

    return new Promise((resolve, reject) => {
      api.post(`api/public/referral/${tokenReferral}/request_change`, userData)
        .then(resolve)
        .catch(reject)
        .finally(() => loading('auth/switchProgramReferral', false))
    })
  },
  switchProgramReferralV2({ commit }, { tokenReferral, userData }) {
    loading('auth/switchProgramReferral', true)

    return new Promise((resolve, reject) => {
      api.post(`api/public/v2/referral/${tokenReferral}/request_change`, userData)
        .then(resolve)
        .catch(reject)
        .finally(() => loading('auth/switchProgramReferral', false))
    })
  },
}
