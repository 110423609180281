import { Validator } from 'vee-validate'

const requiredRadio = {
  getMessage: () => 'Field is required.',
  validate(value) {
    return new Promise(resolve => resolve({
      valid: value !== null && value !== undefined && value !== false,
    }))
  },
}

Validator.extend('required_radio', requiredRadio)
