<template>
  <router-link
    class="anchor goal-tile-anchor"
    data-testid="goal-tile-view"
    :to="{ name: 'GoalsPreviewGoal', params: { id: goal.id } }"
  >
    <modular-tile
      class="goal-tile"
      :class="{
        'goal-tile__completed': isCompleted,
        'goal-tile__in-progress': isInProgress,
      }"
      data-testid="goal-tile"
      :headerLevel="3"
      :headerText="title"
      :showLinkToTitle="true"
    >
      <template #default>
        <p
          v-if="isInProgress"
          class="goal-tile__in-progress-text nhsuk-u-margin-bottom-4"
          data-testid="goal-tile-description"
        >
          In Progress
        </p>
        <div
          v-if="isCompleted"
          class="goal-tile__completed-text nhsuk-u-margin-bottom-4"
          data-testid="goal-tile-description"
        >
          <p>COMPLETED</p>
          <p>{{ completedDate(goal.completed_at) }}</p>
        </div>
        <p
          class="goal-tile__description"
          data-testid="goal-tile-description"
        >
          {{ description }}
        </p>
      </template>
      <template #action>
        <router-link
          class="anchor"
          data-testid="goal-tile-view"
          :to="{ name: 'GoalsPreviewGoal', params: { id: goal.id } }"
        >
          {{ title }}
        </router-link>
      </template>
    </modular-tile>
  </router-link>
</template>

<script>
  import { isNil } from 'lodash'
  import moment from 'moment'
  import { mapGetters } from 'vuex'

  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  import weightUnitConverter from '@/mixins/weight-unit-converter/weightUnitConverter'

  export default {
    components: {
      ModularTile,
    },

    mixins: [
      weightUnitConverter,
    ],

    props: {
      goal: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },

    methods: {
      weightGoalDescription(weight) {
        if (weight === '' || isNil(weight)) return ''

        const convertedWeightObj = this.convertToStonesOrPoundsIfNeeded(weight)
        if (this.isStonesAndPounds) {
          return `${convertedWeightObj.stones} st ${convertedWeightObj.pounds} lbs`
        }
        const convertedWeight = convertedWeightObj.value
        let units = 'kg'
        if (this.isPounds) {
          units = 'lbs'
        }

        return `${convertedWeight} ${units}`
      },
      completedDate(completedDate) {
        const time = moment(completedDate)
          .format('LT')
        const date = moment(completedDate)
          .format('L')
        return `${time} on ${date}`
      },
    },

    computed: {
      ...mapGetters('goals', [
        'getProfileById',
      ]),
      ...mapGetters('user', [
        'isMetric',
        'isPounds',
        'isStonesAndPounds',
      ]),
      goalProfile() {
        return this.getProfileById(this.goal.profile_id)
      },
      title() {
        return this.goalProfile?.title
      },
      isWeightGoal() {
        /* eslint-disable-next-line camelcase */
        return this.goalProfile?.template_slug === 'weight_goal'
      },
      isCompleted() {
        return this.goal.completed_at !== null
      },
      isInProgress() {
        const { completed_markable: completedMarkable } = this.goalProfile
        return this.goal.completed_at === null && completedMarkable
      },
      description() {
        if (!this.goal?.answers) return ''

        const answer = this.goal.answers[0]

        if (this.isWeightGoal) {
          return this.weightGoalDescription(answer.content[0])
        }

        return answer.content[0]
      },
    },

    slug: 'component.goals.goals-tile',
  }
</script>

<style lang="scss">
  .goal-tile-anchor {
    display: flex;
    width: 100% !important;
    text-decoration: none !important;
    margin-bottom: 2.2rem !important;
  }
  .goal-tile {
    font-size: 1.1rem;
    &__in-progress {
      .modular-tile__header {
        margin-bottom: 0 !important;
      }
      .modular-tile__content .goal-tile__in-progress-text {
        color: color(grey);
      }
    }
    &__completed {
      .modular-tile__header {
        display: flex;
        align-items: center;
        &::before {
          content: '';
          background-image: url('~@/assets/img/icon-check-circle.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 20px;
          height: 20px;
          margin-right: 1rem;
        }
      }
      .modular-tile__content .goal-tile__completed-text {
        border-radius: 5px;
        padding: 1rem;
        background-color: #F1F4F7;
        p {
          font-size: initial;
          font-weight: 600;
          color: color(dark-primary);
          &:first-of-type {
            font-weight: 700;
          }
          &:not(:first-of-type) {
            text-transform: lowercase;
          }
        }
      }
    }
    @include min-lg {
      font-size: 1.4rem;
    }
    &.modular-tile {
      margin-bottom: 0 !important;
    }
    .modular-tile {
      &__content {
        display: flex;
        flex-direction: column;
      }
    }
    &__view-goal {
      min-width: max-content;
      max-width: fit-content;
      margin: auto 0 0;
      font-size: 1.1rem;
      @include min-lg {
        font-size: 1.4rem;
      }
    }
  }
</style>
