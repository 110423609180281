<template>
  <div
    class="stepper-buttons"
    data-testid="stepper-buttons"
  >
    <base-button
      v-if="showLeftButton"
      ref="leftButton"
      light
      class="stepper-buttons__left"
      data-testid="stepper-buttons-left"
      @click="onLeftButtonClick"
    >
      {{ $t(leftButtonText) }}
    </base-button>

    <base-button
      ref="rightButton"
      :key="buttonType"
      class="stepper-buttons__right"
      data-testid="stepper-buttons-right"
      :isDisabled="isNextDisabled"
      :isLoading="isLoading"
      :type="buttonType"
      @click="onRightButtonClick"
    >
      {{ $t(rightButtonText) }}
    </base-button>
  </div>
</template>

<script>
  import { BaseButton } from '@/components/base'

  import { isFocused } from '@/helpers'

  export default {
    inheritAttrs: false,

    components: {
      BaseButton,
    },

    props: {
      cancelLabel: {
        type: String,
        default: 'Cancel',
      },
      previousLabel: {
        type: String,
        default: 'Previous',
      },
      nextLabel: {
        type: String,
        default: 'Next',
      },
      finishLabel: {
        type: String,
        default: 'Finish',
      },
      currentStep: {
        type: Number,
        required: true,
      },
      hasCancel: {
        type: Boolean,
        default: true,
      },
      isForm: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      isNextDisabled: {
        type: Boolean,
        default: false,
      },
      maxStep: {
        type: Number,
        required: true,
      },
      indexWithCancel: {
        type: Array,
        required: false,
        default: () => [],
      },
    },

    watch: {
      buttonType(type) {
        if (type === 'submit' && isFocused(this.$refs.rightButton)) {
          this.focusRightButton()
        }
      },

      isNextDisabled(isDisabled) {
        if (isDisabled && isFocused(this.$refs.rightButton)) {
          this.$emit('focus-lost')
        }
      },

      isLoading(isLoading) {
        if (!isLoading && this.focusAfterLoading) {
          this.focusAfterLoading = false
          this.focusRightButton()
        } else if (isLoading && isFocused(this.$refs.rightButton)) {
          this.focusAfterLoading = true
        }
      },
    },

    data() {
      return {
        focusAfterLoading: false,
      }
    },

    methods: {
      onLeftButtonClick() {
        this.isCancel ? this.$emit('cancel') : this.$emit('previous')
      },
      onRightButtonClick() {
        if (this.isFinish) {
          if (!this.isForm) {
            this.$emit('finish')
          }
        } else {
          this.$emit('next')
        }
      },

      focusRightButton() {
        this.$nextTick(() => this.$refs?.rightButton.focus())
      },
    },

    computed: {
      buttonType() {
        return this.isFinish && this.isForm ? 'submit' : 'button'
      },
      isCancel() {
        return this.currentStep === 0 || this.indexWithCancel.includes(this.currentStep + 1)
      },
      isFinish() {
        return this.currentStep === this.maxStep
      },
      leftButtonText() {
        return this.isCancel
          ? this.cancelLabel
          : this.previousLabel
      },
      rightButtonText() {
        return this.isFinish
          ? this.finishLabel
          : this.nextLabel
      },
      showLeftButton() {
        return this.hasCancel || !this.isCancel
      },
    },
  }
</script>

<style lang="scss">
  .stepper-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
</style>
