import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  beforeDestroy() {
    this.broadcastChannelLegalDocuments?.close()
  },

  watch: {
    getFirstLegalDocument: {
      handler(newVal, oldVal) {
        const queueWasEmptied = !newVal && !!oldVal

        if (!this.hasLegalDocumentToAccept) {
          if (queueWasEmptied && this.isLegalDialogOpen) this.closeDialog()

          // Hiding legacy legal documents on first login
          // return
        }

        // Hiding legacy legal documents on first login
        // this.openLegalDocumentDialog()
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('dialog', [
      'closeDialog',
      'openDialog',
    ]),
    async openLegalDocumentDialog() {
      await this.$nextTick()

      this.openDialog({
        component: 'LegalDocument',
        props: {
          legalDocument: { ...this.getFirstLegalDocument },
        },
      })
    },
  },

  computed: {
    ...mapState('dialog', [
      'isDialogOpen',
      'dialog',
    ]),
    ...mapState('legalDocument', [
      'broadcastChannelLegalDocuments',
    ]),
    ...mapGetters('legalDocument', [
      'getFirstLegalDocument',
      'hasLegalDocumentToAccept',
    ]),

    isLegalDialogOpen() {
      return this.isDialogOpen && this.dialog.component === 'LegalDocument'
    },
  },
}
