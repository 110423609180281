var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-form',{ref:"form",staticClass:"stepper-form",attrs:{"data-testid":"stepper-form","hasSubmitButton":false},on:{"submit":_vm.onSubmit}},[_c('stepper-custom',_vm._b({ref:"stepper",attrs:{"is-form":"","testid":_vm.dataTestid},on:{"cancel":function($event){return _vm.reemitEvent('cancel', $event)},"next":function($event){return _vm.reemitEvent('next', $event)},"previous":function($event){return _vm.reemitEvent('previous', $event)},"step-set":function($event){return _vm.reemitEvent('step-set', $event)},"step-changed":function($event){return _vm.reemitEvent('step-changed', $event)},"last-step-accessed":function($event){return _vm.reemitEvent('last-step-accessed', $event)},"focus-invalid":_vm.onFocusInvalid},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var maxStep = ref.maxStep;
return [_vm._t("default",null,null,{ maxStep: maxStep })]}}],null,true)},'stepper-custom',{
        buttonLabels: _vm.buttonLabels,
        eventCallbacks: _vm.eventCallbacks,
        hasCancel: _vm.hasCancel,
        hideStepCount: _vm.hideStepCount,
        isLoading: _vm.isLoading,
        isNextDisabled: _vm.isNextDisabled,
        validateStep: _vm.validateStep,
        displayLastButtons: _vm.displayLastButtons,
      },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }