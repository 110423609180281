import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/habits-quiz/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Habits Quiz',
      layout: 'home',
      toParent: true,
      trigger: 'habits-quiz',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'Habits',
    },
  },
  routes: [
    {
      path: '/habits-quiz',
      name: 'Habits',
      props: true,
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
