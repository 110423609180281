<script>
  export default {
    functional: true,

    props: {
      isMasked: {
        type: Boolean,
        required: true,
      },
    },

    render(h, { data, props }) {
      const label = `${props.isMasked ? 'Show' : 'Hide'} my password`

      return h('button', {
        ...data,
        staticClass: `button-masked ${data?.staticClass}`,
        attrs: {
          'aria-label': label,
          title: label,
          type: 'button',
        },
      }, [
        h('inline-svg', {
          staticClass: 'button-masked-icon',
          class: {
            'button-masked-icon--hide': !props.isMasked,
          },
          props: {
            src: 'eye.svg',
          },
        }),
      ])
    },
  }
</script>

<style lang="scss">
  .button-masked {
    z-index: z-index(content);
    &-icon {
      position: relative;
      align-self: flex-end;
      padding: 0 0 0.5rem 0;
      &:before {
        content: '';
        position: absolute;
        top: 0.5rem;
        left: 1rem;
        width: 0.3rem;
        height: 0;
        background-color: var(--color-dark-primary);
        border-right: 1px solid white;
        transform: rotate(40deg);
        transform-origin: top right;
        transition: height 0.125s;
        @include min-lg {
          top: 0.6rem;
        }
      }
      &--hide {
        &:before {
          height: 1.4rem;
        }
      }
      svg {
        width: 1.4rem;
        fill: color(dark-primary);
      }
    }
  }
</style>
