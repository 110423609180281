import VeeValidate from 'vee-validate'
import Vue from 'vue'

import dictionary from '@/validator/dictionary'
import '@/validator/custom-rules'

const validator = {
  init: i18n => {
    Vue.use(VeeValidate, {
      i18n,
      dictionary,
      delay: 5000,
    })
  },
}

export default validator
