import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/activity-quiz/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Activity Quiz',
      layout: 'home',
      toParent: true,
      trigger: 'activity-quiz',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'ActivityQuiz',
    },
  },
  routes: [
    {
      path: '/activity-quiz',
      name: 'ActivityQuiz',
      meta: {
        parent: 'Tools',
        pluginHome: true,
      },
    },
  ],
}
