<template>
  <form-group
    class="radio-multi-group"
    :data-testid="dataTestid"
    :label="labelText"
    v-bind="{
      hideErrorMessage,
      name,
      tag,
      testid,
      validation,
    }"
    v-slot="{ errors }"
  >
    <div
      :class="[
        {'nhsuk-form-group--error': errors.length}
      ]">
      <base-legend
        data-testid="checkbox-radio-group-legend"
        :aria-hidden="isLegendHidden"
        :isHidden="isLegendHidden"
        :legend="legend || labelText"
      >
        <slot name="legend" />
      </base-legend>
      <div
        class="nhsuk-hint"
        data-testid="date-input-hint">
        {{ description }}
      </div>

      <span
        class="nhsuk-error-message"
        v-if="errors[0]"
        :id="ariaDescribedby"
        >
        <span class="nhsuk-u-visually-hidden">{{ i18n('(Error)') }}</span>
        {{ errors[0] }}
      </span>
      <div
        class="radio-multi-group__wrapper"
      >
        <base-radio
          v-for="(option, i) in options"
          :key="i"
          is-multi
          :aria-invalid="!!errors.length"
          :inputValue="extractValue(option, valueKey)"
          :isFirst="i === 0"
          :isRequired="validation.required"
          :label="extractValue(option, labelKey)"
          :description="option.description || ''"
          :aria-describedby="ariaDescribedby"
          v-bind="{
            describedBy,
            isAnySelected,
            name,
          }"
          v-model="model"
        >
          <template
            #option
            slote-scope="{ option }"
          >
            <slot
              name="option"
              v-bind="{ option }"
            />
          </template>

          <template
            #default
            slote-scope="{ option }"
          >
            <slot
              v-bind="{ option }"
            />
          </template>
        </base-radio>
      </div>
    </div>
  </form-group>
</template>

<script>
  import { BaseRadio } from '@/components/base'
  import BaseLegend from '@/components/base/BaseLegend'
  import FormGroup from '@/components/forms/form-group/FormGroup'

  import addVModel from '@/mixins/addVModel'
  import i18n from '@/mixins/i18n'
  import labelText from '@/mixins/labelText'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      BaseLegend,
      BaseRadio,
      FormGroup,
    },

    mixins: [
      addVModel('model', [Number, String, Boolean], 'undefined'),
      i18n,
      labelText,
      testid(),
    ],

    props: {
      describedBy: {
        type: String,
        default: '',
      },
      hideErrorMessage: {
        type: Boolean,
        default: false,
      },
      isLegendHidden: {
        type: Boolean,
        default: false,
      },
      labelKey: {
        type: String,
        default: 'label',
      },
      legend: {
        type: String,
        required: false,
        default: '',
      },
      description: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      tag: {
        type: String,
        default: 'fieldset',
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      valueKey: {
        type: String,
        default: 'value',
      },
      label: {
        type: String,
        default: '',
      },
    },

    methods: {
      extractValue(item, key) {
        return item?.[key] ?? item
      },
    },

    computed: {
      getLegend() {
        return this.hasLegend ? this.legend : labelText
      },
      hasLegend() {
        return this.legend || 'legend' in this.$scopedSlots
      },
      isAnySelected() {
        return !!this.options.find(el => this.extractValue(el, this.valueKey) == this.model)
      },
      ariaDescribedby() {
        return `radio-error-${this.name}`
      },
    },
  }
</script>

<style lang="scss">
  .radio-multi-group {
    margin: 1.8rem 0 0;
    .input-details--has-error {
      margin-top: 1.8rem;
    }
  }
</style>
