import { api } from '@/tools/api'
import urlBase64ToUint8Array from '@/tools/url-base64-to-uint8-array'

export default function pushSubscribe() {
  navigator.serviceWorker.register('/sw.js')
    .then(registration => (
      new Promise(resolve => {
        navigator.serviceWorker.ready
          .then(() => {
            resolve(registration)
          })
      })
    ))
    .then(registration => {
      if ('safari' in window && 'pushNotification' in window.safari) return Promise.reject(new Error('safari'))

      return Promise.resolve(registration)
    })
    .then(registration => (
      registration.pushManager.getSubscription()
        .then(subscription => {
          if (subscription) {
            return subscription
          }

          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_VAPID),
          })
        })
    ))
    .then(subscription => {
      const data = {
        endpoint: subscription.endpoint,
        token: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
        auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))),
      }
      api.post('/api/notifications/push/subscribe', data)
    })
    .catch(e => {
      if ('Notification' in window) {
        if (Notification.permission !== 'denied' && e?.message !== 'safari') throw e
      }
    })
}
