const bemConstructor = block => (block
  ? _constructFullBemChain.bind(this, block)
  : _constructFullBemChain
)

const _constructFullBemChain = (block, element = '', modifier = '') => {
  if (!block) throw new Error('Block is required')
  if (!modifier.length) return _constructBem(block, element)

  const modifierArray = (Array.isArray(modifier)) ? modifier : [modifier]

  return `${_constructBem(block, element)} ${_constructBemModifiers(block, element, modifierArray)}`
}

const _constructBemModifiers = (block, element = '', modifiers) => modifiers
  .filter(item => !!item)
  .map(item => _constructBem(block, element, item))
  .join(' ')

const _constructBem = (block, element = '', modifier = '') => {
  const elementFragment = element ? `__${element}` : ''
  const modifierFragment = modifier ? `--${modifier}` : ''

  return `${block}${elementFragment}${modifierFragment}`
}

export default bemConstructor
