import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/reflecting-quiz/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Reflecting on Walking Experiences',
      layout: 'home',
      toParent: true,
      trigger: 'reflecting-quiz',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'ReflectingResults',
    },
  },
  routes: [
    {
      path: '/reflecting-quiz',
      name: 'Reflecting',
      meta: {
        parent: 'Tools',
      },
    },
    {
      path: '/reflecting-quiz/quiz',
      name: 'ReflectingQuiz',
      props: true,
      meta: {
        parent: 'Reflecting',
      },
    },
    {
      path: '/reflecting-quiz/results',
      name: 'ReflectingResults',
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
