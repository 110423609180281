<template>
  <div
    class="home-single-notification"
    :data-testid="dataTestid"
  >
    <img
      v-if="getDataValue('icon')"
      alt=""
      class="home-single-notification__image"
      :data-testid="`${dataTestid}-image`"
      :src="getDataValue('icon')"
      :onerror="`this.onerror=null; this.src='${getOnErrorImage}'`"
    >

    <div class="home-single-notification__text">
      <h3
        class="home-single-notification__text--title"
        :data-testid="`${dataTestid}-title`"
      >
        {{ getDataValue('title') }}
      </h3>
      <p
        class="home-single-notification__text--description"
        :data-testid="`${dataTestid}-description`"
      >
        {{ getDataValue('description') }}
      </p>
      <component
        :is="cta.element"
        v-if="getDataValue('action')"
        class="home-single-notification__text--link"
        :data-testid="`${dataTestid}-cta-button`"
        v-bind="cta.link"
      >
        {{ getDataValue('action_label') }}
      </component>
    </div>
    <button
      class="home-single-notification__close-button"
      :aria-label="$t('Dismiss notification', { title: getDataValue('title') })"
      :data-testid="`${dataTestid}-close-button`"
      @click="onClose"
      @keydown.exact.tab="$emit('tabOut', $event)"
      @keydown.shift.tab="$emit('shiftTabOut', $event)"
    >
      <inline-svg
        class="home-single-notification__close"
        :class="{ 'home-single-notification__close--disabled': isPending }"
        src="plus.svg"
      />
    </button>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import imgCoachingDefault from '@/assets/img/coaching-notification-default.jpeg'
  import testid from '@/mixins/testid/testid'

  export default {
    mixins: [
      testid('single-notification'),
    ],

    props: {
      notification: {
        type: Object,
        required: true,
      },
    },

    methods: {
      ...mapActions('notifications', [
        'markAsRead',
      ]),
      onClose() {
        if (!this.closePending) {
          this.markAsRead(this.notification.id)
        }
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isPending() {
        return this.getLoadingStatesForActions([
          `notifications/markAsRead/${this.notification.id}`,
        ])
      },
      notificationData() {
        return this.notification?.data
      },
      getDataValue() {
        return key => this.notificationData?.[key]
      },
      getOnErrorImage() {
        const icon = this.getDataValue('icon')
        if (this.getDataValue('action') === 'coaching') {
          return imgCoachingDefault
        }
        return icon
      },
      isExternalUrl() {
        return this.notificationData?.['external_url'] === '1'
      },
      cta() {
        return this.isExternalUrl
          ? { element: 'a', link: { href: this.getDataValue('action'), target: '_blank' } }
          : { element: 'router-link', link: { to: this.getDataValue('action') } }
      },
    },
  }
</script>

<style lang="scss">
  .home-single-notification {
    display: flex;
    &__image {
      flex: 0;
      min-width: 6.1rem;
      height: 6.1rem;
      border-radius: 5rem;
      object-fit: cover;
    }
    &__text {
      flex-direction: column;
      width: 100%;
      margin: 0 1.5rem 0 1rem;
      text-align: left;
      word-break: break-word;
      @include min-sm {
        margin-right: 2.5rem;
      }
      @include min-lg {
        margin-right: 6rem;
      }
      &--description {
        padding: 0;
        font-size: 1.3rem;
        @include min-sm {
          font-size: 1.4rem;
        }
      }
      &--link {
        display: block;
        margin-top: 0.4rem;
        text-decoration: underline;
      }
      &--title {
        margin: 0 0 0.5rem 0;
        color: color(primary);
      }
    }
    &__close-button {
      display: flex;
    }
    &__close {
      display: flex;
      align-items: flex-start;
      align-self: flex-start;
      font-size: 4rem;
      line-height: 1;
      cursor: pointer;
      svg {
        width: 1.6rem;
        transform: rotate(45deg);
        stroke: color(grey);
        stroke-miterlimit: 10;
        stroke-width: 4px;
      }
      &--disabled {
        cursor: default;
        opacity: 0.5;
      }
    }
    &__text-title {
      font-size: 1.4rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
</style>
