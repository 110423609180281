export default () => ({
  topics: null,
  categories: null,
  categoriesItemsIds: null,
  categoriesItemsOrder: null,
  category: null,
  categoryItemsIds: null,
  categoryItemsOrder: null,
  item: null,
  contentDetails: {},
})
