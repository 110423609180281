import BroadcastChannel from 'broadcast-channel'

import { traverseModules } from './helpers'

export default store => {
  store.subscribe(mutation => {
    const { type } = mutation

    if ((type == 'auth/setAuthorization'
      || type == 'auth/setToken')
      && store.state.auth.token) {
      traverseModules(
        store._modules,
        store,
        'broadcastChannel',
        BroadcastChannel,
      )
    }
  })
}
