import { render, staticRenderFns } from "./JourneyArticle.vue?vue&type=template&id=46022150&"
import script from "./JourneyArticle.vue?vue&type=script&lang=js&"
export * from "./JourneyArticle.vue?vue&type=script&lang=js&"
import style0 from "./JourneyArticle.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports