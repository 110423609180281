export default () => ({
  triggers: [],
  updating: [],
  routes: {
    goal: 'GoalsView',
    goals: 'GoalsView',
    'goal-plugin': 'GoalsView',
    coaching: 'CoachingPlugin',
    'coaching-plugin': 'CoachingPlugin',
    'step-tracking': 'StepTracking',
    'weight-tracking': 'WeightTracking',
    'food-tracking': 'FoodTracking',
    'blood-glucose-tracking': 'GlucoseTracking',
    'carb-calculator': 'CarbCalculator',
    'shopping-list-switcher': 'ShoppingListSwitcher',
    'activity-quiz': 'ActivityQuiz',
    'risk-calculator': 'RiskCalculator',
    'face-age-test': 'FaceAgeTest',
    'reading-room': 'ReadingRoom',
    'getting-confident-quiz': 'GettingConfident',
    'tricky-situations-quiz': 'TrickySituations',
    'reflecting-quiz': 'Reflecting',
    'winning-against-temptations': 'Temptations',
    'getting-support-quiz': 'GettingSupport',
    'setting-the-strongest-plan': 'SettingTheStrongestPlan',
    'maintenance-plan': 'MaintenancePlan',
    'reasons-to-lose-weight': 'ReasonsToLoseWeight',
    'capturing-bmi': 'CapturingBMI',
    'power-review': 'WeeklyReviewIntro',
    'eq-5d-health-questionnaire': 'Eq5d',
    'duk-risk-calculator': 'DukRiskCalculator',
    'habits-quiz': 'Habits',
    'initial-assessment': 'InitialAssessment',
  },
  quickActions: {
    goal: { label: 'View Goals', to: { name: 'GoalsView' } },
    goals: { label: 'View Goals', to: { name: 'GoalsView' } },
    'goal-plugin': { label: 'View Goals', to: { name: 'GoalsView' } },
    coaching: { label: 'New Appointment', to: { name: 'CoachingPluginNewAppointment' } },
    'coaching-plugin': { label: 'New Appointment', to: { name: 'CoachingPluginNewAppointment' } },
    'step-tracking': { label: 'Log Steps / Activity', to: { name: 'StepTrackingLog' } },
    'weight-tracking': { label: 'Log Weight', to: { name: 'WeightTracking', params: { logWeight: true } } },
    'food-tracking': { label: 'Log Food', to: { name: 'FoodTrackingNew' } },
    'carb-calculator': { label: 'Carb Calculator', to: { name: 'CarbCalculator' } },
    'shopping-list-switcher': { label: 'Shopping List Switcher', to: { name: 'ShoppingListSwitcher' } },
    'activity-quiz': { label: 'Activity Quiz', to: { name: 'ActivityQuiz' } },
    'risk-calculator': { label: 'Risk Calculator', to: { name: 'RiskCalculator' } },
    'face-age-test': { label: 'Face Age Test', to: { name: 'FaceAgeTest' } },
    'reading-room': { label: 'Reading Room', to: { name: 'ReadingRoom' } },
    'getting-confident-quiz': { label: 'Getting Confident Quiz', to: { name: 'GettingConfident' } },
    'tricky-situations-quiz': { label: 'Tricky Situations', to: { name: 'TrickySituations' } },
    'reflecting-quiz': { label: 'Reflecting on Walking Experiences', to: { name: 'ReflectingQuiz' } },
    'winning-against-temptations': { label: 'Winning Against Temptations', to: { name: 'TemptationsQuiz' } },
    'getting-support-quiz': { label: 'Getting Support Quiz', to: { name: 'GettingSupport' } },
    'setting-the-strongest-plan': { label: 'Setting The Strongest Plan', to: { name: 'SettingTheStrongestPlan' } },
    'maintenance-plan': { label: 'Maintenance Plan', to: { name: 'MaintenancePlan' } },
    'reasons-to-lose-weight': { label: 'Reasons To Lose Weight', to: { name: 'ReasonsToLoseWeight' } },
    'capturing-bmi': { label: 'Capturing BMI', to: { name: 'CapturingBMI' } },
    'power-review': { label: 'Power Review', to: { name: 'WeeklyReviewIntro' } },
    'eq-5d-health-questionnaire': { label: 'EQ-5D Health Questionnaire', to: { name: 'Eq5d' } },
    'duk-risk-calculator': { label: 'DUK Risk Calculator', to: { name: 'DukRiskCalculator' } },
    'habits-quiz': { label: 'Habits Quiz', to: { name: 'Habits' } },
    'initial-assessment': { label: 'Initial Assessment', to: { name: 'InitialAssessment' } },
  },
  descriptions: {
    goal: 'Update goals and action plans for your weight, food, activity and lifestyle change goals.',
    goals: 'Update goals and action plans for your weight, food, activity and lifestyle change goals.',
    'goal-plugin': 'Update goals and action plans for your weight, food, activity and lifestyle change goals.',
    coaching: 'Book and manage appointments, and view content selected for you by your coach.',
    'coaching-plugin': 'Book and manage appointments, and view content selected for you by your coach.',
    'step-tracking': 'Log your daily steps, view progress graphs, and set or adjust your step goals.',
    'weight-tracking': 'Log your weight, view progress graphs, and set or adjust your weight goals.',
    'food-tracking': 'Log and label the foods you eat, and set or adjust your food goals.',
    'carb-calculator': 'Interested in Low Carb eating? Find out how many carbs you eat on a typical day.',
    'shopping-list-switcher': 'Discover healthier alternatives to some of your favourite high-carb foods.',
    'activity-quiz': 'How much do you really know about physical activity? Take our quiz and find out!',
    'risk-calculator': 'Find out how diabetes risk level is calculated, and which risk factors you can manage.',
    'face-age-test': '',
    'reading-room': '',
    'getting-confident-quiz': '',
    'tricky-situations-quiz': '',
    'reflecting-quiz': '',
    'winning-against-temptations': '',
    'getting-support-quiz': '',
    'setting-the-strongest-plan': '',
    'maintenance-plan': '',
    'reasons-to-lose-weight': '',
    'capturing-bmi': '',
    'power-review': '',
    'eq-5d-health-questionnaire': '',
    'duk-risk-calculator': 'Find out how diabetes risk level is calculated, and which risk factors you can manage.',
    'habits-quiz': '',
    'initial-assessment': '',
  },
})
