<template>
  <base-main
    data-testid="search-results"
    :loading="$options.loading"
    :reloading="$options.loading"
  >
    <div class="search-results">
      <simple-tile
        class="search-results__content"
        :direction="'column'"
      >
        <div aria-live="polite">
          <h2
            data-testid="search-results-heading"
          >
            {{ i18n('search-results') }}
            <b> '{{ query }}'</b>
          </h2>
          <screen-readers-only>
            {{ i18n('currently-on-page', { page }) }}
          </screen-readers-only>
        </div>

        <span
          v-if="hasAnyResults && !firstLoad"
          class="search-results__count"
          data-testid="search-results-count"
        >
          {{ totalResults }} {{ i18n('results-found') }}
        </span>

        <div
          data-testid="search-results-results"
          role="table"
        >
          <screen-readers-only role="rowgroup">
            <div role="row">
              <span
                aria-sort="none"
                role="columnheader"
              >
                {{ i18n('results-item') }}
              </span>
              <span
                aria-sort="none"
                role="columnheader"
              >
                {{ i18n('results-category') }}
              </span>
            </div>
          </screen-readers-only>
          <search-results-item
            v-for="(item, i) in searchesByPage"
            :key="i"
            role="rowgroup"
            v-bind="{ ...item, query }"
          />
        </div>

        <div
          v-if="!hasAnyResults"
          aria-live="polite"
          class="search-results__not-found"
          data-testid="search-results-not-found"
        >
          {{ i18n('nothing-found') }}
        </div>

        <base-pagination
          v-if="hasAnyResults && !firstLoad && !isAllResults"
          :aria-label="i18n('article-navigation')"
          v-bind="paginationData"
          @pageChanged="changePage"
          @viewAllResults="viewAllResults"
        />
      </simple-tile>
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import {
    BaseMain,
    BasePagination,
  } from '@/components/base'
  import ScreenReadersOnly from '@/components/screen-readers-only/ScreenReadersOnly'
  import SearchResultsItem from '@/components/search/SearchResultsItem'
  import SimpleTile from '@/components/tiles/simple-tile'

  import i18n from '@/mixins/i18n'
  import requireImage from '@/mixins/requireImage'

  export default {
    metaInfo() {
      const { query } = this

      return {
        title: this.$t('views.search-results.meta.title', { query }),
      }
    },

    components: {
      BaseMain,
      BasePagination,
      ScreenReadersOnly,
      SearchResultsItem,
      SimpleTile,
    },

    mixins: [
      i18n,
      requireImage,
    ],

    props: {
      query: {
        type: String,
        required: true,
      },
      page: {
        type: [Number, String],
        default: '1',
      },
    },

    watch: {
      currentProgramId: 'search',
      query: {
        handler() {
          this.search()
        },
        immediate: true,
      },
      page: {
        handler(page) {
          if (!this.isAllResults && this.searchResults.length > 0) {
            this.fetchContentArticlesByPage(page)
          }
        },
        immediate: true,
      },
      isLastLoading: {
        handler(isLastLoading) {
          if (!isLastLoading) {
            this.fetchContentArticlesByPage(this.page)
          }
        },
        immediate: true,
      },
    },

    data() {
      return {
        itemsPage: 10,
        itemsSearchFirstLoad: 30,
        isAllResults: false,
      }
    },

    methods: {
      ...mapActions('search', [
        'findArticlesByPhaseAndTotalItems',
        'fetchContentArticlesSearch',
      ]),
      search() {
        this.findArticlesByPhaseAndTotalItems({
          page: 1,
          perPage: this.itemsSearchFirstLoad,
          programId: this.currentProgramId,
          query: this.query,
        })
        this.isAllResults = false
        this.itemsPage = 10
      },
      getIcon(icon) {
        return icon || this.requireImage('birb.jpg')
      },
      getArticleLink({ article_id: articleId }) {
        return {
          name: 'SearchArticleView',
          params: {
            id: articleId,
          },
        }
      },
      getReadingRoomLink({ table_optional_content_id: categoryId, table_id: id }) {
        return {
          name: 'ReadingRoomArticle',
          params: {
            categoryId,
            id,
          },
        }
      },
      getLink(data) {
        if (this.isReadingRoomSource(data.source)) {
          return this.getReadingRoomLink(data)
        }

        return this.getArticleLink(data._source)
      },
      changePage(page) {
        this.$router.push({
          params: {
            page,
          },
        })
      },
      fetchContentArticlesByPage(page) {
        const firstItemPosition = (page - 1) * this.itemsPage
        const lastPosition = firstItemPosition + this.itemsPage
        const fetchItems = this.searchResults.slice(firstItemPosition, lastPosition)
        this.fetchContentArticlesSearch({ items: fetchItems, firstItemPosition, lastPosition })
      },
      getContent(data) {
        return data._source?.content
      },
      isReadingRoomSource(source) {
        return source == 'reading_room'
      },
      viewAllResults() {
        this.isAllResults = true
        this.itemsPage = this.totalResults
        if (parseInt(this.page, 10) !== 1) {
          this.$router.push({
            params: {
              page: 1,
            },
          })
        }

        this.fetchContentArticlesSearch({
          items: this.searchResults,
          firstItemPosition: 0,
          lastPosition: this.searchResults.length,
        })
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapState('layout', [
        'wW',
      ]),
      ...mapState('search', [
        'searchResults',
        'firstLoad',
      ]),
      isMobile() {
        return this.wW < 768
      },
      isLastLoading() {
        return this.getLoadingStatesForActions(['search/findArticlesByPhaseAndTotalItems'])
      },
      hasAnyResults() {
        return this.searchResults.length
      },
      paginationData() {
        return {
          firstPage: 1,
          lastPage: Math.round(this.totalResults / this.itemsPage),
          currentPage: parseInt(this.page, 10),
          totalResults: this.totalResults,
          itemsPage: this.itemsPage,
        }
      },
      totalResults() {
        return this.searches.length
      },
      searches() {
        const results = this.searchResults.map(result => ({
          title: result._source.title,
          key: result._id,
          content: result.content || this.getContent(result),
          type: result._source?.type,
          source: result.source,
          icon: this.getIcon(result._source.icon),
          articleLink: this.getLink(result),
          isActive: result.accessed || this.isReadingRoomSource(result.source),
        }))

        return results
      },
      searchesByPage() {
        if (!this.searches || this.searches.length === 0) {
          return
        }
        const firstItemPosition = (this.page - 1) * this.itemsPage
        const lastPosition = firstItemPosition + this.itemsPage
        return this.searches.slice(firstItemPosition, lastPosition)
      },
    },

    loading: [
      'search/findArticlesFirstPhase',
    ],

    slug: 'views.search-results',
  }
</script>

<style lang="scss">
  .search-results {
    width: 100%;
    @include min-lg {
      padding-top: 3rem;
      width: 92.2rem;
    }
    &__content {
      padding: 1rem;
      & h2 {
        margin: 1rem 0;
        font-weight: 400;
      }
    }
    &__count {
      margin: 0 0 1.5rem;
    }
    &__item {
      margin: 1rem 0 0;
      display: flex;
      justify-content: space-between;
    }
    &__content {
      display: flex;
    }
    &__not-found {
      padding: 1rem 0;
      font-size: 1.6rem;
      color: color(secondary-text);
      @include min-lg {
        padding: 3rem 0;
      }
    }
  }
</style>
