import moment from 'moment'

/* eslint-disable camelcase */
export default {
  getTriggerByName: state => name => (
    state.triggers?.find(trigger => trigger.slug.includes(name))
  ),
  getTriggersByStatus: state => (
    state.triggers
      ?.filter(({ display }) => display !== 3)
      .reduce((acc, curr) => {
        const type = `${curr.unlocked ? 'un' : ''}locked`

        acc[type].push(curr)

        return acc
      }, { locked: [], unlocked: [] }) || {}
  ),
  getTriggersByType: state => {
    // display: 1 = big, 2 = small, 3 = hidden
    const big = state.triggers?.filter(trigger => trigger.display == 1)
    const small = state.triggers?.filter(trigger => trigger.display == 2)
    return { big, small }
  },
  getTriggerDataIdByPluginName: (state, getters) => name => (
    getters.getTriggerByName(name).triggerdata_id
  ),
  getTriggerPluginIdByPluginName: (state, getters) => name => (
    getters.getTriggerByName(name)?.plugin.id
  ),
  getTriggerBySlugs: (state, getters) => slugs => (
    slugs.map(slug => getters.getTriggerBySlug(slug))
      .filter(Boolean)
      ?.[0]
  ),
  getTriggerBySlug: state => slug => (
    state.triggers?.find(trigger => trigger?.slug?.includes(slug))
  ),
  getTriggerRouteBySlug: state => slug => state.routes?.[slug],
  getTriggerRouteByPluginId: (state, getters) => id => {
    const trigger = state.triggers.find(t => t.plugin_id == id)
    if (!trigger) return null

    const { slug } = trigger
    return getters.getTriggerRouteBySlug(slug)
  },
  getTriggerByPluginId: state => id => (
    state.triggers?.find(trigger => trigger.plugin_id == id)
  ),
  getTriggerSettingsByName: state => name => (
    state?.triggers.find(trigger => trigger.slug.includes(name))?.plugin
  ),
  getTriggerSlugByPluginId: state => id => (
    state.triggers?.find(trigger => trigger.plugin_id == id)?.slug
  ),
  getTriggerUnlockDateByPluginName: (state, getters) => name => (
    moment(getters.getTriggerByName(name)?.unlocked_at).format('YYYY-MM-DD')
  ),
  hasTriggers: state => state?.triggers?.length,
  getUnlockedTriggers: (state, getters) => getters.getTriggersByStatus?.unlocked,
  getLockedTriggers: (state, getters) => getters.getTriggersByStatus?.locked,
  isTriggerUpdating: state => id => state.updating.find(v => v.toString() === id.toString()),
}
/* eslint-enable camelcase */
