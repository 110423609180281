class UnauthorizedError extends Error {
  constructor() {
    super(
      `It appears we have a problem authorising your request. Please contact Changing Health support for assistance
      at <a href='mailto:customersupport@changinghealth.com'>
      customersupport@changinghealth.com</a>`,
    )

    this.name = 'UnauthorizedError'
    this.title = 'Problem with authorization'
  }
}

export default UnauthorizedError
