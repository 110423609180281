import { colors } from '@/assets/styles/utils/variables/colors.json'

export default {
  colors: state => {
    const fallback = Object.keys(colors)
                      .reduce((acc, curr) => ({ ...acc, [`color-${curr}`]: colors[curr] }), {})

    return { ...fallback, ...state.programColors }
  },
}
