import { PackageNotEnabledError } from '@/extensions/error'

import logoutAndOpenDialog from '@/helpers/logoutAndOpenDialog'

import loading from '@/store/helpers/loading'
import rootCommit from '@/store/helpers/rootCommit'

import { api } from '@/tools/api'

export default {
  fetchProgram({ commit, getters }, id) {
    return new Promise((resolve, reject) => {
      if (getters.isProgramFetched) {
        return resolve()
      }
      loading('program/fetchProgram', true)
      api.get(`api/programs/${id}/open`)
        .then(response => {
          if (!response.program) {
            logoutAndOpenDialog('PackageNotEnabled')
            loading('program/fetchProgram', false)

            return reject(new PackageNotEnabledError())
          }

          const {
            colors,
            blocked_plugin: blockedPlugin,
            ...program
          } = response.program

          commit('setProgram', program)
          rootCommit('blockedPlugin/setBlockedPluginConditionally', blockedPlugin)
          rootCommit('colors/setProgramColors', colors)

          loading('program/fetchProgram', false)

          resolve()
        })
        .catch(() => {
          loading('program/fetchProgram', false)

          reject()
        })
    })
  },
  changeCurrentProgram({ commit }, data) {
    commit('flagProgramUpToDate', false)

    return new Promise((resolve, reject) => {
      loading('program/changeCurrentProgram', true)

      api.post('api/user/package/current-program', data)
        .then(response => {
          rootCommit('_package/setPackage', response)

          resolve()
        })
        .catch(reject)
        .finally(() => loading('program/changeCurrentProgram', false))
    })
  },
  fetchNextSection({ commit }, { programId, sectionKey }) {
    return new Promise((resolve, reject) => {
      if (!programId || !sectionKey) return reject()

      loading('program/fetchNextSection', true)
      api.get(`api/programs/${programId}/next/${sectionKey}`)
        .then(response => {
          commit('mergeProgram', response)
          resolve()
        })
        .catch(reject)
        .finally(() => loading('program/fetchNextSection', false))
    })
  },
}
