<template>
  <base-form
    ref="form"
    class="profile-form"
    :showSummaryErrors="true"
    v-bind="{ hasSubmitButton, isSaving }"
    @submit="onSubmit"
  >

    <div
      v-if="isProfile"
      class="profile-form__avatar"
      :data-testid="`${dataTestid}-avatar`"
    >
      <span
        class="profile-form__avatar-heading"
        data-testid="`${dataTestid}-avatar-heading`"
      >
        {{ $t('Profile image') }}
      </span>

      <image-group
        vertical
        :alt="$t('Profile image')"
        :captureImageText="$t(`${$options.slug}.capture-profile-image`)"
        :imageUrl="avatarSource"
        :name="'avatar'"
        :uploadImageText="$t(`${$options.slug}.upload-profile-image`)"
        :validation="$options.validation.avatar"
        v-model="form.avatar"
        @change="onImageChange"
        tag="div"
      />
    </div>
    <div v-if="isProfile">
      <base-button
        data-testid="`${dataTestid}-change-password`"
        @click="openChangePassword"
      >
        {{ $t('Change password') }}
      </base-button>
    </div>
    <input-group
      class="no-margin"
      label="First name"
      type="text"
      name="first_name"
      :autocomplete="'given-name'"
      :validation="$options.validation.first_name"
      v-model="form.first_name"
    />
    <input-group
      label="Last name"
      type="text"
      name="last_name"
      :autocomplete="'family-name'"
      :validation="$options.validation.last_name"
      v-model="form.last_name"
    />
    <input-group
      label="Email address"
      type="email"
      name="email"
      :autocomplete="'email'"
      :tooltip="$t(newEmailHelper, { email: user.new_email })"
      :validation="$options.validation.email"
      v-model="form.email"
    />

    <p
      v-if="newEmail"
      class="profile-form__field-description"
      :data-testid="`${dataTestid}-confirm-email`"
    >
      [{{ newEmail }}
      {{ $t(`${$options.slug}.confirm-email`) }}]
    </p>

    <validate-password
      v-if="isCompleteProfile"
      label="Set a password"
      :firstName="form.first_name"
      :lastName="form.last_name"
      :email="form.email"
      @validPassword="(password) => form.password = password"
    />

    <input-autocomplete-group
      :label="$t('Timezone')"
      type="text"
      name="timezone"
      id="autocomplete-timezone"
      v-model="form.timezone"
      :validation="$options.validation.timezone"
      :options="optionsTimezone"
      :defaultValue="defaultTimezone" />

    <phones-group
      class="profile-form__phones"
      :isAnyRequired="false"
      :mobile.sync="form.phone"
      :landline.sync="form.landline_phone"
      :validationMobile="$options.validation.mobile"
      :validationLandline="$options.validation.landline"
      v-bind="{ ...phonesGroupLabels }"
    />

    <date-input-group
      v-if="isProfile"
      class="nhsuk-u-padding-top-3"
      required
      label="Date of birth"
      name="date"
      :validation="$options.validation.birthdate"
      :format="'YYYY-MM-DD'"
      v-model="form.birthdate" />

    <date-input-group
      v-else
      class="nhsuk-u-padding-top-3"
      required
      label="Date of birth"
      name="date"
      :validation="$options.validation.birthdate"
      :format="'YYYY-MM-DD'"
      v-model="form.date" />

    <template
      v-if="isCompleteProfile"
    >
      <checkbox-group
        class="nhsuk-u-margin-top-3 checkbox-group"
        id="legal-document-accept"
        :label="'I agree to'"
        :name="'accept'"
        :testid="'dialog-legal-document'"
        :validation="$options.validation.agree"
        v-model="form.agree"
      />
      <ul class="nhsuk-list nhsuk-list--bullet nhsuk-u-margin-bottom-0">
        <li
          v-for="(consent, i) in consentDocuments"
          :key="i">
          <a
            :href="consent.url"
            class="anchor"
            target="_blank"
            >{{ consent.title }}</a>
        </li>
      </ul>

      <checkbox-group
        v-if="form.isAversaPackageId"
        class="nhsuk-u-margin-top-3 checkbox-group"
        id="legal-document-accept"
        label="I confirm I've read the patient <a
            href='https://aversa.changinghealth.com/#patient-information-section'
            class='anchor nhsuk-u-font-weight-normal'
            target='_blank'
            >information page</a>"
        :name="'gp'"
        :testid="'gp'"
        :validation="$options.validation.gpCheckbox"
        v-model="form.gp"
      />
      <radio-multi-group
        v-if="form.isHealthyLivingPackageId"
        class="nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-1"
        :legend="$t('Can we tell your GP you have used Healthy Living?')"
        :description="$t('This will help them keep your health record up to date.')"
        :name="'gp'"
        :options="$static.gp"
        :validation="$options.validation.gp"
        v-model="form.gp"
      />
    </template>

    <radio-multi-group
      :legend="$t('Log measurements as:')"
      :name="'measurements'"
      :options="$static.measurements"
      :validation="$options.validation.measurements"
      v-model="form.distance_unit"
    />

    <template
      v-if="isProfile"
    >
      <div class="profile-form__2fa">
        <base-checkbox
          class="profile-form__2fa-checkbox"
          :descriptionId="'profile-form-2fa-desc'"
          :inputValue="1"
          :label="'Enable 2 Factor Authentication'"
          :name="'two-factor'"
          :value="is2fa"
          :data-testid="`${dataTestid}-2fa-input`"
          @click.native.prevent="openUser2faDialog"
          @keydown.space.enter.native.prevent="openUser2faDialog"
        />
        <p
          id="profile-form-2fa-desc"
          class="profile-form__2fa-description"
          :data-testid="`${dataTestid}-2fa-description`"
        >
          {{ $static.checkbox2faDescription }}
        </p>
      </div>

      <div
        v-if="optionalData"
        class="profile-form__opd"
        :data-testid="`${dataTestid}-opd`"
      >
        <accordion
          is-full-width
          v-for="(data, i) in filteredOptionalData"
          :key="i"
          :title="data.category.name"
        >
          <optional-profile-data-field
            v-for="(item, j) in data.items"
            :key="j"
            :isRequired="item.value !== null"
            :hasLegend="true"
            v-bind="{ item }"
            v-model="formOptionalData[data.category.slug][item.key]"
          />
        </accordion>

      </div>

      <div>
        <base-button
          danger
          data-testid="`${dataTestid}-delete-account`"
          @click="openDeleteAccount"
        >
          {{ $t('Delete account') }}
        </base-button>
      </div>
    </template>

    <slot/>
  </base-form>
</template>

<script>
  import { isNil } from 'lodash'
  import moment from 'moment'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import Accordion from '@/components/accordion/Accordion'
  import {
    BaseButton,
    BaseCheckbox,
    BaseForm,
  } from '@/components/base'
  import CheckboxGroup from '@/components/forms/checkbox-group/CheckboxGroup'
  import DateInputGroup from '@/components/forms/date-input-group/DateInputGroup'
  import ImageGroup from '@/components/forms/image-group/ImageGroup'
  import InputAutocompleteGroup from '@/components/forms/input-autocomplete-group/InputAutocompleteGroup'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import PhonesGroup from '@/components/forms/phones-group/PhonesGroup'
  import { RadioMultiGroup } from '@/components/forms/radio'
  import ValidatePassword from '@/components/forms/validate-password/ValidatePassword'
  import { OptionalProfileDataField } from '@/components/optional-profile-data'

  import { DATE_FORMATS } from '@/constants'

  import twoFactorStatics from '@/data/auth/2fa'

  import scrollToTop from '@/mixins/scrollToTop'
  import testid from '@/mixins/testid/testid'

  const { checkboxDescription: checkbox2faDescription } = twoFactorStatics

  export default {
    components: {
      Accordion,
      BaseButton,
      BaseCheckbox,
      BaseForm,
      ImageGroup,
      InputGroup,
      OptionalProfileDataField,
      PhonesGroup,
      RadioMultiGroup,
      DateInputGroup,
      CheckboxGroup,
      InputAutocompleteGroup,
      ValidatePassword,
    },

    mixins: [
      testid('profile-form'),
      scrollToTop,
    ],

    props: {
      hasEmailChanged: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasSubmitButton: {
        type: Boolean,
        default: true,
      },
      isSuccess: {
        type: Boolean,
        required: false,
        default: false,
      },
      showSnackbar: {
        type: Boolean,
        requried: false,
        default: false,
      },
      method: {
        type: String,
        requried: false,
        default: undefined,
      },
      error: {
        type: Object,
        default: null,
      },
      user: {
        type: Object,
        default: () => ({}),
      },
      isProfile: {
        type: Boolean,
        default: true,
      },
      isCompleteProfile: {
        type: Boolean,
        default: false,
      },
      isSaving: {
        type: Boolean,
        required: false,
        default: false,
      },
      optionalData: {
        type: [Object, Boolean],
        required: false,
        default: false,
      },
    },

    mounted() {
      this.form._method = this.method
      this.init()
    },

    watch: {
      user: {
        handler() {
          if (!this.user) return

          this.fillInForm()
        },
        immediate: true,
      },
      optionalData: {
        handler() {
          if (!this.optionalData) return

          this.fillFormOptionalData()
        },
        immediate: true,
      },
      showSnackbar() {
        if (!this.showSnackbar) return

        this.addSnackbar({ message: this.snackbarMessage })

        if (!this.hasEmailChanged) return

        this.form.email = this.user.email
        this.openChangeEmailDialog(this.user.new_email)
      },
      method(v) {
        this.form._method = v
      },
    },

    data() {
      return {
        DATE_FORMATS,
        defaultTimezone: 'Europe/London',
        avatarSavedPreview: '',
        avatarUploadedPreview: '',
        form: {
          _method: this.method,
          avatar: '',
          birthdate: {},
          date: {},
          distance_unit: '',
          email: '',
          first_name: '',
          landline_phone: '',
          last_name: '',
          new_email: '',
          phone: '',
          timezone: '',
          agree: false,
          gp: '',
          password: '',
          isPasswordValid: false,
        },
        formOptionalData: {},
        optionsTimezone: [],
      }
    },

    methods: {
      ...mapActions('dialog', [
        'openDialog',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      ...mapActions('timezone', [
        'getTimezones',
      ]),
      openChangeEmailDialog(newEmail) {
        this.openDialog({ component: 'UserEmailChange', props: { newEmail } })
      },
      openDeleteAccount() {
        this.$router.push({ name: 'DeleteAccountView' })
      },
      openChangePassword() {
        this.$router.push({ name: 'ChangePasswordView' })
      },
      fillFormOptionalData() {
        this.formOptionalData = Object.values(this.optionalData)
          .reduce((acc, { category, items }) => (
            Object.assign(acc, {
              [category.slug]: Object.values(items)
                .reduce((acc1, { key, value }) => (
                  Object.assign(acc1, {
                    [key]: value,
                  })
                ), {}),
            })
          ), {})
      },
      onSubmit(isValid) {
        let isPasswordValid = this.isCompleteProfile ? !!this.form.password : true

        if (this.isCompleteProfile) {
          const passwordToCompare = this.form.password.toLowerCase()
          const firstNameToCompare = this.form.first_name.toLowerCase()
          const lastNameToCompare = this.form.last_name.toLowerCase()
          isPasswordValid = isPasswordValid && !passwordToCompare.includes(firstNameToCompare)
          isPasswordValid = isPasswordValid && !passwordToCompare.includes(lastNameToCompare)
          const [firstPartEmail] = this.form.email.split('@')
          isPasswordValid = isPasswordValid
            && !passwordToCompare.includes(firstPartEmail.toLowerCase())
        }

        if (!isValid || !isPasswordValid) {
          this.$refs.form.focusFirstInvalid()
          return
        }

        const form = new FormData()
        Object.entries(this.form).forEach(([key, value]) => {
          if (key === 'new_email') return
          if (key === 'date') return
          if (key === 'avatar' && !value) return
          if (key === '_method' && value !== 'PATCH') return
          if (key === 'birthdate' && !this.isProfile) {
            return form.append(key, this.form.date)
          }
          if (key === 'timezone') {
            return form.append('time_zone', this.form.timezone)
          }

          form.append(key, value)
        })

        if (this.optionalData) {
          const formOptionalData = Object.entries(this.formOptionalData)
            .concatMap(([category, values]) => (
              Object.entries(values)
                .map(([key, value]) => ({ category, key, value }))
            )).filter(({ value }) => value !== null && value !== '')

          this.$emit('submit', { form, formOptionalData })
        } else {
          this.$emit('submit', { form })
        }
      },
      onImageChange(e) {
        const file = e.target.files[0] || e.dataTransfer.files[0]
        if (!file) return

        this.form.avatar = file

        this.createPreview(file)
      },
      createPreview(file) {
        const reader = new FileReader()

        reader.onload = e => {
          this.avatarUploadedPreview = e.target.result
        }

        reader.readAsDataURL(file)
      },
      fillInForm() {
        Object.keys(this.form).forEach(key => {
          if (key === 'avatar') {
            this.avatarSavedPreview = this.user.avatar_url || this.getCurrentUserAvatar
          } else if (key == 'distance_unit') {
            // special case because the key returns Boolean
            this.form[key] = this.user[key]
          } else if (key === 'first_name' || key === 'last_name') {
            this.form[key] = this.user[key]
              ? this.user[key]
              : this.user[`sys_${key}`]
          } else if (key === 'birthdate' && this.user[key] && !this.isProfile) {
            const date = moment(this.user[key])

            this.form.date = {
              day: date.date(),
              month: date.month(),
              year: date.year(),
            }
          } else if (key === 'timezone') {
            this.form[key] = this.user.time_zone
          } else if (key === 'gp') {
            if (this.user.isHealthyLivingPackageId) {
              this.form[key] = this.user[key]
            } else {
              this.form[key] = false
            }
          } else if (this.user[key]) {
            this.form[key] = this.user[key]
          }
        })

        this.form.isAversaPackageId = this.user.isAversaPackageId
        this.form.isHealthyLivingPackageId = this.user.isHealthyLivingPackageId
      },
      openUser2faDialog() {
        this.openDialog({ component: 'User2fa' })
      },
      getBirthdate() {
        const { year, month, day } = this.form.date || {}

        if (isNil(year) || isNil(month) || isNil(day)) return false

        return moment()
          .year(year)
          .month(month)
          .date(day)
          .format('YYYY-MM-DD')
      },
      async init() {
        this.optionsTimezone = await this.getTimezones()
        if (!this.timezone) {
          this.$emit('update:timezone', this.defaultValue)
        }
      },
    },

    computed: {
      ...mapGetters('user', [
        'getCurrentUserAvatar',
      ]),
      ...mapGetters('optionalProfileData', [
        'getDataById',
      ]),
      ...mapGetters('program', [
        'getCurrentProgramOptionalData',
      ]),
      ...mapState('auth', [
        'is2fa',
        'consentDocuments',
      ]),
      ...mapState('dialog', [
        'isDialogOpen',
      ]),
      newEmail() {
        const { new_email: newEmail } = this.user || {}

        return newEmail
      },
      avatarSource() {
        return this.avatarUploadedPreview.length
                ? this.avatarUploadedPreview
                : this.avatarSavedPreview.length
                  ? this.avatarSavedPreview
                  : null
      },
      userActivity() {
        const getHumanizedDate = prop => moment(this.user?.[prop]).format(DATE_FORMATS.dateTime)

        return {
          createdAt: getHumanizedDate('created_at'),
          lastLoginAt: getHumanizedDate('last_login'),
        }
      },
      filteredOptionalData() {
        return Object.values(this.optionalData)
                .map(data => {
                  const programOptionalData = this.getCurrentProgramOptionalData || []

                  return {
                    category: data.category,
                    items: Object.values(data.items)
                            .filter(item => programOptionalData.includes(item.id)),
                  }
              })
                .filter(data => data.items.length)
      },
      hasAvatarPreview() {
        return Boolean(this.avatarUploadedPreview.length || this.avatarSavedPreview.length)
      },
      newEmailHelper() {
        /* eslint-disable-next-line camelcase */
        return this.user?.new_email
                ? `${this.$options.slug}.respond-to-email`
                : ''
      },
      snackbarMessage() { // @TODO: refoactor asap
        if (this.error && this.error.errors.email) {
          return `${this.$options.slug}.email-used`
        }

        if (this.emailChanged) {
          return `${this.$options.slug}.sent-message`
        }

        return this.isSuccess
                ? 'Changes saved'
                : 'Something went wrong. Please try again.'
      },
      birthdateMaxDate() {
        return moment.utc().format('YYYY-MM-DD')
      },
      phonesGroupLabels() {
        return this.isProfile
          ? {}
          : {
              labelLandline: 'Landline phone number <span class="sign-up__label-attribution">(You don\'t have to tell us)</span>',
              labelMobile: 'Phone number <span class="sign-up__label-attribution">(You don\'t have to tell us)</span>',
            }
      },
    },

    static() {
      return {
        checkbox2faDescription,
        measurements: [
          {
            label: 'Height in feet and inches, weight in pounds',
            name: 'measurements',
            value: 1,
          },
          {
            label: 'Height in feet and inches, weight in stones and pounds',
            name: 'measurements',
            value: 2,
          },
          {
            label: 'Height in centimetres, weight in kilograms',
            name: 'measurements',
            value: 0,
          },
        ],
        gp: [
          {
            label: 'Yes',
            name: 'gp',
            value: 1,
          },
          {
            label: 'No',
            name: 'gp',
            value: 2,
          },
        ],
      }
    },

    slug: 'component.forms.profile-form',

    validation: {
      avatar: {
        mimes: ['image/jpeg', 'image/png'],
        size: 10240,
      },
      first_name: {
        // name_lastname: true, // DELETE to issue 65
        max: 50,
        min: 2,
        required: true,
      },
      last_name: {
        // name_lastname: true, // DELETE to issue 65
        max: 50,
        min: 2,
        required: true,
      },
      email: {
        email: true,
        required: true,
      },
      birthdate: {
        prevent_future_date: true,
        required_object: { keys: ['day', 'month', 'year'] },
      },
      mobile: {
        phone_number: 'mobile',
      },
      landline: {
        phone_number: 'landline',
      },
      timezone: {
        required: true,
      },
      measurements: {
        required: true,
      },
      gpCheckbox: {
        checkbox_required: true,
      },
      gp: {
        required: true,
      },
      agree: {
        checkbox_required: true,
      },
    },
  }
</script>

<style lang="scss">
  .profile-form {
    flex-grow: 1;
    &__avatar {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      margin-bottom: 4rem;
      &-heading {
        margin-bottom: 2rem;
      }
    }
    &__image {
      width: 15rem;
      height: 15rem;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
    }
    &__img {
      height: 100%;
      z-index: z-index(base);
      object-fit: cover;
      &--empty {
        z-index: z-index(default);
      }
    }
    &__field-description {
      margin-top: 0;
      color: color(primary);
      word-break: break-word;
    }
    &__box {
      margin: 2rem 0 0;
    }
    &__message {
      @include min-lg {
        margin: 3.4rem 0 3.2rem;
        font-size: 3.2rem;
      }
    }
    &__2fa {
      &-checkbox {
        margin: 3rem 0 1rem;
      }
      &-description {
        margin: 0 0 1rem;
      }
    }
    .input-group {
      margin: 2.1rem 0 0;
      padding: 0;
    }
    &__phones {
      padding: 0 0 0.1rem;
    }
    &__opd {
      z-index: z-index(content);
    }
    &__opd-legend {
      @include visually-hidden;
    }
    .no-margin {
      margin-top: 0;
    }
    .multi-radio-group,
    .accordion,
    .button,
    .form__end-row {
      margin: 2.1rem 0 0;
    }
    .form__end-row {
      .button {
        margin: 0;
      }
    }
    &__snackbar-button.button {
      margin: 0 1rem;
    }
    .checkbox-group {
      .base-checkbox__check {
        border-width: 2px;
        width: 3rem;
        height: 3rem;
        &::before {
          top: 1.8rem;
          left: 1.2rem;
          transform: rotate(-140deg) scale3d(1.5, 2, 1);
        }
        &::after {
          top: 1.3rem;
          left: 0.6rem;
          transform: rotate(-45deg) scale3d(1.5, 2, 1);
        }
      }
    }
    ul.nhsuk-list {
      > li {
        font-size: 1.4rem;
      }
    }
  }
  .section-flex {
    display: flex;
  }
  .datepicker-datebox {
    min-height: 33rem;
  }

</style>
