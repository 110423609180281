import * as OfflinePluginRuntime from 'offline-plugin/runtime'

let isUpdating = false

export default () => {
  OfflinePluginRuntime.install({
    onUpdateReady: () => {
      if (!isUpdating) {
        isUpdating = true
        OfflinePluginRuntime.applyUpdate()
      }
    },
    onUpdated: () => {
      if (isUpdating) {
        isUpdating = false
        window.swUpdate = true
        if (process.env.NODE_ENV !== 'development') {
          window.location.reload()
        }
      }
    },
    onUpdateFailed: () => {
      isUpdating = false
    },
  })
}
