import Vue from 'vue'
import VueGtag, { event } from 'vue-gtag'

let initializeAnalyticsTimes = 0
const initializeAnalyticsMaxTimes = 10

export default {
  initializeAnalytics({ dispatch, state }) {
    initializeAnalyticsTimes += 1
    if (state.hasAnalyticsInitialized) {
      return
    }
    if (window.isIubendaActive === undefined) {
      if (initializeAnalyticsMaxTimes === initializeAnalyticsTimes) {
        return
      }
      setTimeout(() => {
        dispatch('initializeAnalytics')
      }, 200)
    } else if (window.isIubendaActive) {
      dispatch('initAnalyticsEvents')
    }
  },
  initAnalyticsEvents({ commit, state }) {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    if (state.hasAnalyticsInitialized) {
      return
    }
    Vue.use(VueGtag, {
      config: {
        id: process.env.VUE_APP_GA_MEASUREMENT_ID,
        params: {
          send_page_view: true,
        },
      },
    })
    commit('setHasInitialized', true)
  },
  sendAnalyticsEvent({ state }, eventName) {
    if (!state.hasAnalyticsInitialized) {
      return
    }
    event(eventName)
  },
}
