class EmptyPackageError extends Error {
  constructor() {
    super(
      `You do not have a Package assigned to your account, please contact Changing Health support for assistance
      at <a href='mailto:customersupport@changinghealth.com'>
      customersupport@changinghealth.com</a>`,
    )

    this.name = 'EmptyPackageError'
    this.title = 'You received empty package'
  }
}

export default EmptyPackageError
