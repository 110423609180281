import Vue from 'vue'

import initialState from './state'

export default {
  addResult(state, result) {
    state.results = [result, ...state.results]
  },
  deleteResult(state, id) {
    state.results = state.results.filter(result => result.id !== id)
  },
  setResults(state, results) {
    state.results = results
  },
  setTemporaryResult(state, result) {
    state.temporaryResult = result
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
