<template>
  <div class="dashboard">
    <div
      class="dashboard__first-row first-row"
    >
      <div
        class="first-row__column"
        v-for="(column, i) in widgets"
        :key="i"
      >
        <component
          :is="widget.component"
          v-for="(widget, j) in column.firstRow"
          :ref="columnRef(i)"
          :key="j"
          class="dashboard__widget"
          v-bind="{ widget }"
          @shiftTabOut="onShiftTabOut($event, i, widget.id)"
          @tabOut="onTabOut($event, i, widget.id)"
        />
      </div>
    </div>

    <div class="dashboard__content">
      <div
        v-for="(column, i) in widgets"
        :key="i"
        class="dashboard__column"
      >
        <component
          :is="widget.component"
          v-for="(widget, j) in column.rest"
          :ref="columnRef(i)"
          :key="j"
          class="dashboard__widget"
          v-bind="{ widget }"
          @shiftTabOut="onShiftTabOut($event, i, widget.id)"
          @tabOut="onTabOut($event, i, widget.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { findIndex } from 'lodash'
  import moment from 'moment'
  import { mapGetters, mapState } from 'vuex'

  import HomeNotifications from '@/components/notification/home/HomeNotifications'
  import {
    Widget,
    WidgetCoachingReminder,
    WidgetStart,
  } from '@/components/widgets'

  import { DATE_FORMATS } from '@/constants'
  import { getAllFocusables, getFirstFocusable } from '@/helpers'

  import matchMedia from '@/mixins/matchMedia'

  export default {
    components: {
      HomeNotifications,
      Widget,
      WidgetStart,
      WidgetCoachingReminder,
    },

    mixins: [
      matchMedia({
        media: 'min-md',
      }),
    ],

    methods: {
      onTabOut(e, col, widgetId) {
        const next = this.nextFocusableInColumn(col, widgetId)
          || ((col === 0) ? this.firstFocusableInColumn(1) : null)

        this.overrideTab(e, next)
      },
      onShiftTabOut(e, col, widgetId) {
        const previous = this.previousFocusableInColumn(col, widgetId)
          || ((col === 1) ? this.lastFocusableInColumn(0) : null)

        this.overrideTab(e, previous)
      },
      overrideTab(e, newFocus) {
        if (!this.shouldOverrideTabs || !newFocus) return

        e.preventDefault()
        newFocus.focus()
      },
      columnRef(col) {
        return `column${col}`
      },
      firstFocusableInColumn(col) {
        const firstWidgetId = this.getVisibleColumnItems(col)?.[0]?.id
        const firstWidgetEl = this.getWidgetEl(col, firstWidgetId)

        return getFirstFocusable(firstWidgetEl)
      },
      lastFocusableInColumn(col) {
        const items = this.getVisibleColumnItems(col)
        const lastWidgetId = items[items.length - 1]?.id

        if (!lastWidgetId) return

        const lastWidgetEl = this.getWidgetEl(col, lastWidgetId)
        const focusables = getAllFocusables(lastWidgetEl)

        return focusables[focusables.length - 1]
      },
      nextFocusableInColumn(col, widgetId) {
        const nextWidgetEl = this.getWidgetEl(col, widgetId, 1)

        return getFirstFocusable(nextWidgetEl)
      },
      previousFocusableInColumn(col, widgetId) {
        const previousWidgetEl = this.getWidgetEl(col, widgetId, -1)
        const focusables = getAllFocusables(previousWidgetEl)

        return focusables?.[focusables?.length - 1] || null
      },
      getColumnRefs(col) {
        return this.$refs[this.columnRef(col)]
      },
      getVisibleColumnItems(col) {
        return this.visibleWidgetsByDevice.columns[col]?.items
      },
      getWidgetEl(col, widgetId, offset = 0) {
        const items = this.getVisibleColumnItems(col)
        const widgetIndex = findIndex(items, ({ id }) => id === widgetId)
        const targetIndex = widgetIndex + offset

        if (widgetIndex < 0 || targetIndex < 0) return

        const targetWidgetId = items[targetIndex]?.id

        return this.getColumnRefs(col).find(({ widget: { id } }) => id === targetWidgetId)?.$el
      },
      extractCallTime({ duration, time }) {
        const start = moment(time)
        const end = moment(start).add(duration, 'minutes')
        const format = 'HH:mm'

        return `${start.format(format)} - ${end.format(format)}`
      },
    },

    computed: {
      ...mapGetters('dashboard', [
        'visibleWidgets',
      ]),
      ...mapGetters('coaching', [
        'coachImage',
        'getAppointmentsByType',
      ]),
      ...mapState('coaching', [
        'coach',
      ]),
      ...mapState('layout', [
        'wW',
      ]),
      isMobile() {
        return this.wW < 768
      },
      visibleWidgetsByDevice() {
        return this.isMobile ? this.visibleWidgets.mobileWidgets : this.visibleWidgets.widgets
      },
      shouldOverrideTabs() {
        return this.visibleWidgetsByDevice.columns.length > 1 && this.isMinMd
      },
      appointmentsTypes() {
        return this.getAppointmentsByType?.filter(i => i.appointments.length)
      },
      getAppointment() {
        if (!this.appointmentsTypes) return

        const appointments = this.appointmentsTypes
          .find(type => type.appointments.length && type.isUpcoming)?.appointments
          .filter(app => app.result === null)
          .sort((a, b) => {
            const dateA = new Date(a.to).getTime()
            const dateB = new Date(b.to).getTime()
            return dateA > dateB ? 1 : -1
          })

        if (!appointments || appointments.length === 0) {
          return
        }
        const appointment = appointments[0]

        const { to } = appointment
        const days = moment(to).diff(moment(), 'days') + 1
        // user has a booked coach appointment within the next 7 days.
        if (days >= 7) {
          return
        }
        return appointment
      },
      widgets() {
        const columns = this.visibleWidgetsByDevice.columns.map(column => {
          if (!this.isMinMd) {
            return {
              firstRow: [],
              rest: column.items,
            }
          }
          const isCoachingWidget = column.items.findIndex(item => item.type === 'plugin_widget' && item.plugin_id === 8)
          const appointment = this.getAppointment
          const { items } = column

          let widgetCoachingReminder = null
          if (this.coach) {
            const { user, icon } = this.coach
            if (isCoachingWidget !== -1 && appointment) {
              widgetCoachingReminder = {
                id: 2113,
                order: 1,
                component: 'WidgetCoachingReminder',
                display_orientation: 0,
                title: 'Your next appointment',
                description: `with ${this.coach.name} is on`,
                date: moment(appointment.since).format(DATE_FORMATS.date),
                time: this.extractCallTime(appointment.timedata.call),
                cta_button_text: 'Update appointment',
                idAppointment: appointment.id,
                vertical_orientation_icon: null,
                horizontal_orientation_icon: null,
                img: icon?.url || user.avatar_url,
              }
              items[isCoachingWidget] = widgetCoachingReminder
            }
          }

          const [first, second, ...rest] = column.items
          const restWidgets = rest || []

          return first && first.display_orientation === 1
            ? {
              firstRow: [first].filter(Boolean),
              rest: [second, ...restWidgets].filter(Boolean),
            }
            : {
              firstRow: [first, second].filter(Boolean),
              rest: [...restWidgets].filter(Boolean),
            }
        })
        return columns
      },
    },
  }
</script>

<style lang="scss">
  $block: 'dashboard';

  .#{$block} {
    &__first-row,
    &__content {
      display: grid;
      width: 100%;
      margin: 0;
      row-gap: 0;
      @include min-md {
        grid-template-columns: repeat(2, 1fr);
        max-width: 118rem;
        column-gap: 2.4rem;
        row-gap: 2.4rem;
      }
    }

    &__widget {
      height: auto !important;
      margin: 0 0 2.2rem !important;
    }
    &__column {
      display: grid;
      grid-auto-rows: minmax(min-content, max-content);
      grid-column-gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
      & > *:not([class*='--is-single']) {
        grid-column-start: span 2;
      }
    }
    .first-row {
      margin: 2rem 0 0 0;
      @include min-md {
        margin: 5.2rem auto 0;
      }
      &__column {
        grid-column-gap: 2rem;
        display: flex;
        & > *[class*='--is-single'] {
          width: 50%;
        }
      }
      .#{$block}__widget[class*='--is-single']:nth-child(1) {
        margin: 0 2rem 2.2rem 0;
      }
    }
    .home-notifications__header-text {
      font-size: 1.6rem;
      font-weight: 800;
      @include min-md {
        font-weight: 600;
      }
    }
    .home-notifications__clear .button-text {
      font-weight: 800;
      @include min-md {
        font-weight: 600;
      }
    }
  }
</style>
