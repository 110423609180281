import { cloneDeep, isPlainObject } from 'lodash'
import moment from 'moment'

import store from '@/store'

const defaultInputFormat = 'YYYY-MM-DD HH:mm:ss'
const defaultOutputFormat = 'YYYY-MM-DDTHH:mm:ssZ'

const formatDateFields = (obj, config = {}) => {
  if (!obj) return obj

  const _obj = cloneDeep(obj)
  const {
    timezone = store.getters['user/getTimezoneOffset'],
    dateFieldKeys = ['created_at', 'started_at', 'deleted_at',
      'modified_at', 'last_login', 'read_at', 'accepted_at'],
    inputFormat = defaultInputFormat,
    outputFormat = defaultOutputFormat,
  } = config

  Object.entries(_obj).forEach(([key, value]) => {
    if (dateFieldKeys.includes(key) && value) {
      _obj[key] = moment
        .utc(value, inputFormat)
        .utcOffset(timezone)
        .format(outputFormat)
    } else if (Array.isArray(value)) {
      _obj[key] = value.map(item => formatDateFields(item, config))
    } else if (isPlainObject(value)) {
      _obj[key] = formatDateFields(value, config)
    }
  })

  return _obj
}

export default formatDateFields
