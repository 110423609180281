import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

const module = 'eq5d'

export default {
  fetchBeginning({ commit }) {
    return new Promise((resolve, reject) => {
      const action = `${module}/fetchBeginning`
      loading(action, true)

      api.get('api/questionnaire/eq5d/begin')
        .then(response => resolve(response))
        .catch(err => reject(err))
        .finally(() => loading(action, false))
    })
  },
  saveAnswer({ commit }, { form, token }) {
    return new Promise((resolve, reject) => {
      const action = `${module}/saveAnswer`
      loading(action, true)

      api.post(`api/questionnaire/eq5d/${token}`, form)
        .then(({ next }) => resolve(next))
        .catch(err => reject(err))
        .finally(() => loading(action, false))
    })
  },
}
