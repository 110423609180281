import loading from '@/store/helpers/loading'
import normalize from '@/store/helpers/normalize'

import { api } from '@/tools/api'

export default {
  fetchPlugin({ commit }, { triggerDataId, userId }) {
    return new Promise((resolve, reject) => {
      loading('goals/fetchPlugin', true)

      api.get(`api/goals/plugins/${triggerDataId}/of-user/${userId}`)
        .then(response => {
          const { plugin, unlocked_profiles } = response
          const { items } = normalize(plugin.profiles)

          Object.entries(items).forEach(([, value]) => {
            // [#453] extra Green and Red Food Goal descriptions
            if (value.title === 'Green Food Goal') {
              value.extraDescription = "'Green foods' are any food you want to eat more of, as part of your current eating approach"
              value.smallExtraDescription = 'Green foods are any food you want to eat more of.'
            } else if (value.title === 'Red Food Goal') {
              value.extraDescription = "'Red foods' are any food you want to eat less of, as part of your current eating approach"
              value.smallExtraDescription = 'Red foods are any food you want to eat less of.'
            }
          })

          plugin.profiles = items
          Object.assign(plugin, { unlocked_profiles })

          commit('setPlugin', plugin)
          loading('goals/fetchPlugin', false)

          resolve()
        })
        .catch(() => {
          loading('goals/fetchPlugin', false)

          reject()
        })
    })
  },
  fetchGoals({ commit }, { programId, userId }) {
    return new Promise((resolve, reject) => {
      loading('goals/fetchGoals', true)

      api.get(`api/programs/${programId}/goals/user/${userId}`)
        .then(response => {
          commit('setGoals', response.goals)
          loading('goals/fetchGoals', false)

          resolve()
        })
        .catch(() => {
          loading('goals/fetchGoals', false)

          reject()
        })
    })
  },
  fetchGoal({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading('goals/fetchGoal', true)

      api.get(`api/goals/goals/${id}`)
        .then(response => {
          commit('setGoal', response.goal)
          loading('goals/fetchGoal', false)

          resolve()
        })
        .catch(() => {
          loading('goals/fetchGoal', false)

          reject()
        })
    })
  },
  addGoal({ commit }, goal) {
    return new Promise((resolve, reject) => {
      loading('goals/addGoal', true)

      api.post('api/goals/goals', goal)
        .then(() => {
          loading('goals/addGoal', false)

          resolve()
        })
        .catch(() => {
          loading('goals/addGoal', false)

          reject()
        })
    })
  },
  editGoal({ commit }, { goal, id }) {
    return new Promise((resolve, reject) => {
      loading('goals/editGoal', true)

      api.patch(`api/goals/goals/${id}`, goal)
        .then(response => {
          loading('goals/editGoal', false)

          resolve(response?.goal?.id)
        })
        .catch(() => {
          loading('goals/editGoal', false)

          reject()
        })
    })
  },
  setGoalAsCompleted({ commit }, { data, id }) {
    return new Promise((resolve, reject) => {
      loading('goals/setGoalAsCompleted', true)

      api.post(`api/goals/goals/${id}/completed`, data)
        .then(response => {
          commit('setGoal', response.goal)
          loading('goals/setGoalAsCompleted', false)

          resolve()
        })
        .catch(() => {
          loading('goals/setGoalAsCompleted', false)

          reject()
        })
    })
  },
}
