<template>
  <div class="journey-image">
    <img
      class="journey-image__img"
      :alt="alt"
      :src="hasImage ? image : requireImage(image)"
    >
  </div>
</template>

<script>
  import requireImage from '@/mixins/requireImage'

  export default {
    mixins: [
      requireImage,
    ],

    props: {
      alt: {
        type: String,
        default: '',
      },
      isCompleted: {
        type: Boolean,
        required: true,
      },
      image: {
        type: String,
        default: '',
      },
      hasImage: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss">
  .journey-image {
    grid-area: image;
    min-width: 9rem;
    max-width: 9rem;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 1rem 0 0 1rem;
    background-color: color(_white);
    @include min-lg {
      min-width: 12rem;
      max-width: 12rem;
    }
    &__img {
      height: 100%;
      object-fit: cover;
    }
  }
  .journey-section-item--is-completed .journey-image {
    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: color(complete-learn-card-overlay);
    }
  }
</style>
