<template>
  <base-main
    class="auth"
    data-testid="auth-wrapper"
  >
    <div class="auth__content">

      <div class="auth__background">
        <img
          alt=""
          class="auth__background-image"
          :src="requireImage('login_bg_2.png')"
        >
      </div>

      <div class="auth__tile-wrapper">
        <simple-tile
          class="switch-program-view__tile"
          direction="column"
          :data-testid="'switch-program-view-tile'"
          :name="'switch-program-view-tile'"
        >
          <div class="switch-program-view__header">
            <img
              :src="requireImage('logo-ch.png')"
              class="auth__logo"
              alt=""
            >
          </div>
          <p class="switch-program-view__paragraph">
            The Account with email {{ email }} already exists but it is linked to a
            different program do you want to switch the program for this account?
          </p>
          <div class="switch-program-view__buttons">
            <base-button
              light
              class="switch-program-view__cancel"
              :data-testid="'switch-program-view-button-cancel'"
              :name="'switch-program-view__cancel'"
              :link="{ name: 'AuthLogin' }"
            >
              {{ $t('NO') }}
            </base-button>

            <base-button
              class="switch-program-view__submit"
              :data-testid="'switch-program-view-button-submit'"
              :type="'submit'"
              :name="'switch-program-view__submit'"
              @click="onSwitchProgram"
            >
             <!-- TODO
             6a. If YES an email is sent to support@changinghealth.com
             to switch the program for the user -->
              {{ $t('YES') }}
            </base-button>
          </div>
        </simple-tile>
      </div>
    </div>
  </base-main>
</template>
<script>
  import { mapActions, mapState } from 'vuex'

  import { BaseButton } from '@/components/base'

  import BaseMain from '@/components/base/BaseMain'
  import SimpleTile from '@/components/tiles/simple-tile'

  import requireImage from '@/mixins/requireImage'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.support.meta.title'),
      }
    },

    components: {
      BaseMain,
      SimpleTile,
      BaseButton,
    },

    mixins: [
      requireImage,
    ],

    props: {
      isV2: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      ...mapActions('auth', [
        'switchProgramReferral',
        'switchProgramReferralV2',
      ]),
      onSwitchProgram() {
        const newUser = {
          user_id: this.referralErrorUser.user_id?.toString(),
          email: this.referralErrorUser.email?.toString(),
          new_package_id: this.referralUser.package_id?.toString(),
          new_unit_id: this.referralUser.unit_id?.toString(),
          old_package_id: this.referralErrorUser.package_id?.toString(),
          old_unit_id: this.referralErrorUser.unit_id?.toString(),
          old_current_program_id: this.referralErrorUser.current_program_id?.toString(),
        }

        let switchProgramReferral

        if (this.isV2) {
          switchProgramReferral = this.switchProgramReferralV2({
            tokenReferral: this.referralUser.ppuid,
            userData: newUser,
          })
        } else {
          switchProgramReferral = this.switchProgramReferral({
            tokenReferral: this.referralUser.ppuid,
            userData: newUser,
          })
        }

        switchProgramReferral
          .then(() => this.$router.push({ name: 'AuthLogin' }))
          .catch()
      },
    },

    computed: {
      ...mapState('auth', [
        'referralErrorUser',
        'referralUser',
      ]),
      email() {
        return this.referralErrorUser?.email
      },
    },
  }
</script>

<style lang="scss">
  .switch-program-view__tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    justify-content: center;
    .switch-program-view__header {
      display: flex;
    }
    .switch-program-view__buttons {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
    }
  }
</style>
