import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  fetchNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      loading('notifications/fetchNotifications', true)

      api.get('api/notifications/unread')
        .then(response => {
          commit('notificationReceived', response.notifications)
          loading('notifications/fetchNotifications', false)

          resolve()
        })
        .catch(() => {
          loading('notifications/fetchNotifications', false)

          reject()
        })
    })
  },

  markAsRead({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading(`notifications/markAsRead/${id}`, true)

      api.post(`api/notifications/${id}/read`)
        .then(() => {
          commit('markedNotificationAsRead', id)
          loading(`notifications/markAsRead/${id}`, false)

          resolve()
        })
        .catch(() => {
          loading(`notifications/markAsRead/${id}`, false)

          reject()
        })
    })
  },

  snooze({ commit }, { id, until }) {
    return new Promise((resolve, reject) => {
      loading(`notifications/snooze/${id}`, true)

      api.post(`api/notifications/${id}/snooze/${until}`)
        .then(() => {
          commit('snoozedNotification', id)
          loading(`notifications/snooze/${id}`, false)

          resolve()
        })
        .catch(() => {
          loading(`notifications/snooze/${id}`, false)

          reject()
        })
    })
  },
}
