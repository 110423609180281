<template>
  <base-dialog
    class="dialog--weekly-review dialog-weekly-review"
    data-testid="dialog-weekly-review"
    :headerLevel="1"
    :headerText="$t(dialog.title)"
  >
    <div
      class="dialog-weekly-review__text"
      data-testid="dialog-weekly-review-text"
      v-html="$t(dialog.text)"
    />
    <template #content-action>
      <base-button
        class="dialog-weekly-review__close-button"
        data-testid="dialog-weekly-review-close"
        @click="closeDialog"
      >
        {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  import { dialogs } from '@/data/weekly-review/weeklyReviewDialogs'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    props: {
      dialogId: {
        type: Number,
        required: true,
      },
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },

    computed: {
      dialog() {
        return dialogs[this.dialogId]
      },
    },
  }
</script>

<style lang="scss">
  .dialog-weekly-review {
    max-width: 51rem;
    h2 {
      margin: 0 0 1rem;
    }
    h3, .heading-3 {
      margin: 1rem 0;
      font-size: 1.6rem;
    }
    ul {
      list-style-type: initial;
      padding: 0 0 0 2rem;
    }

    &__close-button {
      margin: 0;
    }
  }
</style>
