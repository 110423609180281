import Vue from 'vue'

import initialState from './state'

const omitVariables = [
  'hasInitialized',
]

export default {
  setHasBootstrapped(state, value) {
    state.hasBootstrapped = value
  },
  setHasInitialized(state, value) {
    state.hasInitialized = value
  },
  resetState(state) {
    const newState = initialState()
    const stateVariables = Object.entries(newState)
      .filter(([key]) => !omitVariables.includes(key))

    stateVariables.forEach(([key, value]) => Vue.set(state, key, value))
  },
}
