<template>
  <base-dialog
    :key="isWarningConfirmed"
    class="delete-account-dialog"
    data-testid="dialog-user-delete-account"
    :headerLevel="1"
    :headerText="$t(`${$options.slug}.title`)"
  >
    <p
      v-if="!isWarningConfirmed"
      class="dialog-title">
      {{ isWarningConfirmed
        ? 'Why have you chosen to delete your account?'
        : 'Are you sure you want to delete this account?'
      }}
    </p>
    <p
      v-if="!isWarningConfirmed"
      id="delete-account-description"
      class="dialog-text"
      data-testid="dialog-user-delete-account-description"
    >
      This will delete all your personal and identifiable data,
      and you will not be able to access this programme any more.
    </p>

    <form-fields-generator
      v-if="isWarningConfirmed"
      :dataModel="form"
      :formFields="formFields"
    />

    <template #action>
      <base-button
        light
        small
        @click="closeDialog"
        data-testid="dialog-user-delete-account-cancel"
      >
        {{ $t('Cancel') }}
      </base-button>

      <base-button
        v-if="isWarningConfirmed"
        danger
        small
        data-testid="dialog-user-delete-account-confirmation"
        :describedBy="'delete-account-description'"
        :disabled="!form.reason || isLoading"
        v-bind="{ isLoading }"
        @click="deleteMyAccount"
      >
        {{ $t(`${$options.slug}.confirmation`) }}
      </base-button>

      <base-button
        v-else
        danger
        small
        data-testid="dialog-user-delete-account-continue"
        :describedBy="'delete-account-description'"
        @click="confirmWarning"
      >
        {{ $t('Continue') }}
      </base-button>

    </template>

  </base-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'
  import FormFieldsGenerator from '@/components/forms/form-fields-generator/FormFieldsGenerator'

  export default {
    components: {
      BaseButton,
      BaseDialog,
      FormFieldsGenerator,
    },

    data() {
      return {
        isWarningConfirmed: false,
        form: {
          reason: null,
        },
        options: [
          { value: 1, label: 'The programme is no longer relevant to me' },
          { value: 2, label: 'I have achieved my goals' },
          { value: 3, label: 'I have found a more appropriate programme' },
          { value: 4, label: 'Prefer not to say' },
        ],
      }
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
      ...mapActions('user', [
        'deleteAccount',
      ]),
      ...mapActions('auth', [
        'logout',
      ]),
      confirmWarning() {
        this.isWarningConfirmed = true
      },
      deleteMyAccount() {
        const reason = this.options.find(option => option.value === this.form.reason)?.label || ''
        this.deleteAccount(reason)
          .then(this.logout)
          .then(this.$nextTick)
          .then(() => this.$router.push({ name: 'AuthLogin' }))
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStateForAction',
      ]),
      isLoading() {
        return this.getLoadingStateForAction('user/deleteAccount')
      },
      formFields() {
        return [
          {
            type: 'RadioMultiGroup',
            name: 'reason',
            class: 'feedback__radio',
            props: {
              name: 'reason',
              labelKey: 'label',
              valueKey: 'value',
              legend: 'Why have you chosen to delete your account?',
              options: this.options,
              testid: 'radio-feedback',
              validation: { required: true },
            },
          },
        ]
      },
    },

    slug: 'component.dialog.delete-account-dialog',

    static() {
      return {
        first: `${this.$options.slug}.addnotation-1`,
        second: `${this.$options.slug}.addnotation-2`,
      }
    },
  }
</script>

<style lang="scss">
  .delete-account-dialog.dialog {
    max-width: 40rem;
    .dialog-title {
      margin-bottom: 1rem;
    }
    .dialog-text {
      text-align: justify;
    }
  }
</style>
