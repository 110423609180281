const detectUndefined = def => (def === 'undefined'
  ? undefined
  : def)

const addVModel = (computedName = 'valueModel', type = [Array, Number, String, Boolean], def = false) => ({
  props: {
    value: {
      type,
      default: detectUndefined(def),
    },
  },

  computed: {
    [computedName]: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
})

export default addVModel
