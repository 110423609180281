import Vue from 'vue'

import initialState from './state'

export default {
  changeLoadingState(state, [action, value]) {
    Vue.set(state.loadings, action, value)
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
