var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-group',_vm._b({},'form-group',{
      descriptionId : 'country-autocomplete-container',
      name: 'country-autocomplete-container',
      testid: _vm.testid,
      validation: {}
    },false),[_c('div',{staticClass:"nhsuk-form-group input-group"},[_c('label',{staticClass:"input-group__label",attrs:{"for":"country-autocomplete"}},[_vm._v(" "+_vm._s(_vm.$t('Country'))+" ")]),_c('div',{attrs:{"id":"country-autocomplete-container"}})])]),_c('form-group',_vm._b({ref:"mobilePhoneFormGroup",staticClass:"phone__form-group",attrs:{"events":['change'],"mode":'lazy'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('div',{staticClass:"nhsuk-form-group input-group",class:{ 'nhsuk-form-group--error': errors.length}},[_c('label',{staticClass:"input-group__label",attrs:{"for":"mobil-number"}},[_vm._v(" "+_vm._s(_vm.$t('Mobile phone'))+" ")]),_c('div',{staticClass:"nhsuk-hint",attrs:{"data-testid":"mobil-input-hint"}},[_vm._v(" "+_vm._s(_vm.$t('For example, +447111111111'))+" ")]),(errors.length > 0)?_c('span',{staticClass:"nhsuk-error-message",attrs:{"id":"mobile-number-error"}},[_c('span',{staticClass:"nhsuk-u-visually-hidden"},[_vm._v(_vm._s(_vm.i18n('(Error)')))]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelMobile),expression:"modelMobile"}],staticClass:"nhsuk-input input-group__input input-tel",attrs:{"id":"mobil-number","data-testid":"mobil-number","name":"mobil-number","aria-describedby":"mobile-number-error"},domProps:{"value":(_vm.modelMobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.modelMobile=$event.target.value}}})])]}}])},'form-group',{
      descriptionId: 'mobil-number',
      name: 'mobil-number',
      testid: _vm.testid,
      validation: _vm.validationMobile,
      tag: _vm.tag,
    },false)),_c('form-group',_vm._b({ref:"landlinePhoneFormGroup",staticClass:"phone__form-group",attrs:{"events":['change'],"mode":'lazy'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('div',{staticClass:"nhsuk-form-group input-group",class:{ 'nhsuk-form-group--error': errors.length}},[_c('label',{staticClass:"input-group__label",attrs:{"for":"landline-number"}},[_vm._v(" "+_vm._s(_vm.$t('Landline number'))+" ")]),_c('div',{staticClass:"nhsuk-hint",attrs:{"data-testid":"landline-input-hint"}},[_vm._v(" "+_vm._s(_vm.$t('For example, +442011111111 or +441302111111'))+" ")]),(errors.length > 0)?_c('span',{staticClass:"nhsuk-error-message",attrs:{"id":"landline-number-error"}},[_c('span',{staticClass:"nhsuk-u-visually-hidden"},[_vm._v(_vm._s(_vm.i18n('(Error)')))]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelLandline),expression:"modelLandline"}],staticClass:"nhsuk-input input-group__input input-tel",attrs:{"id":"landline-number","data-testid":"landline-number","name":"landline-number","aria-describedby":"landline-number-error"},domProps:{"value":(_vm.modelLandline)},on:{"input":function($event){if($event.target.composing){ return; }_vm.modelLandline=$event.target.value}}})])]}}])},'form-group',{
      descriptionId: 'landline-number',
      name: 'landline-number',
      testid: _vm.testid,
      validation: _vm.validationLandline,
      tag: _vm.tag,
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }