import { CouldNotRegisterUserError } from '@/extensions/error'

import { formatDateFields } from '@/helpers'

import loading from '@/store/helpers/loading'
import rootCommit from '@/store/helpers/rootCommit'
import rootDispatch from '@/store/helpers/rootDispatch'

import { api } from '@/tools/api'

export default {
  updateUser({ commit }, { userId, form }) {
    return new Promise((resolve, reject) => {
      loading('user/updateUser', true)

      api.post(`api/user/${userId}`, form)
        .then(response => {
          const {
            current_program_id,
            personal_data_store,
            unit,
            locale: {
              locale: language,
            },
            ...newUser
          } = response.user

          const userTimezoned = formatDateFields(newUser)

          rootCommit('program/setInitialCurrentProgramId', current_program_id)
          rootCommit('languages/setCurrentUserLanguage', { language, priority: 1 })
          commit('decodeUser', userTimezoned)
          rootCommit('personalDataStore/setStore', personal_data_store)

          rootDispatch('timezone/setUserOffsetTimezone', response.user.time_zone)
          resolve()
        }).catch(err => {
          loading('user/updateUser', false)
          const error = err?.response?.data

          reject(error)
        })
        .finally(() => {
          loading('user/updateUser', false)
        })
    })
  },
  canRegisterWithCredentials({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('user/canRegisterWithCredentials', true)

      api.post('api/user/register/can-register', form)
        .then(() => {
          loading('user/canRegisterWithCredentials', false)

          resolve()
        })
        .catch(err => {
          loading('user/canRegisterWithCredentials', false)

          const errors = {
            401: new CouldNotRegisterUserError(),
            422: new Error(Object.values(err?.response?.data?.errors)),
          }

          const { status } = err?.response || {}

          reject(errors[status] || err)
        })
    })
  },
  confirmRegistration({ commit }, { token, email }) {
    return new Promise((resolve, reject) => {
      loading('user/confirmRegistration', true)

      api.post('api/user/register/confirm', { token, email })
        .then(response => {
          loading('user/confirmRegistration', false)
          const { access_token: accessToken } = response || {}

          rootDispatch('auth/decodeJWT', { accessToken })
          rootDispatch('auth/fetchCurrentUser')

          resolve()
        })
        .catch(err => {
          loading('user/confirmRegistration', false)

          reject(err)
        })
    })
  },
  register({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('user/register', true)

      api.post('api/user/register', form)
        .then(() => {
          loading('user/register', false)

          resolve()
        })
        .catch(err => {
          loading('user/register', false)

          reject(err)
        })
    })
  },
  setEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      loading('user/updateUser', true)

      api.post('api/user/register/set-email', data)
        .then((response => {
          rootCommit('auth/setInvitationUserEmail', response.user.email)

          resolve()
        }))
        .catch(err => {
          reject(err)
        })
        .finally(() => loading('user/updateUser', false))
    })
  },
  verifyResetPasswordToken({ commit }, token) {
    loading('user/verifyResetPasswordToken', true)

    return new Promise((resolve, reject) => {
      api.get(`api/user/reset-password/${token}`)
        .then(resolve)
        .catch(() => reject(new Error('Invalid token')))
        .finally(() => loading('user/verifyResetPasswordToken', false))
    })
  },
  resetPassword({ commit }, form) {
    loading('user/resetPassword', true)

    return new Promise((resolve, reject) => {
      const { code = '' } = form

      api.post(`api/user/reset-password/${code}`, form)
        .then(resolve)
        .catch(reject)
        .finally(() => loading('user/resetPassword', false))
    })
  },
  changeEmail({ commit }, form) {
    return new Promise((resolve, reject) => {
      loading('user/changeEmail', true)

      api.post('api/user/change-email/verify', form)
        .then(() => {
          loading('user/changeEmail', false)

          resolve()
        }).catch(() => {
          loading('user/changeEmail', false)

          reject()
        })
    })
  },
  deleteAccount({ commit }, deleteReasons) {
    return new Promise((resolve, reject) => {
      loading('user/deleteAccount', true)

      api.post('api/user/delete-me', { delete_reasons: deleteReasons })
        .then(() => {
          loading('user/deleteAccount', false)

          rootDispatch('dialog/closeDialog')

          resolve()
        }).catch(() => {
          loading('user/deleteAccount', false)

          reject()
        })
    })
  },
  changePassword({ commit }, { userId, newPassword, password }) {
    return new Promise((resolve, reject) => {
      loading('user/changePassword', true)

      api.post('api/user/me/change-password', {
        user_id: userId, new_password: newPassword, password,
      })
        .then(response => {
          loading('user/changePassword', false)

          resolve(response)
        }).catch(err => {
          loading('user/changePassword', false)

          // AxiosError
          reject(err.response.data.errors)
        })
    })
  },
}
