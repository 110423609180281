/* eslint-disable import/prefer-default-export */
const importComponent = file => () => import(`./${file}`)

export const BaseAnchor = importComponent('BaseAnchor')
export const BaseArticle = importComponent('base-article/BaseArticle')
export const BaseArticleLink = importComponent('base-article/BaseArticleLink')
export const BaseButton = importComponent('BaseButton')
export const BaseCheckbox = importComponent('BaseCheckbox')
export const BaseDialog = importComponent('BaseDialog')
export const BaseFieldset = importComponent('BaseFieldset')
export const BaseFooter = importComponent('BaseFooter')
export const BaseForm = importComponent('BaseForm')
export const BaseHeader = importComponent('base-header/BaseHeader')
export const BaseLegend = importComponent('BaseLegend')
export const BaseMain = importComponent('BaseMain')
export const BasePagination = importComponent('BasePagination')
export const BaseRadio = importComponent('BaseRadio')
export const BaseSelect = importComponent('BaseSelect')
export const BaseView = importComponent('BaseView')
