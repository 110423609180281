<template>
  <base-anchor
    data-testid="widget-base-anchor"
    class="widget__base-anchor"
    :class="{ 'widget__base-anchor--is-single': isSingle }"
    :to="link">
    <modular-tile
      class="widget"
      data-testid="widget"
      v-bind="{ image, imagePosition }"
      :showLinkToTitle="true"
    >
      <div class="widget__content">
        <h2 class="widget__header">
          <div
            @keydown.exact.tab="$emit('tabOut', $event)"
            @keydown.shift.tab="$emit('shiftTabOut', $event)"
          >
            <a
              class="widget__header-link"
              data-testid="widget-cta"
              :to="link"
              @click="onCtaClick"
            >
              {{ widget.title }}
            </a>
          </div>
        </h2>
        <p
          class="widget__text"
          v-html="widget.description"
        />
      </div>
      <template #action/>
    </modular-tile>
  </base-anchor>
</template>

<script>
  import { capitalize } from 'lodash'
  import { mapActions, mapGetters } from 'vuex'

  import { BaseAnchor } from '@/components/base'
  import ModularTile from '@/components/tiles/modular-tile'

  import { requireImage } from '@/helpers'

  import matchMedia from '@/mixins/matchMedia'

  const IMAGE_POSITIONS = {
    0: 'top',
    1: 'side',
  }

  export default {
    components: {
      BaseAnchor,
      ModularTile,
    },

    mixins: [
      matchMedia({
        media: 'min-lg',
      }),
      matchMedia({
        media: 'max-md',
      }),
      matchMedia({
        media: 'min-md',
      }),
    ],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    methods: {
      ...mapActions('triggers', [
        'saveTriggerAccess',
      ]),
      onCtaClick() {
        if (!this.isPlugin) return

        this.saveTriggerAccess(this.widget.plugin_id)
      },
    },

    computed: {
      ...mapGetters('triggers', [
        'getTriggerRouteByPluginId',
      ]),
      image() {
        const images = {
          top: this.widget.vertical_orientation_icon?.url,
          side: this.widget.horizontal_orientation_icon?.url,
        }

        return images[this.imagePosition] ?? this.defaultImage
      },
      defaultImage() {
        const requirePluginImage = name => (
          requireImage(`static-dashboard/${name}-${this.isImageSingle ? 'single' : 'double'}.jpg`)
        )

        return this.isPlugin
          ? requirePluginImage('plugin-widget-default')
          : requirePluginImage('help-plugin')
      },
      imagePosition() {
        return this.isMinMd ? IMAGE_POSITIONS[this.orientation] : 'top'
      },
      isPlugin() {
        return this.widget.type == 'plugin_widget'
      },
      isHelpWidget() {
        return this.widget.type == 'help_widget'
      },
      isSingle() {
        return this.orientation === 0 && this.isMinMd
      },
      isImageSingle() {
        return this.orientation === 0 || this.isMaxMd
      },
      link() {
        if (this.isHelpWidget) {
          return { name: 'Support' }
        }
        return this.isPlugin
          ? { name: this.getTriggerRouteByPluginId(this.widget.plugin_id) }
          : this.widget?.url
      },
      orientation() {
        return this.widget.display_orientation
      },
      renderText() {
        return capitalize(this.widget.cta_button_text)
      },
    },
  }
</script>

<style lang="scss">
  $block: 'widget';

  .#{$block} {
    &__base-anchor {
      text-decoration: none !important;
      height: 100% !important;
      display: flex;
      font-weight: normal !important;
      &--is-single {
        .#{$block}__header {
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
    }
    &__header {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 2px;
      @include min-md {
        font-size: 2.1rem;
        margin: 0;
        margin-bottom: 16px;
        text-transform: initial;
      }
      &-link {
        text-decoration: underline;
        color: color(primary);
      }
    }
    .modular-tile {
      &__main {
        padding: 2.2rem;
      }
      &__header-row {
        margin: 0;
      }
      &__content {
        display: flex;
        align-items: center;
      }
      &__side-image-row {
        display: block;
        position: relative;
        width: 100%;
        max-width: 20rem;
      }
      &__side-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
</style>
