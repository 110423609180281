import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/step-tracking/',
    sharedMeta: {
      fetchProgram: false,
      layout: 'home',
      toParent: true,
      trigger: 'step-tracking',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'StepTracking',
    },
  },
  routes: [
    {
      path: '/step-tracking',
      name: 'StepTracking',
      meta: {
        parent: 'Tools',
        header: 'Step Tracking',
        pluginHome: true,
      },
    },
    {
      path: '/step-tracking/log/:id',
      props: true,
      name: 'StepTrackingLog',
      meta: {
        header: 'Step Tracking Log',
        parent: 'StepTracking',
      },
    },
    {
      path: '/step-tracking/settings',
      name: 'StepTrackingSettings',
      meta: {
        header: 'Step Tracking Settings',
        parent: 'StepTracking',
      },
    },
    {
      path: '/step-tracking/delete/:id?',
      name: 'StepTrackingDelete',
      props: true,
      meta: {
        parent: 'StepTracking',
        header: 'Step tracking Delete',
      },
    },
  ],
}
