const timezones = {
  'America/Adak': '-10:00',
  'America/Anchorage': '-09:00',
  'America/Juneau': '-09:00',
  'America/Nome': '-09:00',
  'America/Sitka': '-09:00',
  'America/Yakutat': '-09:00',
  'America/Dawson': '-08:00',
  'America/Los_Angeles': '-08:00',
  'America/Metlakatla': '-08:00',
  'America/Santa_Isabel': '-08:00',
  'America/Tijuana': '-08:00',
  'America/Vancouver': '-08:00',
  'America/Whitehorse': '-08:00',
  'America/Boise': '-07:00',
  'America/Cambridge_Bay': '-07:00',
  'America/Chihuahua': '-07:00',
  'America/Creston': '-07:00',
  'America/Dawson_Creek': '-07:00',
  'America/Denver': '-07:00',
  'America/Edmonton': '-07:00',
  'America/Hermosillo': '-07:00',
  'America/Inuvik': '-07:00',
  'America/Mazatlan': '-07:00',
  'America/Ojinaga': '-07:00',
  'America/Phoenix': '-07:00',
  'America/Shiprock': '-07:00',
  'America/Yellowknife': '-07:00',
  'America/Bahia_Banderas': '-06:00',
  'America/Belize': '-06:00',
  'America/North_Dakota/Beulah': '-06:00',
  'America/Cancun': '-06:00',
  'America/North_Dakota/Center': '-06:00',
  'America/Chicago': '-06:00',
  'America/Costa_Rica': '-06:00',
  'America/El_Salvador': '-06:00',
  'America/Guatemala': '-06:00',
  'America/Indiana/Knox': '-06:00',
  'America/Managua': '-06:00',
  'America/Matamoros': '-06:00',
  'America/Menominee': '-06:00',
  'America/Merida': '-06:00',
  'America/Mexico_City': '-06:00',
  'America/Monterrey': '-06:00',
  'America/North_Dakota/New_Salem': '-06:00',
  'America/Rainy_River': '-06:00',
  'America/Rankin_Inlet': '-06:00',
  'America/Regina': '-06:00',
  'America/Resolute': '-06:00',
  'America/Swift_Current': '-06:00',
  'America/Tegucigalpa': '-06:00',
  'America/Indiana/Tell_City': '-06:00',
  'America/Winnipeg': '-06:00',
  'America/Atikokan': '-05:00',
  'America/Bogota': '-05:00',
  'America/Cayman': '-05:00',
  'America/Detroit': '-05:00',
  'America/Grand_Turk': '-05:00',
  'America/Guayaquil': '-05:00',
  'America/Havana': '-05:00',
  'America/Indiana/Indianapolis': '-05:00',
  'America/Iqaluit': '-05:00',
  'America/Jamaica': '-05:00',
  'America/Lima': '-05:00',
  'America/Kentucky/Louisville': '-05:00',
  'America/Indiana/Marengo': '-05:00',
  'America/Kentucky/Monticello': '-05:00',
  'America/Montreal': '-05:00',
  'America/Nassau': '-05:00',
  'America/New_York': '-05:00',
  'America/Nipigon': '-05:00',
  'America/Panama': '-05:00',
  'America/Pangnirtung': '-05:00',
  'America/Indiana/Petersburg': '-05:00',
  'America/Port-au-Prince': '-05:00',
  'America/Thunder_Bay': '-05:00',
  'America/Toronto': '-05:00',
  'America/Indiana/Vevay': '-05:00',
  'America/Indiana/Vincennes': '-05:00',
  'America/Indiana/Winamac': '-05:00',
  'America/Caracas': '-04:30',
  'America/Anguilla': '-04:00',
  'America/Antigua': '-04:00',
  'America/Aruba': '-04:00',
  'America/Asuncion': '-04:00',
  'America/Barbados': '-04:00',
  'America/Blanc-Sablon': '-04:00',
  'America/Boa_Vista': '-04:00',
  'America/Campo_Grande': '-04:00',
  'America/Cuiaba': '-04:00',
  'America/Curacao': '-04:00',
  'America/Dominica': '-04:00',
  'America/Eirunepe': '-04:00',
  'America/Glace_Bay': '-04:00',
  'America/Goose_Bay': '-04:00',
  'America/Grenada': '-04:00',
  'America/Guadeloupe': '-04:00',
  'America/Guyana': '-04:00',
  'America/Halifax': '-04:00',
  'America/Kralendijk': '-04:00',
  'America/La_Paz': '-04:00',
  'America/Lower_Princes': '-04:00',
  'America/Manaus': '-04:00',
  'America/Marigot': '-04:00',
  'America/Martinique': '-04:00',
  'America/Moncton': '-04:00',
  'America/Montserrat': '-04:00',
  'America/Port_of_Spain': '-04:00',
  'America/Porto_Velho': '-04:00',
  'America/Puerto_Rico': '-04:00',
  'America/Rio_Branco': '-04:00',
  'America/Santiago': '-04:00',
  'America/Santo_Domingo': '-04:00',
  'America/St_Barthelemy': '-04:00',
  'America/St_Kitts': '-04:00',
  'America/St_Lucia': '-04:00',
  'America/St_Thomas': '-04:00',
  'America/St_Vincent': '-04:00',
  'America/Thule': '-04:00',
  'America/Tortola': '-04:00',
  'America/St_Johns': '-03:30',
  'America/Araguaina': '-03:00',
  'America/Bahia': '-03:00',
  'America/Belem': '-03:00',
  'America/Argentina/Buenos_Aires': '-03:00',
  'America/Argentina/Catamarca': '-03:00',
  'America/Cayenne': '-03:00',
  'America/Argentina/Cordoba': '-03:00',
  'America/Fortaleza': '-03:00',
  'America/Godthab': '-03:00',
  'America/Argentina/Jujuy': '-03:00',
  'America/Argentina/La_Rioja': '-03:00',
  'America/Maceio': '-03:00',
  'America/Argentina/Mendoza': '-03:00',
  'America/Miquelon': '-03:00',
  'America/Montevideo': '-03:00',
  'America/Paramaribo': '-03:00',
  'America/Recife': '-03:00',
  'America/Argentina/Rio_Gallegos': '-03:00',
  'America/Argentina/Salta': '-03:00',
  'America/Argentina/San_Juan': '-03:00',
  'America/Argentina/San_Luis': '-03:00',
  'America/Santarem': '-03:00',
  'America/Sao_Paulo': '-03:00',
  'America/Argentina/Tucuman': '-03:00',
  'America/Argentina/Ushuaia': '-03:00',
  'America/Noronha': '-02:00',
  'America/Scoresbysund': '-01:00',
  'America/Danmarkshavn': '+00:00',
  'Europe/Dublin': '+00:00',
  'Europe/Guernsey': '+00:00',
  'Europe/Isle_of_Man': '+00:00',
  'Europe/Jersey': '+00:00',
  'Europe/Lisbon': '+00:00',
  'Europe/London': '+00:00',
  'Europe/Amsterdam': '+01:00',
  'Europe/Andorra': '+01:00',
  'Europe/Belgrade': '+01:00',
  'Europe/Berlin': '+01:00',
  'Europe/Bratislava': '+01:00',
  'Europe/Brussels': '+01:00',
  'Europe/Budapest': '+01:00',
  'Europe/Busingen': '+01:00',
  'Europe/Copenhagen': '+01:00',
  'Europe/Gibraltar': '+01:00',
  'Europe/Ljubljana': '+01:00',
  'Europe/Luxembourg': '+01:00',
  'Europe/Madrid': '+01:00',
  'Europe/Malta': '+01:00',
  'Europe/Monaco': '+01:00',
  'Europe/Oslo': '+01:00',
  'Europe/Paris': '+01:00',
  'Europe/Podgorica': '+01:00',
  'Europe/Prague': '+01:00',
  'Europe/Rome': '+01:00',
  'Europe/San_Marino': '+01:00',
  'Europe/Sarajevo': '+01:00',
  'Europe/Skopje': '+01:00',
  'Europe/Stockholm': '+01:00',
  'Europe/Tirane': '+01:00',
  'Europe/Vaduz': '+01:00',
  'Europe/Vatican': '+01:00',
  'Europe/Vienna': '+01:00',
  'Europe/Warsaw': '+01:00',
  'Europe/Zagreb': '+01:00',
  'Europe/Zurich': '+01:00',
  'Europe/Athens': '+02:00',
  'Europe/Bucharest': '+02:00',
  'Europe/Chisinau': '+02:00',
  'Europe/Helsinki': '+02:00',
  'Europe/Istanbul': '+02:00',
  'Europe/Kiev': '+02:00',
  'Europe/Mariehamn': '+02:00',
  'Europe/Riga': '+02:00',
  'Europe/Simferopol': '+02:00',
  'Europe/Sofia': '+02:00',
  'Europe/Tallinn': '+02:00',
  'Europe/Uzhgorod': '+02:00',
  'Europe/Vilnius': '+02:00',
  'Europe/Zaporozhye': '+02:00',
  'Europe/Kaliningrad': '+03:00',
  'Europe/Minsk': '+03:00',
  'Europe/Moscow': '+03:00',
  'Europe/Samara': '+04:00',
  'Europe/Volgograd': '+04:00',
}

export default timezones
