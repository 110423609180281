import Vue from 'vue'

import initialState from './state'

export default {
  addFood(state, food) {
    state.foods.push(food)
  },
  setFoods(state, foods) {
    state.foods = foods
  },
  setFood(state, food) {
    state.food = food
  },
  editFood(state, food) {
    state.foods = state.foods.map(item => (item.id === food.id ? food : item))
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
