<template>
  <div
    v-if="showSummaryErrors"
    class="nhsuk-error-summary"
    aria-labelledby="error-summary-title"
    role="alert"
    tabindex="-1"
    >
    <h2
        class="nhsuk-error-summary__title"
        id="error-summary-title">
        There is a problem
    </h2>
    <div class="nhsuk-error-summary__body">
    <p>
        Check the following items before submitting this form again.
    </p>
    <ul class="nhsuk-list nhsuk-error-summary__list">
        <li
          v-for="(field, i) in errorsKeys"
          :key="i">
          <a :href="`#${getIdField(field)}`">{{ summaryErrors[field][0] }}</a>
        </li>
    </ul>
    </div>
</div>
</template>

<script>
  export default {
    props: {
      summaryErrors: {
        type: Object,
        required: true,
      },
    },

    methods: {
      getIdField(field) {
        const element = document.getElementsByName(field)[0]
        return element?.id
      },
    },

    computed: {
      showSummaryErrors() {
        let showSummaryErrors = false
        this.errorsKeys.forEach(key => {
            showSummaryErrors = showSummaryErrors || !!this.summaryErrors[key][0]
        })
        return showSummaryErrors
      },
      errorsKeys() {
        const summaryErrorKeys = Object.keys(this.summaryErrors)
          .filter(key => this.summaryErrors[key] && this.summaryErrors[key].length > 0)

        return summaryErrorKeys
      },
    },
  }
</script>

<style lang="scss">
  .nhsuk-error-summary {
    margin: 2rem 0;
  }
</style>
