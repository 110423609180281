<script>
  export default {
    functional: true,

    props: {
      descriptionId: {
        type: [String, Boolean],
        default: false,
      },
      inputError: {
        type: String,
        required: false,
        default: '',
      },
      helper: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: false,
        default: '',
      },
      testid: {
        type: String,
        default: '',
      },
    },

    render(h, { data, props, parent }) {
      const baseClass = 'input-details'
      const { attrs, staticClass, ...newData } = data
      const { 'data-testid': dataTestid, ...newAttrs } = attrs
      const rootTestid = `${props.testid ? `${props.testid}-${baseClass} ` : ''}${baseClass}`

      if (props.inputError && parent.$announcer) {
        parent.$announcer.set(parent.$t(props.inputError), 'polite')
      }

      return h('div', {
        class: {
          [`${baseClass}--has-error`]: props.inputError,
          [`${baseClass}--has-helper`]: props.helper,
        },
        staticClass: [baseClass].concat(staticClass).join(' ').trim(),
        ...newData,
        attrs: {
          'data-testid': rootTestid,
          ...newAttrs,
        },
      }, [
        h('div', {
          staticClass: 'input-details__error',
          domProps: {
            id: props.descriptionId || undefined,
          },
          attrs: {
            'data-testid': 'input-details-error',
          },
        }, [
          h('inline-svg', {
            staticClass: 'input-details__error-icon',
            props: { src: 'icons/icon-exclamation.svg' },
          }),
          h('div', {
            attrs: {
              'data-testid': `${props.testid}-errors`,
            },
            domProps: {
              // shitty edge fix, otherwise it'd display 'null' sometimes
              innerHTML: parent.$t(props.inputError) || '',
            },
          }),
        ]),
        h('div', {
          staticClass: 'input-details__helper',
          domProps: {
            innerHTML: parent.$t(props.helper),
          },
        }),
      ])
    },
  }
</script>

<style lang="scss">
  $detailsBlock: 'input-details';

  .#{$detailsBlock} {
    margin: -1px 0 0 0;
    font-size: 1.2rem;
    &--has-error {
      .#{$detailsBlock}__error {
        padding: 1rem 1.4rem 1rem 4.8rem;
        opacity: 1;
      }
      .#{$detailsBlock}__error-icon {
        display: block;
      }
    }
    &--has-helper {
      .#{$detailsBlock}__helper {
        padding: 0.5rem 0 0;
      }
    }
    &__helper {
      color: color(secondary-text);
    }
    &__error {
      position: relative;
      padding: 0 1.4rem 0 4.8rem;
      color: color(error);
      font-size: 1.4rem;
      font-weight: 600;
      transition: padding 0.15s;
      border: 1px solid color(error);
      background-color: color(_white);
      opacity: 0;
      transition: opacity .15s, padding .15s;
      &-icon {
        display: none;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 50%;
        left: 1.4rem;
        transform: translateY(-50%);
        fill: color(error);
      }
    }
    a {
      color: inherit;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .input-group,
  .select-group,
  .textarea-group,
  .radio-group,
  .checkbox-group {
    &--has-error {
      & ~ .#{$detailsBlock} .#{$detailsBlock}__error {
        opacity: 1;
        padding: 1rem 1.4rem 1rem 4.8rem;
      }
      .#{$detailsBlock}__error-icon {
        display: block;
      }
    }
  }
</style>
