import Vue from 'vue'

import initialState from './state'

export default {
  setAnswers(state, { answers, pluginName }) {
    state[pluginName].answers = answers
  },
  setAnswersId(state, { id, pluginName }) {
    state[pluginName].answersId = id
  },
  setAnswersUpdatedAt(state, { updatedAt, pluginName }) {
    state[pluginName].answersUpdatedAt = updatedAt
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
