<template>
  <h2 class="journey-title nhsuk-heading-m">
    {{ title }}
  </h2>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .journey-title {
    grid-area: title;
    margin: 0;
    color: color(incomplete-learn-card-title);
    font-weight: 600;
  }
  .journey-section-item--is-completed .journey-title {
    color: color(complete-learn-card-title);
  }
  .journey-section-item--is-active .journey-title {
    color: color(active-learn-card-title);
  }
</style>
