import formatFileSize from '@/validator/helpers/formatFileSize'

export default {
  _default: field => `${field} is not valid.`,
  after: (field, [target, inclusion]) => `${field} must be after ${inclusion ? 'or equal to ' : ''}${target}.`,
  alpha_dash: field => `${field} may contain alpha-numeric characters as well as dashes and underscores.`,
  alpha_num: field => `${field} may only contain alpha-numeric characters.`,
  alpha_spaces: field => `${field} may only contain alphabetic characters as well as spaces.`,
  alpha: field => `${field} may only contain alphabetic characters.`,
  before: (field, [target, inclusion]) => `${field} must be before ${inclusion ? 'or equal to ' : ''}${target}.`,
  between: (field, [min, max]) => `${field}  must be between ${min} and ${max}.`,
  confirmed: field => `${field} confirmation field does not match.`,
  credit_card: field => `${field} is invalid.`,
  date_between: (field, [min, max]) => `${field} must be between ${min} and ${max}.`,
  date_format: (field, [format]) => `${field} must be in the format ${format}.`,
  decimal: (field, [decimals = '*'] = []) => `${field} must be numeric
    and may contain ${decimals === '*' ? '' : decimals} decimal ${decimals == 1 ? 'place' : 'places'}.`,
  digits: (field, [length]) => `${field} must be numeric and exactly contain ${length} digits.`,
  dimensions: (field, [width, height]) => `${field} must be ${width} pixels by ${height} pixels.`,
  email: field => `${field} must be a valid email.`,
  ext: field => `${field} must be a valid file.`,
  image: field => `${field} must be an image.`,
  included: field => `${field} must be a valid value.`,
  integer: field => `${field} must be an integer.`,
  ip: field => `${field} must be a valid ip address.`,
  length: (field, [length, max]) => (max
    ? `${field} length must be between ${length} and ${max}.`
    : `${field} length must be ${length}.`),
  max: (field, [length]) => `${field} may not be greater than ${length} characters.`,
  max_value: (field, [max]) => `${field} must be ${max} or less.`,
  mimes: field => `${field} must have a valid file type.`,
  min: (field, [length]) => `${field} must be at least ${length} characters long.`,
  min_value: (field, [min]) => `${field} must be ${min} or more.`,
  excluded: field => `${field} must be a valid value.`,
  numeric: field => `${field} may only contain numeric characters.`,
  regex: field => `${field} format is invalid.`,
  required: field => `${field} is required`,
  required_object: field => `All fields in ${field} are required`,
  size: (field, [size]) => `${field} file size must be less than ${formatFileSize(size)}.`,
  url: field => `${field} is not a valid URL.`,
}
