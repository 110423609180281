<script>
  const PORTALS = [
    'camera',
    'datepicker',
    'fab',
    'flexible-space-image',
    'main-tile',
    'progress',
  ]

  export default () => (
    <div>
      {
        PORTALS.map((name, i) => (
          <portal-target
            key={i}
            name={name}
            slim={true}/>
        ))
      }
    </div>
  )
</script>
