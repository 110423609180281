<template>
  <div
    class="image-group"
    role="group"
    :class="attrsClasses"
    :data-testid="dataTestid"
  >
    <div class="image-group__container">
      <div class="image-group__content">
        <div class="image-group__preview">
          <img
            v-if="preview"
            :alt="alt"
            class="image-group__img"
            :src="preview"
            v-bind="{ role }"
          >

          <image-placeholder v-else />
        </div>

        <div class="image-group__buttons">
          <base-button
            ref="captureButton"
            data-testid="image-group-take-photo"
            :aria-describedby="captureButtonAriaDescribedby"
            :title="label"
            @click="takePhoto"
          >
            <span aria-hidden="true">
              {{ $t('Capture') }}
            </span>
            <screen-readers-only>
              {{ captureImageText }}
            </screen-readers-only>
          </base-button>
          <vue-upload-component
            ref="vueUpload"
            :accept="$static.mimes"
            @input="onFileInput"
          >
            <base-button
              ref="uploadButton"
              light
              data-testid="image-group-upload"
              :aria-describedby="uploadButtonAriaDescribedby"
              :title="label"
              @click="onUpload"
            >
              <span aria-hidden="true">
                {{ $t('Upload') }}
              </span>
              <screen-readers-only>
                {{ uploadImageText }}
              </screen-readers-only>
            </base-button>
          </vue-upload-component>
        </div>
      </div>

      <form-group
        class="image-group__inputs"
        ref="inputs"
        :name="inputsName"
        :validation="imageValidation"
        v-slot="{ validate, errors }"
        v-bind="{ descriptionId, label, tag }"
        @errors="onErrors"
      >
        <!-- 💩 fix for capture image validation on desktops 💩 -->
        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
        <input
          hidden
          data-testid="image-group-desktop-validation-fix"
          type="text"
          :aria-invalid="!!errors.length"
          :name="inputsName"
          v-model="image"
        >

        <input
          ref="fileInput"
          hidden
          data-testid="image-group-file-input"
          :accept="$static.mimes"
          :aria-labelledby="descriptionId"
          :capture="photoEnvironment"
          :id="`image-${uuid}-1`"
          :name="inputsName"
          type="file"
          @change="onFileChange($event, true, validate)"
        >
      </form-group>
    </div>

    <camera
      class="image-group__camera"
      :isVisible="isCameraVisible"
      @capture="saveCameraImages"
      @close="toggleCamera"
    />
  </div>
</template>

<script>
  import Compressor from 'compressorjs'

  import VueUploadComponent from 'vue-upload-component'

  import { mapState } from 'vuex'

  import {
    BaseButton,
  } from '@/components/base'
  import Camera from '@/components/camera/Camera'
  import FormGroup from '@/components/forms/form-group/FormGroup'
  import ImagePlaceholder from '@/components/image-placeholder/ImagePlaceholder'
  import ScreenReadersOnly from '@/components/screen-readers-only/ScreenReadersOnly'

  import { isFocused } from '@/helpers'

  import forceAnnounce from '@/mixins/forceAnnounce'
  import testid from '@/mixins/testid/testid'

  export default {
    inheritAttrs: false,

    components: {
      BaseButton,
      Camera,
      FormGroup,
      ImagePlaceholder,
      VueUploadComponent,
      ScreenReadersOnly,
    },

    mixins: [
      forceAnnounce,
      testid(),
    ],

    props: {
      alt: {
        type: String,
        required: false,
        default: null,
      },
      captureImageText: {
        type: String,
        default: 'capture image',
      },
      describedBy: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      imageUrl: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: false,
        default: '',
      },
      secondDescription: {
        type: [String, Boolean],
        default: false,
      },
      uploadImageText: {
        type: String,
        default: 'upload image',
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      value: {
        type: [File, Blob, String],
        required: true,
      },
      tag: {
        type: String,
        required: false,
        default: 'fieldset',
      },
    },

    data() {
      return {
        isCameraVisible: false,
        newImagePreview: '',
        photoEnvironment: false,
        shouldRegainFocus: false,
        compressor: null,
      }
    },

    methods: {
      takePhoto() {
        if (this.isMobile) {
          this.setEnvPhoto()
          this.$nextTick(() => this.$refs.fileInput.click())
        } else {
          this.clearEnvPhoto()
          this.toggleCamera()
        }
      },
      setEnvPhoto() {
        this.photoEnvironment = 'environment'
      },
      clearEnvPhoto() {
        this.photoEnvironment = false
      },
      async toggleCamera() {
        this.isCameraVisible = !this.isCameraVisible

        if (this.isCameraVisible) {
          this.shouldRegainFocus = isFocused(this.$refs.captureButton)
        } else if (this.shouldRegainFocus) {
          this.focusCaptureButton()
        }
      },
      saveCameraImages({ image, newImagePreview }) {
        this.processFile(image)
        this.newImagePreview = newImagePreview
      },
      async onFileChange(e, isUpload, validate) {
        const file = e?.target?.files[0] || e?.dataTransfer?.files[0]
        if (!file) return

        if (isUpload) {
          const { valid } = await validate(e?.target?.files)

          if (!valid) return
        }

        this.processFile(file)
      },
      onFileInput(payload) {
        const file = payload[0]?.file
        if (!file) return

        this.processFile(file)
        setTimeout(() => this.$refs.uploadButton.focus(), 0)
      },
      processFile(file) {
        /* eslint-disable-next-line no-new */
        this.compressor = new Compressor(file, {
          ...this.$static.compressionOptions,
          drew: (context, canvas) => {
            this.createPreview(canvas)
          },
          success: compressedFile => {
            this.setFile(compressedFile)
            this.compressor = null
          },
          error: () => {
            this.addImageError()
            this.compressor = null
          },
        })
      },
      createPreview(canvas) {
        this.newImagePreview = canvas.toDataURL('image/png')
      },
      setFile(file) {
        this.image = file
      },
      addImageError() {
        this.$refs.inputs.$refs.provider.applyResult({
          errors: ['Invalid file type'],
          valid: false,
          failedRules: {},
        })
      },
      onUpload() {
        this.$refs.vueUpload.$el.querySelector('label').click()
      },
      onErrors(error) {
        if (error.length) this.$refs.captureButton.focus()
      },
      async focusCaptureButton() {
        this.shouldRegainFocus = false

        await this.$awaitTicks(2)

        return this.$refs.captureButton?.focus()
      },
    },

    computed: {
      ...mapState('layout', [
        'isMobile',
      ]),
      image: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      imageValidation() {
        return {
          mimes: ['image/jpeg', 'image/png'],
          size: 10240,
          ...this.validation,
        }
      },
      inputsName() {
        return this.name || `image_group_${this.uuid}`
      },
      preview() {
        return this.newImagePreview || this.imageUrl
      },
      attrsClasses() {
        return this.$attrs
          ? Object.keys(this.$attrs).map(key => `image-group--${key}`)
          : []
      },
      role() {
        return this.alt ? null : 'presentation'
      },
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
      captureButtonAriaDescribedby() {
        return [this.secondDescription, this.descriptionId]
          .filter(Boolean)
          .join(' ')
      },
      uploadButtonAriaDescribedby() {
        return this.describedBy || undefined
      },
    },

    static() {
      return {
        mimes: 'image/*',
        compressionOptions: {
          maxHeight: 600,
          maxWidth: 600,
          minHeight: 130,
          minWidth: 130,
          quality: 0.8,
        },
      }
    },
  }
</script>

<style lang="scss">
  $block: 'image-group';
  $imageSide: 13rem;

  .#{$block} {
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include min-sm {
        flex-direction: row;
      }
    }
    &__preview {
      width: $imageSide;
      height: $imageSide;
      position: relative;
    }
    &__img {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: z-index(base);
      border-radius: 50%;
      object-fit: cover;
    }
    &__buttons {
      display: grid;
      grid-column-gap: 3rem;
      grid-template-columns: auto;
      grid-template-rows: repeat(2, auto);
      .button {
        white-space: nowrap;
      }
      .input-details {
        margin: 0 0.8rem;
      }
    }
    &__inputs {
      margin: 1.25rem 0 0;
    }
    &--vertical {
      flex-direction: column;
      .#{$block}__buttons {
        grid-template-columns: auto;
        grid-template-rows: repeat(2, auto);
        @include min-md {
          grid-template-columns: repeat(2, auto);
          grid-template-rows: auto;
        }
      }
      .image-group__content {
        @include min-sm {
          flex-direction: column;
        }
      }
    }
    .file-uploads {
      overflow: visible;
      &.file-uploads-html5 {
        input {
          visibility: hidden;
        }
        label {
          z-index: 0;
        }
      }
    }
  }
</style>
