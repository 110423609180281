import moment from 'moment'

import pascalize from '@/helpers/pascalize'

import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

const applyTimezone = (time, timezone) => moment(time)
    .utcOffset(timezone, true)
    .format()

const createReadingPayload = ({ value, timestamp, type, isGlucose, time = null }) => {
  const format = 'YYYY-MM-DD HH:mm:ss'
  const measurementDate = timestamp

  if (!isGlucose) {
    measurementDate.set({
      hour: 12,
      minute: 0,
    })
  }
  measurementDate.set('second', 0)

  return {
    value,
    measurement_date: measurementDate.utcOffset(0).format(format),
    measurement_type: type,
    ...(time ? { measurement_time: time } : null),
  }
}

export default {
  fetchSettings({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      const id = rootGetters['user/getCurrentUserId']

      loading('glucoseTracking/fetchSettings', true)

      api.get(`api/diabetes-tracking/${id}/settings`)
        .then(({ diabetes_setting }) => {
          commit('setSettings', diabetes_setting)
          resolve()
        })
        .catch(reject)
        .finally(() => {
          loading('glucoseTracking/fetchSettings', false)
        })
    })
  },
  saveSettings({ rootGetters, commit }, form) {
    return new Promise((resolve, reject) => {
      const id = rootGetters['user/getCurrentUserId']

      loading('glucoseTracking/saveSettings', true)

      api.post(`api/diabetes-tracking/${id}/settings`, form)
        .then(({ diabetes_setting }) => {
          commit('setSettings', diabetes_setting)
          resolve()
        })
        .catch(reject)
        .finally(() => {
          loading('glucoseTracking/saveSettings', false)
        })
    })
  },
  fetchTimePeriod({ commit, state, rootGetters }, { period, endDate }) {
    return new Promise((resolve, reject) => {
      const id = rootGetters['user/getCurrentUserId']
      const timezone = rootGetters['user/getTimezoneOffset']
      const _endDate = moment(endDate).endOf('day').utcOffset(0)
      const startDate = _endDate.clone().subtract(1, `${period}s`)
      const { settings } = state

      loading('glucoseTracking/fetchTimePeriod', true)

      api.post(`api/diabetes-tracking/${id}/diabetes/${period}`, {
        startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
        endDate: _endDate.format('YYYY-MM-DD HH:mm:ss'),
      }).then(response => {
        Object.keys(response).forEach(key => {
          if (key !== 'glucose' && key !== 'hba1c') return
          if (!response[key]) return

          response[key].label = response[key].label.map(label => applyTimezone(label, timezone))
        })
        response.from = moment.utc(response.from).utcOffset(timezone).format()
        response.to = moment.utc(response.to).utcOffset(timezone).format()

        commit(`set${pascalize(period)}`, response)
        resolve()
      })
      .catch(() => {
        commit(`set${pascalize(period)}`, {
          from: moment(startDate).format(),
          to: moment(_endDate).format(),
          glucose: null,
          hba1c: null,
          settings,
        })
        reject()
      })
      .finally(() => {
        loading('glucoseTracking/fetchTimePeriod', false)
      })
    })
  },
  saveReading({ rootGetters }, reading) {
    return new Promise((resolve, reject) => {
      const id = rootGetters['user/getCurrentUserId']

      loading('glucoseTracking/saveReading', true)

      api.post(`api/diabetes-tracking/${id}/diabetes`, createReadingPayload(reading))
        .then(resolve)
        .catch(reject)
        .finally(() => {
          loading('glucoseTracking/saveReading', false)
        })
    })
  },
  updateReading(store, { reading, id }) {
    return new Promise((resolve, reject) => {
      loading('glucoseTracking/updateReading', true)

      api.patch(`api/diabetes-tracking/${id}/update`, createReadingPayload(reading))
        .then(resolve)
        .catch(reject)
        .finally(() => {
          loading('glucoseTracking/updateReading', false)
        })
    })
  },
  deleteReading({ commit }, id) {
    return new Promise((resolve, reject) => {
      loading('glucoseTracking/deleteReading', true)

      api.delete(`api/diabetes-tracking/me/diabetes/${id}`)
        .then(() => {
          loading('glucoseTracking/deleteReading', false)
          resolve()
        })
        .catch(() => {
          loading('glucoseTracking/deleteReading', false)
          reject()
        })
    })
  },
}
