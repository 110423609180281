import getCssColorVariable from '@/helpers/getCssColorVariable'

export default {
  getChartDataByTimePeriod: state => period => {
    if (!state[period]) return

    const colorPrimary = getCssColorVariable('primary')
    const colorDarkPrimary = getCssColorVariable('dark-primary')

    return {
      data: {
        labels: state[period].label,
        datasets: [{
          backgroundColor: state[period].value.map((val, i) => (
            i == state[period].value.length - 1 ? colorPrimary : colorDarkPrimary
          )),
          data: state[period].value,
        }],
      },
    }
  },
}
