import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('program', [
      'getSectionNameOfArticle',
    ]),
    ...mapGetters('triggers', [
      'getTriggerBySlug',
      'getTriggerBySlugs',
    ]),
    pluginName() {
      const { trigger: slug } = this.$route?.meta || {}

      if (!slug) return null

      const trigger = Array.isArray(slug)
        ? this.getTriggerBySlugs(slug)
        : this.getTriggerBySlug(slug)

      return trigger?.plugin?.name
    },
    sectionName() {
      return this.getSectionNameOfArticle(this.$route?.params?.id)
    },
    routeHeader() {
      return this.$route.meta?.header
        || this.$route.matched
          .reduce((acc, { meta }) => (
            meta?.header || acc
          ), '')
    },
    routeName() {
      return this.$route.name
    },
    pageName() {
      return this.sectionName
        || this.pluginName
        || this.sectionName
        || this.routeHeader
        || this.routeName
    },
  },
}
