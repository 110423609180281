export default () => ({
  plugin: null,
  goals: null,
  goal: null,
  categories: [
    { id: 1, text: 'Main Goal' },
    { id: 2, text: 'To Help me achieve my Main Goal' },
    { id: 3, text: 'Food Tracking' },
    { id: 4, text: 'Weight' },
    { id: 5, text: 'Step' },
  ],
})
