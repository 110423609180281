export default (ctx, store, echo) => {
  let uid = null

  const _getUserId = () => store.getters['user/getCurrentUserId']
  const _getProgramId = () => store.getters['program/currentProgramId']

  const _updateUid = () => {
    const programId = _getProgramId()
    const userId = _getUserId()
    const newUid = `${userId}.${programId}`

    if (uid != newUid) {
      if (uid) {
        echo.leave(`progress-updated.${uid}`)
        uid = null
      }

      if (!userId || !programId) return

      if (newUid) {
        echo.private(`progress-updated.${newUid}`)
          .listen('ProgressUpdated', progress => {
            store.commit('program/setProgress', progress)
          })
      }

      uid = newUid
    }
  }

  store.subscribe(mutation => {
    const { type } = mutation

    if (type == 'auth/logout'
      || type == 'auth/resetState'
      || type == 'user/setUser'
      || type == 'user/decodeUser'
      || type == 'package/setPackage'
      || type == 'program/setProgram') {
      _updateUid()
    }
  })
}
