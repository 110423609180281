import { sortBy } from 'lodash'

const findLanguage = allLanguages => lang => {
  const foundLanguage = allLanguages.find(({ locale }) => locale === lang.value)

  if (!foundLanguage) return null

  return {
    ...foundLanguage,
    default: lang.default,
  }
}

export default {
  packageTranslatedLanguages: state => {
    const langs = state.packageLanguages
      .map(findLanguage(state.allLanguages))
      .filter(Boolean)

    return [
      ...langs.filter(el => el.default),
      ...sortBy(langs.filter(el => !el.default), 'name'),
    ]
  },
}
