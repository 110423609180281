var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('journey-section-item',_vm._b({},'journey-section-item',{ isCompleted: _vm.isCompleted, isActive: _vm.isActive },false),[_c('router-link',{staticClass:"journey-article",class:{
      'journey-article--disabled': _vm.isDisabled,
      'journey-article--is-active': _vm.isActive,
    },attrs:{"tabindex":"0","data-testid":"journey-article","aria-disabled":_vm.isDisabled,"to":_vm.articleLink},nativeOn:{"click":function($event){return _vm.checkIfLaunchFullScreen(_vm.item.type)}}},[_c('journey-image',_vm._b({attrs:{"data-testid":"journey-article-image"}},'journey-image',{
        hasImage: _vm.hasImage,
        image: _vm.image,
        isCompleted: _vm.isCompleted,
      },false)),_c('div',{staticClass:"journey-article__info"},[_c('journey-type',{attrs:{"data-testid":"journey-article-type","type":_vm.item.type}}),_c('journey-status',_vm._b({attrs:{"data-testid":"journey-article-status"}},'journey-status',{ isCompleted: _vm.isCompleted },false)),_c('journey-title',_vm._b({attrs:{"data-testid":"journey-article-title"}},'journey-title',{ title: _vm.title },false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }