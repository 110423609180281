import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/weight-tracking/',
    sharedMeta: {
      fetchProgram: false,
      layout: 'home',
      toParent: true,
      trigger: 'weight-tracking',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'WeightTracking',
    },
  },
  routes: [
    {
      path: '/weight-tracking',
      name: 'WeightTracking',
      props: true,
      meta: {
        pluginHome: true,
        parent: 'Tools',
        header: 'Weight tracking',
      },
    },
    {
      path: '/weight-tracking/start',
      name: 'WeightTrackingStart',
      meta: {
        header: 'Weight Tracking Start',
      },
    },
    {
      path: '/weight-tracking/log/:type',
      props: true,
      name: 'WeightTrackingLog',
      meta: {
        header: 'Weight Tracking Log',
        parent: 'WeightTracking',
      },
    },
    {
      path: '/weight-tracking/log/:id',
      props: true,
      name: 'WeightTrackingLog',
      meta: {
        header: 'Weight Tracking Log',
        parent: 'WeightTracking',
      },
    },
    {
      path: '/weight-tracking/delete/:id?',
      name: 'WeightTrackingDelete',
      props: true,
      meta: {
        parent: 'WeightTracking',
        header: 'Weight tracking Delete',
      },
    },
  ],
}
