<template>
  <base-main
    background
    class="tools"
    data-testid="tools"
  >
    <container>
      <h2
        class="tools__heading"
        data-testid="tools-heading"
      >
        {{ $t(`${$options.slug}.heading`) }}
      </h2>
      <p
        class="tools__description"
        data-testid="tools-description"
      >
        {{ $t(`${$options.slug}.description`) }}
      </p>

      <template
        v-if="hasUnlockedTriggers"
      >
        <h2
          class="tools__heading"
          data-testid="tools-heading"
        >
          {{ $t(`${$options.slug}.active`) }}
        </h2>

        <div class="tools__grid">
          <base-anchor
            v-for="(trigger, i) in unlockedTriggers"
            :key="i"
            data-testid="tools-base-anchor"
            class="tools__base-anchor"
            :to="getTagAttributes(trigger.unlocked, trigger.slug).link">
            <modular-tile
              data-testid="tools-modular-tile"
              :headerLevel="3"
              :headerText="trigger.plugin.name"
              :image="getImage(trigger.plugin)"
              :imagePosition="'top'"
              :showLinkToTitle="true"
            >
              <p>
                {{ trigger.plugin.description }}
              </p>
              <template #action>
                <tag
                  :tag="getTagAttributes(trigger.unlocked, trigger.slug).tag"
                  :to="getTagAttributes(trigger.unlocked, trigger.slug).link"
                  data-testid="tools-use-tool"
                  @click.native="saveTriggerAccess(trigger.plugin_id)"
                >
                  {{ trigger.plugin.name }}
                </tag>
              </template>
            </modular-tile>
          </base-anchor>
        </div>
      </template>

      <template
        v-if="hasLockedTriggers"
      >
        <h2
          v-if="getLockedTriggers.length"
          class="tools__heading"
          data-testid="tools-heading"
        >
          {{ $t(`${$options.slug}.locked-tools`) }}
          <tooltip
            :message="$static.lockedTooltipText"
          />
        </h2>

        <div class="tools__grid">
          <modular-tile
            v-for="(trigger, i) in lockedTriggers"
            :key="i"
            data-testid="tools-modular-tile"
            :headerText="trigger.plugin.name"
            :headerLevel="3"
            :image="getImage(trigger.plugin)"
            :imagePosition="'top'"
          >
            <p>
              {{ trigger.plugin.lockedMessage }}
            </p>
            <template #action>
              <span class="anchor anchor--disabled">
                {{ $t(`${$options.slug}.locked`) }}
              </span>
            </template>
          </modular-tile>
        </div>
      </template>
    </container>
  </base-main>
</template>

<script>
  import { capitalize } from 'lodash'
  import { mapActions, mapGetters } from 'vuex'

  import { BaseAnchor, BaseMain } from '@/components/base'
  import Container from '@/components/container/Container'
  import Tag from '@/components/layout/tag/Tag'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'
  import Tooltip from '@/components/tooltip/Tooltip'

  import requireImage from '@/helpers/requireImage'

  const defaultImagePath = 'plugins/default-plugin.jpg'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.tools.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      ModularTile,
      Tag,
      Tooltip,
      BaseAnchor,
    },

    methods: {
      ...mapActions('triggers', [
        'saveTriggerAccess',
      ]),
      getImage(plugin) {
        let image = plugin.quickAction ? plugin.quickAction.image : plugin.image
        image = image && !image.includes('https') ? requireImage(plugin.image, false) : image

        return image || requireImage(defaultImagePath)
      },
      getTagAttributes(isUnlocked, slug) {
        const route = this.getTriggerRouteBySlug(slug)

        return isUnlocked && route
                ? { tag: 'router-link', link: { name: route } }
                : { tag: 'div', link: null }
      },
      renderText(translateText) {
        return capitalize(translateText)
      },
    },

    computed: {
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('triggers', [
        'getLockedTriggers',
        'getTriggerRouteBySlug',
        'getUnlockedTriggers',
        'hasTriggers',
      ]),
      ...mapGetters('user', [
        'getCurrentUserFirstName',
      ]),
      hasUnlockedTriggers() {
        return !!this.unlockedTriggers.length
      },
      hasLockedTriggers() {
        return !!this.lockedTriggers.length
      },
      unlockedTriggers() {
        return this.getUnlockedTriggers || []
      },
      lockedTriggers() {
        return this.getLockedTriggers || []
      },
    },

    slug: 'views.tools',

    static() {
      return {
        lockedTooltipText: `${this.$options.slug}.locked-tooltip`,
      }
    },
  }
</script>

<style lang="scss">
  $block: 'tools';

  .#{$block} {
    max-width: none;
    @include min-md {
      padding-right: 5.5rem;
      padding-left: 5.5rem;
    }
    &__heading {
      width: 100%;
      margin: 3.5rem 0 1.8rem;
      font-size: 2.2rem;
      font-weight: 800;
      line-height: 2.6rem;
      @include min-md {
        padding-right: 0;
        padding-left: 0;
      }
    }
    &__base-anchor {
      text-decoration: none !important;
      height: 100% !important;
      display: flex;
      font-weight: normal !important;
    }
    &__description {
      width: 100%;
      margin: 0 0 3.6rem;
      @include min-md {
        max-width: 56rem;
        padding-right: 0;
        padding-left: 0;
        line-height: 1.4;
      }
    }
    &__grid {
      position: relative;
      display: grid;
      grid-row-gap: 3rem;
      width: 100%;
      z-index: z-index(default);
      @include min-md {
        grid-column-gap: 3rem;
        grid-template-columns: repeat(3, 1fr);
      }
      @include min-xl {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    .container {
      width: 100%;
      max-width: 36rem;
      padding: 0 2rem $footer-height;
      text-align: left;
      @include min-md {
        max-width: 128rem;
      }
      @include min-lg {
        padding: 0 2rem ($footer-height--lg + 2rem);
      }
    }
    .modular-tile {
      margin: 0;
      &__top-image-row {
        max-height: 8rem;
        img {
          height: 8rem;
          width: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }
    .tooltip-toggle {
      margin-left: -4px;
    }
  }
</style>
