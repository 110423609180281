<template functional>
  <div
    class="progress-indicator"
    :class="[
      { 'progress-indicator--is-contrast': props.isContrast },
      data.staticClass,
    ]">
      <div
        class="progress-indicator-bar"
        role="progressbar"
        aria-valuemax="100"
        aria-valuemin="0"
        :aria-valuenow="props.progress"
        :style="{ width: `${props.progress}%` }"/>
  </div>
</template>

<script>
  export default {
    props: {
      progress: {
        type: Number,
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  $height: 1.3rem;

  .progress-indicator {
    width: 100%;
    height: $height;
    position: relative;
    border-radius: $height / 2;
    background-color: color(progress-base);
    &-bar {
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: width 0.2s;
      border-radius: $height / 2;
      background-color: color(progress-fill);
    }
  }
</style>
