<template>
  <base-main
    data-testid="learn"
    :loading="$options.loading"
  >

    <SpinnerDots
      v-if="isLoadingJourney"
      class="spinner"/>

    <div
      class="learn nhsuk-u-margin-top-6"
      v-else>

      <progress-tile
        v-if="program"
        data-testid="learn-progress"
        :startDate="program.activity.started_at"
        :progress="progressPercent"
      />

      <div
        v-if="program"
        class="learn__journey"
      >
        <accordion
          v-for="(section, i) in program.journey.sections"
          :key="i"
          data-testid="learn-accordion"
          :disabled="isDisabledSection(section)"
          :startExpanded="isActiveSection(section.id)"
          :title="section.display_name"
        >
          <journey-section
            data-testid="learn-section"
            v-bind="{ section }"
          />
        </accordion>

        <div class="learn__completed-wrapper">
          <info-label
            data-testid="learn-completed-information"
            v-if="isJourneyCompleted"
            class="learn__completed"
          >
            {{ $t('Congratulations!') }}<br>
            {{ $t('You completed your programme') }}
          </info-label>
        </div>
      </div>

      <empty-view
        data-testid="learn-empty-view"
        v-else
      >
        {{ $t('You don\'t have any packages assigned.') }}<br>
        {{ $t(`${$options.slug}.contact`) }}
      </empty-view>

    </div>
  </base-main>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import Accordion from '@/components/accordion/Accordion'
  import BaseMain from '@/components/base/BaseMain'
  import EmptyView from '@/components/empty-view/EmptyView'
  import InfoLabel from '@/components/info-label/InfoLabel'
  import { JourneySection } from '@/components/journey'
  import { SpinnerDots } from '@/components/spinner'
  import ProgressTile from '@/components/tiles/progress-tile/ProgressTile'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.learn.meta.title'),
      }
    },

    components: {
      Accordion,
      BaseMain,
      EmptyView,
      InfoLabel,
      JourneySection,
      ProgressTile,
      SpinnerDots,
    },

    watch: {
      sections: {
        handler(newValue) {
          if (newValue?.length > 0) {
            setTimeout(() => {
              this.scrollToCurrentArticle()
            }, 200)
          }
        },
        immediate: true,
      },
    },

    methods: {
      scrollToCurrentArticle() {
        const element = document.querySelector('.journey-section-item--is-active')
        if (element) {
          const absoluteElementTop = element.getBoundingClientRect().top + window.pageYOffset
          const middle = absoluteElementTop - (window.innerHeight / 2)
          setTimeout(() => {
            document.querySelector('main').scrollTo(0, middle)
          }, 200)
        }
      },
      isActiveSection(id) {
        return this.getCurrentSection.id === id
      },
      isDisabledSection(section) {
        return !section.started && !section.completed && !this.isActiveSection(section.id)
      },
    },

    computed: {
      ...mapGetters('program', [
        'getCurrentSection',
        'getCurrentSectionItem',
      ]),
      ...mapState('program', [
        'program',
        'progress',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('layout', [
        'isMobile',
      ]),
      isSomethingMore() {
        return this.program.is_something_more
      },
      progressPercent() {
        return this.progress?.progress
      },
      isJourneyCompleted() {
        return this.progressPercent === 100
      },
      isLoadingJourney() {
        return this.getLoadingStatesForActions([
          'bootstrap/journey',
        ])
      },
      sections() {
        return this.program.journey?.sections
      },
    },

    slug: 'views.learn',

    loading: [
      'program/fetchNextSection',
    ],
  }
</script>

<style lang="scss">
  .spinner {
    margin: auto;
  }
  .learn {
    width: 100%;
    @include min-lg {
      width: 92.2rem;
      text-align: center;
    }
    &__message {
      margin: 2rem 0 1.8rem;
      font-size: 2.2rem;
      font-weight: 400;
      line-height: 2.6rem;
      @include min-lg {
        margin: 3.4rem 0 3.2rem;
        font-size: 3.2rem;
      }
    }
    &__journey {
      width: 100%;
      margin: 2.3rem 0;
      @include min-lg {
        max-width: 92.2rem;
        margin: 4rem 0;
      }
      .nhsuk-details {
        margin-bottom: 0;
      }
      .nhsuk-expander:not([open]) {
        .nhsuk-details__summary {
          padding-bottom: 0 !important;
        }
      }
      .nhsuk-details__summary-text {
        color: color(dark-primary) !important;
        font-size: 1.4rem !important;
        font-weight: 600;
        text-decoration: none;
        @include min-lg {
          font-size: 1.8rem !important;
        }
      }
      .nhsuk-details, .nhsuk-details__summary, .nhsuk-details__text {
        background-color: transparent !important;
        border: none;
      }
    }
    &__completed-wrapper {
      display: flex;
    }
    &__completed {
      margin: 0 auto;
    }
  }
</style>
