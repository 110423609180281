export const getCookie = name => {
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(`${name}=`) === 0) {
      const [, ...tokens] = c.split('=')

      return tokens.join('=')
    }
  }
}

export const removeCookie = name => setCookie(name, null, 'Thu, 01 Jan 1970 00:00:01 GMT')

export const setCookie = (name, data, exp) => {
  document.cookie = `${name}=${data}; path=/; expires=${exp}`
}
