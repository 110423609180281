import { quickActions } from '@/data/plugins'

const getSlug = slug => {
  const [, ...name] = slug.split('-')
  return name.join('-')
}

const simplifyTriggers = triggers => (
  Object.values(triggers)
    .map(trigger => {
      const {
        // default is a reserved word
        plugin: { id },
        slug: oldSlug,
      } = trigger
      const slug = getSlug(oldSlug)
      const unlocked = !!trigger.unlocked
      const imageFallback = `plugins/${slug}.jpg`

      const plugin = {
        id,
        description: trigger.plugin.description,
        image: trigger.plugin.icon?.url || imageFallback,
        lockedMessage: trigger.plugin.locked_message,
        lockedMessageDialog: trigger.plugin.message,
        lockedTitleDialog: trigger.plugin.title,
        name: trigger.plugin.display_name,
        optional: trigger.plugin.optional,
        quickAction: {
          /* eslint-disable-next-line camelcase */
          image: trigger.plugin.quick_icon?.url || imageFallback,
          /* eslint-disable-next-line camelcase */
          label: trigger.plugin.icon_text || quickActions[slug]?.label,
          to: quickActions[slug]?.to,
        },
      }

      return { ...trigger, plugin, slug, unlocked }
    })
)

export default simplifyTriggers
