import jwtDecode from 'jwt-decode'

import storageFactory from '@/tools/storage-factory'

// function userIdFromToken(token) {
//   return jwtDecode(token.replace('Bearer ', ''))?.user?.id
// }

// function compareTokens(tokenA, tokenB) {
//   return userIdFromToken(tokenA) === userIdFromToken(tokenB)
// }

const decodeToken = token => jwtDecode(token.replace('Bearer ', ''))

const isProcessing2fa = token => {
  if (!token) return false

  const { user: { _2fa: has2faEnabled } = {}, verified } = decodeToken(token) || {}

  return has2faEnabled && !verified
}

export default {
  install(Vue, options) {
    const { storage, store } = { storage: localStorage, ...options }
    const storageAccess = storageFactory(storage)

    if (!store) {
      throw new Error('store option is required')
    }

    const { session } = store.state?.auth || {}

    // commented out as auth/init action is dispatched on store init
    // and it sets token as well but without decoding it
    // if (currentToken) {
    //   store.dispatch('auth/decodeJWT', currentToken)
    // }

    window.addEventListener('storage', e => {
      if (e.key == storageAccess.generateStorageKey('Authorization')) {
        const { token: currentToken } = store.state.auth
        const {
          session: otherSession,
          token: newToken,
        } = storageAccess.parseValue(e.newValue) || {}

        // fix for safari
        if ((session == otherSession) || (currentToken == newToken)) {
          return false
        }

        setTimeout(() => {
          if (isProcessing2fa(newToken)) return

          const isUserLoggingOut = currentToken && !newToken
          const hasNewTokenBeenSent = !!newToken
          const isUserLoggingInOrOut = !currentToken || !newToken

          if (isUserLoggingOut) {
            store.dispatch('auth/logout')
          } else if (hasNewTokenBeenSent) {
            store.dispatch('auth/decodeJWT', { accessToken: newToken, omitLocalStorage: true })
          }

          if (isUserLoggingInOrOut) {
            document.location.reload()
          }
        })
      }
    })
  },
}
