import getBrowserLang from '@/helpers/getBrowserLang'
import localStorage from '@/tools/local-storage'

const getUserLang = () => {
  const lang = localStorage.get('X-Custom-Lang', null)

  return lang || getBrowserLang()
}

export default getUserLang
