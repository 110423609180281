import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  fetchLanguagesCoveredByTranslations({ commit }) {
    const action = 'languages/fetchLanguagesCoveredByTranslations'

    loading(action, true)

    return new Promise((resolve, reject) => {
      api.get('api/languages/covered-by-translations')
        .then(({ data }) => {
          commit('setAllLanguages', data)
          resolve()
        })
        .catch(reject)
        .finally(() => loading(action, false))
    })
  },
  fetchLanguages({ commit, rootGetters }) {
    loading('languages/fetchLanguages', true)
    const programId = rootGetters['program/currentProgramId']

    return new Promise((resolve, reject) => {
      api.get(`api/programs/${programId}/languages/list`)
        .then(response => {
          commit('setLanguages', response)
          loading('languages/fetchLanguages', false)

          resolve()
        })
        .catch(() => {
          loading('languages/fetchLanguages', false)

          reject()
        })
    })
  },
  setUserLanguageInProgram({ commit, rootGetters }, data) {
    loading('languages/setUserLanguageInProgram', true)
    const programId = rootGetters['program/currentProgramId']

    return new Promise((resolve, reject) => {
      api.post(`api/programs/${programId}/languages`, data)
        .then(response => {
          const { user_program_language: { language } } = response

          commit('setCurrentUserLanguage', { language, priority: 2 })
          loading('languages/setUserLanguageInProgram', false)

          resolve()
        })
        .catch(() => {
          loading('languages/setUserLanguageInProgram', false)

          reject()
        })
    })
  },

}
