import { kebabCase, partition } from 'lodash'

/**
  * divides passed attrs to 2 collections: baseClass modifiers and other attrs
  * on the basis of modifiers arg - if the modifiers list includes an attribute
  * then it get assigned as a baseClass modifier
  *
  * @param {Object} [data.attrs={}] attrs passed to a component
  * @param {String} [data.baseClass] base class that will receive modifiers
  * @param {Array} [data.modifiers] list of allowed modifiers
  * @return {Object} with classes (baseClass + its modifiers) and other attrs
  *
  * returns object with classes (baseClass + its modifiers) and modifierless attrs
*/

const partitionAttrs = ({ attrs = {}, baseClass, modifiers }) => {
  const [
    attrsModifiers = [],
    attrsRegular = [],
  ] = partition(Object.entries(attrs || {}), ([key]) => modifiers.includes(key))

  const attrsClasses = attrsModifiers.map(([key]) => `${baseClass}--${key}`)
  const newAttrs = Object.fromEntries(attrsRegular.map(([key, val]) => [kebabCase(key), val]))
  const classes = [baseClass, ...attrsClasses]

  return { classes, newAttrs }
}

export default partitionAttrs
