import moment from 'moment'

import shouldFetch from '@/helpers/shouldFetch'
import store from '@/store'
import eventBus from '@/tools/event-bus'

class HealthKitManager {
  constructor() {
    this.isCordova = window.cordova
    this.isPermitted = false
    this.interval = null
  }

  init() {
    this.attach()
    eventBus.$on('healthKitEnabled', this.attach)
  }

  attach() {
    if (!this.isCordova) return

    this.assureAccess()
      .then(() => {
        if (this.interval) return

        this.interval = setInterval(this.sync, 1000 * 60 * 15)
      })
  }

  /* eslint-disable-next-line class-methods-use-this */
  assureAccess() {
    return new Promise((resolve, reject) => {
      window.plugins.healthkit.checkAuthStatus({
        type: 'HKQuantityTypeIdentifierStepCount',
      },
      resolve, // has permission, check every 15 minutes
      reject) // doesn't have permission
    })
  }

  sync() {
    if (!this.isCordova) return

    this.assureAccess()
      .then(this.pullData)
      .then(data => this.saveSteps(data))
      .catch(() => {})
  }

  /* eslint-disable-next-line class-methods-use-this */
  pullData() {
    return new Promise((resolve, reject) => {
      window.plugins.healthkit.querySampleType({
        startDate: moment().startOf('day').toDate(),
        endDate: new Date(),
        sampleType: 'HKQuantityTypeIdentifierStepCount',
        unit: 'count',
      },
      value => resolve(value),
      error => reject(error))
    })
  }

  /* eslint-disable-next-line class-methods-use-this */
  saveSteps(data) {
    const steps = data.reduce((acc, curr) => acc + curr.quantity, 0)

    if (steps < 1) return

    shouldFetch(
      store.state.stepTracking.settings,
      store.dispatch('stepTracking/fetchSettings'),
    )
      .then(settings => {
        if (settings
        && settings.device_uuid == window.device.uuid
        && settings.method_step_tracking == 'automat'
        && settings.tracking_system == 'apple') {
          const formData = new FormData()
          const form = {
            activity: 'HealthKit',
            logging_steps: steps,
          }

          Object.entries(form).forEach(([key, value]) => {
            formData.append(key, value)
          })

          store.dispatch('stepTracking/logSteps', formData)
        }
      })
  }

  grantPermissions() {
    return new Promise((resolve, reject) => {
      if (this.isPermitted) {
        resolve(true)
      } else {
        window.plugins.healthkit.requestAuthorization({
          readTypes: [
            'HKQuantityTypeIdentifierStepCount',
          ],
        },
        () => {
          this.isPermitted = true
          store.dispatch('triggerEvent', {
            eventName: 'Steps API Connected',
            params: {
              source: 'Apple Health',
            },
          })
          resolve(true)
        },
        () => {
          this.isPermitted = false
          reject()
        })
      }
    })
  }

  kill() {
    clearInterval(this.interval)
    eventBus.$off('healthKitEnabled', this.attach)
  }
}

export default new HealthKitManager()
