class CouldNotRegisterUserError extends Error {
  constructor() {
    super(
      `We can't create an account with your email,
      please try again, use another email or contact us for assistance
      at <a href='mailto:customersupport@changinghealth.com'>
      customersupport@changinghealth.com</a>`,
    )

    this.name = 'CouldNotRegisterUserError'
  }
}

export default CouldNotRegisterUserError
