export default () => ({
  isAppKioskMode: false,
  plugins: {
    'activity-quiz': false,
    'carb-calculator': false,
    coaching: false,
    'face-age-test': false,
    'food-tracking': false,
    'goal-plugin': false,
    'risk-calculator': false,
    'shopping-list-switcher': false,
    'step-tracking': false,
    'weight-tracking': false,
  },
})
