export const ALLOWED_KEYS = {
  enter: 13,
  space: 32,
  escape: 27,
}

export const ARTICLE_TYPES = {
  article: 'BaseArticleText',
  survey: 'BaseArticleSurvey',
  video: 'BaseArticleVideo',
  'profile-data': 'BaseArticleProfileData',
}

export const BASE_MAIN_MODIFIERS = [
  'appbar',
  'background',
  'default',
  'flexible-space',
  'is-open-fab',
  'no-top',
  'white',
]

export const BLOOD_READING_TIMES = {
  'Before food': 1,
  'After food': 2,
  'Before bed': 3,
  'After bed': 4,
}

export const BLOOD_READING_TYPES = {
  glucose: 1,
  hba1c: 2,
}

export const DATE_FORMATS = {
  date: 'D MMMM YYYY',
  dateTime: 'D MMMM YYYY H:mm',
  dateTimeShort: 'D MMM YYYY H:mm',
  dateShort: 'D MMM YYYY',
  day: 'D',
  dayMonth: 'D MMMM',
  dayMonthShort: 'D MMM',
  month: 'MMMM',
  monthShort: 'MMM',
  monthYear: 'MMMM YYYY',
  monthYearShort: 'MMM YYYY',
  time: 'H:mm',
  year: 'YYYY',
}

export const FOCUSABLES_SELECTOR = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

export const GLUCOSE_UNITS = {
  1: 'mg/dl',
  2: 'mmol/l',
}

export const GOAL_CATEGORIES = {
  mainGoal: 1,
  helpWithMainGoal: 2,
  foodTracking: 3,
  weightTracking: 4,
  stepTracking: 5,
}

export const HBA1C_UNITS = {
  1: '%',
  2: 'mmol/mol',
}

export const NAME_REGEX = '^(?=.{0,50}$)[\\p{L}]+[ \'.-]?[\\p{L}]+$'

export const NO_AUTH_ENDPOINTS = [
  'api/auth/login',
  'api/auth/logout',
  'api/auth/refresh',
  'api/invitation/',
  'api/lang/statics',
  'api/languages/covered-by-translations',
  'api/user/change-email/verify',
  'api/user/register',
  'api/user/register/can-register',
  'api/user/register/confirm',
  'api/user/reset-password',
  'api/user/unsubscribe',
  'api/public',
  'https://worldtimeapi.org/api/timezone',
]

export const OPTIONAL_TOKEN_ENDPOINTS = [
  'api/user/feedback',
  'api/init',
]

export const PAGE_TYPES = {
  auth: 'auth',
  cms: 'cms',
  other: 'other',
  plugin: 'plugin',
  profile: 'profile',
  tab: 'tab',
}

export const SIBLINGS_LAYOUTS = ['home']

export const SPECIAL_WEIGHT_VALUE = 999

export const UNIT_CONVERTERS = {
  kgInPounds: 2.204622621,
  poundInKgs: 0.45359237,
  poundsInStones: 14,
}

export const VERBS = {
  payload: ['post', 'put', 'patch'],
  noPayload: ['get', 'delete'],
}

export const WEIGHT_UNITS = {
  kg: 0,
  lbs: 1,
  'st-lbs': 2,
}
