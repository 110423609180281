var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({ref:"group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{directives:[{name:"merge-attr",rawName:"v-merge-attr:data-testid",arg:"data-testid"}],staticClass:"input-group",class:{
      'input-group--has-after-text': _vm.afterText,
      'input-group--has-error': errors.length,
      'nhsuk-form-group--error': errors.length,
      'input-group--has-icon': _vm.icon,
      'input-group--has-toggle-mask': _vm.showToggleMask,
      'input-group--has-tooltip': _vm.hasTooltip,
      'input-group--is-disabled': _vm.disabled,
      'input-group--is-focused': _vm.isFocused,
      'input-group--is-required': _vm.isRequired && !_vm.hasHiddenAsterisk
    },attrs:{"data-testid":(_vm.dataTestid + " input-group")}},[(_vm.showLabel && _vm.label)?_c('label',{staticClass:"input-group__label",class:{ 'input-group__label--is-active': _vm.isFocused || _vm.value },attrs:{"for":_vm.name,"data-testid":"input-group-label"}},[_vm._v(" "+_vm._s(_vm.labelText)+" "),(_vm.screenReaderMessage)?_c('screen-readers-only',[_vm._v(" "+_vm._s(_vm.screenReaderMessage)+" ")]):_vm._e()],1):_vm._e(),_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"textarea-group__errors",attrs:{"id":_vm.descriptionId}},[_c('span',{staticClass:"nhsuk-error-message"},[_c('span',{staticClass:"nhsuk-u-visually-hidden"},[_vm._v(_vm._s(_vm.i18n('(Error)')))]),_vm._v(" "+_vm._s(error)+" ")])])}),_vm._t("description"),_c('div',{staticClass:"input-group__container"},[((_vm.inputType)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"input-group__input nhsuk-input",attrs:{"data-testid":"input-group-input","aria-describedby":_vm.descriptionId || _vm.describedBy,"aria-invalid":!!errors.length,"aria-label":_vm.customAriaLabel,"aria-required":_vm.validation.required,"id":_vm.name,"placeholder":_vm.placeholderProp,"readonly":_vm.readonlyProp,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"blur":_vm.onBlur,"click":_vm.onClick,"focus":_vm.onFocus,"keydown":_vm.onKeydown,"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}},'input',Object.assign({}, {autocomplete: _vm.autocomplete,
          disabled: _vm.disabled,
          name: _vm.name},
          _vm.additional,
          _vm.extraProps,
          _vm.ariaLabelProp),false)):((_vm.inputType)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"input-group__input nhsuk-input",attrs:{"data-testid":"input-group-input","aria-describedby":_vm.descriptionId || _vm.describedBy,"aria-invalid":!!errors.length,"aria-label":_vm.customAriaLabel,"aria-required":_vm.validation.required,"id":_vm.name,"placeholder":_vm.placeholderProp,"readonly":_vm.readonlyProp,"type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"blur":_vm.onBlur,"click":_vm.onClick,"focus":_vm.onFocus,"keydown":_vm.onKeydown,"change":function($event){_vm.model=null}}},'input',Object.assign({}, {autocomplete: _vm.autocomplete,
          disabled: _vm.disabled,
          name: _vm.name},
          _vm.additional,
          _vm.extraProps,
          _vm.ariaLabelProp),false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",staticClass:"input-group__input nhsuk-input",attrs:{"data-testid":"input-group-input","aria-describedby":_vm.descriptionId || _vm.describedBy,"aria-invalid":!!errors.length,"aria-label":_vm.customAriaLabel,"aria-required":_vm.validation.required,"id":_vm.name,"placeholder":_vm.placeholderProp,"readonly":_vm.readonlyProp,"type":_vm.inputType},domProps:{"value":(_vm.model)},on:{"blur":_vm.onBlur,"click":_vm.onClick,"focus":_vm.onFocus,"keydown":_vm.onKeydown,"input":function($event){if($event.target.composing){ return; }_vm.model=$event.target.value}}},'input',Object.assign({}, {autocomplete: _vm.autocomplete,
          disabled: _vm.disabled,
          name: _vm.name},
          _vm.additional,
          _vm.extraProps,
          _vm.ariaLabelProp),false)),(_vm.icon)?_c('inline-svg',{staticClass:"input-group__icon",attrs:{"data-testid":"input-group-icon","src":(_vm.icon + ".svg")},on:{"click":_vm.onClick}}):_vm._e(),(_vm.afterText)?_c('span',{staticClass:"input-group__after-text"},[_vm._v(" "+_vm._s(_vm.afterText)+" ")]):_vm._e(),(_vm.showToggleMask)?_c('button-masked',_vm._b({staticClass:"input-group__button-masked",attrs:{"data-testid":"input-group-toggle-masked"},on:{"click":function($event){return _vm.toggle('isMasked')}}},'button-masked',{ isMasked: _vm.isMasked },false)):_vm._e(),(_vm.hasTooltip)?_c('tooltip',{attrs:{"message":_vm.tooltip}}):_vm._e()],1)],2)]}}],null,true)},'form-group',{
    descriptionId: _vm.descriptionId,
    helper: _vm.helper,
    label: _vm.label,
    name: _vm.name,
    tag: _vm.tag,
    testid: _vm.testid,
    validation: _vm.validation,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }