import { p, saveState, setNewState, translations } from './helpers'
import initialState from './state'

const AES = require('crypto-js/aes')
const enc = require('crypto-js/enc-utf8')

export default {
  setAppKioskMode(state, boolean) {
    state.isAppKioskMode = boolean
    saveState(state, AES, enc)
  },
  setPluginKioskMode(state, [pluginName, boolean]) {
    /* eslint-disable indent */
    const name = Object.keys(state.plugins).includes(pluginName)
                  ? pluginName
                  : translations[pluginName]
    /* eslint-enable */

    state.plugins[name] = boolean
    saveState(state, AES, enc)
  },
  setState(state, localStorageState) {
    setNewState(state, JSON.parse(AES.decrypt(localStorageState, p).toString(enc)))
  },
  resetState(state) {
    setNewState(state, initialState())
  },
}
