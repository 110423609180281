export default {
  methods: {
    exitFullscreen() {
      const element = document.documentElement

      if (element.exitFullscreen) {
        element.exitFullscreen()
      } else if (element.mozCancelFullScreen) {
        element.mozCancelFullScreen()
      } else if (element.webkitExitFullscreen) {
        element.webkitExitFullscreen()
      } else if (element.msExitFullscreen) {
        element.msExitFullscreen()
      }
    },
  },
}
