import { merge } from 'lodash'
import Vue from 'vue'

import initialState from './state'

export default {
  setTopics(state, topics) {
    state.topics = [...topics]
  },
  setCategories(state, categories) {
    state.categories = state.categories
      ? merge(state.categories, categories)
      : categories
    state.categories = categories
  },
  setCategoriesItemsIds(state, categoriesItemsIds) {
    state.categoriesItemsIds = categoriesItemsIds
  },
  setCategoriesItemsOrder(state, categoriesItemsOrder) {
    state.categoriesItemsOrder = categoriesItemsOrder
  },
  setCategory(state, category) {
    const id = Object.keys(category)[0]

    state.category = category

    if (!state.categories) return

    state.categories[id] = category[id]
  },
  setCategoryItemsIds(state, categoryItemsIds) {
    state.categoryItemsIds = categoryItemsIds
  },
  setCategoryItemsOrder(state, categoryItemsOrder) {
    state.categoryItemsOrder = categoryItemsOrder
  },
  setContentUser(state, { id, content_user, source }) {
    const { name, categoryId } = source

    categoryId
      ? Vue.set(state[name][categoryId].items[id], 'content_user', content_user)
      : Vue.set(state[name][id], 'content_user', content_user)
  },
  setItem(state, item) {
    state.item = item
  },
  setContentDetails(state, { id, contentDetails }) {
    Vue.set(state.contentDetails, id, contentDetails)
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
