export default {
  setDialog(state, dialog) {
    state.dialog = dialog
  },
  setIsDialogOpen(state, boolean) {
    state.isDialogOpen = boolean
  },
  setLastFocus(state, el) {
    state.lastFocus = el
  },
}
