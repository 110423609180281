import Vue from 'vue'

import localStorage from '@/tools/local-storage'

const p = 'LqNSCQixd0iPv4'

const translations = {
  goal: 'goals-plugin',
  'goal-plugin': 'goals-plugin',
  'coaching-plugin': 'coaching',
}

const saveState = (state, AES) => {
  const encrypted = AES.encrypt(JSON.stringify(state), p)
  localStorage.set('kioskMode', encrypted.toString())
}

const setNewState = (state, newState) => {
  Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
}

export {
  p,
  translations,
  saveState,
  setNewState,
}
