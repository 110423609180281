import Vue from 'vue'

import getBrowserLang from '@/helpers/getBrowserLang'
import getUniqueArrayByKey from '@/helpers/getUniqueArrayByKey'

import { requestLanguageStatics } from '@/i18n'

import { api } from '@/tools/api'
import localStorage from '@/tools/local-storage'

import initialState from './state'

export default {
  setAllLanguages(state, languages) {
    state.allLanguages = languages
  },
  setPackageLanguagesFromIntro(state, packageIntro) {
    const {
      default_languages_ordered: defaultLanguagesOrdered,
      used_languages: usedLanguages,
    } = packageIntro

    const packageLanguagesWithDefaultsFirst = [
      ...Object.values(defaultLanguagesOrdered).map(value => ({ default: true, value })),
      ...usedLanguages.map(value => ({ default: false, value })),
    ]

    state.packageLanguages = getUniqueArrayByKey(packageLanguagesWithDefaultsFirst, 'value')
  },
  setLanguages(state, languages) {
    state.languages = languages
  },
  setCurrentUserLanguage(state, { language, priority }) {
    if (priority < state.currentLanguagePriority) return

    const langFromStorage = localStorage.get('X-Custom-Lang', null)
    const lang = language || langFromStorage || getBrowserLang()

    state.currentUserLanguage = lang
    state.currentLanguagePriority = priority
    api._setLangHeader(lang)
    requestLanguageStatics(lang)
  },
  resetState(state) {
    const newState = initialState()

    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
