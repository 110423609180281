import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

let echo

Pusher.logToConsole = true

const poxa = process.env.VUE_APP_POXA

export default function _echo(config) {
  if (!echo) {
    Object.assign(config, {
      broadcaster: process.env.VUE_APP_ECHO_BROADCASTER,
      key: process.env.VUE_APP_ECHO_KEY,
      cluster: process.env.VUE_APP_ECHO_CLUSTER,
      encrypted: process.env.VUE_APP_ECHO_ENCRYPTED,
    })

    if (poxa === 'yes') {
      Object.assign(config, {
        httpHost: `${process.env.VUE_APP_POXA_WS_HOST}:${process.env.VUE_APP_POXA_WS_PORT}`,
        httpPort: `${process.env.VUE_APP_POXA_WS_HOST}:${process.env.VUE_APP_POXA_WS_PORT}`,
        wsHost: process.env.VUE_APP_POXA_WS_HOST,
        wsPort: process.env.VUE_APP_POXA_WS_PORT,
        wssHost: process.env.VUE_APP_POXA_WS_HOST,
        key: process.env.VUE_APP_POXA_KEY,
        wssPort: process.env.VUE_APP_POXA_WS_PORT,
        enabledTransports: ['ws', 'flash'],
        disabledTransports: ['flash'],
        encrypted: false,
      })
    }

    echo = new Echo(config)
  }

  return echo
}
