import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {
  fetchProblemCreatedDate({ commit }, userdId) {
    return new Promise((resolve, reject) => {
      loading('trickySituations/fetchProblemCreatedDate', true)

      api.get(`api/user/${userdId}/optional-data/quiz-tricky-situations/tricky-situations-problem`)
        .then(response => {
          if (response.updated_at) {
            commit('setProblemCreatedDate', response.updated_at)
          }
          loading('trickySituations/fetchProblemCreatedDate', false)

          resolve()
        })
        .catch(() => {
          loading('trickySituations/fetchProblemCreatedDate', false)

          reject()
        })
    })
  },
}
