<template>
  <component :is="tag">
    <slot/>
  </component>
</template>

<script>
  import { clamp } from 'lodash'

  export default {
    props: {
      level: {
        type: Number,
        required: true,
      },
    },

    computed: {
      tag() {
        return `h${clamp(this.level, 1, 6)}`
      },
    },
  }
</script>
