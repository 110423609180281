<template>
  <base-dialog
    data-testid="dialog-kiosk-mode-pin"
    :headerText="$t(`${$options.slug}.title`, { action, mode })"
  >
    <i18n
      data-testid="dialog-kiosk-mode-pin-content"
      :path="$t(`${$options.slug}.content`, { action, mode })"
      tag="p"
    >
      <template #action>
        {{ $t(action) }}
      </template>
      <template #mode>
        {{ mode }}
      </template>
    </i18n>
    <base-form
      ref="form"
      :testid="'dialog-kiosk-mode-pin'"
      @submit="onSubmit">
        <input-group
          :label="`${$options.slug}.pin`"
          :name="'pin'"
          :testid="'dialog-kiosk-mode-pin'"
          :type="'text'"
          :validation="$options.validation.pin"
          v-model="form.pin"
        />
    </base-form>

    <template #action>
      <base-button
        data-testid="dialog-kiosk-mode-pin-close"
        @click="closeDialog"
      >
          {{ $t('Close') }}
      </base-button>
      <base-button
        data-testid="dialog-kiosk-mode-pin-submit"
        :isLoading="isSaving"
        :disabled="!form.pin || isSaving"
        @click="onSubmit"
      >
          {{ $t(action) }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
    BaseForm,
  } from '@/components/base'
  import InputGroup from '@/components/forms/input-group/InputGroup'

  import capitalize from '@/filters/capitalize'

  import exitFullscreen from '@/mixins/fullscreen/exitFullscreen'
  import addCustomValidatiorError from '@/mixins/validation/addCustomValidatorError'

  export default {
    components: {
      BaseButton,
      BaseDialog,
      BaseForm,
      InputGroup,
    },

    mixins: [
      addCustomValidatiorError,
      exitFullscreen,
    ],

    props: {
      eventBus: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      selectedMode: {
        type: String,
        required: false,
        default: '',
      },
      selectedPlugin: {
        type: String,
        required: false,
        default: '',
      },
    },

    data() {
      return {
        form: {
          pin: null,
        },
      }
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
        'openDialog',
      ]),
      ...mapActions('kioskMode', [
        'disableAppKioskMode',
        'enableAppKioskMode',
        'disablePluginKioskMode',
        'enablePluginKioskMode',
      ]),
      async onSubmit() {
        const isFormValid = await this.$refs.form.$refs.observer.validate()

        if (!isFormValid || this.isSaving) return

        const { action } = this
        const args = {
          form: this.form,
          programId: this.currentProgramId,
          pluginName: this.pluginName,
        }

        this[`${action}${this.mode}KioskMode`](args)
          .then(isPinValid => {
            if (this.eventBus) {
              this.eventBus.$emit('pinValidation', isPinValid)
            }

            isPinValid
              ? this.onValid(action)
              : this.addCustomValidatorError('pin', 'Invalid PIN')
          })
      },
      onValid(action) {
        action == 'enable'
          ? this.openDialog({ component: 'KioskModeEnabled', props: { mode: this.mode } })
          : this.close()

        if (this.selectedPlugin) {
          this.$router.replace({ name: this.getTriggerRouteBySlug(this.selectedPlugin) })
        }
      },
      close() {
        this.closeDialog()

        if (!this.isPWA) {
          this.exitFullscreen()
        }
      },
    },

    computed: {
      ...mapGetters('kioskMode', [
        'getCurrentPluginName',
        'isPluginKioskMode',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('triggers', [
        'getTriggerRouteBySlug',
      ]),
      ...mapState('kioskMode', [
        'isAppKioskMode',
      ]),
      ...mapState('layout', [
        'isPWA',
      ]),
      action() {
        return this.isKioskMode
                ? 'disable'
                : 'enable'
      },
      headerTitle() {
        return `${capitalize(this.action)} ${this.mode} Kiosk Mode`
      },
      isKioskMode() {
        return this.isAppKioskMode || this.isPluginKioskMode
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'kioskMode/disableAppKioskMode',
          'kioskMode/enableAppKioskMode',
          'kioskMode/disablePluginKioskMode',
          'kioskMode/enablePluginKioskMode',
        ])
      },
      mode() {
        return this.selectedMode || (this.isAppKioskMode ? 'App' : 'Plugin')
      },
      pluginName() {
        return this.getCurrentPluginName || this.selectedPlugin
      },
    },

    validation: {
      pin: {
        required: true,
      },
    },

    slug: 'component.dialog.pin-kiosk-mode-dialog',
  }
</script>
