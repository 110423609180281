import defaultAvatar from '@/assets/img/default-avatar.jpg'

export default {
  // eslint-disable-next-line arrow-body-style
  isUserLoggedIn: state => {
    return !!state?.user
  },
  /* eslint-disable-next-line camelcase */
  currentUserFullName: state => state?.user?.full_name,
  getCurrentUserAge: state => {
    if (!state?.user?.birthdate) return

    return new Date().getFullYear() - Number(state.user.birthdate.split('-')[0])
  },
  /* eslint-disable camelcase */
  getCurrentUserAvatar: state => state?.user?.avatar_url || defaultAvatar,
  getCurrentUserId: state => state?.user?.id,
  getCurrentUserFirstName: state => state?.user?.first_name,
  getCurrentUserLandlinePhone: state => state?.user?.landline_phone,
  getCurrentUserPhone: state => state?.user?.phone,
  getCurrentUserDistanceUnit: state => state?.user?.distance_unit,
  getCurrentUserUnitId: state => state?.user?.unit_id,
  /* eslint-enable */
  getCurrentUserWeightUnit: state => {
    /* eslint-disable camelcase */
    if (state?.user?.distance_unit === 0) {
      return { name: 'kilograms', abbr: 'kg' }
    }
    if (state?.user?.distance_unit === 1) {
      return { name: 'pounds', abbr: 'lbs' }
    }
    // isStonesAndPounds
    return { name: 'stonesAndPounds', abbr: ['st', 'lbs'] }
  },
  getPPUid: state => state?.user?.ppuid,
  /* eslint-disable camelcase */
  hasCurrentUserEnabledPackage: state => !!state?.user?.package_enabled,
  isMetric: state => state?.user?.distance_unit === 0,
  isPounds: state => state?.user?.distance_unit === 1,
  isStonesAndPounds: state => state?.user?.distance_unit === 2,
  hasCurrentUserUncompletedProfile: state => state?.user?.has_completed_profile === 0,
  /* eslint-enable camelcase */
  getTimezone: state => state?.user?.timezone || 'Europe/London',
  getTimezoneOffset: state => state?.user?.timezoneOffset || '+00:00',
  getTimezoneOffsetNumber: state => Number(state?.user?.timezoneOffset?.split(':')[0]) || 0,
}
