export default {
  hasCurrentUserUncompletedProfile: (state, getters, rootState, rootGetters) => (
    rootGetters['user/hasCurrentUserUncompletedProfile']
  ),
  getFirstLegalDocument: (state, getters) => {
    if (getters.hasCurrentUserUncompletedProfile) return null

    return state.legalDocuments?.[state.legalDocumentsOrder?.[0]]
  },
  hasLegalDocumentToAccept: (state, getters) => (
    !!state.legalDocumentsOrder.length
      && !getters.hasCurrentUserUncompletedProfile
  ),
}
