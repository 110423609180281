<template>
  <portal
    :to="'camera'"
  >
    <transition-scale>
      <div
        v-if="show"
        class="camera"
        role="dialog"
        aria-modal="true"
        :aria-label="$t('component.camera.capture')"
      >
        <focus-lock>
          <div class="camera__video-container">
            <!-- eslint-disable-next-line vuejs-accessibility/media-has-caption -->
            <video
              ref="video"
              autoplay
              class="camera__video"
            />
          </div>
          <div
            v-if="hasError"
            class="camera__overlay camera__overlay--error"
          >
            {{ $t('component.camera.problem') }}
          </div>
          <div class="camera__overlay camera__overlay--actions">
            <base-button
              class="camera__cancel"
              data-testid="camera-cancel"
              @click.stop="closeCamera"
            >
              {{ $t('Cancel') }}
            </base-button>
            <base-button
              v-if="!hasError"
              class="camera__take"
              data-testid="camera-take"
              @click.stop="takeSnapshot"
            >
              {{ $t('Take photo') }}
            </base-button>
          </div>
        </focus-lock>
      </div>
    </transition-scale>
  </portal>
</template>

<script>
  import 'blueimp-canvas-to-blob'
  import FocusLock from 'vue-focus-lock'

  import { BaseButton } from '@/components/base'

  import TransitionScale from '@/transitions/transition-scale/TransitionScale'

  export default {
    components: {
      BaseButton,
      FocusLock,
      TransitionScale,
    },

    props: {
      isVisible: {
        type: Boolean,
        required: true,
        default: false,
      },
    },

    watch: {
      isVisible() {
        this.toggleGlobalAriaHidden()

        if (!this.isVisible) return

        this.show = this.isVisible
        this.startStreaming()
      },
      immediate: true,
    },

    data() {
      return {
        hasError: false,
        image: null,
        imagePreview: '',
        show: this.isVisible,
        stream: null,
      }
    },

    methods: {
      startStreaming() {
        if (navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
            .then(stream => {
              const { video } = this.$refs
              video.srcObject = stream
              this.stream = stream
            })
            .catch(() => {
              this.hasError = true
            })
        }
      },
      takeSnapshot() {
        const { video } = this.$refs
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const width = video.videoWidth
        const height = video.videoHeight

        canvas.width = width
        canvas.height = height

        context.drawImage(video, 0, 0, width, height)

        this.imagePreview = canvas.toDataURL('image/png')

        canvas.toBlob(blob => {
          this.image = blob
          this.closeCameraAfterSnapshot()
        })
      },
      closeCameraAfterSnapshot() {
        this.$emit('capture', { image: this.image, imagePreview: this.imagePreview })
        this.closeCamera()
      },
      closeCamera() {
        if (this.stream) {
          this.stream.getTracks().forEach(track => track.stop())
        }
        this.show = false
        this.hasError = false
        this.$emit('close')
      },
      toggleGlobalAriaHidden() {
        const toHide = ['.view', '.overlay', '.dialogs']

        toHide.forEach(
          selector => document.querySelector(`#app > ${selector}`)?.setAttribute('aria-hidden', this.isVisible),
        )
      },
    },
  }
</script>

<style lang="scss">
  .camera {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: z-index(camera);
    background-color: color(_black);
    &__video-container {
      width: 100%;
      padding-bottom: 75%;
    }
    &__video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &__overlay {
      display: flex;
      justify-content: center;
      width: 100%;
      position: absolute;
      left: 0;
      &--actions {
        bottom: 2rem;
      }
      &--error {
        align-items: center;
        height: 100%;
        top: 0;
        color: color(_white);
        font-size: 1.5rem;
        font-weight: 400;
      }
    }
    &__cancel {
      color: color(_white);
    }
  }
</style>
