<script>
  import { kebabCase } from 'lodash'

  import { hasSlot } from '@/helpers'

  export default {
    functional: true,

    props: {
      direction: {
        type: String,
        default: 'row',
      },
      height: {
        type: [Number, String],
        default: 'auto',
      },
      tag: {
        type: String,
        default: 'div',
      },
      width: {
        type: [Number, String],
        default: '100%',
      },
    },

    render(h, { data, props, slots }) {
      const { attrs: dataAttrs = {}, staticClass, ...newData } = data
      const blockClass = 'simple-tile'
      const styles = () => {
        const customDimensions = {
          width: 'auto',
          height: 'auto',
        }

        if (props.width !== 'auto') {
          customDimensions.width = `${props.width}px`
          customDimensions.display = 'inline-flex'
        }
        if (props.height !== 'auto') {
          customDimensions.height = props.height
        }

        return customDimensions
      }
      const { attrs, modifiers } = Object.entries(dataAttrs)
        .reduce((acc, [key, value]) => {
          value
            ? acc.attrs[key] = value
            : acc.modifiers.push(kebabCase(key))

          return acc
        }, { attrs: {}, modifiers: [] })
      const classes = [
        blockClass,
        ...modifiers.map(modifier => `${blockClass}--${modifier}`),
        staticClass,
      ].join(' ')

      /* eslint-disable operator-linebreak */
      return (
        <props.tag
          staticClass={ classes }
          style={ styles }
          {...{ attrs, ...newData }}>
            <div class={`${blockClass}__main`}>
              {
                hasSlot(slots, 'default') &&
                  <div
                    class={`${blockClass}__content`}
                    style={ { flexDirection: props.direction } }>
                    {slots().default}
                  </div>
              }
            </div>
        </props.tag>
      )
      /* eslint-enable operator-linebreak */
    },
  }
</script>

<style lang="scss">
  $block: simple-tile;
  $padding-sm: 1.4rem;
  $padding-lg: 2.2rem;
  $border-radius: 1rem;

  .#{$block} {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: $padding-lg;
    position: relative;
    background-color: color(_white);
    border-radius: $border-radius;
    box-shadow: box-shadow(default);
    font-size: 1.4rem;
    text-decoration: none;
    &--background-image {
      background-size: contain;
      background-position: center;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: z-index(default);
        background: linear-gradient(to right,
          white 10%,
          rgba(255,255,255,0.96) 30%,
          rgba(255,255,255,0) 80%);
        border-radius: $border-radius;
      }
    }
    &--is-empty {
      box-shadow: none;
      border: 2px dashed color(grey);
      background-color: transparent;
      color: color(grey);
    }
    &--dark {
      background-color: color(dark-primary);
      .#{$block}__header {
        color: color(primary);
      }
      .#{$block}__content {
        color: color(_white);
      }
    }
    &--centered {
      text-align: center;
    }
    &--align-right {
      text-align: right;
      &::before {
        background: linear-gradient(to left,
          white 10%,
          rgba(255,255,255,0.96) 30%,
          rgba(255,255,255,0) 80%);
      }
    }
    &__main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: $padding-sm;
      // z-index: z-index(over-base);
      @include min-lg {
        padding: $padding-lg;
      }
    }
    &__content {
      display: flex;
      flex-grow: 1;
      z-index: z-index(over-base) + 1;
    }
    @include min-lg {
      margin-bottom: $padding-lg;
    }
  }
</style>
