import each from 'lodash/each'

const traverseModules = (modules, store, name, ...args) => {
  each(modules, module => {
    if (module._rawModule[name]) {
      module._rawModule[name](module.context, store, ...args)
    }

    traverseModules(module._children, store, name, ...args)
  })
}

export default traverseModules
