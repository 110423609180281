import axios from 'axios'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import store from '@/store'
import getUserLang from '@/store/helpers/getUserLang'

const defaultHtmlLang = 'en'

Vue.use(VueI18n)

export const i18n = new VueI18n()

export function requestLanguageStatics(lang) {
  if (i18n.locale === lang) return

  store.dispatch('bootstrap/init')
}

export function setI18nStatics(lang, translates) {
  const [baseLang] = lang?.split('_') || []
  const htmlLang = baseLang?.toLowerCase() || defaultHtmlLang

  i18n.setLocaleMessage(lang, translates)
  i18n.locale = lang || getUserLang()

  if (Object.prototype.hasOwnProperty.call(lang, 'replace')) {
    axios.defaults.headers.common['Accept-Language'] = lang.replace('_', '-')
  }
  document.documentElement.setAttribute('lang', htmlLang)

  return lang
}
