import Vue from 'vue'

import initialState from './state'

export default {
  setPlugin(state, payload) {
    Vue.set(state, 'plugin', payload)
  },
  setGoals(state, payload) {
    Vue.set(state, 'goals', payload)
  },
  setGoal(state, payload) {
    Vue.set(state, 'goal', payload)
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
