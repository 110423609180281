import isDialogDifferent from '@/store/modules/dialog/helpers'

export default {
  openDialog({ commit }, dialog) {
    commit('setLastFocus', document.activeElement)
    commit('setDialog', dialog)
    commit('setIsDialogOpen', true)
  },
  closeDialog({ commit, state }, dialog = null) {
    if (dialog?.component && isDialogDifferent(state, dialog)) return

    const { lastFocus } = state

    commit('setIsDialogOpen', false)
    commit('setDialog', {})

    if (lastFocus) setTimeout(() => lastFocus.focus(), 0)

    commit('setLastFocus', null)
  },
}
