<template>
  <div
    v-if="show"
  >
    <div class="snackbar">
      <div
        class="snackbar-snack"
        :class="{ 'snackbar-snack--has-action': hasAction }"
      >

        <div
          ref="snackbarContent"
          class="snackbar-content"
        >
          <slot name="content"/>
        </div>

        <div class="snackbar-action">
          <slot name="action"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      hasAction: {
        type: Boolean,
        required: false,
        default: false,
      },
      isOpen: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    watch: {
      isOpen() {
        if (!this.isOpen) {
          this.show = false
          return
        }

        this.show = true
      },
    },

    data() {
      return {
        show: false,
      }
    },
  }
</script>

<style lang="scss">
  .snackbar {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: $footer-height + 2rem;
    left: 0;
    z-index: z-index(dialog);
    @include min-lg {
      bottom: $footer-height--lg + 2rem;
    }
    &-snack {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      min-height: 4.8rem;
      padding: 0.6rem 1.6rem;
      color: color(_white);
      font-size: 1.4rem;
      background-color: color(snackbar-background);
      box-shadow: box-shadow(snackbar);
      @include min-sm {
        width: auto;
        min-width: 28.8rem;
        max-width: 56.8rem;
        border-radius: 2px;
      }
      &--has-action {
        padding: 0.6rem 0.8rem;
      }
    }
    .button {
      margin: 0 0 0 0.8rem;
      padding: 0;
    }
  }
</style>
