<script>
  import requireImage from '@/helpers/requireImage'

  export default {
    functional: true,

    render(h, { props: { title = '', description = '', testid = 'content-navigation-end' } }) {
      return (
        <div
          class="content-navigation-end"
          data-testid={testid}
        >
          <div class="content-navigation-end__content">
            <p class="content-navigation-end__title">
              { title }
            </p>
            <p class="content-navigation-end__description">
              { description }
            </p>
          </div>
          <div class="content-navigation-end__icon">
            <img
              alt=""
              src={requireImage('content-navigation/complete.png')}
            />
          </div>
        </div>
      )
    },
  }
</script>

<style lang="scss">
  .content-navigation-end {
    display: flex;
    margin: 0 0 2rem;
    &__content {
      margin: 0 1rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__title {
      font-size: 1.8rem;
      font-weight: 800;
      text-transform: uppercase;
    }
    &__description {
      font-size: 1.4rem;
      font-weight: 600;
    }
    &__icon {
      width: 4.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @include min-sm {
      margin: 0.6rem 0 0;
    }
  }
</style>
