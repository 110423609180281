class ExpiredInvitationTokenError extends Error {
  constructor() {
    super(
      'extensions.error.expired-invitation-token-error.message',
    )

    this.title = 'Link expired'
  }
}

export default ExpiredInvitationTokenError
