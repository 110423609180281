<script>
  import { isObject } from 'lodash'

  import Spinner from '@/components/spinner/Spinner'

  import { partitionAttrs } from '@/helpers'

  const MODIFIERS = [
    'align-start',
    'align-end',
    'bordered',
    'danger',
    'large',
    'light',
    'no-bg',
    'no-margin',
    'no-min-width',
    'no-padding',
    'round',
    'small',
  ]

  export default {
    functional: true,

    props: {
      describedBy: {
        type: String,
        default: null,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
      link: {
        type: [Object, String],
        required: false,
        default: null,
      },
      tag: {
        type: String,
        default: 'button',
      },
      type: {
        type: String,
        required: false,
        default: 'button',
      },
      light: {
        type: Boolean,
        required: false,
        default: false,
      },
      customColor: {
        type: String,
        required: false,
        default: '',
      },
    },

    render(h, { props, children, data, listeners }) {
      const { classes, newAttrs } = partitionAttrs({
        attrs: data.attrs,
        baseClass: 'button',
        modifiers: MODIFIERS,
      })

      const isRouterLink = isObject(props.link)
      const link = props.link || newAttrs?.href

      const tag = link && !props.isDisabled
                    ? isRouterLink
                      ? 'router-link'
                      : 'a'
                    : props.tag

      const linkAttrs = tag == 'a'
                          ? { href: props.link, target: '_blank' }
                          : {}

      const spinner = props.isLoading
                        ? h('div', { staticClass: 'button-spinner' }, [h(Spinner)])
                        : null
      const disabled = tag == 'button' ? (props.isDisabled || props.isLoading) : null

      const testids = ['base-button']
        .concat(data.attrs?.['data-testid'], props.dataTestid)
        .filter(val => val)
        .join(' ')

      const otherClass = []
      if (props.light) {
          otherClass.push('button--light')
      }
      if (props.customColor) {
        otherClass.push('button--custom-color')
      }
      return h(tag, {
        ...data,
        staticClass: [data.staticClass, ...otherClass, ...classes].join(' ').trim(),
        style: props.customColor ? { backgroundColor: `${props.customColor} !important` } : {},
        attrs: {
          disabled,
          'aria-describedby': props.describedBy,
          type: link ? null : props.type,
          ...linkAttrs,
          ...newAttrs,
          'data-testid': testids,
        },
        props: {
          to: props.link && isRouterLink ? props.link : null,
        },
        on: {
          ...listeners,
        },
      }, [
        h('span', {
          staticClass: 'button-text',
          class: {
            'button-text--is-hidden': props.isLoading,
          },
        }, children),
        spinner,
      ])
    },
  }
</script>

<style lang="scss">
  .button {
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    min-width: 16.0rem;
    height: 4rem;
    margin: 0.6rem 0.8rem;
    padding: 0 2rem 0;
    position: relative;
    z-index: z-index(base);
    color: color(button-text);
    font-size: 1.4rem;
    font-weight: 800;
    vertical-align: middle;
    text-decoration: none;
    user-select: none;
    border-radius: 2rem;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      left: 0;
      z-index: z-index(under-base);
      background-color: color(button-background);
      transition:
        color 1ms,
        background-color 0.15s,
        transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.575);
      transform: scale(1);
      border-radius: 2rem;
      box-shadow: box-shadow(button);
    }
    &--custom-color {
      &::before {
        background-color: unset;
      }
    }
    @include min-lg {
      &:hover {
        color: color(button-hover-text);
        &::before {
          background-color: color(button-hover-background);
        }
      }
    }
    &:active {
      color: color(button-active-text);
      &::before {
        transform: scale(0.95);
        background-color: color(button-active-background);
      }
      path {
        fill: color(button-active-text);
      }
    }
    &:disabled {
      color: color(_white);
      cursor: default;
      pointer-events: none;
      fill: color(_white);
      &::before {
        transition: none;
        background-color: color(dark-grey);
      }
      .spinner {
        &-path {
          stroke: color(_white);
        }
      }
    }
    &--large {
      min-width: 20.0rem;
      height: 5rem;
    }
    &--small {
      min-width: 12.0rem;
      height: 3rem;
      .button-text {
        font-size: 1.2rem;
      }
    }
    &--no-padding {
      padding: 0;
    }
    &--no-margin {
      margin: 0;
    }
    &--no-min-width {
      min-width: 0;
    }
    &--light {
      color: color(dark-primary);
      &::before {
        border: 1px solid color(dark-primary);
        background-color: color(_white);
      }
      @include min-lg {
        &:hover {
          color: color(button-hover-text);
          &::before {
            background-color: color(light-grey);
          }
        }
      }
      &:active {
        color: color(button-active-text);
        &::before {
          background-color: color(dark-primary);
        }
      }
      &:disabled {
        color: color(dark-grey);
        fill: color(dark-grey);
        &::before {
          border: 1px solid color(dark-grey);
          background-color: color(light-grey);
        }
      }
    }
    &--danger {
      color: color(button-text);
      &::before {
        background-color: color(error);
      }
      @include min-lg {
        &:hover::before {
          background-color: color(dark-error);
        }
      }
    }
    &--no-bg {
      color: color(dark-primary);
      &::before,
      &:hover::before,
      &:active::before,
      &:disabled::before {
        background-color: transparent;
        box-shadow: none;
      }
      &:disabled {
        color: color(grey);
      }
    }
    &--align-start {
      align-self: flex-start;
    }
    &--align-end {
      align-self: flex-end;
      margin-left: auto;
    }
    &--bordered {
      color: color(dark-grey);
      font-weight: 400;
      text-transform: none;
      &:active {
        color: color(dark-grey);
      }
      &::before,
      &:hover::before,
      &:active::before {
        border: 1px solid color(dark-grey);
        background: color(_white);
        box-shadow: none;
      }
      &:disabled {
        color: color(light-grey);
        fill: color(light-grey);
        &::before {
          border-color: color(light-grey);
        }
      }
      .button-text {
        font-weight: 400;
      }
    }
    &--round {
      width: 3.4rem;
      min-width: 0;
      height: 3.4rem;
      padding: 0;
      border-radius: 50%;
    }
    &-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      &--is-hidden {
        visibility: hidden;
        opacity: 0;
      }
    }
    &-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      &::before {
        background-color: inherit;
      }
    }
    .spinner {
      width: 2rem;
      height: 2rem;
    }
  }
  .is-tab {
    .button {
      &:focus {
        outline: none;
        box-shadow: none;
        &::before {
          box-shadow: 0 0 0 5px color(accent);
        }
      }
    }
  }
</style>
