<template>
  <div id="app">
    <transition-fade
      :isOutIn="false"
    >
      <preloader
        v-if="isBootstrapping"
        :key="isBootstrapping"
      />

      <blocked-app
        v-else-if="isAppBlocked"
        :key="isAppBlocked"
      />

      <base-view
        v-else
        :key="!isAppBlocked"
      />
    </transition-fade>

    <overlay/>
    <dialogs/>
    <snackbars/>
    <portal-targets/>
    <vue-announcer/>
  </div>
</template>

<script>
  import { setUser } from '@sentry/browser'
  import cssVars from 'css-vars-ponyfill'
  import { debounce, includes } from 'lodash'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import { BaseView } from '@/components/base'
  import BlockedApp from '@/components/layout/blocked-app/BlockedApp'
  import Dialogs from '@/components/layout/dialogs/Dialogs'
  import Overlay from '@/components/layout/overlay/Overlay'
  import { PortalTargets } from '@/components/layout/portal-targets'
  import Preloader from '@/components/layout/preloader/Preloader'
  import Snackbars from '@/components/layout/snackbars/Snackbars'

  import logoutAndOpenDialog from '@/helpers/logoutAndOpenDialog'

  import blockerGuard from '@/mixins/blockerGuard'
  import completeBlockedPlugin from '@/mixins/completeBlockedPlugin'
  import detectBrowser from '@/mixins/detectBrowser'
  import handleLegalDocuments from '@/mixins/handleLegalDocuments'

  import loading from '@/store/helpers/loading'

  import healthKitManager from '@/tools/healthKitManager'
  import pushSubscribe from '@/tools/push-subscribe'

  import TransitionFade from '@/transitions/transition-fade/TransitionFade'

  export default {
    name: 'App',

    metaInfo() {
      return {
        title: 'Changing Health',
        titleTemplate: '%s',
      }
    },

    components: {
      BaseView,
      BlockedApp,
      Dialogs,
      Overlay,
      PortalTargets,
      Preloader,
      Snackbars,
      TransitionFade,
    },

    mixins: [
      blockerGuard,
      completeBlockedPlugin,
      detectBrowser,
      handleLegalDocuments,
    ],

    created() {
      this.init()
    },

    mounted() {
      this.guard(this.$route)
      this.addRouteToHistory()
      this.toggleEventListeners('add')
      this.setComplementRoute()
      this.detectPWA()

      if (process.env.NODE_ENV === 'production') {
        this.initializeAnalytics()
      }
    },

    beforeDestroy() {
      this.toggleEventListeners('remove')
      healthKitManager.kill()
    },

    watch: {
      $route(to) {
        this.$nextTick(() => this.guard(to))
        this.addRouteToHistory()
        this.fetchCurrentProgram()
        this.setColors()
      },
      blockedPlugin: {
        handler() {
          this.afterEachRoute()
        },
      },
      isUserAndProgramLoaded: {
        handler() {
          if (!this.isUserAndProgramLoaded) return this.setCanBeDispatched(false)

          this.setUserData({
            program: this.program,
            user: this.user,
          }).then(() => {
            this.setCanBeDispatched(true)
            this.dispatchAll()
          })

          this.fetchCoach({
            programId: this.program.id,
            userId: this.user.id,
          })
            .then(() => this.fetchAppointments({
                          coachId: this.coach.id,
                          userId: this.user.id,
                          silence: true,
                        }))
            .catch(() => {})
        },
        immediate: true,
      },
      shouldRedirectToBlockedPlugin: {
        handler() {
          if (!this.shouldRedirectToBlockedPlugin) return

          this.redirectToBlockedPlugin()
        },
        immediate: true,
      },
      colors(colors) {
        if (window.StatusBar && colors['color-dark-primary']) {
          window.StatusBar.backgroundColorByHexString(colors['color-dark-primary'])
        }
      },
      getPPUid: {
        handler() {
          const user = this.getPPUid ? { id: this.getPPUid } : null
          setUser(user)
        },
        immediate: true,
      },
      shouldLetUserIn: {
        handler() {
          if (!this.shouldLetUserIn) return

          loading('auth/authGuard', false)
          this.redirectIfNecessary()
          this.registerPushNotificationService()
        },
        immediate: true,
      },
      hasAnalyticsInitialized: {
        handler() {
          if (this.hasAnalyticsInitialized) {
            if (process.env.NODE_ENV === 'production') {
              if (this.os === 'ios') {
                this.sendAnalyticsEvent('ios_bookmark_login')
              } else if (this.os === 'android') {
                this.sendAnalyticsEvent('android_bookmark_login')
              }
            }
          }
        },
        immediate: true,
      },
    },

    data() {
      return {
        resizeHandler: debounce(this.setWindowWidth, 200),
      }
    },

    methods: {
      ...mapActions('bootstrap', [
        'preBootstrap',
      ]),
      ...mapActions('coaching', [
        'fetchAppointments',
        'fetchCoach',
      ]),
      ...mapMutations('blockedPlugin', [
        'setBeforeBlockedPluginRoute',
      ]),
      ...mapActions('multiAnalytics', [
        'setMa',
        'setMam',
        'setUserData',
        'setCanBeDispatched',
        'dispatchAll',
      ]),
      ...mapActions('program', [
        'fetchProgram',
      ]),
      ...mapMutations('history', [
        'push',
      ]),
      ...mapMutations('languages', [
        'setCurrentUserLanguage',
      ]),
      ...mapMutations('layout', [
        'set',
      ]),
      ...mapActions('analytics', [
        'initializeAnalytics',
        'sendAnalyticsEvent',
      ]),
      fetchCurrentProgram() {
        if (!this.token
          || !this.currentProgramId
          || !this.$route.meta.fetchProgram
        ) return

        this.fetchProgram(this.currentProgramId)
      },
      afterEachRoute() {
        this.completeBlockedPlugin()
        if (this.shouldRedirectToBlockedPlugin) {
          this.redirectToBlockedPlugin()
        } else if (this.hasBlockedPluginRouteWithoutBlocker) {
          this.$router.push(this.beforeBlockedPluginRoute)
          this.setBeforeBlockedPluginRoute(false)
        }
      },
      init() {
        this.setMa(this.$ma)
        this.setMam(this.$mam)
        this.setCurrentUserLanguage({
          language: null,
          priority: 0,
        })

        if (this.token) {
          this.preBootstrap()
            .catch(err => logoutAndOpenDialog('Error', { err }))
        }

        this.setColors()
        this.$router.afterEach(this.afterEachRoute)
        healthKitManager.init()
      },
      addRouteToHistory() {
        this.push({
          state: 'history',
          value: { name: this.$route.name, params: this.$route.params, url: window.location.href },
        })
      },
      toggleEventListeners(action) {
        const events = ['mousedown', 'keydown']

        events.forEach(event => document[`${action}EventListener`](event, this.toggleBodyClass))
        window[`${action}EventListener`]('resize', this.resizeHandler)
        window[`${action}EventListener`]('orientationchange', this.setWindowWidth)
      },
      toggleBodyClass({ keyCode }) {
        const { body } = document
        const navKeyCodes = [13, 27, 32, 37, 38, 39, 40]
        const tabKeyCode = 9

        if (keyCode === tabKeyCode) {
          body.classList.add('is-tab')
        } else if (!includes(navKeyCodes, keyCode)) {
          body.classList.remove('is-tab')
        }
      },
      setColors() {
        if (this.browser != 'ie') return

        cssVars({
          watch: true,
        })
      },
      registerPushNotificationService() {
        if (this.token && navigator.serviceWorker) {
          pushSubscribe()
        }
      },
      redirectToBlockedPlugin() {
        this.$nextTick()
          .then(() => {
            if (!this.blockedPlugin) return

            const { plugin_id: pluginId } = this.blockedPlugin
            if (pluginId && !this.isPluginRoute(pluginId, this.$route)) {
              if (!this.beforeBlockedPluginRoute) {
                this.setBeforeBlockedPluginRoute(this.$route)
              }
              this.$router.push({ name: this.getTriggerRouteByPluginId(pluginId) })
            }
          })
      },
      isPluginRoute(pluginId, route) {
        return route?.path?.includes(this.getTriggerSlugByPluginId(pluginId))
      },
      redirectIfNecessary() {
        if (this.hasCurrentUserUncompletedProfile) {
          return this.$router.push({ name: 'AuthSignUp' })
        }

        if (this.$route.name?.includes('Auth')) {
          return this.$router.push({ name: 'Home' })
        }
      },
      detectPWA() {
        if (window.navigator.standalone
          || window.matchMedia('(display-mode: standalone)').matches) {
          this.set({ state: 'isPWA', value: true })
        }
      },
      setWindowWidth() {
        this.set({ state: 'wW', value: window.innerWidth })
      },
      setComplementRoute() {
        this.$announcer.setComplementRoute(this.$t('has loaded'))
        this.$el.querySelector('#announcer').setAttribute('aria-hidden', true)
      },
    },

    computed: {
      ...mapGetters('colors', [
        'colors',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('_package', [
        'hasPackage',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('legalDocument', [
        'hasLegalDocumentToAccept',
      ]),
      ...mapGetters('triggers', [
        'getTriggerRouteByPluginId',
        'getTriggerSlugByPluginId',
        'getTriggerByPluginId',
        'isTriggerUpdating',
      ]),
      ...mapGetters('user', [
        'hasCurrentUserEnabledPackage',
        'hasCurrentUserUncompletedProfile',
        'getPPUid',
      ]),
      ...mapState('bootstrap', [
        'hasInitialized',
      ]),
      ...mapState('user', [
        'user',
      ]),
      ...mapState('program', [
        'program',
      ]),
      ...mapState('auth', [
        'isCurrentUserAuthorized',
        'token',
      ]),
      ...mapState('blockedPlugin', [
        'blockedPlugin',
        'beforeBlockedPluginRoute',
      ]),
      ...mapState('layout', [
        'browser',
        'os',
      ]),
      ...mapState('coaching', [
        'coach',
      ]),
      ...mapState('analytics', [
        'hasAnalyticsInitialized',
      ]),
      isAppBlocked() {
        // Hiding legacy legal documents on first login
        // return this.hasLegalDocumentToAccept
        return false
      },
      isBootstrapping() {
        return this.getLoadingStatesForActions([
          'auth/authGuard',
          'bootstrap/bootstrap',
          'bootstrap/preBootstrap',
          'bootstrap/init',
          'router/pluginsGuardWithBootstrap',
          'program/changeCurrentProgram',
          'coaching/fetchAppointments',
          'coaching/fetchCoach',
        ]) || !this.hasInitialized
      },
      shouldLetUserIn() {
        return this.isCurrentUserAuthorized && this.hasCurrentUserEnabledPackage
      },
      isUserAndProgramLoaded() {
        return !!this.program
          && !!this.user
          && !!this.user?.email
          && this.hasPackage
          && this.program?.id
      },
      shouldRedirectToBlockedPlugin() {
        return this.blockedPlugin
          /* eslint-disable-next-line camelcase */
          && this.getTriggerByPluginId(this.blockedPlugin?.plugin_id)
          /* eslint-disable-next-line camelcase */
          && !this.isTriggerUpdating(this.blockedPlugin?.plugin_id)
      },
      hasBlockedPluginRouteWithoutBlocker() {
        return this.beforeBlockedPluginRoute && !this.blockedPlugin
      },
    },
  }
</script>

<style lang="scss">
  @import './assets/styles/main.scss';

  :root {
    @each $name, $color in $colors {
      --color-#{$name}: #{$color};
    }
  }
  #app {
    height: 100%;
  }
</style>
