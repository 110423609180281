import normalize from '@/store/helpers/normalize'

const normalizeCategories = categories => (
  categories.reduce((acc, curr) => {
    const { ids, items } = normalize(curr.extended_items)

    Object.assign(acc.categories, {
      [curr.id]: {
        ...curr,
        items,
      },
    })
    Object.assign(acc.categoriesItemsIds, { [curr.id]: ids })
    Object.assign(acc.categoriesItemsOrder, {
      [curr.id]: curr.extended_items.map(({ id }) => id),
    })

    return acc
  }, { categories: {}, categoriesItemsIds: {}, categoriesItemsOrder: {} })
)

export default normalizeCategories
