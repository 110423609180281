import Vue from 'vue'

import initialState from './state'

export default {
  setProblemCreatedDate(state, date) {
    state.problemCreatedDate = date
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
