<template>
  <transition
    :name="$static.block"
    :mode="'out-in'"
    v-bind="{
      enterClass,
      leaveActiveClass,
    }"
    @beforeEnter="onBeforeEnter"
    @afterEnter="onAfterEnter"
  >
    <slot default/>
  </transition>
</template>

<script>
  import scrollToTop from '@/mixins/scrollToTop'

  import eventBus from '@/tools/event-bus'

  export default {
    mixins: [
      scrollToTop,
    ],

    created() {
      eventBus.$on(this.$static.eventPrevious, this.animatePrevious)
      eventBus.$on(this.$static.eventNext, this.animateNext)
    },

    beforeDestroy() {
      eventBus.$off(this.$static.eventPrevious, this.animatePrevious)
      eventBus.$off(this.$static.eventNext, this.animateNext)
    },

    data() {
      return {
        reverse: null,
      }
    },

    methods: {
      animatePrevious(to) {
        this.reverse = true
        this.$router.push(to)
      },
      animateNext(to) {
        this.reverse = false
        this.$router.push(to)
      },
      onBeforeEnter() {
        this.scrollToTop()
      },
      onAfterEnter() {
        this.reverse = null
      },
    },

    computed: {
      enterClass() {
        if (this.reverse === null) return

        return `${this.$static.block}-enter ${this.reverse
          ? `${this.$static.block}-left`
          : `${this.$static.block}-right`
        }`
      },
      leaveActiveClass() {
        if (this.reverse === null) return

        return `${this.$static.block}-leave-active ${this.reverse
          ? `${this.$static.block}-right`
          : `${this.$static.block}-left`
        }`
      },
    },

    static() {
      return {
        block: 'transition-article',
        eventPrevious: 'transition/article/previous',
        eventNext: 'transition/article/next',
      }
    },
  }
</script>

<style lang="scss">
  $block: 'transition-article';
  $distance: 10rem;
  $time: 0.5s;

  .#{$block}-enter-active, .#{$block}-leave-active {
    transition: opacity $time, transform $time;
  }
  .#{$block}-enter, .#{$block}-leave-to {
    opacity: 0;
  }
  .#{$block}-enter-to, .#{$block}-leave {
    opacity: 1;
    transform: translateX(0);
  }
  .#{$block}-right {
    transform: translateX($distance);
  }
  .#{$block}-left {
    transform: translateX(-$distance);
  }
</style>
