import loading from '@/store/helpers/loading'

import { api } from '@/tools/api'

export default {

  unsubscribe({ commit }, { email, type, expiry, hash }) {
    const action = 'unsubscribe'

    loading(action, true)

    return new Promise((resolve, reject) => {
      api.get(`api/user/unsubscribe?email=${encodeURIComponent(email)}&type=${type}&expiry=${expiry}&hash=${hash}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => loading(action, false))
    })
  },
}
