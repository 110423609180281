export const addManyNewLegalDocuments = (data, ctx) => {
  const { ids } = data

  ctx.dispatch('fetchLegalDocumentsByIds', {
    document_ids: Object.values(ids),
  })
}

export const addNewLegalDocument = (data, ctx) => {
  const { id } = data

  ctx.dispatch('fetchLegalDocument', { id })
}

export const removeLegalDocument = ({ tc_id: id }, ctx) => {
  ctx.commit('removeLegalDocument', id)
}
