<template>
  <base-dialog
    class="package-not-enabled-dialog"
    data-testid="dialog-user-deleted"
    :headerText="$t(`${$options.slug}.title`)"
  >
    {{ $t(`${$options.slug}.content-1`) }} <br>
    {{ $t(`${$options.slug}.content-2`) }}
    <a href="mailto:customersupport@changinghealth.com">
      customersupport@changinghealth.com
    </a>

    <template #action>
      <base-button
        data-testid="dialog-user-deleted-close"
        @click="closeDialog"
      >
        {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },

    slug: 'component.dialog.user-deleted-dialog',
  }
</script>
