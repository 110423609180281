import moment from 'moment'

const slug = 'store.modules.coaching'

export default {
  /* eslint-disable-next-line global-require */
  coachImage: state => state?.coach?.icon?.url || require('@/assets/img/coach.png'),
  getAppointmentsByType: state => {
    if (!state.appointments) return null

    return [
      {
        name: 'upcoming',
        title: `${slug}.upcoming`,
        hasAction: true,
        isUpcoming: true,
        appointments: state.appointments
          .filter(a => !a.cancelled
            && moment.utc(a.to).isAfter(moment(), 'second')),
      },
      {
        name: 'past-cancelled',
        title: `${slug}.past`,
        hasAction: true,
        isUpcoming: false,
        appointments: state.appointments
          .filter(a => a.cancelled
            || moment.utc(a.to).isBefore(moment(), 'second'))
          .sort((a, b) => moment(a.to).valueOf() - moment(b.to).valueOf()),
      },
    ]
  },
  getContentItemById: state => id => {
    if (!state.content) return null

    return state.content.find(item => item.id == id)
  },
  getItemIndex: state => id => state
    .content?.map(item => String(item.id)).indexOf(String(id)),
  getItemOffset: (state, getters) => (id, offset) => {
    const index = getters.getItemIndex(id)

    if (index === -1) return

    return state.content?.[index + offset]
  },
  getTimeRange: () => (since, to) => `${moment(since).utc().format('H:mm')} - ${moment(to).utc().format('H:mm')}`,
}
