export default {
  invokePausedRequests({ commit, getters }) {
    const requests = getters.getPausedRequests()

    requests.forEach(({ deferred, promise }) => {
      promise()
        .then(response => {
          deferred.resolve(response)
        })
        .catch(err => {
          deferred.reject(err)
        })
    })
  },
}
