import loading from '@/store/helpers/loading'
import { api } from '@/tools/api'

export default {
  fetchQuestions({ commit }, articleId) {
    loading('quickQuestions/fetchQuestions', true)

    return new Promise((resolve, reject) => {
      api.get(`api/article/${articleId}/quick-questions`)
        .then(response => {
          commit('saveQuestions', { questions: response.quick_questions, articleId })

          resolve()
        })
        .catch(reject)
        .finally(() => loading('quickQuestions/fetchQuestions', false))
    })
  },
  saveResponseQuestion({ commit }, { questionId, answers }) {
    loading('quickQuestions/saveResponseQuestion', true)

    const answersIds = answers.map(a => a.id)

    return new Promise((resolve, reject) => {
      api.post(`api/quick-questions/${questionId}/user-answers`, { question_answers: answersIds })
        .then(() => {
          resolve()
        })
        .catch(reject)
        .finally(() => loading('quickQuestions/saveResponseQuestion', false))
    })
  },
  updateResponseQuestion({ commit }, { questionId, answers }) {
    loading('quickQuestions/updateResponseQuestion', true)

    const answersIds = answers.map(a => a.id)

    return new Promise((resolve, reject) => {
      api.patch(`api/quick-questions/${questionId}/user-answers`, { question_answers: answersIds })
        .then(() => {
          resolve()
        })
        .catch(reject)
        .finally(() => loading('quickQuestions/updateResponseQuestion', false))
    })
  },
  getResponseQuestion({ commit }, questionId) {
    loading('quickQuestions/getResponseQuestion', true)

    return new Promise((resolve, reject) => {
      api.get(`api/quick-questions/${questionId}/user-answers`)
        .then(response => {
          const answerIds = response.user_quick_question_answers
            // eslint-disable-next-line camelcase
            ?.map(({ question_answer_id }) => question_answer_id) || []

          resolve(answerIds)
        })
        .catch(reject)
        .finally(() => loading('quickQuestions/getResponseQuestion', false))
    })
  },
}
