import { merge } from 'lodash'

import modules from './modules'
import { importView } from '../helpers'

const setRouteComponent = (route, dir) => {
  route.component = importView(`${dir}${route.component || route.name}`)
}

const setRouteChildrenMeta = (children, meta, dir) => {
  if (!children) return

  children.forEach(child => {
    setRouteComponent(child, dir)

    const { meta: childMeta = {} } = child
    child.meta = merge(childMeta, meta, { dir })
  })

  return setRouteChildrenMeta(children.children, meta, dir)
}

const routes = modules.concatMap(({ properties = {}, routes: r }) => {
  const { dir = '', isLazy = true, sharedMeta = {} } = properties

  return r.reduce((acc, curr) => {
    const { meta = {} } = curr

    if (isLazy) {
      setRouteComponent(curr, dir)
      setRouteChildrenMeta(curr.children, meta, dir)
    }

    curr.meta = merge(meta, sharedMeta, { dir })

    return [...acc, curr]
  }, [])
})

routes.push({
  path: '*',
  redirect: { name: 'Home' },
})

export default routes
