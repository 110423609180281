export default {
  namespaced: true,
  state: {
    title: '',
    topic: '',
    category: '',
  },
  getters: {
    title: state => state.title,
    category: state => state.category,
    topic: state => state.topic,
  },
  mutations: {
    setBreadcrumbTitle(state, title) {
      state.title = title
    },
    setBreadcrumbCategory(state, category) {
      state.category = category
    },
    setBreadcrumbTopic(state, topic) {
      state.topic = topic
    },
    resetBreadcrumbs(state) {
      state.title = ''
      state.category = ''
      state.topic = ''
    },
  },
  actions: {
    setTitle({ commit }, title) {
      commit('setBreadcrumbTitle', title)
    },
    setCategory({ commit }, category) {
      commit('setBreadcrumbCategory', category)
    },
    setTopic({ commit }, topic) {
      commit('setBreadcrumbTopic', topic)
    },
    resetTitle({ commit }) {
      commit('resetBreadcrumbs')
    },
  },
}
