import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/setting-the-strongest-plan/',
    sharedMeta: {
      fetchProgram: false,
      header: 'Setting The Strongest Plan',
      layout: 'home',
      toParent: true,
      trigger: 'setting-the-strongest-plan',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'SettingTheStrongestPlan',
    },
  },
  routes: [
    {
      path: '/setting-the-strongest-plan',
      name: 'SettingTheStrongestPlan',
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
