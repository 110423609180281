import { isNil } from 'lodash'
import moment from 'moment'
import { Validator } from 'vee-validate'

const preventFutureDate = {
  getMessage: () => 'You cannot select a future date.',
  validate(date) {
    const { day, month, year } = date || {}

    if (isNil(day) || isNil(month) || isNil(year)) return true

    const newDate = moment().year(year).month(month).date(day)

    return moment().isAfter(newDate)
  },
}

Validator.extend('prevent_future_date', preventFutureDate)
