import { PAGE_TYPES } from '@/constants'

export default {
  properties: {
    dir: 'plugins/eq-5d/',
    sharedMeta: {
      fetchProgram: false,
      header: 'EQ-5D Health Questionnaire',
      layout: 'home',
      toParent: true,
      trigger: 'eq-5d-health-questionnaire',
      type: PAGE_TYPES.plugin,
      unblockPluginAt: 'Eq5dThankYou',
    },
  },
  routes: [
    {
      path: '/eq-5d-health-questionnaire',
      name: 'Eq5d',
      meta: {
        parent: 'Tools',
      },
    },
    {
      path: '/eq-5d-health-questionnaire/questionnaire',
      name: 'Eq5dQuestionnaire',
      props: true,
      meta: {
        parent: 'Eq5d',
      },
    },
    {
      path: '/eq-5d-health-questionnaire/health',
      name: 'Eq5dHealth',
      props: true,
      meta: {
        parent: 'Eq5d',
      },
    },
    {
      path: '/eq-5d-health-questionnaire/thank-you',
      name: 'Eq5dThankYou',
      props: true,
      meta: {
        parent: 'Tools',
      },
    },
  ],
}
