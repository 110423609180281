<script>
  import FocusLock from 'vue-focus-lock'

  import { SpinnerDots } from '@/components/spinner'

  export default {
    functional: true,

    render() {
      return (
        <div class="preloader">
          <FocusLock>
            <SpinnerDots/>
          </FocusLock>
        </div>
      )
    },
  }
</script>

<style lang="scss">
  .preloader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: color(_white);
    z-index: z-index(preloader);
    & div[tabindex]:focus {
      outline: none;
      box-shadow: none;
    }
  }
</style>
