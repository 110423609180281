import router from '@/router'

import rootDispatch from '@/store/helpers/rootDispatch'

export default (ctx, store, echo) => {
  let uid

  const _updateUid = newUid => {
    if (uid != newUid) {
      if (uid) {
        echo.leave(`App.Entities.UserhubUser.${uid}`)
        echo.leave(`global.${uid}`)
      }

      if (newUid) {
        echo.private(`App.Entities.UserhubUser.${newUid}`)
          .notification(e => {
            ctx.commit('notificationReceived', e)
          })

        echo.private(`global.${newUid}`)
          .listen('.logout', () => {
            rootDispatch('dialog/openDialog', { component: 'UserDeleted' })
            rootDispatch('auth/logout')
            router.push({ name: 'AuthLogin' })
          })
      }

      uid = newUid
    }
  }

  store.subscribe(mutation => {
    const { type } = mutation
    if (type == 'auth/logout'
      || type == 'auth/resetState'
      || type == 'user/decodeUser'
      || type == 'user/setUser') {
      _updateUid(store.getters['user/getCurrentUserId'])
    }
  })

  _updateUid(store.getters['user/getCurrentUserId'])
}
