<template>
  <base-dialog
    data-testid="dialog-error"
    :headerText="err.title"
  >
    <p
      class="dialog-text"
      data-testid="dialog-error-message"
      v-html="err.message"
    />

    <template #action>
      <base-button
        data-testid="dialog-error-close"
        @click="closeDialog"
      >
        {{ $t('Close') }}
      </base-button>
    </template>
  </base-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseDialog,
  } from '@/components/base'

  export default {
    components: {
      BaseButton,
      BaseDialog,
    },

    props: {
      err: {
        type: Error,
        required: true,
      },
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
    },
  }
</script>

<style lang="scss">
  .dialog.error-dialog {
    min-width: 90%;
    max-width: none;
    @media all and (min-width: map-get($breakpoints, sm)) {
      min-width: 0;
      max-width: 45rem;
    }
  }
  .error-dialog {
    a {
      color: color(dark-primary);
      text-decoration: underline;
    }
  }
</style>
